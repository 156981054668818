export const listData = [
  {
    tabName: "Top Gainers",
    exchange: 2,
    segment: 1,
    secIdxCode: 13,
    typeOfFlag: "G",
    requestCode: 120,
    instrument: "EQ",
    apiPayload: "Gain",
  },
  {
    tabName: "Top Losers",
    exchange: 1,
    segment: 1,
    secIdxCode: 13,
    typeOfFlag: "L",
    requestCode: 120,
    instrument: "EQ",
    apiPayload: "lose",
  },
  {
    tabName: "52W High",
    exchange: 1,
    segment: 1,
    secIdxCode: 13,
    typeOfFlag: "H",
    requestCode: 100,
    instrument: "EQUITY",
    apiPayload: "FiftyTwoWeekHigh",
  },
  {
    tabName: "52W Low",
    exchange: 1,
    segment: 1,
    secIdxCode: 13,
    typeOfFlag: "L",
    requestCode: 100,
    instrument: "EQUITY",
    apiPayload: "FiftyTwoWeekLow",
  },
  {
    tabName: "By Value",
    exchange: 1,
    segment: 1,
    secIdxCode: 13,
    typeOfFlag: "",
    requestCode: 165,
    instrument: "EQ",
    apiPayload: "ValueToppers",
  },
  {
    tabName: "Top By Volume",
    exchange: 1,
    segment: 1,
    secIdxCode: 13,
    typeOfFlag: "",
    requestCode: 121,
    instrument: "EQ",
    apiPayload: "VolumeToppers",
  },
];

export const exchangeObject = [
  {
    name: "NSE",
    id: 0,
    data: [
      {
        buttonName: "NIFTY MIDCAP 150",
        secIdxCode: 1,
        exchange: 1,
        segment: 1,
      },
      {
        buttonName: "NIFTY",
        secIdxCode: 13,
        exchange: 1,
        segment: 1,
      },
      {
        buttonName: "NIFTY 100",
        secIdxCode: 17,
        exchange: 1,
        segment: 1,
      },
      {
        buttonName: "BANKNIFTY",
        secIdxCode: 25,
        exchange: 1,
        segment: 1,
      },
      {
        buttonName: "NIFTY NEXT 50",
        secIdxCode: 38,
        exchange: 1,
        segment: 1,
      },
    ],
  },
  {
    name: "BSE",
    id: 1,
    data: [
      {
        buttonName: "SMLCAP",
        secIdxCode: 82,
        exchange: 2,
        segment: 4,
      },
      {
        buttonName: "MIDCAP",
        secIdxCode: 64,
        exchange: 2,
        segment: 4,
      },
      {
        buttonName: "SENSEX",
        secIdxCode: 51,
        exchange: 2,
        segment: 4,
      },
      {
        buttonName: "BSE100",
        secIdxCode: 65,
        exchange: 2,
        segment: 4,
      },
      {
        buttonName: "BANKEX",
        secIdxCode: 69,
        exchange: 2,
        segment: 4,
      },
    ],
  },
];

export const lCategoryList = [
  {
    name: "Account Opening",
    value: "Account Opening",
    subCat: [
      { subCatName: "New Account Opening", value: "NAO" },
      { subCatName: "Product Information", value: "PI" },
      { subCatName: "Account opening fund transfer status", value: "AOFTS" },
      { subCatName: "Status of account opening application", value: "SAOA" },
      { subCatName: "Others", value: "OTH" },
    ],
  },
  {
    name: "Back Office",
    value: "Back Office",
    subCat: [
      { subCatName: "Activation of Physical Contract Notes", value: "APCN" },
      { subCatName: "Brokerage Charges", value: "BC" },
      { subCatName: "Charges", value: "CH" },
      { subCatName: "Contract Notes", value: "CN" },
      {
        subCatName: "Deactivation of Physical Contract Notes",
        value: "DAOPCN",
      },
      { subCatName: "Financial Statements Query", value: "FSQ" },
      { subCatName: "Financial Statements Request", value: "FSR" },
      { subCatName: "Stock Holdings", value: "SH" },
      { subCatName: "TDS Certificate", value: "TC" },
      { subCatName: "Others", value: "OTH" },
    ],
  },
  {
    name: "Customer Services",
    value: "Customer Services",
    subCat: [
      { subCatName: "Complaint Against Dealer", value: "CAD" },
      { subCatName: "Complaint Against Services", value: "CAS" },
      { subCatName: "Others", value: "OTH" },
    ],
  },
  {
    name: "Depository",
    value: "Depository",
    subCat: [
      { subCatName: "Bonus Shares/Dividend", value: "BS" },
      { subCatName: "Client Master", value: "CM" },
      { subCatName: "Delivery Instruction Booklet", value: "DIB" },
      { subCatName: "Dematerialisation of Shares", value: "DOS" },
      { subCatName: "DP Charges", value: "DC" },
      { subCatName: "DP Holding Statement", value: "DPHS" },
      { subCatName: "DP Holding Transaction Statement", value: "DPHTS" },
      { subCatName: "Nominee", value: "NOM" },
      { subCatName: "Rights Issue", value: "RI" },
      { subCatName: "Share Transfer", value: "ST" },
      { subCatName: "Others", value: "OTH" },
    ],
  },
  {
    name: "Funds",
    value: "Funds",
    subCat: [
      { subCatName: "Bank Details Required", value: "BDR" },
      { subCatName: "Cheque/DD Related", value: "CR" },
      { subCatName: "Fund Transfer", value: "FT" },
      { subCatName: "Fund Withdrawal", value: "FW" },
      { subCatName: "UPI Fund Transfer", value: "UFT" },
      { subCatName: "Third Party Fund Transfer", value: "TPFT" },
      { subCatName: "Others", value: "OTH" },
    ],
  },
  {
    name: "IPO",
    value: "IPO",
    subCat: [
      { subCatName: "IPO Allotment", value: "IA" },
      { subCatName: "New OP Launches", value: "NOL" },
      { subCatName: "Offer for Sale", value: "OFS" },
      { subCatName: "Others", value: "OTH" },
    ],
  },
  {
    name: "Mutual Funds",
    value: "Mutual Funds",
    subCat: [
      { subCatName: "Purchase", value: "PUR" },
      { subCatName: "Systematic Investment", value: "SI" },
      { subCatName: "Queries", value: "Q" },
      { subCatName: "Redemptions", value: "RED" },
      { subCatName: "Statement", value: "S" },
      { subCatName: "Holdings", value: "HO" },
      { subCatName: "Pay In", value: "PI" },
      { subCatName: "Pay Out", value: "PO" },
      { subCatName: "Others", value: "OTH" },
    ],
  },
  {
    name: "NRI",
    value: "NRI",
    subCat: [{ subCatName: "NRI Investment Services", value: "NRI" }],
  },
  {
    name: "Others",
    value: "Others",
    subCat: [{ subCatName: "Others", value: "OTH" }],
  },
  {
    name: "Password",
    value: "Password",
    subCat: [
      { subCatName: "OTP", value: "OTP" },
      { subCatName: "OTP Not Delivered", value: "OTPND" },
      { subCatName: "Q&A Authentication", value: "QA" },
      { subCatName: "Trading Password Unlock &Reset", value: "TPU" },
    ],
  },
  {
    name: "Product Activation",
    value: "Product Activation",
    subCat: [
      { subCatName: "Currency", value: "CURR" },
      { subCatName: "Commodities", value: "COMM" },
      { subCatName: "Mutual Funds", value: "MF" },
      { subCatName: "Others", value: "OTH" },
    ],
  },
  {
    name: "Profile Changes",
    value: "Profile Changes",
    subCat: [
      { subCatName: "Address Change", value: "AC" },
      { subCatName: "Bank Change", value: "BC" },
      { subCatName: "E-mail Change", value: "EC" },
      { subCatName: "Nominee", value: "NOMI" },
      { subCatName: "Phone Number Change", value: "PN" },
      { subCatName: "Others", value: "OTH" },
    ],
  },
  {
    name: "Research",
    value: "Research",
    subCat: [
      { subCatName: "Research Feedback", value: "RF" },
      { subCatName: "Research Query / Support", value: "RQF" },
      { subCatName: "Others", value: "OTH" },
    ],
  },
  {
    name: "Technical Assistance",
    value: "Technical Assistance",
    subCat: [
      { subCatName: "Demo", value: "Demo" },
      { subCatName: "Login Issue", value: "LI" },
      { subCatName: "Mobile Trading", value: "MT" },
      { subCatName: "Web Trading", value: "WT" },
      { subCatName: "Software Trading", value: "ST" },
      { subCatName: "Rates Refresh Problem", value: "RRT" },
      { subCatName: "Trading Issue / Assistance", value: "TI" },
      { subCatName: "Others", value: "OTH" },
    ],
  },
  {
    name: "Trading or Risk",
    value: "Trading or Risk",
    subCat: [
      { subCatName: "Account Suspended", value: "AS" },
      { subCatName: "Limits", value: "L" },
      { subCatName: "Margin / Exposure", value: "ME" },
      { subCatName: "Order Conversion", value: "OC" },
      { subCatName: "Order Rejection", value: "OR" },
      { subCatName: "Order Status", value: "OS" },
      { subCatName: "Position Square Off", value: "PSO" },
      { subCatName: "Span Shortages and Penalties", value: "SSP" },
      { subCatName: "Trade Modification", value: "TM" },
      { subCatName: "Others", value: "OTH" },
    ],
  },
];

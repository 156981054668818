import React from "react";
import { memberDetails } from "../../../utils/Data";

const MemberDetails = ({ isMobile }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: isMobile ? 40 : 10,
        justifyContent: "center",
      }}
    >
      {memberDetails.map((item) => {
        return (
          <div className={isMobile ? "mainareamobile" : "mainarea"}>
            <img src={item.img} alt="Maheshji" />
            <h5>{item.name}</h5>
            <h6>{item.post}</h6>
            <h6 title={item.company}>
              {item.company.length > 35
                ? item.company.substring(0, 35) + "..."
                : item.company}
            </h6>
            <p>{item.bio}</p>
          </div>
        );
      })}
    </div>
  );
};

export default MemberDetails;

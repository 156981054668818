import { ThreeDots } from "react-loader-spinner";
import { Avatar } from "@mui/material";
import { stringAvatar } from "../../../components/common/avatar";
import { useContext } from "react";
import { Context } from "../../../context";
import { useState, useEffect } from "react";
import { getListedIpo } from "../../../utils/APICollection";

const ListedIpo = () => {
  const [listedIpos, setListedIpos] = useState([]);
  const [listedLoading, setListedLoading] = useState(false);
  const isMobile = window.innerWidth <= 1040;

  const getMonth = (date) => {
    const d = new Date(date);
    const monthList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return monthList[d.getMonth()];
  };

  const getFullDate = (date) => {
    const d = new Date(date);
    return d.getDate() + " " + getMonth(date) + " " + d.getFullYear();
  };

  const { exchange } = useContext(Context);

  const fetchListedIpo = async () => {
    setListedLoading(true);
    try {
      const data = await getListedIpo(exchange);
      setListedIpos(data?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setListedLoading(false);
    }
  };

  useEffect(() => {
    fetchListedIpo();
  }, [exchange]);
  return (
    <div className="ipostn">
      <div className="ipotitle titleipo">
        <h4>Listed</h4>
      </div>
      {/* <div className={isMobile ? "" : "ipotable"}> */}

      <div className={isMobile ? "ipotable defaulttable" : "ipotable"}>
        <table
          className="table"
          // style={{ width: isMobile && "800px" }}
        >
          <thead>
            <tr>
              <th scope="col" className={isMobile ? "mheadname" : ""}>
                Name
              </th>
              <th scope="col" className={isMobile ? "mheadname" : ""}>
                Listed on{" "}
              </th>
              <th scope="col" className={isMobile ? "mheadname" : ""}>
                Issue Price{" "}
              </th>
              <th scope="col" className={isMobile ? "mheadname" : ""}>
                Status
              </th>
            </tr>
          </thead>
          <tbody style={{ width: 500 }}>
            {listedLoading ? (
              <ThreeDots
                height="90"
                width="90"
                radius="10"
                color="#3AA29F"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                }}
                // wrapperClassName=""
                visible={true}
              />
            ) : listedIpos == null ? (
              <tr>
                <td scope="row">No Data Found</td>
              </tr>
            ) : (
              listedIpos?.map((stock) => {
                return (
                  <tr>
                    <td scope="row">
                      <div className="ipologo">
                        <Avatar
                          sx={{
                            background: "#405cb2",
                            width: 36,
                            height: 36,
                            mr: 2,
                          }}
                          {...stringAvatar(stock?.lname)}
                        />
                        <div>
                          {/* <Tooltip
                                                    title={stock.Lname}
                                                    placement="right-start"
                                                    arrow
                                                  > */}
                          {/* <div className="ellipsis"> */}
                          {stock?.lname}
                          {/* </div> */}
                          {/* </Tooltip> */}
                          <strong>{stock?.companySymbol}</strong>
                        </div>
                      </div>
                    </td>
                    <td scope="row"> {getFullDate(stock?.LISTDATE)}</td>
                    <td scope="row">
                      <i className="finr">
                        {stock?.PriceBand.split("-")[1] === undefined
                          ? stock?.PriceBand
                          : stock?.PriceBand.split("-")[1]}
                      </i>
                    </td>
                    <td scope="row">
                      <div className="list">
                        Listed at <i className="finr">{stock?.Listprice}</i> for{" "}
                        <p
                          className={
                            stock?.PerChange_ListingGain.toFixed() > 0
                              ? "tablevalue priceup"
                              : "tablevalue pricedown"
                          }
                        >
                          {stock?.PerChange_ListingGain.toFixed(2)}%{" "}
                          {stock?.PerChange_ListingGain.toFixed() > 0
                            ? "gains"
                            : "loss"}
                        </p>
                        {/* {stock?.Listprice}
                                                {" "} for{" "}
                                                <p className={stock?.PerChange_ListingGain.toFixed() > 0 ? "tablevalue priceup" : "tablevalue pricedown"}>
                                                  {stock?.PerChange_ListingGain.toFixed(2)}
                                                  %
                                                </p>{" "}
                                                {stock?.PerChange_ListingGain.toFixed() > 0 ? "gains" : "loss"} */}
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListedIpo;

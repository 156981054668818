import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const AuthFaq = () => {
  return (
    <>
      <div
        className="webcent"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Frequently Asked Questions</h3>
              </div>
              <div className="faqcont">
                <Accordion allowZeroExpanded="true">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is a Authorized Person?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          A Authorized Person is an individual who works on
                          behalf of a stock broker. Authorized Person promote
                          and sell the services of a particular stock broker
                          they are affiliated with to interested customers and
                          clients. So, Authorized Person will not have unique
                          services but will connect customers to the stock
                          broker. They will help customers with paperwork, and
                          payment, and in reverse, they will get a fixed
                          commission from the stock broker.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to Start your Authorized Person Franchise?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          To start a Authorized Person business, you must
                          partner with a SEBI-registered stock brokerage
                          company. You have to submit the required documents
                          like identity proof and educational certificates. Then
                          you will get your Authorized Person code and initiate
                          the business after making the required initial
                          payments.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the eligibility criteria to become a Authorized
                        Person of SMC?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          To become an SMC Authorized Person, you must be at
                          least 18 years of age. Mentioned below is the list of
                          documents and infrastructure requirements for becoming
                          a Authorized Person.
                        </p>
                        <p>Documents Required</p>
                        <ul>
                          <li>Pan Card</li>
                          <li>Aadhar Card</li>
                          <li>Educational proof (minimum intermediate)</li>
                          <li>Residential address proof</li>
                          <li>
                            Office address proof (electricity bill, phone
                            bill-BSNL or MTNL, bank statement)
                          </li>
                          <li>Four photographs</li>
                          <li>CA reference letter</li>
                          <li>
                            Name affidavit in case of any kind of mismatch in
                            name on a Rs. 10 stamp paper
                          </li>
                        </ul>
                        <strong>Note:</strong> Above documents should be
                        attested by a Notary or CA
                        <p>Infrastructure Required</p>
                        <ul>
                          <li>
                            Min. 250-500sq feet of office space preferably in a
                            prime location.
                          </li>
                          <li>Telephone line with voice logger.</li>
                          <li>Workstation with VSAT/broadBand</li>
                          <li>
                            Network connectivity required to set up a trading
                            terminal back office
                          </li>
                        </ul>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How much does a Authorized Person earn through
                        commission?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The Authorized Person commission might be between 40%
                          and 80% of the total business transaction amount.
                          Moreover, the commission rate can be high if you sell
                          more products to your customers.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the benefits of becoming SMC Authorized Person?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          SMC has an impressive track record of more than 20Lac+
                          clients and experience of more than 30 years in the
                          financial sector. So, you can expect multiple benefits
                          by partnering with them. The benefits of working with
                          SMC are
                        </p>
                        <p>
                          Exposure to multiple investment products like Equity
                          and Derivatives, Mutual Funds, Commodity/Currency
                          broking, Insurance, Retail loans, Portfolio Management
                          Services, etc.–
                        </p>
                        <ul>
                          <li>
                            Exposure to multiple investment products like Equity
                            and Derivatives, Mutual Funds, Commodity/Currency
                            broking, Insurance, Retail loans, Portfolio
                            Management Services etc.{" "}
                          </li>
                          <li>
                            Marketing assistance and professional training
                          </li>
                          <li>
                            Cutting-edge software for tracking payouts and
                            clients
                          </li>
                        </ul>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthFaq;

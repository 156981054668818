import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Context } from "../context";

export const Learn_Drop = ({ setActive, setIsOpen, menus }) => {
  const {
    isDemat,
    num,
    setIsDemat,
    setName,
    setNum,
    setEmail,
    setState,
    setCity,
    name,
    email,
    state,
    city,
    categoryID,
    urlData,
    ipAddress,
  } = useContext(Context);

  const emptyField = () => {
    setCity("");
    setEmail("");
    setName("");
    setState("");
    setNum("");
    setActive(!menus);
    setIsOpen(false);
  };
  return (
    <>
      <div className="menubox">
        <div className="sitemapcol">
          <h4 style={{ cursor: "context-menu" }}>Learning Center</h4>
          <ul>
            <li>
              <NavLink to="/learning-centre/webinar" onClick={emptyField}>
                Webinar
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://blog.smctradeonline.com:7171/"
                onClick={emptyField}
                target="_blank"
              >
                Blog
              </NavLink>
            </li>
            {/* <li>Demat</li>
            <li>Trading</li>
            <li>Online</li>
            <li>Intraday trading</li> */}
          </ul>
        </div>
        {/* <div className="sitemapcol">
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li>Podcast</li>
            <li>Future and Options</li>
            <li>Mutual Funds</li>
            <li>Share Market</li>
            <li>IPO</li>
          </ul>
        </div> */}
      </div>
    </>
  );
};

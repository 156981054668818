import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Trend } from "../images/svg/Trend";
import { Back } from "../images/svg/Back";
import { searchCompanies } from "../utils/APICollection";
import { Search } from "../images/svg/Search";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../context";

export const Searchbox = ({ setIsOpen }) => {
  const [data, setData] = useState([]);
  const [companyCode, setcompanyCode] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mutualFund, setMutualFund] = useState([]);
  const [stock, setStock] = useState([]);
  const [ipo, setIpo] = useState([]);
  const [etf, setEtf] = useState([]);
  const [count, setCount] = useState(1);
  const [selectedEntity, setSelectedEntity] = useState("All");

  const { setCompanyReturnsData } = useContext(Context);
  const navigate = useNavigate();
  const searchResult = async () => {
    setLoading(true);
    const { data } = await searchCompanies(companyCode);
    if (data) {
      setData(data);
      const mfData = data?.filter((item) => item.entity_type == "mf");
      setMutualFund(mfData);
      const stockData = data?.filter((item) => item.entity_type == "stock");
      setStock(stockData);
      const ipoData = data?.filter((item) => item.entity_type == "ipo");
      setIpo(ipoData);
      const etfData = data?.filter((item) => item.entity_type == "etf");
      setEtf(etfData);
      setLoading(false);
    }
    setLoading(false);
  };

  const onHandleChange = (event) => {
    setcompanyCode(event.target.value.toUpperCase());
    if (event.target.value?.length >= 3) {
      setIsActive(true);
    } else {
      setIsActive(false);
      console.log("error");
      setData([]);
    }
  };
  const RemoveLayer = () => {
    setIsActive(false);
    setData([]);
    setcompanyCode("");
  };
  const selectedSearchItem = async (co_code, code_symbol, plan_id, entity_type, company_name, bse_listed_flag, nse_listed_flag) => {
    setData([]);
    setIsActive(false);
    setcompanyCode("");
    if (entity_type == "mf") {
      navigate(`/invest/mutual-funds/${plan_id}/${company_name?.toLowerCase()?.replaceAll(" ", "-").replaceAll("&", "-").replaceAll("(", "-").replaceAll(")", "-")}`);
    } else if (entity_type == "ipo") {
      navigate(`/invest/ipo/${co_code}/${company_name?.toLowerCase()?.replaceAll(" ", "-").replaceAll("&", "-").replaceAll(")", "-").replaceAll("(", "-")}-ipo`);
    } else if (entity_type == "stock") {
      setCompanyReturnsData({});
      navigate(`/invest/equities/${co_code}/${company_name?.toLowerCase()?.replaceAll(" ", "-").replaceAll("&", "-").replaceAll("(", "-").replaceAll(")", "-")}-share-price`);
    }
    else if (entity_type == "etf") {
      navigate(`/invest/etf/${co_code}/${company_name?.toLowerCase()?.replaceAll(" ", "-").replaceAll("&", "-").replaceAll("(", "-").replaceAll(")", "-")}`);
    }
  };
  React.useEffect(() => {
    if (companyCode?.length >= 3) {
      searchResult();
    } else return;
  }, [companyCode]);

  const backFn = () => {
    setIsOpen(false);
    setcompanyCode("");
    setIsActive(false);
  };

  const showData = () => {
    setCount(count + 1);
  };

  const tabChange = (tabName) => {
    setCount(1);
    setSelectedEntity(tabName);
  };
  const isEdgeBrowser = navigator.userAgent.indexOf("Edg") !== -1;
  const isMobile = window.innerWidth <= 540;
  return (
    <>
      <div className={isActive ? " layer" : ""} onClick={RemoveLayer}></div>
      <div
        className="parentsearch"
        style={{ marginLeft: isEdgeBrowser ? "40px" : "0px" }}
      >
        <div className="inputsearch">
          <Link className="goback d-none">
            <i className="backarrow" onClick={backFn}>
              <Back />
            </i>
          </Link>
          <i
            className="searchicon"
            style={{ cursor: isMobile ? "pointer" : "default" }}
            onClick={!isMobile ? () => { } : () => setIsOpen(true)}
          >
            <Search />
          </i>
          <input
            type="text"
            value={companyCode}
            placeholder="Search any Equity, MF, IPO, ETF etc"
            autoComplete="off"
            onChange={onHandleChange}
          />
        </div>
        {companyCode?.length >= 3 && (
          <div className={"dropdownsearch"}>
            {loading ? (
              <div>Loading...</div>
            ) : (
              <div className="dropdownmain">
                <div className="searchtabs">
                  <Tabs>
                    <TabList style={{ justifyContent: "flex-start" }}>
                      <Tab onClick={() => tabChange("All")}>
                        <span>All</span>
                      </Tab>
                      <Tab onClick={() => tabChange("Equity")}>
                        <span>Equity</span>
                      </Tab>

                      <Tab onClick={() => tabChange("MF")}>
                        <span>MF</span>
                      </Tab>
                      <Tab onClick={() => tabChange("IPO")}>
                        <span>IPO</span>
                      </Tab>
                      <Tab onClick={() => tabChange("ETF")}>
                        <span>ETF</span>
                      </Tab>
                    </TabList>
                    <TabPanel>
                      <div className="dropdownresult searchscroll">
                        {data?.length !== 0 ? (
                          data
                            ?.slice(0, count * 10)
                            ?.map(
                              ({
                                company_name,
                                nse_symbol,
                                co_code,
                                code_symbol,
                                plan_id,
                                basic_name,
                                entity_type,
                                bse_listed_flag,
                                nse_listed_flag
                              }) => {
                                return (
                                  <div className="dropdownrow">
                                    <a>
                                      <div className="dropdownricon">
                                        <Trend />
                                      </div>
                                      <div
                                        className="dropdownsearchcont"
                                        onClick={() =>
                                          selectedSearchItem(
                                            co_code,
                                            code_symbol,
                                            plan_id,
                                            entity_type,
                                            company_name,
                                            bse_listed_flag,
                                            nse_listed_flag
                                          )
                                        }
                                      >
                                        <div className="dropdownsearchleft">
                                          <span>
                                            {company_name
                                              ? company_name
                                              : basic_name}
                                          </span>
                                          <span>{nse_symbol}</span>
                                        </div>
                                        <div className="dropdownsearchright">
                                          <span>
                                            {entity_type === "mf"
                                              ? "MF"
                                              : entity_type === "ipo"
                                                ? "IPO"
                                                : entity_type === "etf"
                                                  ? "ETF"
                                                  : "Equity"}
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                );
                              }
                            )
                        ) : (
                          <p> No data found</p>
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="dropdownresult searchscroll">
                        {stock?.length !== 0 ? (
                          stock
                            ?.slice(0, count * 10)
                            ?.map(
                              ({
                                company_name,
                                co_code,
                                code_symbol,
                                entity_type,
                                plan_id,
                                bse_listed_flag,
                                nse_listed_flag
                              }) => {
                                return (
                                  entity_type == "stock" && (
                                    <div className="dropdownrow">
                                      <a>
                                        <div className="dropdownricon">
                                          <Trend />
                                        </div>
                                        <div
                                          className="dropdownsearchcont"
                                          onClick={() =>
                                            selectedSearchItem(
                                              co_code,
                                              code_symbol,
                                              plan_id,
                                              entity_type,
                                              company_name,
                                              bse_listed_flag,
                                              nse_listed_flag
                                            )
                                          }
                                        >
                                          <div className="dropdownsearchleft">
                                            <span>{company_name} </span>
                                          </div>
                                          <div className="dropdownsearchright">
                                            <span>Equity</span>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  )
                                );
                              }
                            )
                        ) : (
                          <p>No Data found</p>
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="dropdownresult searchscroll">
                        {mutualFund?.length !== 0 ? (
                          mutualFund
                            ?.slice(0, count * 10)
                            ?.map(
                              ({
                                entity_type,
                                plan_id,
                                co_code,
                                code_symbol,
                                company_name,
                                bse_listed_flag,
                                nse_listed_flag
                              }) => {
                                return (
                                  entity_type == "mf" && (
                                    <div className="dropdownrow">
                                      <a>
                                        <div className="dropdownricon">
                                          <Trend />
                                        </div>
                                        <div
                                          className="dropdownsearchcont"
                                          onClick={() =>
                                            selectedSearchItem(
                                              co_code,
                                              code_symbol,
                                              plan_id,
                                              entity_type,
                                              company_name,
                                              bse_listed_flag,
                                              nse_listed_flag
                                            )
                                          }
                                        >
                                          <div className="dropdownsearchleft">
                                            <span>{company_name}</span>
                                          </div>
                                          <div className="dropdownsearchright">
                                            <span>MF</span>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  )
                                );
                              }
                            )
                        ) : (
                          <p> No data found</p>
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="dropdownresult searchscroll">
                        {ipo?.length !== 0 ? (
                          ipo
                            ?.slice(0, count * 10)
                            ?.map(
                              ({
                                entity_type,
                                plan_id,
                                company_name,
                                co_code,
                                code_symbol,
                                bse_listed_flag,
                                nse_listed_flag
                              }) => {
                                return (
                                  entity_type == "ipo" && (
                                    <div className="dropdownrow">
                                      <a>
                                        <div className="dropdownricon">
                                          <Trend />
                                        </div>
                                        <div
                                          className="dropdownsearchcont"
                                          onClick={() =>
                                            selectedSearchItem(
                                              co_code,
                                              code_symbol,
                                              plan_id,
                                              entity_type,
                                              company_name,
                                              bse_listed_flag,
                                              nse_listed_flag
                                            )
                                          }
                                        >
                                          <div className="dropdownsearchleft">
                                            <span>{company_name} </span>
                                          </div>
                                          <div className="dropdownsearchright">
                                            <span>IPO</span>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  )
                                );
                              }
                            )
                        ) : (
                          <p> No data found</p>
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="dropdownresult searchscroll">
                        {etf?.length !== 0 ? (
                          etf
                            ?.slice(0, count * 10)
                            ?.map(
                              ({
                                entity_type,
                                plan_id,
                                company_name,
                                co_code,
                                code_symbol,
                                bse_listed_flag,
                                nse_listed_flag
                              }) => {
                                return (
                                  entity_type == "etf" && (
                                    <div className="dropdownrow">
                                      <a>
                                        <div className="dropdownricon">
                                          <Trend />
                                        </div>
                                        <div
                                          className="dropdownsearchcont"
                                          onClick={() =>
                                            selectedSearchItem(
                                              co_code,
                                              code_symbol,
                                              plan_id,
                                              entity_type,
                                              company_name,
                                              bse_listed_flag,
                                              nse_listed_flag
                                            )
                                          }
                                        >
                                          <div className="dropdownsearchleft">
                                            <span>{company_name} </span>
                                          </div>
                                          <div className="dropdownsearchright">
                                            <span>ETF</span>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  )
                                );
                              }
                            )
                        ) : (
                          <p> No data found</p>
                        )}
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
                {selectedEntity === "All" && data.length > 10 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="viewmorebutton" onClick={showData}>
                      View More
                    </button>
                  </div>
                )}
                {selectedEntity === "Equity" && stock.length > 10 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="viewmorebutton" onClick={showData}>
                      View More
                    </button>
                  </div>
                )}
                {selectedEntity === "MF" && mutualFund.length > 10 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="viewmorebutton" onClick={showData}>
                      View More
                    </button>
                  </div>
                )}
                {selectedEntity === "IPO" && ipo.length > 10 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="viewmorebutton" onClick={showData}>
                      View More
                    </button>
                  </div>
                )}
                {selectedEntity === "ETF" && etf.length > 10 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="viewmorebutton" onClick={showData}>
                      View More
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

import React, { useState, useRef } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import Banner from "../../../../images/mtf-finanancing.webp";
import { Link } from "react-router-dom";
import { Faq } from "../../../../components/Faq";
import { Reviews } from "../../../../components/Reviews";
import { Form } from "../../../../components/Form";
import Icon01 from "../../../../images/mtficon01.webp";
import Icon02 from "../../../../images/mtficon02.webp";
import Icon03 from "../../../../images/mtficon03.webp";
import Icon04 from "../../../../images/mtficon04.webp";
import Ios from "../../../../images/app.webp";
import Android from "../../../../images/google.webp";
import Tradimg from "../../../../images/download-app.webp";
import Trading from "../../../../images/download-appn.webp";
import Frame1 from "../../../../images/app01.webp";
import Frame2 from "../../../../images/app02.webp";
import Frame3 from "../../../../images/app03.webp";
import Frame4 from "../../../../images/app04.webp";
import Slider from "react-slick";

export const MTF = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef();

  const mobboxes = [
    {
      id: 0,
      title: "Step 01",
      image: Frame1,
      content: "Click ‘Buy’ on any MTF applicable stock",
    },
    {
      id: 1,
      title: "Step 02",
      image: Frame2,
      content: "Activate Margin Trading Facility from the stock details page",
    },
    {
      id: 2,
      title: "Step 03",
      image: Frame3,
      content: "Read & accept the T&C",
    },
    {
      id: 3,
      title: "Step 04",
      image: Frame4,
      content: "Enter your OTP and done!",
    },
  ];

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const handleMobboxClick = (index) => {
    setActiveIndex(index);
    sliderRef.current.slickGoTo(index);
  };
  return (
    <>
      <Header />
      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>MTF Finanacing</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1 className="pb-2">
                    Trade Now, <span>Pay Later</span>
                  </h1>
                  <p>Margin Trading Facility (MTF)</p>
                </div>
                <div className="bannergt">
                  <img src={Banner} alt="Hassle-free investing" />
                </div>
                <Form />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent"
            id="Account"
            data-aos="fade-in"
            data-aos-offset="10"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Key features</h3>
                  </div>
                  <div className="iconrow">
                    <ul>
                      <li>
                        <span>
                          <img
                            src={Icon01}
                            alt="Never miss a trading opportunity due to lack of funds"
                          />
                        </span>
                        <p>
                          Never miss a trading opportunity due to lack of funds
                        </p>
                      </li>
                      <li>
                        <span>
                          <img
                            src={Icon02}
                            alt="Trade in top stocks with just 50% funds"
                          />
                        </span>
                        <p>Trade in top stocks with just 50% funds</p>
                      </li>
                      <li>
                        <span>
                          <img src={Icon03} alt="Low cost borrowing" />
                        </span>
                        <p>Low cost borrowing</p>
                      </li>
                      <li>
                        <span>
                          <img
                            src={Icon04}
                            alt="One click activation, no paperwork needed"
                          />
                        </span>
                        <p>One click activation, no paperwork needed</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent mtfactivation grey-background p-100 pb-0 border-0"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>How To Activate Margin Trading Facility?</h3>
                    <p>It’s quick, easy and a one-time process</p>
                  </div>
                  <div className="moblay">
                    <div className="mobcol">
                      {mobboxes.map((mobbox, index) => (
                        <div
                          key={mobbox.id}
                          className={`mobbox ${
                            index === activeIndex ? "active" : ""
                          }`}
                          onClick={() => handleMobboxClick(index)}
                        >
                          <div className="mobicon">
                            <h4>{mobbox.title}</h4>
                            <p>{mobbox.content}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="mobcol">
                      <div className="mobileframeslide">
                        <div className="mframslder">
                          <Slider {...sliderSettings} ref={sliderRef}>
                            {mobboxes.map((mobbox) => (
                              <div key={mobbox.id}>
                                <div className="framslider">
                                  <img src={mobbox.image} alt={mobbox.title} />
                                </div>
                              </div>
                            ))}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="web-container">
                <div className="title">
                  <h3 className="lh-1 pb-2">
                    Double your buying power with Margin Trading Facility
                  </h3>
                </div>
                <div className="calculatorbp col-12 float-start d-flex align-items-center justify-content-between">
                  <div className="calculatorbpbox graybg">
                    <div className="col-12 float-start d-grid">
                      <span>You Pay</span>
                      <span>
                        <em className="finr"></em> 70,000
                      </span>
                    </div>
                  </div>
                  <div className="calculatorsymbol">
                    <span>+</span>
                  </div>
                  <div className="calculatorbpbox graybg">
                    <div className="col-12 float-start d-grid">
                      <span>SMC Pays</span>
                      <span>
                        <em className="finr"></em> 70,000
                      </span>
                    </div>
                  </div>
                  <div className="calculatorsymbol">
                    <span>=</span>
                  </div>
                  <div className="calculatorbpbox gradient">
                    <div className="col-12 float-start d-grid">
                      <span className="text-white">
                        You can buy stocks worth
                      </span>
                      <span className="text-white">
                        <em className="finr"></em> 1,40,000
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="p-100 grey-background col-12 float-start">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>
                        All you need to know about Margin Trading Facility
                      </h3>
                      <p className="w-100">
                        Have you ever missed a good trading opportunity just
                        because you were low on funds at that moment? If you had
                        Margin Trading Facility (MTF) this would have never
                        happened. MTF allows an investor to buy a stock by
                        paying just a fraction of the total transaction value.
                        The balance amount is funded by the broker (such as
                        Smc). You can avail up to 4x of your buying power via
                        MTF.
                      </p>
                    </div>
                    <div className="col-12 float-start algohead border-0 bg-transparent mtfsetn p-0">
                      <div className="row">
                        <div className="col-lg-4 col-xs-6 col-12">
                          <h4>Limits & Charge</h4>
                          <ul>
                            <li>MTF is applicable on these 950 stocks</li>
                            <li>
                              Buy stocks worth <i className="finr"></i>50,00,000
                              by borrowing up to <i className="finr"></i>
                              25,00,000
                            </li>
                            <li>
                              Interest of <i className="finr"></i> 20/day for
                              every slab of <i className="finr"></i>40,000
                              borrowed
                            </li>
                          </ul>
                          <span>
                            E.g. Interest on <i className="finr"></i>70,000 =
                            <i className="finr"></i>40/day (
                            <i className="finr"></i>20/day for the first slab of
                            <i className="finr"></i>40,000 +
                            <i className="finr"></i>20/day for the second slab
                            of <i className="finr"></i>30,000)
                          </span>
                        </div>
                        <div className="col-lg-4 col-xs-6 col-12">
                          <iframe
                            width="100%"
                            height="300"
                            src="https://www.youtube.com/embed/3LeZWpWdJbE?rel=0"
                            title="SMC MTF Video"
                            frameBorder="0"
                          ></iframe>
                        </div>
                        <div className="col-lg-4 col-xs-6 col-12">
                          <h4>Keep in Mind</h4>
                          <ul>
                            <li>
                              MTF lets you borrow 50% funds to buy stocks for a
                              limited period of 365 days only
                            </li>
                            <li>
                              Gains or losses on stocks bought via MTF could be
                              two times than a normal order
                            </li>
                            <li>
                              As per regulatory guidelines, it is mandatory to
                              pledge MTF Positions on the CDSL website by 7 PM
                              on the same day
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 float-start text-end">
                      <p>
                        <strong>
                          <u>Terms & Conditions</u>
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="webcent "
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="downloadapp">
                    <div className="applef border-bottom-theme-color">
                      <img src={Trading} alt="" />
                    </div>
                    <div className="apprgt">
                      <div className="downloadlinks">
                        <figure>
                          {" "}
                          <img src={Tradimg} alt="Download" />
                        </figure>
                        <div className="downloadcont">
                          <h4>
                            Download SMC ACE App for a Sleek Investing
                            Experience{" "}
                          </h4>
                          <div className="downloadimg">
                            <span>
                              <img src={Android} alt="Play Store" />
                            </span>
                            <span>
                              <img src={Ios} alt="IOS" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Faq />
          <Reviews />
        </section>
      </main>

      <Footer />
    </>
  );
};

import React from 'react'

export const HTML = (props) => {
  return (
    <>
          <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={25}
    height={25}
    viewBox="0 0 550.801 550.801"
    {...props}
  >
    <path d="M475.095 131.986c-.032-2.525-.844-5.015-2.568-6.992L366.324 3.684c-.021-.029-.053-.045-.084-.071-.633-.712-1.36-1.289-2.141-1.803a10.984 10.984 0 0 0-2.838-1.313c-.2-.058-.379-.145-.59-.188A10.474 10.474 0 0 0 358.203 0H97.2C85.292 0 75.6 9.688 75.6 21.601v507.6c0 11.907 9.692 21.601 21.6 21.601h356.4c11.908 0 21.601-9.693 21.601-21.601V133.197c-.001-.406-.064-.804-.106-1.211zM97.2 21.601h250.203v110.51c0 5.962 4.831 10.8 10.8 10.8H453.6l.011 223.837H97.2V21.601zm83.257 477.71h-21.642v-41.26h-35.744v41.26h-21.769v-98.613h21.769v37.895h35.744v-37.895h21.642v98.613zm85.417-79.882h-26.188v79.882h-21.779v-79.882h-25.763v-18.731h73.73v18.731zm93.542 79.882-1.424-37.747c-.422-11.85-.854-26.188-.854-40.532h-.422c-2.996 12.583-6.982 26.631-10.685 38.19l-11.665 38.476H317.43l-10.252-38.18c-3.133-11.56-6.412-25.608-8.69-38.486h-.285c-.564 13.321-1.002 28.535-1.692 40.827l-1.72 37.457h-20.07l6.117-98.613h28.903l9.397 32.917c2.995 11.412 5.975 23.704 8.121 35.264h.422c2.711-11.417 5.975-24.427 9.112-35.406l10.252-32.774h28.329l5.263 98.613h-21.221v-.006zm97.822 0H397.3v-98.613h21.779v79.882h38.153v18.731h.006z" />
    <path d="m154.132 249.086 82.74 38.437v-18.269l-62.577-27.403v-.346l62.577-27.411v-18.267l-82.74 38.435zM249.642 294.416h17.405l36.883-124.964h-17.403zM313.938 214.094l63.957 27.411v.346l-63.957 27.403v18.269l82.73-37.918v-15.86l-82.73-37.918z" />
  </svg>
    </>
  )
}

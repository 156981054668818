import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const RedirectToExternalUrl = ({ url }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get current URL including the hash

  useEffect(() => {
    const currentHash = location.hash; // Get the current hash fragment (e.g., #goibonds or #capitalgain)

    if (url.startsWith("http")) {
      // If it's an external URL, append the hash fragment if present
      const [baseUrl, hashFragment] = url.split("#");
      const redirectUrl = hashFragment ? `${baseUrl}#${hashFragment}` : url;
      window.location.replace(redirectUrl);
    } else {
      // If it's an internal URL, handle hash fragment
      const [baseUrl, hashFragment] = url.split("#");

      // First, navigate to the base path (without the hash)
      navigate(baseUrl, { replace: true });

      // After navigating, manually append the hash to the URL
      if (hashFragment) {
        window.location.hash = `#${hashFragment}`;
      } else if (currentHash) {
        // If no hash fragment in new URL but one exists in the current URL
        window.location.hash = currentHash;
      }
    }
  }, [url, navigate, location]);

  // Render nothing since this component is only for redirection
  return null;
};

export default RedirectToExternalUrl;

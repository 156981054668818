import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import { formatNumber } from "../../../../components/common";
import { NavLink } from "react-router-dom";

const MutualFundFaqDetails = ({ fundDetails, expenseRatioData, fundReturnsData }) => {
    const getFormattedDate = (currentDate) => {
        let d = new Date(currentDate);
        let monthList = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        return d.getDate() + " " + monthList[d.getMonth()] + " " + d.getFullYear();
    };
    return (
        <>
            <div className="webcent" itemscope="" itemtype="http://schema.org/FAQPage">
                <div className="title text-center justify-content-center">
                    <h3>Frequently Asked Questions</h3>
                </div>
                <div className="faqcont">
                    <Accordion allowZeroExpanded="true">
                        <AccordionItem>
                            <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                                <AccordionItemHeading>
                                    <AccordionItemButton itemprop="name">
                                        {`How to invest in ${fundDetails?.basic_name} ?`}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                        <div itemProp="text">
                                            <p>
                                                {`You can invest in ${fundDetails?.basic_name} easily on SMC App And Website. Invest in a few minutes with the following steps:`}
                                            </p>
                                            <p>
                                                1. Visit To <NavLink to={"https://play.google.com/store/apps/details?id=com.smc.smcmf&hl=en_IN"}>APP</NavLink> or <NavLink to={"https://www.smcinvesteasy.com/"}>Website</NavLink>.
                                            </p>
                                            <p>
                                                2. Click "SIGNUP" and complete your KYC Process.
                                            </p>
                                            <p>
                                                {`3. Search the ${fundDetails?.basic_name} and select it.`}
                                            </p>
                                            <p>{`4. Now You Can Start Investing in ${fundDetails?.basic_name}`}.</p>
                                        </div>
                                    </div>
                                </AccordionItemPanel>
                            </div>
                        </AccordionItem>
                        <AccordionItem>
                            <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                                <AccordionItemHeading>
                                    <AccordionItemButton itemprop="name">
                                        {`What is the NAV of ${fundDetails?.basic_name} ?`}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                        <div itemProp="text">
                                            <p>
                                                The NAV of {fundDetails?.basic_name} is <i className="finr"></i>
                                                {Number(fundDetails?.latest_nav).toFixed(
                                                    2
                                                ) === "NaN"
                                                    ? "NA"
                                                    : Number(
                                                        fundDetails?.latest_nav
                                                    ).toFixed(2)} as of {fundDetails?.nav_date &&
                                                        getFormattedDate(fundDetails?.nav_date)}.
                                            </p>
                                        </div>
                                    </div>
                                </AccordionItemPanel>
                            </div>
                        </AccordionItem>
                        <AccordionItem>
                            <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                                <AccordionItemHeading>
                                    <AccordionItemButton itemprop="name" >
                                        {` What is the minimum sip amount of ${fundDetails?.basic_name} ?`}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                        <div itemProp="text">
                                            <p>
                                                {` The minimum SIP amount of ${fundDetails?.basic_name} is ₹${fundDetails?.min_subsequent_sip_investment}`}.
                                            </p>
                                        </div>
                                    </div>
                                </AccordionItemPanel>
                            </div>
                        </AccordionItem>
                        <AccordionItem>
                            <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                                <AccordionItemHeading>
                                    <AccordionItemButton itemprop="name">
                                        {` What is the expense ratio charged by the ${fundDetails?.basic_name} ?`}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                        <div itemProp="text">
                                            <p>
                                                {`The expense ratio of ${fundDetails?.basic_name} is  ${Number(expenseRatioData?.expense_ratio).toFixed(2)} as of ${getFormattedDate(fundDetails?.nav_date)}`}.
                                            </p>
                                        </div>
                                    </div>
                                </AccordionItemPanel>
                            </div>
                        </AccordionItem>
                        <AccordionItem>
                            <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                                <AccordionItemHeading>
                                    <AccordionItemButton itemprop="name">
                                        {` What is the AUM of ${fundDetails?.basic_name} ?`}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                        <div itemProp="text">
                                            <p>
                                                {` The AUM of ${fundDetails?.basic_name} is ₹${formatNumber(fundDetails?.latest_aum)} as of  ${getFormattedDate(fundDetails?.nav_date)}`}.
                                            </p>
                                        </div>
                                    </div>
                                </AccordionItemPanel>
                            </div>
                        </AccordionItem>
                        <AccordionItem>
                            <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                                <AccordionItemHeading>
                                    <AccordionItemButton itemprop="name">
                                        {` How to Redeem my investments in  ${fundDetails?.basic_name} ?`}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                        <div itemProp="text">
                                            <p>
                                                {` You can Redeem your investment in ${fundDetails?.basic_name} easily on SMC App And Website, By following these simple steps:`}
                                            </p>
                                            <p>1. Visit To SMC <NavLink to={"https://play.google.com/store/apps/details?id=com.smc.smcmf&hl=en_IN"}>APP</NavLink> or <NavLink to={"https://www.smcinvesteasy.com/"}>Website</NavLink>.</p>
                                            <p>
                                                {`2. Search the ${fundDetails?.basic_name} and select it`}.
                                            </p>
                                            <p>
                                                3. Click "Reedem Now".
                                            </p>
                                            <p>
                                                4. Enter the amount and Submit.
                                            </p>
                                        </div>
                                    </div>
                                </AccordionItemPanel>
                            </div>
                        </AccordionItem>
                        <AccordionItem>
                            <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                                <AccordionItemHeading>
                                    <AccordionItemButton itemprop="name">
                                        {` What kind of returns have ${fundDetails?.basic_name} generated ?`}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>

                                    <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                        <div itemProp="text">
                                            <p>
                                                {` The  ${fundDetails?.basic_name} has been generated ${Number(fundReturnsData?.ret_since_launch).toFixed(2)}% since its inception.`}
                                            </p>
                                        </div>
                                    </div>
                                </AccordionItemPanel>
                            </div>
                        </AccordionItem>

                    </Accordion>
                </div>
            </div>
        </>
    );
};

export default MutualFundFaqDetails;

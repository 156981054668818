import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import Icon01 from "../../images/unique-clients.webp";
import Icon02 from "../../images/employees.webp";
import Icon03 from "../../images/cities.webp";
import Icon04 from "../../images/authorised-person.webp";
import Icon05 from "../../images/network.webp";
import Icon06 from "../../images/loan-provider.webp";
import CountUp from "react-countup";

const NumberCount = () => {
  const [state, setState] = useState({
    turnover: 1,
    workforce: 1,
    states: 1,
    cities: 1,
  });
  return (
    <>
      <div
        className="webcent grey-background p-100"
        data-aos="fade-in"
        data-aos-offset="10"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>About SMC</h3>
              </div>
              <div className="cont col-lg-9 m-auto col-12 text-center">
                <p>
                  Established in 1990, SMC is a diversified financial services
                  company in India offering brokerage services across the asset
                  classes of equities (cash and derivatives), commodities and
                  currency, investment banking, wealth management, distribution
                  of third party financial products, research, financing,
                  depository services, insurance broking (life & non-life),
                  clearing services, mortgage advisory and real estate advisory
                  services to corporates, institutions, high net worth
                  individuals and other retail clients.
                </p>
              </div>
              <div className="iconrow mt-5">
                <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                  {({ isVisible }) => (
                    <ul>
                      <li>
                        <span>
                          <img src={Icon01} alt="Regular Training Program" />
                        </span>
                        <div className="countnum">
                          {isVisible ? (
                            <CountUp
                              start={state.turnover === 1 ? 1 : 2}
                              end={2}
                              duration={3}
                              onEnd={() => setState({ turnover: 2 })}
                            />
                          ) : (
                            <CountUp start={1} end={2} duration={0} />
                          )}{" "}
                          M+
                          <p style={{ textTransform: "none" }}>
                            Unique Customers
                          </p>
                        </div>{" "}
                      </li>
                      <li>
                        <span>
                          <img
                            src={Icon02}
                            alt="Advanced Software to Manage Clients"
                          />
                        </span>
                        <div className="countnum">
                          {isVisible ? (
                            <CountUp
                              start={state.workforce === 1 ? 1 : 4000}
                              end={4000}
                              duration={2}
                              onEnd={() => setState({ workforce: 4000 })}
                            />
                          ) : (
                            <CountUp start={1} end={4000} duration={0} />
                          )}
                          +
                          <p style={{ textTransform: "none" }}>
                            Lives Driving the Brand SMC
                          </p>
                        </div>{" "}
                      </li>
                      <li>
                        <span>
                          <img
                            src={Icon03}
                            alt="Multiple Product Offering For Cross Sell"
                          />
                        </span>
                        <div className="countnum">
                          {isVisible ? (
                            <CountUp
                              start={state.cities === 1 ? 1 : 455}
                              end={455}
                              duration={3}
                              onEnd={() => setState({ cities: 455 })}
                            />
                          ) : (
                            <CountUp start={1} end={455} duration={0} />
                          )}
                          +
                          <p style={{ textTransform: "none" }}>
                            Cities Across India
                          </p>
                        </div>
                      </li>
                      <li>
                        <span>
                          <img src={Icon04} alt="Regular Marketing Support" />
                        </span>
                        <div className="countnum">
                          {isVisible ? (
                            <CountUp
                              start={state.states === 1 ? 1 : 2550}
                              end={2550}
                              duration={1}
                              onEnd={() => setState({ states: 2550 })}
                            />
                          ) : (
                            <CountUp start={1} end={2550} duration={0} />
                          )}
                          +
                          <p style={{ textTransform: "none" }}>
                            Authorized Person & Franchises
                          </p>
                        </div>{" "}
                      </li>
                      <li>
                        <span>
                          <img src={Icon05} alt="Regular Marketing Support" />
                        </span>
                        <div className="countnum">
                          {isVisible ? (
                            <CountUp
                              start={state.cities === 1 ? 1 : 13600}
                              end={13600}
                              duration={3}
                              onEnd={() => setState({ cities: 13600 })}
                            />
                          ) : (
                            <CountUp start={1} end={13600} duration={0} />
                          )}
                          +
                          <p style={{ textTransform: "none" }}>
                            {" "}
                            Independent Distributors
                          </p>
                        </div>
                      </li>
                      <li>
                        <span>
                          <img src={Icon06} alt="Regular Marketing Support" />
                        </span>
                        <div className="countnum">
                          {isVisible ? (
                            <CountUp
                              start={state.turnover === 1 ? 1 : 2200}
                              end={2200}
                              duration={3}
                              onEnd={() => setState({ turnover: 2200 })}
                            />
                          ) : (
                            <CountUp start={1} end={20} duration={0} />
                          )}{" "}
                          +
                          <p style={{ textTransform: "none" }}>
                            Cumulative Loans Disbursed by NBFC Segment
                          </p>
                        </div>{" "}
                      </li>
                    </ul>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NumberCount;

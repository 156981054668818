import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { LineCharts } from "../../../../charts/LineChart";
import { useLocation } from "react-router-dom";
import {
  getFundNavDetailsData,
  getReturnPercentage,
} from "../../../../utils/APICollection";

const MfGraphData = ({ fundReturnsData, currentTenure, setCurrentTenure }) => {
  const [priceData, setPriceData] = useState([]);
  const [returnPercentageData, setReturnPercentageData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const tenureList = [
    { name: "1m" },
    { name: "6m" },
    { name: "1y" },
    { name: "3y" },
    { name: "5y" },
    { name: "ALL" },
  ];
  const returnPercentage = {
    "1m": "ret_1month",
    "6m": "ret_6month",
    "1y": "ret_1year",
    "3y": "ret_3year",
    "5y": "ret_5year",
    ALL: "ret_since_launch",
  };
  const fetchFundNavDetails = async () => {
    setIsLoading(true);
    try {
      const res = await getFundNavDetailsData(
        window.location.pathname.split("/")[3],
        currentTenure
      );
      setPriceData(res?.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchReturnPercentages = async () => {
    try {
      const res = await getReturnPercentage(
        window.location.pathname.split("/")[3]
      );
      setReturnPercentageData(res?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchReturnPercentages();
  }, [location.pathname]);

  useEffect(() => {
    fetchFundNavDetails();
  }, [currentTenure, location.pathname]);

  return (
    <div className="achart">
      <div className="charttab">
        <Tabs>
          <TabPanel></TabPanel>
          <TabPanel></TabPanel>
          <TabPanel></TabPanel>
          <TabPanel></TabPanel>
          <TabPanel></TabPanel>
          <TabPanel></TabPanel>
          <>
            <div className="graphpercentage">
              <div className="graphnumber justify-content-start">
                <span>
                  {fundReturnsData?.ret_1month &&
                    Number(
                      fundReturnsData[returnPercentage[currentTenure]]
                    ).toFixed(2)}
                  %
                </span>
                <p>{currentTenure?.toUpperCase()} return</p>
              </div>
              <div className="graphperiod">
                {Number(returnPercentageData?.perchange1d) > 0 ? (
                  <span className="increase">
                    +
                    {returnPercentageData?.perchange1d &&
                      Number(returnPercentageData?.perchange1d).toFixed(2)}
                    %
                  </span>
                ) : (
                  <span className="loss">
                    {returnPercentageData?.perchange1d &&
                      Number(returnPercentageData?.perchange1d).toFixed(2)}
                    %
                  </span>
                )}
                <p>
                  <strong>1D</strong>
                </p>
              </div>
            </div>
            <LineCharts
              priceData={priceData}
              tenure={currentTenure}
              heading={"Nav"}
              isLoading={isLoading}
            />
          </>
          <TabList
            style={{
              justifyContent: "center",
              gap: "15px",
            }}
          >
            {tenureList.map((tenure) => (
              <Tab onClick={() => setCurrentTenure(tenure?.name)}>
                {tenure?.name?.toUpperCase()}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </div>
    </div>
  );
};

export default MfGraphData;

export const calcPercentage = (string) => {
  let arr = string.split(" - ");
  let low = parseInt(arr[0]);
  let high = parseInt(arr[1]);
  return ((high - low) / (low / 100)).toFixed(2);
};

export function convertDate(inputFormat) {
  // function pad(s) {
  //   return s < 10 ? "0" + s : s;
  // }
  var date = new Date(inputFormat);
  let formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "2-digit",
  });
  // return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  return formattedDate;
}

export const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber).toLocaleString("en-IN");
  // let splitArray=formetedNumber.split('.');
  // if(splitArray.length>1){
  //   formetedNumber=splitArray[0];
  // }
  if (formetedNumber === "NaN") return "";
  return formetedNumber;
};

export const convertString = (str) => {
  let newStr = str.replace(
    /(\d+)(st|nd|rd|th) (\w+)/g,
    function (match, p1, p2, p3, offset, string) {
      // p1: day, p2: ordinal, p3: month
      return p1 + p2 + (p3 === string.match(/\b\w+\b/g)[1] ? "" : " " + p3);
    }
  );
  return newStr;
};

export function validatePAN(panVal) {
  var regpan = /([a-z0-9A-Z]){10}/g;
  return regpan.test(panVal);
}

export function validateClientCode(clientVal) {
  var regeclient = /^(?=.*[a-zA-Z]){7}(?=.*[0-9])[a-zA-Z0-9]+$/;
  if (regeclient.test(clientVal)) {
    return true;
  } else {
    return false;
  }
}

// export function validatePAN(panVal) {
//   var regpan = /^([a-zA-Z]){5}?$/;
//   var reg = /^([a-zA-Z]){3}?$/;
//   if (regpan?.test(panVal) == true) {
//     return sessionStorage.setItem("panvalue", true);
//   } else if (reg) {
//     if (reg?.test(panVal) == true) {
//       return sessionStorage.setItem("clientvalue", true);
//     }
//   }
// }

import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import { Context } from "../context";
import { useContext } from "react";
import SquareOutlinedIcon from "@mui/icons-material/SquareOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export const Filter = ({
  allAmcData,
  allFundsData,
  setAllFundsData,
  allFundsDataCopy,
  setAllFundsDataCopy,
  fundsCategory,
  setFundsCategory,
  fetchPlansByCategory,
  amcFilters,
  setAmcFilters,
  investData,
  setInvestData,
  sipFilters,
  setSipFilters,
  searchText,
  setSearchText,
  fundSize,
  setFundSize,
  fundSizefilters,
  setFundSizeFilters,
}) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [checkedAmcItems, setCheckedAmcItems] = useState({});
  const [checkedRiskItems, setCheckedRiskItems] = useState({});
  const [checkedInvestItems, setcheckedInvestItems] = useState({});
  const [checkedFundSizeItems, setCheckedFundSizeItems] = useState({});

  const {
    selectedFilters,
    setSelectedFilters,
    allRiskData,
    setAllRiskData,
    riskFilters,
    setRiskFilters,
  } = useContext(Context);

  const handleClick = (e, category) => {
    const { checked } = e.target;

    //const result = fundsCategory.map(item => item?.primary_category_name === category ? { ...item, checked: true } : item)
    //setFundsCategory(result);
  };
  const handleChange = (event) => {
    if (event.target.checked) {
      setSelectedFilters((prev) => [...prev, event.target.name]);
    } else {
      setSelectedFilters(
        selectedFilters.filter((x) => x !== event.target.name)
      );
    }
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handleAmcFilters = (event) => {
    if (event.target.checked) {
      setAmcFilters((prev) => [...prev, event.target.name]);
    } else {
      setAmcFilters(amcFilters.filter((x) => x !== event.target.name));
    }
    setCheckedAmcItems({
      ...checkedAmcItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handleRiskFilters = (event) => {
    if (event.target.checked) {
      if (event.target.name.length > 1) {
        setRiskFilters((prev) => [
          ...prev,
          event.target.name.split(",").map(Number),
        ]);
      } else {
        setRiskFilters((prev) => [...prev, Number(event.target.name)]);
      }
    } else {
      // const parts = event.target.name.split(',');
      // const filteredParts = parts.filter(item => item !== event.target.name);
      // const resultString = filteredParts.join(',');

      if (event.target.name.length > 1) {
        const elementsToRemove = event.target.name.split(",").map(Number);
        const filteredArray = riskFilters.filter((item) => {
          if (Array.isArray(item)) {
            return JSON.stringify(item) !== JSON.stringify(elementsToRemove);
          }
          return true;
        });
        setRiskFilters(filteredArray);

        // setRiskFilters(riskFilters.filter(num => event.target.name.split(",").map(Number).indexOf(num) === - 1))
      } else {
        setRiskFilters(
          riskFilters.filter((x) => x !== Number(event.target.name))
        );
      }

      //setRiskFilters(riskFilters.filter((x) => x !== event.target.name));
    }
    setCheckedRiskItems({
      ...checkedRiskItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handleInvestFilters = (event) => {
    if (event.target.checked) {
      setSipFilters((prev) => [...prev, event.target.name]);
    } else {
      setSipFilters(sipFilters.filter((x) => x !== event.target.name));
    }
    setcheckedInvestItems({
      ...checkedInvestItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSearchAmc = (event) => {
    setSearchText(event.target.value);
  };

  const handleFundSizeFilters = (event) => {
    if (event.target.checked) {
      setFundSizeFilters((prev) => [...prev, Number(event.target.name)]);
    } else {
      setFundSizeFilters(
        fundSizefilters.filter((x) => x !== Number(event.target.name))
      );
    }
    setCheckedFundSizeItems({
      ...checkedFundSizeItems,
      [event.target.name]: event.target.checked,
    });
  };

  const containesElement = (risk) => {
    for (const item of riskFilters) {
      if (Array.isArray(item)) {
        if (JSON.stringify(item) === JSON.stringify(risk)) {
          return true;
        }
      } else {
        if (item === risk[0]) {
          return true;
        }
      }
    }
    return false;
  };
  return (
    <>
      <div className="filter-accordian">
        {/* First Accordian */}
        <Accordion defaultExpanded className="filteraccord">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="h-auto"
          >
            <Typography>
              <strong className="averta fw700 txtcolor spacing-03">AMC</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="pt-0 mobilepd">
            <Typography>
              <div className="searchoption">
                <div className="inputsearch rounded-0">
                  <i className="fa fa-search"></i>
                  <input
                    type="text"
                    name="search"
                    placeholder="Search Mutual Fund Companies"
                    autoComplete="off"
                    value={searchText}
                    onChange={handleSearchAmc}
                    required
                  />
                </div>
              </div>
              <div className="to-dos listing col-12 pull-left mt-3">
                {allAmcData.length > 0 ? (
                  searchText.length < 3 ? (
                    allAmcData.map((amc, index) => (
                      <div
                        className="to-dos-entry listingrow"
                        style={{ marginBottom: "-10px" }}
                      >
                        <FormGroup key={index}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={
                                  <SquareOutlinedIcon sx={{ fontSize: 17 }} />
                                }
                                checkedIcon={
                                  <CheckBoxIcon sx={{ fontSize: 17 }} />
                                }
                                name={amc.amc_id}
                                type="checkbox"
                                // checked={checkedAmcItems[amc.amc_full_name]}
                                checked={amcFilters.includes(amc.amc_id)}
                                onChange={handleAmcFilters}
                              />
                            }
                            label={
                              <Typography variant="body1" sx={{ fontSize: 13 }}>
                                {amc.amc_full_name}
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </div>
                    ))
                  ) : (
                    allAmcData
                      .filter((amc) =>
                        amc.amc_full_name
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                      )
                      .map((amc, index) => (
                        <div
                          className="to-dos-entry listingrow"
                          style={{ marginBottom: "-10px" }}
                        >
                          <FormGroup key={index}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={
                                    <SquareOutlinedIcon sx={{ fontSize: 17 }} />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon sx={{ fontSize: 17 }} />
                                  }
                                  name={amc.amc_id}
                                  type="checkbox"
                                  // checked={checkedAmcItems[amc.amc_full_name]}
                                  checked={amcFilters.includes(amc.amc_id)}
                                  onChange={handleAmcFilters}
                                />
                              }
                              label={
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: 13 }}
                                >
                                  {amc.amc_full_name}
                                </Typography>
                              }
                            />
                          </FormGroup>
                        </div>
                      ))
                  )
                ) : (
                  <>No Data Found</>
                )}
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* Second Accordian */}
        <Accordion defaultExpanded className="filteraccord">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="h-auto"
          >
            <Typography>
              <strong className="averta fw700 txtcolor spacing-03">
                Category
              </strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="pt-0">
            <Typography>
              <div className="to-dos listing col-12 pull-left">
                {fundsCategory.length > 0 ? (
                  fundsCategory.map((categoryName, index) => (
                    <div
                      className="to-dos-entry listingrow"
                      style={{ marginBottom: "-10px" }}
                    >
                      <FormGroup key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={
                                <SquareOutlinedIcon sx={{ fontSize: 17 }} />
                              }
                              checkedIcon={
                                <CheckBoxIcon sx={{ fontSize: 17 }} />
                              }
                              name={categoryName.primary_category_name}
                              type="checkbox"
                              // checked={
                              //   checkedItems[categoryName.primary_category_name]
                              // }
                              checked={selectedFilters.includes(
                                categoryName.primary_category_name
                              )}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <Typography variant="body1" sx={{ fontSize: 13 }}>
                              {categoryName.primary_category_name}
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </div>
                  ))
                ) : (
                  <>No Data Found</>
                )}
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* Third Accordian */}
        <Accordion defaultExpanded className="filteraccord">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="h-auto"
          >
            <Typography>
              <strong className="averta fw700 txtcolor spacing-03">Risk</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="pt-0">
            <Typography>
              <div className="to-dos listing col-12 pull-left">
                {allRiskData.length > 0 ? (
                  allRiskData.map((risk, index) => (
                    <div
                      className="to-dos-entry listingrow"
                      style={{ marginBottom: "-10px" }}
                    >
                      <FormGroup key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={
                                <SquareOutlinedIcon sx={{ fontSize: 17 }} />
                              }
                              checkedIcon={
                                <CheckBoxIcon sx={{ fontSize: 17 }} />
                              }
                              name={risk.frequency}
                              type="checkbox"
                              // checked={
                              //   checkedRiskItems[risk.name]
                              // }
                              checked={containesElement(risk.frequency)}
                              onChange={handleRiskFilters}
                            />
                          }
                          label={
                            <Typography variant="body1" sx={{ fontSize: 13 }}>
                              {risk.name} Risk
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </div>
                  ))
                ) : (
                  <>No Data Found</>
                )}
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* Fourth Accordian */}
        <Accordion defaultExpanded className="filteraccord">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="h-auto"
          >
            <Typography>
              <strong className="averta fw700 txtcolor spacing-03">
                Fund Size
              </strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="pt-0">
            <Typography>
              <div className="to-dos listing col-12 pull-left">
                {fundSize.length > 0 ? (
                  fundSize.map((fundName, index) => (
                    <div
                      className="to-dos-entry listingrow"
                      style={{ marginBottom: "-10px" }}
                    >
                      <FormGroup key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={
                                <SquareOutlinedIcon sx={{ fontSize: 17 }} />
                              }
                              checkedIcon={
                                <CheckBoxIcon sx={{ fontSize: 17 }} />
                              }
                              name={fundName.value}
                              type="checkbox"
                              // checked={
                              //   checkedFundSizeItems[fundName.name]
                              // }
                              checked={fundSizefilters.includes(fundName.value)}
                              onChange={handleFundSizeFilters}
                            />
                          }
                          label={
                            <Typography variant="body1" sx={{ fontSize: 13 }}>
                              {fundName.name}
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </div>
                  ))
                ) : (
                  <>No Data Found</>
                )}
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* Fifth Accordian */}
        <Accordion defaultExpanded className="filteraccord">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="h-auto"
          >
            <Typography>
              <strong className="averta fw700 txtcolor spacing-03">
                Available To Invest
              </strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="pt-0">
            <Typography>
              <div className="to-dos listing col-12 pull-left">
                {investData.length > 0 ? (
                  investData.map((invest, index) => (
                    <div
                      className="to-dos-entry listingrow"
                      style={{ marginBottom: "-10px" }}
                    >
                      <FormGroup key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={
                                <SquareOutlinedIcon sx={{ fontSize: 17 }} />
                              }
                              checkedIcon={
                                <CheckBoxIcon sx={{ fontSize: 17 }} />
                              }
                              name={invest.flag}
                              type="checkbox"
                              // checked={checkedInvestItems[invest.name]}
                              checked={sipFilters.includes(invest?.flag)}
                              onChange={handleInvestFilters}
                            />
                          }
                          label={
                            <Typography variant="body1" sx={{ fontSize: 13 }}>
                              {invest.name}
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </div>
                  ))
                ) : (
                  <>No Data Found</>
                )}
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

import React from "react";
import Footer from "../../../components/Footer";
import { Link, useParams } from "react-router-dom";
import { ResearchLink } from "../../../components/ResearchLink";
import Equityslider from "../../../components/Equityslider";
import Newsletter from "../../../components/Newsletter";
import { Context } from "../../../context";
import SimpleBackdrop from "../../../components/common/SimpleBackdrop";
import Header from "../../../components/Header";
import ResearchSeo from "../research seo";

export const ResearchCategoryDetails = () => {
  const { categoryName } = React.useContext(Context);
  const [fileLoading, setFileLoading] = React.useState(false);

  return (
    <>
      <Header />
      <ResearchSeo />
      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/Research">Research</Link>
                </li>
                <li>{categoryName}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="reportpage m-auto col-lg-12 col-12 d-flex justify-content-between">
                    <div className="reportmenu gradient">
                      <ResearchLink />
                    </div>
                    <div className="reportcontain">
                      <Equityslider
                        fileLoading={fileLoading}
                        setFileLoading={setFileLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          ></div>
          <Newsletter catID="Research" setFileLoading={setFileLoading} />
        </section>
      </main>
      <Footer />
      {fileLoading && <SimpleBackdrop />}
    </>
  );
};

import React from "react";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import Banner from "../../../images/faqs.webp";
import { Form } from "../../../components/Form";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Header from "../../../components/Header";

export const FAQ = () => {
  return (
    <>
      <Header />

      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>FAQ's</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1 className=" p-0">Quick Solutions of your queries</h1>
                </div>
                <div className="bannergt">
                  <img src={Banner} alt="Hassle-free investing" />
                </div>
                <Form catID="Contact" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Frequently Asked Questions</h3>
                  </div>
                  <div className="faqcont">
                    <Accordion allowZeroExpanded="true">
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What are the products and services offered by SMC
                            through its Business Partners/Associates?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              SMC facilitates trading activities in all the
                              major market segments including; equity,
                              derivatives, commodities, interest derivatives and
                              currency futures & Options. It’s robust and
                              user-friendly trading platform enables to execute
                              trades simultaneously across all Segments. We also
                              have whole bucket of other services like online
                              trading, depository, IPO, mutual funds, insurance
                              broking, Corporate FD, institution broking, margin
                              funding, NRI services, clearing services,
                              investment banking- life insurance, mediclaim ,
                              Vehicle insurance and others, PMS, wealth advisory
                              and research to provide you extra edge over others
                              by updated software ( Autotrender).{" "}
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What is the earning potential for business partners
                            / associates through this association ?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Our association in the business venture earning is
                              dynamic, and there is no limit, starting from Rs.
                              50 k per month.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Does a Authorized Person have to be registered with
                            SEBI, NSE & BSE?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Yes, to become an authorized Authorized Person it
                              is necessary to get registered with the respective
                              stock exchanges and regulators.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What are the eligibility criteria for becoming a
                            Business Partners/Associates?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <ol>
                              <li>
                                Any entity (Individual, Company or Partnership
                                firm) with good track record & reputation in
                                financial services/other business/social circle
                                with good client base
                              </li>
                              <li>
                                Minimum 1-2 years of experience in selling
                                financial products as a Main broker/ Authorized
                                Person/Remisier/Mutual fund
                                distributor/Insurance Advisor/Financial planner
                                or an employee of existing Broker/Sub-broker
                              </li>
                              <li>
                                Strength to invest minimum Rs 3-5 lac initially
                                and ability to invest continuously in
                                Infrastructure & people as per their business
                                requirements
                              </li>
                            </ol>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What are the infrastructure requirements for
                            becoming a Business Partners/Associates?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The infrastructure and financial requirements are:
                            </p>
                            <ol>
                              <li>
                                Min. 250 sq. feet of office space preferably in
                                a prime location of your city/town
                              </li>
                              <li>
                                Workstation with VSAT / Lease Line/ Broad Band
                                (Internet) minimum 1 mbps speed connectivity
                                required to set up a trading terminal and back
                                office systems.
                              </li>
                              <li>Telephone lines with voice logger</li>
                              <li>
                                Separate broadband connection for back office
                                reports
                              </li>
                            </ol>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

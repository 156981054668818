import React from "react";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import Banner from "../../../images/do-dont-banner.webp";
import { Form } from "../../../components/Form";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Tick } from "../../../images/svg/Tick";
import { Cross } from "../../../images/svg/Cross";
import Header from "../../../components/Header";

export const DoDont = () => {
  return (
    <>
      <Header />

      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>Do's & Dont's</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1 className=" p-0">Do's & Dont's</h1>
                </div>
                <div className="bannergt">
                  <img src={Banner} alt="Hassle-free investing" />
                </div>
                <Form catID="Contact" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="returncalulator">
                    <div className="howtoapply">
                      <Tabs>
                        <div className="tradingben mt-0">
                          <div className="iconrow noicon w-100">
                            <TabList className="justify-content-center">
                              <Tab>
                                <h4 className="width150">Do's</h4>
                              </Tab>
                              <Tab>
                                <h4 className="width150">Dont's</h4>
                              </Tab>
                            </TabList>
                          </div>
                        </div>
                        <TabPanel>
                          <div className="dodont col-md-10 col-12 m-auto pull-left">
                            <ul>
                              <li>
                                <span>
                                  <Tick />
                                </span>
                                <p>
                                  Read, understand the term & conditions clearly
                                  and then execute the client-member agreement
                                  with your broker.
                                </p>
                              </li>
                              <li>
                                <span>
                                  <Tick />
                                </span>
                                <p>
                                  Enter correct and complete details in the
                                  form, fill up all fields yourself. Do not
                                  leave any spaces blank or do not handover it
                                  to other person to fill it up.
                                </p>
                              </li>
                              <li>
                                <span>
                                  <Tick />
                                </span>
                                <p>
                                  The details provided by you help us serve you
                                  better & thus, you are required to provide
                                  correct details & keep it updated.
                                </p>
                              </li>
                              <li>
                                <span>
                                  <Tick />
                                </span>
                                <p>
                                  Please provide correct email id & contact
                                  number.
                                </p>
                              </li>
                              <li>
                                <span>
                                  <Tick />
                                </span>
                                <p>
                                  Be aware of the risk associated with your Cash
                                  Market and Futures & Options positions in the
                                  market and margin calls on them. In case of
                                  inadequate margin or non-payment of margin in
                                  your account, your positions will be squared
                                  off by the Risk Management Team.
                                </p>
                              </li>
                              <li>
                                <span>
                                  <Tick />
                                </span>
                                <p>
                                  In case of margin calls, we inform you through
                                  all possible ways i.e. mail id (registered
                                  with us), your contact / mobile number but it
                                  is your responsibility to maintain adequate
                                  margins. Our RMS team can square off your
                                  positions in case of non-payment or inadequate
                                  margin.
                                </p>
                              </li>
                              <li>
                                <span>
                                  <Tick />
                                </span>
                                <p>
                                  Trading password should be kept secret & in no
                                  way should be revealed to other person/s. All
                                  transaction effected from its uses would be
                                  considered entered by you & thus, you will be
                                  solely responsible for this.
                                </p>
                              </li>
                              <li>
                                <span>
                                  <Tick />
                                </span>
                                <p>Strictly follow password policy.</p>
                              </li>
                              <li>
                                <span>
                                  <Tick />
                                </span>
                                <p>
                                  Verify details in contract notes, sent on your
                                  registered mail id & through post, on receipt.
                                  If you find any discrepancy then immediately
                                  inform us on helpdesk@smctradeonline.com
                                </p>
                              </li>
                              <li>
                                <span>
                                  <Tick />
                                </span>
                                <p>
                                  Confirm the delivery of shares in your Demat
                                  Account before selling.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="dodont col-md-10 col-12 m-auto pull-left">
                            <ul>
                              <li>
                                <span>
                                  <Cross />
                                </span>
                                <p>
                                  Do not execute the client member agreement
                                  until you clear your doubt.
                                </p>
                              </li>
                              <li>
                                <span>
                                  <Cross />
                                </span>
                                <p>Do not provide wrong details.</p>
                              </li>
                              <li>
                                <span>
                                  <Cross />
                                </span>
                                <p>
                                  Do not trade in any product without knowing
                                  the risk involved. Keep yourself aware of
                                  equity market investments.
                                </p>
                              </li>
                              <li>
                                <span>
                                  <Cross />
                                </span>
                                <p>Do not trade on behalf of others.</p>
                              </li>
                              <li>
                                <span>
                                  <Cross />
                                </span>
                                <p>
                                  Do not pay cash to any employee of the broking
                                  firm for trading.
                                </p>
                              </li>
                              <li>
                                <span>
                                  <Cross />
                                </span>
                                <p>
                                  Do not trade on the basis of market rumors.
                                  Always verify the authenticity of such rumors.
                                  Analyze the information available to make
                                  investments.
                                </p>
                              </li>
                              <li>
                                <span>
                                  <Cross />
                                </span>
                                <p>
                                  Do not overlook any discrepancy in contract
                                  note.
                                </p>
                              </li>
                              <li>
                                <span>
                                  <Cross />
                                </span>
                                <p>
                                  Do not trust any person who assures you of a
                                  fixed return by trading on your behalf.
                                </p>
                              </li>
                              <li>
                                <span>
                                  <Cross />
                                </span>
                                <p>Do not reveal your password to anyone.</p>
                              </li>
                              <li>
                                <span>
                                  <Cross />
                                </span>
                                <p>
                                  Do not short sell shares. You need to have
                                  required stock available in your demat account
                                  before selling it.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

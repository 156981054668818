const IpoSubscriptionStatus = ({
  subscriptionDetails,
  subscriptionStatus,
  isMobile,
}) => {
  const showSubscribedSubscription = [
    "QualifiedInstitutionalBuyers_QIB_Subscribed",
    "NonInstitutionalInvestors_NII_Subscribed",
    "RetailIndividualInvestors_RII_Subscribed",
    "ReservationPortionShareholder_ExistingRetailShareholders_Subscribed",
    "EmployeeReservation_Subscribed",
    "GroupCompanyReservation_Subscribed",
  ];
  const filteredSubscribedSubscription = Object.keys(subscriptionStatus).map(
    (key, val) => {
      return { name: key, value: subscriptionStatus[key] };
    }
  );
  const subscribedListValue = {
    QualifiedInstitutionalBuyers_QIB_Subscribed:
      "Qualified Institutional Buyers QIB Subscribed",
    NonInstitutionalInvestors_NII_Subscribed:
      "Non Institutional Investors NII Subscribed",
    RetailIndividualInvestors_RII_Subscribed:
      "Retail Individual Investors RII Subscribed",
    ReservationPortionShareholder_ExistingRetailShareholders_Subscribed:
      "Reservation Portion Shareholder Existing Retail Shareholders Subscribed",
    EmployeeReservation_Subscribed: "Employee Reservation Subscribed",
    GroupCompanyReservation_Subscribed: "Group Company Reservation Subscribed",
  };
  const getSubscribedKeyName = (key) => {
    return subscribedListValue[key];
  };
  return (
    <div className="webcent">
      <div className="ipostable">
        <div className="tablisthead ipotable">
          <h4>{subscriptionDetails?.CompanyName} Subscription Status Live</h4>
        </div>
        <div className="tablist">
          <div className="ipostn">
            <div className={isMobile ? "ipotable defaulttable" : "ipotable"}>
              <table className="table" style={{ width: isMobile && "500px" }}>
                <thead>
                  <tr>
                    <th scope="col" width="70%">
                      Inventory Category
                    </th>
                    <th scope="col" width="30%">
                      Subscription (Times){" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredSubscribedSubscription
                    ?.filter((item) =>
                      showSubscribedSubscription.some(
                        (showSubscribedSubscription) =>
                          showSubscribedSubscription === item?.name
                      )
                    )
                    .map((item) => (
                      <tr>
                        <td width={"70%"}>
                          {/* <strong className="mobile-show">
                                                Inventory Category
                                              </strong> */}
                          {getSubscribedKeyName(item?.name)}
                        </td>
                        <td width={"30%"}>
                          {/* <strong className="mobile-show">
                                                Subscription (Times)
                                              </strong> */}
                          {item?.value}
                        </td>
                      </tr>
                    ))}

                  {/* <tr>
                                        <td>
                                          <strong className="mobile-show">
                                            Inventory Category
                                          </strong>
                                          Qualified Institutional Buyer
                                        </td>
                                        <td>
                                          <strong className="mobile-show">
                                            Subscription (Times)
                                          </strong>
                                          NA
                                        </td>
                                      </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IpoSubscriptionStatus;

import React, { useState, useRef } from "react";
import Slider from "react-slick";
import { PDF } from "../images/svg/PDF";
import { HTML } from "../images/svg/HTML";
import nodata from "../images/nodata.webp";
import { useParams } from "react-router-dom";
import { Context } from "../context";
import { getFileUrl, getReportByCategory } from "../utils/APICollection";
import { formatDate } from "./common/FormattedDate";
import { AWS_S3_RESEARCH_REPORT_FOLDER } from "../utils/constants";
import { Tooltip } from "@mui/material";
import left from "../images/larrow.webp";
import right from "../images/arrow.webp";

const Equityslider = ({ setFileLoading, fileLoading }) => {
  const { id } = useParams();
  const { researchData, setResearchData } = React.useContext(Context);
  const subcategoryMap = {};
  const reserhDesc = JSON.parse(localStorage.getItem("researchData")).forEach(
    (category) => {
      category.subCategory.forEach((subcategory) => {
        subcategoryMap[subcategory.subcategory_name] =
          subcategory.subcategory_description;
      });
    }
  );

  const getResearchReportByCategory = async () => {
    setFileLoading(true);
    const { data } = await getReportByCategory(id);
    if (data?.data?.length > 0) {
      setCurrentSlide(0);
      setResearchData(data?.data);
      setFileLoading(false);
    } else {
      setCurrentSlide(0);
      setFileLoading(false);
      setResearchData([]);
    }
  };
  React.useEffect(() => {
    getResearchReportByCategory();
  }, [id]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  function SampleNextArrow(props) {
    const { className, style, onClick, researchData } = props;
    return (
      <div
        className={className}
        style={{
          ...style,

          display: currentSlide === researchData?.length - 1 ? "none" : "block",
        }}
        onClick={onClick}
      >
        <img src={right} alt="right" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: currentSlide === 0 ? "none" : "block",
        }}
        onClick={onClick}
      >
        <img src={left} alt="left" />
      </div>
    );
  }

  let finalData = researchData?.map((obj) => {
    if (obj?.files && obj?.files.length > 0) {
      let description = obj?.files[0].description;
      return { ...obj, description };
    }
    return obj;
  });
  const totalSlides = researchData?.length;
  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: researchData?.length,
          dots: true,
          arrows: false,
        },
      },
    ],
    beforeChange: (current, next) => {
      if (finalData?.length === 0) {
        setCurrentSlide(0);
      } else {
        setCurrentSlide(next);
      }
    },
    nextArrow: <SampleNextArrow researchData={researchData} />,
    prevArrow: <SamplePrevArrow />,
  };

  const getUrl = async (fileName) => {
    setFileLoading(true);
    try {
      const { data } = await getFileUrl({
        file_name: fileName,
        folder_name: AWS_S3_RESEARCH_REPORT_FOLDER,
      });
      if (data?.statusCode === 200) {
        window.open(data?.data?.[0]?.file_url);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFileLoading(false);
    }
  };

  return (
    <>
      <div className="col-lg-12 col-12 pull-left">
        <div className="slider-container">
          {researchData?.length < 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "1px auto",
                flexDirection: "column",
              }}
            >
              <img
                src={nodata}
                style={{ width: "300px", alignSelf: "center" }}
              />
              <h3>No Data Found</h3>
            </div>
          )}
          <Slider ref={sliderRef} {...settings} key={id}>
            {finalData?.map((item) => {
              return (
                <div>
                  <div className="equityanalysis">
                    <div className="boxsetup col-12 pull-left">
                      <div className="title justify-content-start text-start m-0">
                        <h2>{item?.frequency_name}</h2>
                        <h3>{item?.subcategory_name}</h3>
                        <h6>{subcategoryMap[item?.subcategory_name]}</h6>
                      </div>
                    </div>
                    <div className="ipotable">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Title</th>
                            <th scope="col">View Report</th>
                          </tr>
                        </thead>
                        <div className="scrollusenew" style={{ height: 500 }}>
                          <tbody>
                            {item?.files?.map((item) => {
                              return (
                                <tr style={{ height: 55, marginLeft: 10 }}>
                                  <td scope="row">
                                    <div className="ipologo">
                                      <p>{formatDate(item.date)}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <p>{item.title}</p>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",

                                        gap: 4,
                                      }}
                                    >
                                      {item.english_pdf_file && (
                                        <Tooltip title="English Pdf" arrow>
                                          <p style={{ cursor: "pointer" }}>
                                            <PDF
                                              onClick={() =>
                                                getUrl(item.english_pdf_file)
                                              }
                                            />
                                          </p>
                                        </Tooltip>
                                      )}

                                      {item.hindi_pdf_file && (
                                        <Tooltip title="Hindi Pdf" arrow>
                                          <p style={{ cursor: "pointer" }}>
                                            <PDF
                                              onClick={() =>
                                                getUrl(item.hindi_pdf_file)
                                              }
                                            />
                                          </p>
                                        </Tooltip>
                                      )}

                                      {item.html_hindi_file && (
                                        <Tooltip title="Hindi HTML" arrow>
                                          <p style={{ cursor: "pointer" }}>
                                            <HTML
                                              onClick={() =>
                                                getUrl(item.html_hindi_file)
                                              }
                                            />
                                          </p>
                                        </Tooltip>
                                      )}

                                      {item.html_english_file && (
                                        <Tooltip title="English HTML" arrow>
                                          <p style={{ cursor: "pointer" }}>
                                            <HTML
                                              onClick={() =>
                                                getUrl(item.html_english_file)
                                              }
                                            />
                                          </p>
                                        </Tooltip>
                                      )}
                                      <p style={{ marginLeft: 5 }}>
                                        View Report
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </div>
                      </table>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
          {researchData?.length > 0 && (
            <div className="slide-count d-flex justify-content-center">
              {currentSlide + 1}/{totalSlides}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Equityslider;

import Slider from "react-slick";
import brokage from "../productsImages/brokage.svg";
import tradefacility from "../productsImages/tradefacility.svg";
import tradingfacility from "../productsImages/tradingfacility.svg";
import tradeequities from "../productsImages/tradeequities.svg";
import pics from "../productsImages/pis.svg";
import tradingdesk from "../productsImages/tradingdesk.svg";
const SmcBenefits = () => {
  const isBig = window.innerWidth > 1480;
  const isMobile = window.innerWidth <= 540;
  const settings = {
    dots: false,
    navigation: true,
    loop: true,
    infinite: false,
    slidesPerRow: 3,
    rows: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesPerRow: 2,
          rows: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesPerRow: 1,
          rows: 1,
        },
      },
    ],
  };
  return (
    <div
      className="webcent"
      data-aos="fade-up"
      data-aos-offset="200"
      data-aos-duration="600"
      data-aos-once="true"
      data-aos-easing="ease-in-sine"
    >
      <div className="col-12 pull-left ">
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Key Advantages of Investing with SMC</h3>
              </div>
              <p
                style={{
                  textAlign: "center",
                  marginTop: !isMobile && "-40px",
                  fontWeight: "bold",
                }}
              >
                Trusted, Innovative, and Secure Trading Partner
              </p>
              <div className="learningcenter investinind">
                <Slider {...settings}>
                  <div>
                    <div
                      className="learningbox investbx bg-white border-1"
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <div
                        className="learningimg m-0"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            height: "180px",
                            width: "180px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{ height: "100px", width: "100px" }}
                            src={brokage}
                            alt="Stable Government at the Centre"
                          />
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            padding: "10px",
                            color: "white",
                            marginBottom: isBig ? 0 : 26,
                          }}
                          className="w-100"
                        >
                          <strong>Competitive Brokage</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="learningbox investbx bg-white border-1"
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <div
                        className="learningimg m-0"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            height: "180px",
                            width: "180px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{ height: "100px", width: "100px" }}
                            src={tradefacility}
                            alt="Big Focus on Development"
                          />
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            padding: "10px",
                            color: "white",
                          }}
                          className="w-100 mb-1"
                        >
                          <strong>Big Focus on the Development</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="learningbox investbx bg-white border-1"
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <div
                        className="learningimg m-0"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            height: "180px",
                            width: "180px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{ height: "100px", width: "100px" }}
                            src={tradingfacility}
                            alt="One of the Leading Economies"
                          />
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            padding: "10px",
                            color: "white",
                          }}
                          className="w-100 mb-1"
                        >
                          <strong>One of the Leading Economies</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="learningbox investbx bg-white border-1"
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <div
                        className="learningimg m-0"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            height: "180px",
                            width: "180px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{ height: "100px", width: "100px" }}
                            src={tradeequities}
                            alt="Leading FPI Destination"
                          />
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            padding: "10px",
                            color: "white",
                          }}
                          className="w-100 mb-1"
                        >
                          <strong>Leading FPI Destination</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="learningbox investbx bg-white border-1"
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <div
                        className="learningimg m-0"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            height: "180px",
                            width: "180px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{ height: "100px", width: "100px" }}
                            src={pics}
                            alt="Strong Growth Trends"
                          />
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            padding: "10px",
                            color: "white",
                          }}
                          className="w-100 mb-1"
                        >
                          <strong> Manufacturing Destination</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="learningbox investbx bg-white border-1"
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <div
                        className="learningimg m-0"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            height: "180px",
                            width: "180px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{ height: "100px", width: "100px" }}
                            src={tradingdesk}
                            alt="Large Working Population"
                          />
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            padding: "10px",
                            color: "white",
                          }}
                          className="w-100 mb-1"
                        >
                          <strong>Large Working Population</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SmcBenefits;

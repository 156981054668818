import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const Testimonials = () => {
  return (
    <>
      <Header />

      <div className="container">
        <div className="bredcrum">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Testimonials</li>
          </ul>
        </div>
        <div
          className="webcent p-100 pt-0"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-duration="600"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"
        >
          <div className="minicontainer">
            <div className="ipodetail">
              <h3 style={{ color: "#4cafa7", fontWeight: 600, marginTop: 20 }}>
                Testimonials
              </h3>

              <p
                style={{
                  fontSize: "medium",
                  marginBottom: "0px",
                  marginTop: "15px",
                }}
              >
                I have been using SMC Global Securities for a few years now and
                I am very happy with their services. They have a user-friendly
                trading platform and excellent customer support. I am a beginner
                investor, and SMC Global Securities has been very helpful in
                guiding me through the process.
              </p>
              <h6 style={{ fontWeight: 700 }}>Nilesh Jain,Mumbai</h6>

              <p
                style={{
                  fontSize: "medium",
                  marginBottom: "0px",
                  marginTop: "15px",
                }}
              >
                I have been using their services for a few years now and I have
                been happy with them overall. SMC Global Securities offers a
                good selection of research tools and educational resources. This
                has helped me to improve my trading skills. It truly is a
                consolidated platform for everyone’s financial needs.
              </p>
              <h6 style={{ fontWeight: 700 }}>CA Vinod Kumar Atal,Ahmedabad</h6>

              <p
                style={{
                  fontSize: "medium",
                  marginBottom: "0px",
                  marginTop: "15px",
                }}
              >
                They have low brokerage rates, a user-friendly trading platform,
                and excellent customer support. I am an active trader, and I
                appreciate SMC Global Securities&#39; low brokerage rates and
                fast execution. I also like their trading platform, which is
                very easy to use.
              </p>
              <h6 style={{ fontWeight: 700 }}>Deepak Modi,Jaipur</h6>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Testimonials;

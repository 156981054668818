import React from "react";
import { ResearchSidebar } from "../pages/research/research-reports/wisemoney/research-sidebar";

export const ResearchLink = () => {
  return (
    <>
      <div className="linktab">
        <ResearchSidebar />
      </div>
    </>
  );
};

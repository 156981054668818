const FundName = ({ fundDetails }) => {
  return (
    <div className="mdoverviewheading">
      <h1 className="m-0" style={{ fontSize: "19px", fontWeight: "600" }}>
        {fundDetails?.basic_name}
      </h1>
      {/* bookmark and share post will be used for future purpose */}
      {/* <ul>
                    <li>
                        <span className="Bookmarkpost"></span>
                    </li>
                    <li>
                        <span className="sharepost"></span>
                    </li>
                </ul> */}
    </div>
  );
};

export default FundName;

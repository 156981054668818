import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import styled from '@mui/system/styled';
import Typography from "@mui/material/Typography";

const TrackComplaintDialog = ({ open, setOpen }) => {
    const handleClose = () => {
        setOpen(false);
    };
    const StyledHelperText = styled('p')({
        margin: 0,
        fontSize: '14px',
        fontWeight: 500,
        position: 'absolute',
        bottom: '15px', // Position the text below the input
        right: '30px',      // Align it to the right
        color: 'black',   // Style it as needed
    });
    return (
        <>
            <Dialog
                maxWidth="xs"
                fullWidth={false}
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <>
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{ overflow: "hidden", pb: 0, lineHeight: "1" }}
                    >
                        <Typography variant="body1" >
                            Please enter your complaint ticket ID to know about the complaint status
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                padding: 'initial'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ overflow: "hidden", lineHeight: "0.2" }}>
                        <DialogContentText
                            id="alert-dialog-description"
                            sx={{ overflow: "hidden" }}
                        >
                            <div>
                                <Box
                                    component="form"
                                    sx={{ position: 'relative', display: 'inline-block', '& .MuiTextField-root': { m: 1, width: '45ch' } }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        id="filled-multiline-static"
                                        // label="Multiline"
                                        inputProps={{ maxLength: 25 }}
                                        multiline
                                        rows={1}
                                        placeholder="Please Enter your Ticket No"
                                        variant="filled"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true, // Disables the underline
                                        }}
                                        sx={{ borderRadius: "50px" }}
                                    />
                                    {/* <StyledHelperText>words</StyledHelperText> */}
                                </Box>

                            </div>
                            <div className="btnn" style={{ width: "-webkit-fill-available", textAlign: "center" }}>
                                <span >SUBMIT</span>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </>
            </Dialog>
        </>
    );
};
export default TrackComplaintDialog;

import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const Faq = () => {
  return (
    <>
      <div
        className="webcent"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Frequently Asked Questions</h3>
              </div>
              <div className="faqcont">
                <Accordion allowZeroExpanded="true">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are Derivatives?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          A derivative is a financial contract whose value is
                          based on the value of an underlying asset. The
                          underlying asset can be a stock, bond, commodity,
                          currency, or interest rate. Derivatives are used to
                          hedge against risk, speculate on price movements, or
                          to manage cash flow.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who are the participants in the derivatives market?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The participants in the derivatives market can be
                          broadly categorized into four groups.
                        </p>
                        <p>
                          <strong>Hedgers </strong>-Hedgers are derivatives
                          users who seek to reduce their risk exposure.
                          Forexample, a farmer might use a futures contract to
                          hedge against the risk of falling crop prices.
                        </p>
                        <p>
                          <strong>Speculators</strong>-Speculators are
                          derivatives users who seek to profit from price
                          movements. They take on increased risk in hopes of
                          earning a higher return.
                        </p>
                        <p>
                          <strong>Arbitrageurs</strong>-Arbitrageurs are
                          derivatives users who seek to profit from small price
                          differences between different markets.
                        </p>
                        <p>
                          <strong>Market makers </strong>-Market makers are
                          intermediaries who facilitate the trading of
                          derivatives. They provide liquidity to the market by
                          buying and selling derivatives contracts.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are Futures?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          A futures contract is a standardized legal agreement
                          to buy or sell something at a predetermined price for
                          delivery at a specified time between parties not yet
                          known to each other. The asset transacted is usually a
                          commodity or financial instrument. Futures contracts
                          are traded on exchanges and are subject to regulation
                          and oversight. This makes them a more secure
                          investment than over-the-counter (OTC) derivatives,
                          which are not traded on an exchange. Futures contracts
                          are used by various participants in the financial
                          markets, including hedgers, speculators, and
                          arbitrageurs.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are Options?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          An option is a financial contract that gives the buyer
                          the right, but not the obligation, to buy or sell an
                          underlying asset at a specified price on or before a
                          specified date. The underlying asset can be a stock,
                          bond, commodity, currency, or interest rate. 
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the different types of Options?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>There are two types of options - Calls and Puts.</p>

                        <ul>
                          <li>
                            <strong>Call options </strong>Give the buyer the
                            right to buy the underlying asset at a specified
                            price on or before a specified date.
                          </li>
                          <li>
                            <strong>Put options</strong> Give the buyer the
                            right to sell the underlying asset at a specified
                            price on or before a specified date.
                          </li>
                        </ul>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the expiration date in an Option Contract?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The expiration date in an option contract is the last
                          day the option can be exercised. It is the date the
                          option contract expires and is no longer valid. The
                          expiration date is predetermined when the option
                          contract is created and is usually the third Friday of
                          the expiration month. The expiration date is also
                          important for determining the price of an option.
                          Options that expire sooner are typically more
                          expensive than options that expire later. This is
                          because options with a shorter time to expiration have
                          less time for the underlying asset to move in the
                          desired direction.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the difference between Options and Futures?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Options and futures are both derivatives, meaning
                          their value is derived from the value of an underlying
                          asset. However, there are some key differences between
                          the two.
                        </p>
                        <p>
                          <strong>Obligation</strong> - With options, the buyer
                          has the right, but not the obligation, to buy or sell
                          the underlying asset at the specified price on or
                          before the expiration date. With futures contracts,
                          the buyer must buy, and the seller must sell the
                          underlying asset at the specified price on or before
                          the expiration date.
                        </p>
                        <p>
                          <strong>Trading</strong> - Options and futures are
                          both traded on exchanges. However, options are
                          typically traded in smaller quantities than futures
                          contracts.
                        </p>
                        <p>
                          <strong>Price</strong> - The price of an option is
                          called a premium. The price of a futures contract is
                          called a margin. The premium is typically much lower
                          than the margin.
                        </p>
                        <p>
                          <strong>Profit potential</strong> - The profit
                          potential of an option is limited to the premium the
                          buyer paid. The profit potential of a futures contract
                          is unlimited.
                        </p>
                        <p>
                          <strong>Risk</strong> - The risk of an option is
                          limited to the premium the buyer paid. The risk of a
                          futures contract is unlimited.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How do I buy Options?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          You can buy/sell Options contracts through broking
                          firms that are registered members of the BSE or NSE.
                          SMC provides you with online platforms and/or mobile
                          applications allowing you to trade in all derivatives
                          contracts.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How do I use the Call and/or Put Option?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          When looking at an Option, assess the underlying
                          asset(s) and try to estimate the direction its price
                          might take in the coming month.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How can I start trading on the SMC trading app?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          You need to open a Demat and trading account for stock
                          trading online. You can open a Demat and trading
                          account with SMC by completing an application from the
                          website. Further, required documents like an Aadhaar
                          card, PAN, driving license, and passport-size photo
                          must be provided for verification. After successful
                          verification is done by SMC, a client id will be
                          provided to start trading.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;

import React from "react";
import Chart from "react-apexcharts";
import { formatNumber } from "../components/common";
export const DonutChart = ({
  sectorAllocationData,
  fundSize,
  equityPercentage,
}) => {
  if (sectorAllocationData.length < 1) {
    return <h4>No Data Found</h4>;
  }
  const series1 = sectorAllocationData.map((sectorAllocation) =>
    Number(Number(sectorAllocation?.percentage).toFixed(2))
  );
  const options1 = {
    chart: {
      type: "donut",
      fontFamily: "AvertaStd, Arial, sans-serif",
    },
    labels: sectorAllocationData.map(
      (sectorAllocation) =>
        sectorAllocation?.sector +
        "   " +
        Number(sectorAllocation?.percentage).toFixed(2) +
        "%"
    ),
    colors: [
      "#bce893",
      "#00a7e1",
      "#556cfb",
      "#ffb140",
      "#893168",
      "#e94967",
      "#9191e9",
      "#e94967",
      "#f0e100",
      "#52528c",
      "#0E6C3E",
      "#FFFC33",
    ],
    legend: {
      fontSize: "18px",
      position: "left",
      offsetY: 0,
      offsetX: -20,
      margin: "10px",
    },
    dataLabels: {
      enabled: false,
    },
    options1: {
      legend: {
        fontSize: "15px",
        offsetY: 50,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "10%",
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 1480,
        options: {
          chart: {
            width: "100%",
            height: "300",
          },
          legend: {
            fontSize: "15px",
            offsetY: 0,
          },
          plotOptions: {
            pie: {
              donut: {
                size: "60%",
              },
            },
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
            height: "500px",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            showAlways: true,
            show: true,
            name: {
              formatter: () => "",
            },
            value: {
              formatter: () =>
                `₹${formatNumber(
                  Number((Number(equityPercentage) * fundSize) / 100).toFixed(2)
                )}Cr` || "",
              offsetY: -10,
              fontFamily: "AvertaStd, Arial, sans-serif",
              fontWeight: "normal",
              color: "#000",
            },
            total: {
              show: true,
              showAlways: true,
              label: "Umsatz",
              color: "#7ebd0b",
              formatter: function () {
                return `₹${formatNumber(
                  Number((Number(equityPercentage) * fundSize) / 100).toFixed(2)
                )}Cr` || "";
              },
            },
          },
        },
      },
    },
  };
  return (
    <div className="equitypiechart">
      <Chart
        options={options1}
        series={series1}
        type="donut"
        className="donutchart2"
        height={"350"}
      />
    </div>
  );
};

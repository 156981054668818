import React from "react";
import Slider from "react-slick";
import Nitin from "../../images/index-expert.webp";
import Saurabh from "../../images/equity-expert.webp";
import Vandana from "../../images/commodity-expert.webp";

const ExpertSlider = () => {
  var settings3 = {
    dots: false,
    navigation: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <div
        className="webcent pt-0"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="minicontainer">
                <div className="title">
                  <h3>Listen. To Experts.</h3>
                </div>
                <div>
                  <Slider {...settings3}>
                    <div>
                      <div className="reviewbox expert">
                        <div className="commentcircle">
                          <div className="comment">
                            <div className="profile">
                              <div className="climg">
                                <img src={Nitin} alt="" />
                              </div>

                              <div className="name">
                                <h4>Mr. Nitin Murarka</h4>
                                <h5>
                                  Head – Research (Derivatives), SMC Global
                                </h5>
                              </div>
                            </div>
                            <div className="commentcent">
                              <p>
                                Professionally a CA, he has strong 18 years of
                                experiences in the Derivatives. His
                                specialization is Data Interpretation, which
                                involves quasi-statistical techniques and formal
                                statistics. “Nifty ke Nisanebaaz”, “Nitin Ki
                                PCR”, and “Nitin Option Wale "are some of his
                                popular shows which are telecast in news
                                channels such as Zee Business and CNBC Awaaz.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="reviewbox expert">
                        <div className="commentcircle">
                          <div className="comment">
                            <div className="profile">
                              <div className="climg">
                                <img src={Saurabh} alt="" />
                              </div>
                              <div className="name">
                                <h4>Mr. Saurabh Jain</h4>
                                <h5>AVP - Equity Research Editor Wise Money</h5>
                              </div>
                            </div>
                            <div className="commentcent">
                              <p>
                                Possesses strong 17 years of experiences in
                                Equities, he is the regular speaker on the
                                various news channels such as ET Now, CNBC Awaaz
                                and Zee Business. Also have several references
                                in various News agencies such as Reuter,
                                National newspapers and Publications. He is the
                                Editor of the Weekly Magazine "Wise Money",
                                which have wide circulation.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="reviewbox expert">
                        <div className="commentcircle">
                          <div className="comment">
                            <div className="profile">
                              <div className="climg">
                                <img src={Vandana} alt="" />
                              </div>
                              <div className="name">
                                <h4>Mrs. Vandana Bharti</h4>
                                <h5>
                                  AVP - Commodity Research, Editor Commodity
                                  Outlook
                                </h5>
                              </div>
                            </div>
                            <div className="commentcent">
                              <p>
                                Professionally a MBA, possesses strong 16 years
                                of experiences in domestic as well as in
                                international Commodity market. She is the
                                regular speaker on the various news channels
                                such as CNBC Aawaz, Zee business, Doordarshan
                                etc. Also have several references in various
                                News agencies such as Reuter, National
                                newspapers and Publications.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpertSlider;

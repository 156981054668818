import Ticon01 from "../../../images/WATCHLIST.webp";
import Ticon02 from "../../../images/LIVE-STOCK-UPDATE.webp";
import Ticon03 from "../../../images/POSITION.webp";
import Ticon04 from "../../../images/RESEARCH-CALL.webp";
import Ticon05 from "../../../images/PORTFOLIO.webp";
import Ticon06 from "../../../images/MARKET-MOVER.webp";
import Frame1 from "../../../images/app01.webp";
import Frame2 from "../../../images/app02.webp";
import Frame3 from "../../../images/app03.webp";
import Frame4 from "../../../images/app04.webp";
import Frame5 from "../../../images/app05.webp";
import Slider from "react-slick";
import Ios from "../../../images/app.webp";
import Android from "../../../images/google.webp";

const StockIntroductionSmc = () => {
    var settings = {
        dots: true,
        navigation: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 300,
        autoplaySpeed: 5000,
        cssEase: "linear",
    };
    return (
        <div className="webcent">
            <div className="title mb-3">
                <h3>Introducing SMC Ace App</h3>
                <p className="pull-left m-0">
                    The trading app that you can trust in
                </p>
            </div>
            <div className="moblay w-100 m-0 tradingapp justify-content-between">
                <div className="mobcol">
                    <div className="mobbox">
                        <div className="mobicon">
                            <img src={Ticon01} alt="Stock" />
                        </div>
                        <div className="mobicon">
                            <h4>Watchlist</h4>
                        </div>
                    </div>
                    <div className="mobbox">
                        <div className="mobicon">
                            <img src={Ticon02} alt="Stock" />
                        </div>
                        <div className="mobicon">
                            <h4>Live Equities Update</h4>
                        </div>
                    </div>
                    <div className="mobbox">
                        <div className="mobicon">
                            <img src={Ticon03} alt="Stock" />
                        </div>
                        <div className="mobicon">
                            <h4>Positions</h4>
                        </div>
                    </div>
                    <div className="mobbox">
                        <div className="mobicon">
                            <img src={Ticon04} alt="Stock" />
                        </div>
                        <div className="mobicon">
                            <h4>Research Calls</h4>
                        </div>
                    </div>
                    <div className="mobbox">
                        <div className="mobicon">
                            <img src={Ticon05} alt="Stock" />
                        </div>
                        <div className="mobicon">
                            <h4>Portfolio Report</h4>
                        </div>
                    </div>
                    <div className="mobbox">
                        <div className="mobicon">
                            <img src={Ticon06} alt="Stock" />
                        </div>
                        <div className="mobicon">
                            <h4>Market Movers</h4>
                        </div>
                    </div>
                    <div className="downloadcont m-0">
                        <div className="downloadimg">
                            <span>
                                <a href="https://play.google.com/store/apps/details?id=com.rs.smc&hl=en&gl=US&pli=1">
                                    <img src={Android} alt="Play Store" />
                                </a>
                            </span>
                            <span>
                                <a href="https://apps.apple.com/in/app/smc-ace/id1447075606">
                                    <img src={Ios} alt="IOS" />
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="mobcol">
                    <div className="mobileframeslide">
                        <div className="mframslder">
                            <Slider {...settings}>
                                <div>
                                    <div className="framslider">
                                        <img src={Frame1} alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="framslider">
                                        <img src={Frame2} alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="framslider">
                                        <img src={Frame3} alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="framslider">
                                        <img src={Frame4} alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="framslider">
                                        <img src={Frame5} alt="" />
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StockIntroductionSmc;
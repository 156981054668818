import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const MutualFaq = () => {
  return (
    <>
      <div className="webcent">
        <div className="title text-center justify-content-center">
          <h3>Frequently Asked Questions</h3>
        </div>
        <div className="faqcont">
          <Accordion allowZeroExpanded="true">
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What is a Mutual Fund?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="accordiancont">
                  <p>
                    A Mutual Fund is a trust that pools the savings of a number
                    of investors who share a common financial goal. The corpus
                    of the fund is then deployed in investment alternatives that
                    help to meet predefined investment objectives. The income
                    earned through these investments and the capital
                    appreciation realised are shared by its unit holders, in
                    proportion to the number of units owned by them.
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What are the benefits of investing in mutual funds?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="accordiancont">
                  <p>
                    Some of the major benefits on investing in a mutual fund
                    are: - Diversification - Professional management -
                    Convenience - Liquidity - Variety of schemes and types - Tax
                    benefits
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What are the different types of Mutual Funds?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="accordiancont">
                  <h5 style={{ textAlign: "left" }}>. Equity Funds :</h5>
                  <p style={{ textAlign: "left" }}>
                    An equity fund is a mutual fund that invests principally in
                    stocks. There are several types of equity funds like
                    Diversified, Sector and thematic, Large-cap, Mid-cap,
                    Small-cap, Multi-cap, Index funds etc. Equity Linked Savings
                    Scheme (ELSS) qualifies for tax exemptions upto Rs. 1.50
                    Lakhs under section (u/s) 80C of the Indian Income Tax Act.
                  </p>
                  <h5 style={{ textAlign: "left" }}>. Debt Funds :</h5>
                  <p style={{ textAlign: "left" }}>
                    These funds generates returns for the investors by investing
                    in a mix of debt or fixed income securities such as
                    Government Securities, Corporate Bonds, other debt
                    securities of different time horizons.
                  </p>
                  <h5 style={{ textAlign: "left" }}> . Liquid Funds :</h5>
                  <p style={{ textAlign: "left" }}>
                    These funds invests primarily in money market instruments
                    like certificate of deposits, treasury bills, commercial
                    papers and term deposits and focuses on maintaining
                    liquidity and safety of the investments. Lower maturity
                    period of these underlying assets helps a fund manager in
                    meeting the redemption demand from investors.
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What is an open ended mutual fund?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="accordiancont">
                  <p>
                    Open-ended funds can be bought and sold at any time; they
                    have no fixed tenure.
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What is a close ended mutual fund?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="accordiancont">
                  <p>
                    You can buy units of close-ended mutual funds only when a
                    mutual fund company launches the fund. Once you buy them,
                    you have to hold your investment for a fixed tenure.
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What is a Systematic Investment Plan (SIP)?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="accordiancont">
                  <p>
                    A Systematic Investment Plan (SIP) is a convenient method of
                    investing in mutual funds. Under this plan, an investor
                    contributes a fixed amount towards the mutual fund scheme at
                    regular intervals, and gets units at the prevailing NAV.
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What are the benefits of investing in a SIP?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="accordiancont">
                  <p>
                    Investing in SIP offers two major benefits: - You can start
                    investing with a small amount - You can average out your
                    investment, as SIP involves buying units at different points
                    of time and at different NAV levels.
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What is a systematic withdrawal plan?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="accordiancont">
                  <p>
                    Under a Systematic Withdrawal Plan (SWP), an investor
                    redeems a fixed number of mutual fund units at regular
                    intervals.
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>What is NAV?</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="accordiancont">
                  <p>
                    NAV stands for Net Asset Value of a mutual fund. This is
                    basically the price of one unit of a mutual fund.
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Do Mutual funds guarantee safety of capital?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="accordiancont">
                  <p>
                    As per SEBI rules mutual funds cannot guarantee you assured
                    returns.
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Can units of the Scheme be held in dematerialized form?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="accordiancont">
                  <p>
                    Yes, applicants intending to hold units in dematerialized
                    form will be required to have a beneficiary account with a
                    Depository Participant (DP) of the NSDL/CDSL and will be
                    required to mention in the application form DP's Name, DP ID
                    No. and Beneficiary Account No. with the DP, at the time of
                    purchasing units.
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default MutualFaq;

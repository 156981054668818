import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import { formatNumber } from "../../../../components/common";
import { NavLink } from "react-router-dom";
const IpoFaqDetails = ({ subscriptionDetails, ipoTimeline, openIpoData }) => {
    return (
        <div className="webcent" itemscope="" itemtype="http://schema.org/FAQPage">
            <div className="title">
                <h3>Frequently Asked Questions</h3>
            </div>
            <div className="faqcont">
                <Accordion allowZeroExpanded="true">
                    <AccordionItem>
                        <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                            <AccordionItemHeading>
                                <AccordionItemButton itemprop="name" >
                                    How to apply for {subscriptionDetails?.CompanyName || "NA"} IPO?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                    <div itemProp="text">
                                        <p>
                                            You can invest easily into {subscriptionDetails?.CompanyName || "NA"} from <NavLink to={"https://play.google.com/store/search?q=smc+ace+2.0&c=apps&hl=en_IN"}>SMC's Mobile App</NavLink>  or dedicated <NavLink to={"https://ipo.smcindiaonline.org:8327/ipo"}>Website </NavLink> . Start Investing in few easy steps:
                                        </p>
                                        <p>
                                            1. Install SMC's mobile app or visit the website.
                                        </p>
                                        <p>
                                            2. Search for the {subscriptionDetails?.CompanyName || "NA"} and select it.
                                        </p>
                                        <p>
                                            3. Click <NavLink to={"https://ipo.smcindiaonline.org:8327/ipo"}>Apply Now</NavLink>  and start investing.
                                        </p>
                                        <p>
                                            4. For new clients, complete your ekyc and start investing.
                                        </p>
                                    </div>
                                </div>
                            </AccordionItemPanel>
                        </div>
                    </AccordionItem>
                    <AccordionItem>
                        <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                            <AccordionItemHeading>
                                <AccordionItemButton itemprop="name">
                                    What is the {subscriptionDetails?.CompanyName || "NA"} IPO Allotment Date?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                    <div itemProp="text">
                                        <p>
                                            The allotment date of {subscriptionDetails?.CompanyName || "NA"} is {ipoTimeline?.AllotmentDate || "NA"}.
                                        </p>
                                    </div>
                                </div>
                            </AccordionItemPanel>
                        </div>
                    </AccordionItem>
                    <AccordionItem>
                        <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                            <AccordionItemHeading>
                                <AccordionItemButton itemprop="name">
                                    What is the open date of {subscriptionDetails?.CompanyName || "NA"} IPO?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                    <div itemProp="text">
                                        <p>
                                            The open date of {subscriptionDetails?.CompanyName || "NA"} IPO is {ipoTimeline?.ipostartdate || "NA"}.
                                        </p>
                                    </div>
                                </div>
                            </AccordionItemPanel>
                        </div>
                    </AccordionItem>
                    <AccordionItem>
                        <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                            <AccordionItemHeading>
                                <AccordionItemButton itemprop="name">
                                    What is the lot size of {subscriptionDetails?.CompanyName || "NA"} IPO?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                    <div itemProp="text">
                                        <p>
                                            {subscriptionDetails?.CompanyName || "NA"} IPO lot size is {formatNumber(
                                                openIpoData?.MIN_APPLN
                                            ) === "NaN"
                                                ? "NA"
                                                : formatNumber(
                                                    openIpoData?.MIN_APPLN
                                                )}{" "}Shares.
                                        </p>
                                    </div>
                                </div>
                            </AccordionItemPanel>
                        </div>
                    </AccordionItem>
                    <AccordionItem>
                        <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                            <AccordionItemHeading>
                                <AccordionItemButton itemprop="name">
                                    What is the size of ${subscriptionDetails?.CompanyName || "NA"} IPO?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                    <div itemProp="text">
                                        <p>
                                            The size of {subscriptionDetails?.CompanyName || "NA"} IPO is <i className="finr"></i>{" "}
                                            {
                                                openIpoData?.IssueSize?.split("-")[1] === undefined
                                                    ? openIpoData?.IssueSize
                                                    : formatNumber(openIpoData?.IssueSize?.split("-")[1]) === "NaN"
                                                        ? "NA"
                                                        : formatNumber(openIpoData?.IssueSize?.split("-")[1])
                                            }
                                            Cr.
                                        </p>
                                    </div>
                                </div>
                            </AccordionItemPanel>
                        </div>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    )
}
export default IpoFaqDetails;
import React from "react";
import CustomSwitch from "./toggle/ExchangeSwitch";
const IPO_URL = process.env.REACT_APP_IPO_URL;

const IPOCTAButton = () => {
  const openOrderBook = () => {
    window.open(`${IPO_URL}/orderbooklogin`);
  };
  return (
    <>
      <CustomSwitch />
      {/* <div
        style={{
          cursor: "pointer",
        }}
        className="btnn col-6 text-center"
        onClick={() => openOrderBook()}
      >
        Order Book
      </div> */}
    </>
  );
};

export default IPOCTAButton;

import React, { useContext } from "react";
import Footer from "../../components/Footer";
import Investbanner from "../../images/Trade.webp";
import icon01 from "../../images/tech01.webp";
import icon02 from "../../images/tech02.webp";
import icon03 from "../../images/tech03.webp";
import icon04 from "../../images/tech04.webp";
import icon05 from "../../images/tech05.webp";
import icon06 from "../../images/tech06.webp";
import { Benefits } from "../../components/Benefits";
import { Learning } from "../../components/Learning";
import { Link } from "react-router-dom";
import { Form } from "../../components/Form";
import { Context } from "../../context";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import SEO from "../../components/common/SEO";
import { SEO_URL } from "../../utils/constants";
import Faq from "./Faq";
import RoadsAndTradeEasy from "./RoadsAndTradeEasy";
import { seoData } from "../../utils/Data";

export const Trading = () => {
  const isMobile = window.innerWidth <= 540;
  const { loading } = useContext(Context);

  return (
    <>
      <Header />
      <SEO
        title={seoData.trade.title}
        description={seoData.trade.description}
        name="Company name."
        type="article"
        url={seoData.trade.url}
        image={seoData.trade.image}
      />
      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Trade</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1>Simplifying Trading. Amplifying Growth. </h1>
                  <p>
                    Stocks, Derivatives, Currencies, Commodities - Reach out and
                    grab it all
                  </p>
                </div>
                <div className="bannergt">
                  <div className="mobileno">
                    <img src={Investbanner} alt="Hassle-free investing" />
                  </div>
                </div>
                {isMobile ? (
                  <div
                    style={{
                      marginTop: "-60px",
                      width: "100%",
                    }}
                  >
                    <Form catID="Trade" />
                  </div>
                ) : (
                  <Form catID="Trade" />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <RoadsAndTradeEasy />
          <div
            className="webcent p-0"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="calculator">
                    <div className="title">
                      <h3>SMC - Made easy to Trade easy </h3>
                    </div>
                    <div className="crosssection">
                      <div className="crossrow">
                        <div className="leftcross">
                          <div className="tradingshape">
                            <img src={icon01} alt="Quick option" />
                          </div>
                        </div>
                        <div className="rightcross">
                          <h3>Trading just got faster </h3>
                          <p>
                            No more lags, place your trade swiftly with a swipe
                            of your fingers from the Options Chain
                          </p>
                        </div>
                      </div>
                      <div className="crossrow">
                        <div className="leftcross">
                          <div className="tradingshape">
                            <img src={icon02} alt="Quick option" />
                          </div>
                        </div>
                        <div className="rightcross">
                          <h3>Analyse your trades</h3>
                          <p>
                            Analyze your trades on charts with TradingView and
                            Chart IQ to gauge the maximum possible profit, loss,
                            breakeven points, and much more
                          </p>
                        </div>
                      </div>
                      <div className="crossrow">
                        <div className="leftcross">
                          <div className="tradingshape">
                            <img src={icon03} alt="Quick option" />
                          </div>
                        </div>
                        <div className="rightcross">
                          <h3>Basket Orders </h3>
                          <p>
                            Place upto 10 different orders with basket orders,
                            execute your strategies and get margin benefit by
                            hedging your positions.
                          </p>
                        </div>
                      </div>
                      <div className="crossrow">
                        <div className="leftcross">
                          <div className="tradingshape">
                            <img src={icon04} alt="Quick option" />
                          </div>
                        </div>
                        <div className="rightcross">
                          <h3>Know your opportunities </h3>
                          <p>
                            Know the most traded Options based on Open Interest
                            and trading volume to explore long position or short
                            position.
                          </p>
                        </div>
                      </div>
                      <div className="crossrow">
                        <div className="leftcross">
                          <div className="tradingshape">
                            <img src={icon05} alt="Quick option" />
                          </div>
                        </div>
                        <div className="rightcross">
                          <h3>Market updates </h3>
                          <p>
                            Daily market updates, be it corporate actions, stock
                            results, earnings, shareholding patterns to major
                            announcements, track them all in one place.
                          </p>
                        </div>
                      </div>
                      <div className="crossrow">
                        <div className="leftcross">
                          <div className="tradingshape">
                            <img src={icon06} alt="Quick option" />
                          </div>
                        </div>
                        <div className="rightcross">
                          <h3>Expert Research </h3>
                          <p>
                            Expert advisory on Derivative contracts for
                            Intraday, short-term, and Baskets. and Expiry Day
                            for knowledge-empowered decision-making.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Benefits />
          <Learning />
          <Faq />
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

import { formatNumber } from "../../../../components/common";
const StocksOneYrPerformanceIndicator = ({ historicalData, quatesData }) => {
    return (
        <>
            <div className="calheading mt-3">
                <h4>1- Year Performance Indicator</h4>
            </div>
            <div className="performanceindicator">
                <div className="performancetabs">
                    <span>
                        <i className="finr"></i>
                        {historicalData?.["1yearlow"] &&
                            formatNumber(historicalData?.["1yearlow"]) || "NA"}
                    </span>
                    <span>
                        <i className="finr"></i>
                        {formatNumber(
                            quatesData?.Price || quatesData?.price
                        ) === "NaN"
                            ? "NA"
                            : formatNumber(
                                quatesData?.Price || quatesData?.price
                            )}
                    </span>
                    <span>
                        <i className="finr"></i>
                        {historicalData?.["1yearhigh"] &&
                            formatNumber(historicalData?.["1yearhigh"]) || "NA"}
                    </span>
                </div>
                {/* up side and down side will be added in future */}
                {/* <div className="performancearrow">
                              <div className="performancearrowleft down d-flex g-10">
                                <span className="priceup px-2">1.15%</span>
                                <span className="text-nowrap">down side</span>
                              </div>
                              <div className="performancearrowright d-flex g-10 flex-row-reverse">
                                <span className="priceup">0.45%</span>
                                <span className="text-nowrap mx-2">
                                  up side
                                </span>
                              </div>
                            </div> */}
            </div>
        </>
    )
}

export default StocksOneYrPerformanceIndicator;
import React, { useState, useEffect, useContext } from "react";
import { getIncomeStatement } from "../../../utils/APICollection";
import { formatNumber } from "../../../components/common";
import { Context } from "../../../context";
import { ThreeDots } from "react-loader-spinner";

const StockIncomeStatements = ({ financeType }) => {
    const [incomeStatementData, setIncomeStatementData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const fetchIncomeStatements = async () => {
        setIsLoading(true);
        try {
            const data = await getIncomeStatement(window.location.pathname.split("/")[3], financeType);
            setIncomeStatementData(data?.data?.data);
            setIsLoading(false)
        } catch (err) {
            console.log(err);
        }
    };
    const keyValueList = {
        COLUMNNAME: "Financial Year",
        Y202309: "FY 2023-09",
        Y202303: "FY 2023-03",
        Y202209: "FY 2022-09",
        Y202203: "FY 2022-03",
        202403: "Mar 2024",
        202303: "Mar 2023",
        202203: "Mar 2022",
        202103: "Mar 2021",
        202003: "Mar 2020",
        201903: "Mar 2019",
        2023: "1-Year",
        2021: "3-Year",
        2019: "5-Year",
    };

    const getValueOfKey = (key) => {
        return keyValueList[key];
    };

    useEffect(() => {
        fetchIncomeStatements();
    }, [financeType, window.location.pathname.split("/")[4]]);

    return (
        <div className="webcent financialsblock">
            {incomeStatementData === null && (
                <div className="ipotable defaulttable">
                    Income Statement Data Not Available
                </div>
            )}
            {isLoading ?
                <ThreeDots
                    height="100"
                    width="100"
                    radius="10"
                    color="#3AA29F"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                    visible={true}
                /> : (Array.isArray(incomeStatementData) &&
                    <div className="ipotable defaulttable">
                        <table className="table">
                            <thead>
                                <tr>
                                    {/* {incomeStatementData.map(item => (
                                    <th scope="col">{getValueOfKey(item?.YRC)}</th>
                                  ))} */}
                                    <th scope="col">Financial Year</th>
                                    {incomeStatementData?.map((item) => (
                                        <th scope="col">
                                            {getValueOfKey(item?.YRC)}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {/* {incomeStatementData
                                  ?.filter((item) =>
                                    showIncomeStatement.some(
                                      (showIncomeStatement) =>
                                        showIncomeStatement === item.COLUMNNAME
                                    )
                                  )
                                  ?.map((income) => (
                                    <tr>
                                      {Object.values(income).map((value) => (
                                        <td>
                                          {typeof value === "number"
                                            ? value.toFixed(2)
                                            : value}
                                        </td>
                                      ))}
                                    </tr>
                                  ))} */}
                                <tr>
                                    <td>All Expenditure Cr</td>
                                    <td>

                                        {incomeStatementData[0]
                                            ?.All_Expenditure &&
                                            formatNumber(
                                                incomeStatementData[0]?.All_Expenditure.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>

                                        {incomeStatementData[1]
                                            ?.All_Expenditure &&
                                            formatNumber(
                                                incomeStatementData[1]?.All_Expenditure.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>

                                        {incomeStatementData[2]
                                            ?.All_Expenditure &&
                                            formatNumber(
                                                incomeStatementData[2]?.All_Expenditure.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>

                                        {incomeStatementData[3]
                                            ?.All_Expenditure &&
                                            formatNumber(
                                                incomeStatementData[3]?.All_Expenditure.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[4]
                                            ?.All_Expenditure &&
                                            formatNumber(
                                                incomeStatementData[4]?.All_Expenditure.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Operating Profit Cr</td>
                                    <td>
                                        {incomeStatementData[0]
                                            ?.Operating_Profit &&
                                            formatNumber(
                                                incomeStatementData[0]?.Operating_Profit.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[1]
                                            ?.Operating_Profit &&
                                            formatNumber(
                                                incomeStatementData[1]?.Operating_Profit.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[2]
                                            ?.Operating_Profit &&
                                            formatNumber(
                                                incomeStatementData[2]?.Operating_Profit.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[3]
                                            ?.Operating_Profit &&
                                            formatNumber(
                                                incomeStatementData[3]?.Operating_Profit.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[4]
                                            ?.Operating_Profit &&
                                            formatNumber(
                                                incomeStatementData[4]?.Operating_Profit.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>EBIDTA</td>
                                    <td>
                                        {incomeStatementData[0]?.EBIDTA &&
                                            formatNumber(
                                                incomeStatementData[0]?.EBIDTA.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[1]?.EBIDTA &&
                                            formatNumber(
                                                incomeStatementData[1]?.EBIDTA.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[2]?.EBIDTA &&
                                            formatNumber(
                                                incomeStatementData[2]?.EBIDTA.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[3]?.EBIDTA &&
                                            formatNumber(
                                                incomeStatementData[3]?.EBIDTA.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[4]?.EBIDTA &&
                                            formatNumber(
                                                incomeStatementData[4]?.EBIDTA.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Profit Before Tax</td>
                                    <td>
                                        {incomeStatementData[0]
                                            ?.Profit_Before_Tax &&
                                            formatNumber(
                                                incomeStatementData[0]?.Profit_Before_Tax.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[1]
                                            ?.Profit_Before_Tax &&
                                            formatNumber(
                                                incomeStatementData[1]?.Profit_Before_Tax.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[2]
                                            ?.Profit_Before_Tax &&
                                            formatNumber(
                                                incomeStatementData[2]?.Profit_Before_Tax.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[3]
                                            ?.Profit_Before_Tax &&
                                            formatNumber(
                                                incomeStatementData[3]?.Profit_Before_Tax.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[4]
                                            ?.Profit_Before_Tax &&
                                            formatNumber(
                                                incomeStatementData[4]?.Profit_Before_Tax.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tax</td>
                                    <td>
                                        {incomeStatementData[0]?.TAX &&
                                            formatNumber(
                                                incomeStatementData[0]?.TAX.toFixed(2)
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[1]?.TAX &&
                                            formatNumber(
                                                incomeStatementData[1]?.TAX.toFixed(2)
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[2]?.TAX &&
                                            formatNumber(
                                                incomeStatementData[2]?.TAX.toFixed(2)
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[3]?.TAX &&
                                            formatNumber(
                                                incomeStatementData[3]?.TAX.toFixed(2)
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[4]?.TAX &&
                                            formatNumber(
                                                incomeStatementData[4]?.TAX.toFixed(2)
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Net Income</td>
                                    <td>
                                        {incomeStatementData[0]?.Net_Income &&
                                            formatNumber(
                                                incomeStatementData[0]?.Net_Income.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[1]?.Net_Income &&
                                            formatNumber(
                                                incomeStatementData[1]?.Net_Income.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[2]?.Net_Income &&
                                            formatNumber(
                                                incomeStatementData[2]?.Net_Income.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[3]?.Net_Income &&
                                            formatNumber(
                                                incomeStatementData[3]?.Net_Income.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[4]?.Net_Income &&
                                            formatNumber(
                                                incomeStatementData[4]?.Net_Income.toFixed(
                                                    2
                                                )
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>EPS</td>
                                    <td>
                                        {incomeStatementData[0]?.EPS &&
                                            formatNumber(
                                                incomeStatementData[0]?.EPS.toFixed(2)
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[1]?.EPS &&
                                            formatNumber(
                                                incomeStatementData[1]?.EPS.toFixed(2)
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[2]?.EPS &&
                                            formatNumber(
                                                incomeStatementData[2]?.EPS.toFixed(2)
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[3]?.EPS &&
                                            formatNumber(
                                                incomeStatementData[3]?.EPS.toFixed(2)
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[4]?.EPS &&
                                            formatNumber(
                                                incomeStatementData[4]?.EPS.toFixed(2)
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>DPS</td>
                                    <td>
                                        {incomeStatementData[0]?.DPS &&
                                            formatNumber(
                                                incomeStatementData[0]?.DPS.toFixed(2)
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[1]?.DPS &&
                                            formatNumber(
                                                incomeStatementData[1]?.DPS.toFixed(2)
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[2]?.DPS &&
                                            formatNumber(
                                                incomeStatementData[2]?.DPS.toFixed(2)
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[3]?.DPS &&
                                            formatNumber(
                                                incomeStatementData[3]?.DPS.toFixed(2)
                                            )}
                                    </td>
                                    <td>
                                        {incomeStatementData[4]?.DPS &&
                                            formatNumber(
                                                incomeStatementData[4]?.DPS.toFixed(2)
                                            )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
        </div>
    )
}
export default StockIncomeStatements;
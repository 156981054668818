import Esteps from "../../../images/4-steps.webp";
const IpoApply = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <div className="webcent">
            <div className="title">
                <h3>Apply IPO With 3 Easy Steps</h3>
            </div>
            <div className="downloadapp justify-content-between">
                <div className="apprgt">
                    <img src={Esteps} alt="easysteps" />
                </div>
                <div className="applef">
                    <div className="stepstn">
                        <ul>
                            <li>
                                <strong>Step 1 </strong>
                                <p>Open SMC App and click IPO</p>
                            </li>
                            <li>
                                <strong>Step 2 </strong>
                                <p>
                                    Select the preferred IPO in the upcoming
                                    IPOs list
                                </p>
                            </li>
                            <li>
                                <strong>Step 3 </strong>
                                <p>Bid for the IPO</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="mbtn">
                <div className="btnn" style={{ cursor: "pointer" }}>
                    <span onClick={scrollToTop}>Get Started</span>
                </div>
            </div>
        </div>
    )
}

export default IpoApply;
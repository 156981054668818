import React, { useState, useEffect } from "react";
import { getCompanyNewsDetails, getStockNewsData } from "../../../../utils/APICollection";
const StockNews = ({ bioData }) => {
    const [stockNewsData, setStockNewsData] = useState([]);

    const fetchStockNewsData = async () => {
        try {
            const data = await getStockNewsData(window.location.pathname.split("/")[3]);
            const newsData = data?.data?.data
                ?.slice(0, 10)
                .map((news) => ({ ...news, showDetais: false }));
            setStockNewsData(newsData);
        } catch (err) {
            console.log("err", err);
        }
    };
    const getDetailedNews = async (sno, index) => {
        try {
            const data = await getCompanyNewsDetails(sno)
            const result = stockNewsData?.map((item) =>
                item?.sno === sno
                    ? {
                        ...item,
                        showDetais: true,
                        detailedNews: data?.data?.data[0]?.memo
                            .replace(/<\/?p>/gi, "")
                            .replace(/<\/?P>/gi, ""),
                    }
                    : item
            );
            setStockNewsData(result);
        } catch (err) {
            console.log("error", err);
        }
    };

    useEffect(() => {
        fetchStockNewsData();
    }, [window.location.pathname.split("/")[3]]);
    return (
        <div className="webcent">
            <div className="calheading">
                <h3>{bioData?.LNAME} News</h3>
            </div>
            {stockNewsData === undefined && (
                <h6>Stock News Not Available</h6>
            )}
            {stockNewsData && (
                <div className="tcsnews">
                    {stockNewsData?.map((item, index) => (
                        <div className="tcsnewsbox">
                            <h4>{item?.caption}</h4>
                            {/* {item?.showDetails ? <p>{item?.detailedNews}123</p> : <p onClick={() => getDetailedNews(item?.sr_no, index)}>Read More</p>} */}
                            {/* {item?.showDetais ? (
                                <p>{item?.detailedNews}</p>
                            ) : (
                                <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        getDetailedNews(item?.sno, index)
                                    }
                                >
                                    Read More
                                </p>
                            )} */}
                            <p>
                                {item?.arttext?.replace(/<\/?p>/gi, "").replace(/<\/?P>/gi, "").replace(/<\/?b>/gi, "").replace(/<\/?i>/gi, "")}
                            </p>
                            <p>Publish Date {item?.DATE?.split("T")[0]}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default StockNews;
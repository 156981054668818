import React from "react";
import Chart from "react-apexcharts";
import { ThreeDots } from "react-loader-spinner";

export const LineCharts = ({
  priceData = [],
  tenure,
  heading = "Price",
  isLoading,
}) => {
  if (priceData === "Invalid Input Dates") {
    return (
      <>
        <div style={{ height: "450px", textAlign: "center" }}>
          <h6>Data Not Available</h6>
        </div>
      </>
    );
  }
  const filteredData = priceData?.map((data) => [
    data?.upd_Date || data?.nav_date,
    data?.price || data?.Price || data?.nav,
  ]);
  const series = [
    {
      name: `<strong>${heading}</strong>`,
      data: filteredData,
      // sample data format for charts
      // data: [
      //   [new Date('2023-05-22').getTime(), 160.40],
      //   [new Date('2023-05-23').getTime(), 159.71],
      //   [new Date('2023-05-24').getTime(), 159.90],
      //   [new Date('2023-05-25').getTime(), 160.76],
      //   [new Date('2023-05-26').getTime(), 161.32],
      //   [new Date('2023-05-27').getTime(), 161.77],
      //   [new Date('2023-05-28').getTime(), 162.39]
      // ]
    },
  ];

  const timeOptions = {
    chart: {
      type: "line",
      fontFamily: "AvertaStd, Arial, sans-serif",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#44b6a7"],
    xaxis: {
      type: "datetime",
      labels: {
        show: false,
        datetimeUTC: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    stroke: {
      show: true,
      width: 3,
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      inverseOrder: false,
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
      x: {
        show: true,
        format: "dd MMM yyyy HH:mm TT",
        // format: "dd MMM yyyy H:mm",
      },
      y: {
        formatter: function (value) {
          return `<strong> <font>₹</font>${value}</strong>`;
        },
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      marker: {
        show: false,
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
    },
  };

  const dateOptions = {
    chart: {
      type: "line",
      fontFamily: "AvertaStd, Arial, sans-serif",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#44b6a7"],
    xaxis: {
      type: "datetime",
      labels: {
        show: false,
        datetimeUTC: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    stroke: {
      show: true,
      width: 3,
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      inverseOrder: false,
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
      x: {
        show: true,
        format: "dd MMM yyyy",
        // format: "dd MMM yyyy H:mm",
      },
      y: {
        formatter: function (value) {
          return `<strong> <font>₹</font>${value}</strong>`;
        },
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      marker: {
        show: false,
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
    },
  };

  return (
    <>
      <div className="apexchart">
        {isLoading ? (
          <ThreeDots
            height="100"
            width="100"
            radius="10"
            color="#3AA29F"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
            }}
            // wrapperClassName=""
            visible={true}
          />
        ) : (
          <Chart
            options={tenure === "1D" ? timeOptions : dateOptions}
            series={series}
            type="line"
            height={350}
          />
        )}
      </div>
    </>
  );
};

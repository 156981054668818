import { ThreeDots } from "react-loader-spinner";
import { Avatar } from "@mui/material";
import { stringAvatar } from "../../../components/common/avatar";
import { useContext } from "react";
import { Context } from "../../../context";
import { useState, useEffect } from "react";
import { getUpcomingIpo } from "../../../utils/APICollection";

const UpcomingIpos = () => {
  const [upcomingIpoLoading, setUpcomingIpoLoading] = useState(false);
  const { exchange, openIPOData, setOpenIPOData } = useContext(Context);
  const isMobile = window.innerWidth <= 1040;

  const getMonth = (date) => {
    const d = new Date(date);
    const monthList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return monthList[d.getMonth()];
  };

  const getFullDate = (date) => {
    const d = new Date(date);
    return d.getDate() + " " + getMonth(date) + " " + d.getFullYear();
  };

  const fetchUpcomingIpo = async () => {
    setUpcomingIpoLoading(true);
    try {
      const data = await getUpcomingIpo(exchange);
      setOpenIPOData(data?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setUpcomingIpoLoading(false);
    }
  };
  useEffect(() => {
    fetchUpcomingIpo();
  }, [exchange]);
  return (
    <div className="ipostn">
      <div className="ipotitle titleipo">
        <h4>Upcoming IPO</h4>
      </div>
      {/* <div className={isMobile ? "" : "ipotable"}> */}
      <div className={isMobile ? "ipotable defaulttable" : "ipotable"}>
        <table
          className="table"
          // style={{ width: isMobile && "600px" }}
        >
          <thead>
            <tr>
              <th scope="col" className={isMobile ? "mheadname" : ""}>
                Name
              </th>
              <th scope="col" className={isMobile ? "mheadname" : ""}>
                Bidding Dates
              </th>
              <th scope="col" className={isMobile ? "mheadname" : ""}>
                Price Range
              </th>
            </tr>
          </thead>
          <tbody>
            {upcomingIpoLoading ? (
              <ThreeDots
                height="90"
                width="90"
                radius="10"
                color="#3AA29F"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                }}
                // wrapperClassName=""
                visible={true}
              />
            ) : openIPOData == null ? (
              <tr>
                <td scope="row">No Data Found</td>
              </tr>
            ) : (
              openIPOData?.map((stock) => {
                return (
                  <tr>
                    <td scope="row">
                      <div className="ipologo">
                        <Avatar
                          sx={{
                            background: "#405cb2",
                            width: 36,
                            height: 36,
                            mr: 2,
                          }}
                          {...stringAvatar(stock?.LNAME)}
                        />
                        <div>
                          {/* <Tooltip
                                                    title={stock.companyName}
                                                    placement="right-start"
                                                    arrow
                                                  > */}
                          {/* <div className="ellipsis"> */}
                          {stock?.LNAME}
                          {/* </div> */}
                          {/* </Tooltip> */}
                          {/* <strong>
                                                    {stock.companySymbol}
                                                  </strong> */}
                        </div>
                      </div>
                    </td>
                    <td scope="row">
                      {" "}
                      {getFullDate(stock?.OPENDATE) +
                        " - " +
                        getFullDate(stock?.CLOSDATE)}
                    </td>
                    <td scope="row">
                      <i className="finr">{stock?.ISSUEPRICE}</i> {" - "}
                      <i className="finr">{stock?.ISSUEPRI2}</i>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UpcomingIpos;

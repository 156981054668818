import React from "react";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import AboutBanner from "./AboutBanner";
import MemberDetails from "./MemberDetails";

const AboutUs = () => {
  const isMobile = window.innerWidth <= 540;
  return (
    <>
      <Header />

      <section id="banner ">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                {/* <li>
                  <Link to="/partner-us">Partner Us</Link>
                </li> */}
                <li>About Us</li>
              </ul>
            </div>
            <div className={isMobile ? "bglogom" : "bglogo"}>
              <AboutBanner isMobile={isMobile} />
            </div>
            <div className="about-banner" style={{ margin: "80px 0px" }}>
              <h1 style={{ fontSize: isMobile && "23px" }}>
                Meet the Visionaries
              </h1>
              <h2 style={{ fontSize: isMobile && "23px" }}>
                Unveiling the Dynamic Leaders Driving Our Success!
              </h2>
            </div>
            <MemberDetails isMobile={isMobile} />
            <div
              className="title"
              style={{ marginTop: isMobile ? 60 : 40, marginBottom: "20px" }}
            >
              <h3>About SMC</h3>
            </div>
            <div className="cont col-lg-9 m-auto col-12 text-center">
              <p>
                Established in 1990, SMC is a diversified financial services
                company in India offering brokerage services across the asset
                classes of equities (cash and derivatives), commodities and
                currency, investment banking, wealth management, distribution of
                third party financial products, research, financing, depository
                services, insurance broking (life & non-life), clearing
                services, mortgage advisory and real estate advisory services to
                corporates, institutions, high net worth individuals and other
                retail clients.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
export default AboutUs;

import { useState } from "react";
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  styled,
} from "@mui/material";
import Color1 from "../images/color1.svg";
import Color2 from "../images/color2.svg";
import Top1 from "../images/top1.svg";
import Top2 from "../images/top2.svg";
import City from "../images/city.svg";
import Light1 from "../images/lights1.svg";
import { Form } from "./Form";
import { NRIAndFPIForm } from "./NRIAandFPIForm";

const GradientBackground = styled(Box)(({ theme }) => ({
  minHeight: "60vh",
  background: "linear-gradient(120deg, #3F51B5 0%, #4DB6AC 100%)",
  position: "relative",
  overflow: "hidden",
  borderRadius: 10,
  //   padding: theme.spacing(4),
}));

const DecorativePattern = styled(Box)(
  ({ position, position2, positionValue, position2Value }) => ({
    position: "absolute",
    // bottom: 0,
    [position]: positionValue ? positionValue : 0,
    [position2]: position2Value ? position2Value : 0,
    width:
      position2Value !== 0
        ? "400px"
        : position == "left" || position == "right"
        ? "250px"
        : "150px",
    height: position == "left" || position == "right" ? "245px" : "150px",
    backgroundImage: `url(${
      position == "left"
        ? Color1
        : position == "top" && position2 == "left"
        ? Top1
        : position == "top" && position2 == "right" && position2Value == 0
        ? Top2
        : position2Value !== 0
        ? Light1
        : position == "right"
        ? Color2
        : null
    })`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    //   opacity: 0.8,
    zIndex: position == "left" || position == "right" ? 1 : 0,
    "@media (max-width: 600px)": {
      width: "150px",
      height: "150px",
    },
  })
);

const CityScape = styled(Box)({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  height: "200px",
  backgroundImage: `url(${City}?height=200&width=1920)`,
  backgroundSize: "cover",
  backgroundPosition: "center bottom",
  opacity: 0.5,
  zIndex: 0, // Ensure it has the lowest z-index
});

export default function FPIHeader() {
  const isMobile = window.innerWidth <= 540;
  return (
    <GradientBackground>
      <DecorativePattern
        position="left"
        position2="bottom"
        position2Value={0}
      />
      <DecorativePattern
        position="right"
        position2Value={0}
        position2="bottom"
        positionValue={"-3px"}
      />
      <DecorativePattern position="top" position2="left" position2Value={0} />
      <DecorativePattern position="top" position2="right" position2Value={0} />
      <DecorativePattern
        position={"top"}
        position2="right"
        position2Value={"42%"}
        positionValue={"22%"}
      />
      <DecorativePattern
        position={"top"}
        position2="right"
        position2Value={"-5%"}
        positionValue={"35%"}
      />

      <CityScape />
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                color: "white",
                // fontWeight: 600,
                fontSize: { xs: "2.5rem", md: "2.5rem" },
                textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                position: "relative",
                zIndex: 1,
                textAlign: { xs: "center", md: "left" },
                left: { md: "28%" },
                marginTop: { xs: "50%", md: 0 },
              }}
            >
              INDIA,
              <br />
              The Land of a Billion
              <br />
              Opportunities
            </Typography>
          </Grid>
          {!isMobile && (
            <Grid item xs={12} md={6} alignItems="center" sx={{ zIndex: 1 }}>
              <Box
                sx={{
                  width: { md: "50%" },
                  marginBottom: { md: "15px", xs: "30%" },
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                  marginLeft: { md: "15%" },
                  marginTop: { xl: "7%", md: 0 },
                  zIndex: 1, // Ensure this container and its children are on top
                }}
              >
                <NRIAndFPIForm catID="FPI" />
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </GradientBackground>
  );
}

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
const StockFaq = () => {
  return (
    <div className="webcent">
      <div className="title">
        <h3>Frequently Asked Questions</h3>
      </div>
      <div className="faqcont">
        <Accordion allowZeroExpanded="true">
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>What are Equities?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="accordiancont">
                <p>
                  Equities or Shares are a representation of ownership in a
                  particular company. If you own the Equities of a particular
                  company, it simply means that you own a portion of the
                  business/company that has issued it. Thus, Equities is a
                  ‘security’ owned by an investor.
                </p>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What is Equities trading?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="accordiancont">
                <p>
                  Buying and selling of Equities in the Equities exchange
                  marketplace is called Equities trading. Equities trading is
                  based on long-term and short-term financial goals.
                </p>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What is a Equities exchange?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="accordiancont">
                <p>
                  Equities Exchange is an online marketplace where the exchange
                  of securities takes place between buyers and sellers. In
                  India, BSE and NSE are the two giant Equities exchanges
                  regulated by SEBI guidelines.
                </p>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What is the opening and closing timing of the Equities market?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="accordiancont">
                <p>
                  It opens between 9:00 Hrs to 16.00 hrs. There are 3 sessions
                  in a day.
                </p>
                <p>→ Pre-opening session opens between: 9 AM to 9:15 AM.</p>
                <p> → Normal trading time is 9:15 AM to 3:30 PM.</p>
                <p>→ Post-closing session works between 3:30 to 4:00 PM. </p>
                <p> Markets are closed during weekends.</p>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What does Nifty50 represent?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="accordiancont">
                <p>
                  Nifty50 is the index of NSE in the Equities Market
                  representing the group of 50 companies. It consists of the
                  Equities of 50 big companies covering 24 major sectors of
                  India’s economy.
                </p>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What does Sensex represent?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="accordiancont">
                <p>
                  Sensex is an index of BSE in the Equities market. It consists
                  of the 30 largest and most actively traded Equities on the
                  BSE. Sensex covers 13 major sectors of India’s economy.
                </p>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What are Bearish and Bullish Trends in the Equities Market?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="accordiancont">
                <p>
                  An overall rise in the market indices showing an upward trend
                  in the industry's Equities prices represents Bullish Trends.
                  Investors usually gain confidence in investment. An overall
                  fall of the market following the downward trend in the prices
                  of an industry's Equities represents Bearish Trends. This
                  usually restricts the flow of investment from the investor’s
                  end.
                </p>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default StockFaq;

const StocksHistoricalPerformance = ({ historicalData }) => {
    return (
        <>
            <div className="calheading mt-3">
                <h4>Historical Performance</h4>
            </div>
            <div className="ipotable mmanagetable">
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <strong>
                                    1-Year High
                                </strong>
                            </td>
                            <td>
                                <i className="finr"></i>
                                {historicalData?.["1yearhigh"] || "NA"}
                            </td>
                            <td>
                                <strong>
                                    1-Year Low
                                    {/* {getValueOfKey(item?.year)} Low */}
                                </strong>
                            </td>
                            <td>
                                <i className="finr"></i>
                                {historicalData?.["1yearlow"] || "NA"}
                                {/* {item?.low && formatNumber(item?.low)} */}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>
                                    3-Year High
                                    {/* {getValueOfKey(item?.year)} High */}
                                </strong>
                            </td>
                            <td>
                                <i className="finr"></i>

                                {historicalData?.["3yearhigh"]|| "NA"}
                                {/* {item?.High && formatNumber(item?.High)} */}
                            </td>
                            <td>
                                <strong>
                                    3-Year Low
                                    {/* {getValueOfKey(item?.year)} Low */}
                                </strong>
                            </td>
                            <td>
                                <i className="finr"></i>
                                {historicalData?.["3yearlow"] || "NA"}
                                {/* {item?.low && formatNumber(item?.low)} */}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>
                                    5-Year High
                                    {/* {getValueOfKey(item?.year)} High */}
                                </strong>
                            </td>
                            <td>
                                <i className="finr"></i>

                                {historicalData?.["5yearhigh"] || "NA"}
                                {/* {item?.High && formatNumber(item?.High)} */}
                            </td>
                            <td>
                                <strong>
                                    5-Year Low
                                    {/* {getValueOfKey(item?.year)} Low */}
                                </strong>
                            </td>
                            <td>
                                <i className="finr"></i>
                                {historicalData?.["5yearlow"] || "NA"}
                                {/* {item?.low && formatNumber(item?.low)} */}
                            </td>
                        </tr>
                        {/* <tr>
                                  <td>
                                    <strong> 3-Year High</strong>
                                  </td>
                                  <td>4.043.00</td>
                                  <td>3-Year Low</td>
                                  <td>1,865.20</td>
                                </tr> */}
                        {/* <tr>
                                  <td>
                                    <strong> 5-Year High</strong>
                                  </td>
                                  <td>4.043.00</td>
                                  <td>5-Year Low</td>
                                  <td>1,506.05</td>
                                </tr> */}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default StocksHistoricalPerformance;
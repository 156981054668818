import React from "react";
import Frame1 from "../images/app01.webp";
import Frame2 from "../images/app02.webp";
import Frame3 from "../images/app03.webp";
import Frame4 from "../images/app04.webp";
import Frame5 from "../images/app05.webp";
import UsStock from "../images/us-stock.webp";
import Insurance from "../images/insurance.webp";
import Loan from "../images/loan.webp";
import Advisory from "../images/advisory.webp";
import Stock from "../images/stock.webp";
import Deriative from "../images/deriative.webp";
import Ipo from "../images/ipo.webp";
import Currency from "../images/currency.webp";
import Commodities from "../images/comodities.webp";
import Mutual from "../images/mutual-fund.webp";

import Slider from "react-slick";
import { Link } from "react-router-dom";

export const Pinvest = () => {
  var settings = {
    dots: true,
    navigation: true,
    arrows: false,
    // infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    // autoplay: true,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };
  return (
    <>
      <div
        className="webcent"
        data-aos="fade-up"
        data-aos-offset="80"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Financial Needs. Pick your Way.</h3>
                <a>
                  Multiple financial avenues at your fingertips, the choice is
                  yours!{" "}
                </a>
              </div>
              <div className="moblay">
                <div className="mobcol">
                  <div className="mobbox">
                    <div className="mobicon">
                      <img
                        src="./images/stock.webp"
                        alt="Stock"
                        className="responsive"
                        style={{ width: 30, height: 29 }}
                      />
                    </div>
                    <div className="mobicon">
                      <h4>Equities</h4>
                    </div>
                  </div>
                  <div className="mobbox">
                    <div className="mobicon">
                      <img
                        src="./images/deriative.webp"
                        alt="DERIVATIVES"
                        className="responsive"
                        style={{ width: 30, height: 29 }}
                      />
                    </div>
                    <div className="mobicon">
                      <h4>DERIVATIVES</h4>
                    </div>
                  </div>
                  <div className="mobbox">
                    <div className="mobicon">
                      <img
                        src="./images/ipo.webp"
                        alt="IPO"
                        className="responsive"
                        style={{ width: 30, height: 29 }}
                      />
                    </div>
                    <div className="mobicon">
                      <h4>IPO</h4>
                    </div>
                  </div>
                  <div className="mobbox">
                    <div className="mobicon">
                      <img
                        src="./images/currency.webp"
                        alt="Currency"
                        className="responsive"
                        style={{ width: 30, height: 29 }}
                      />
                    </div>
                    <div className="mobicon">
                      <h4>Forex</h4>
                    </div>
                  </div>
                </div>
                <div className="mobcol">
                  <div className="mobileframeslide">
                    <div className="mframslder">
                      <Slider {...settings}>
                        <div>
                          <div className="framslider">
                            <img
                              src="./images/app01.webp"
                              alt=""
                              className="responsive"
                              style={{ width: 252, height: 522 }}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="framslider">
                            <img
                              src="./images/app02.webp"
                              alt=""
                              className="responsive"
                              style={{ width: 252, height: 522 }}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="framslider">
                            <img
                              src="./images/app03.webp"
                              alt=""
                              className="responsive"
                              style={{ width: 252, height: 522 }}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="framslider">
                            <img
                              src="./images/app04.webp"
                              alt=""
                              className="responsive"
                              style={{ width: 252, height: 522 }}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="framslider">
                            <img
                              src="./images/app05.webp"
                              alt=""
                              className="responsive"
                              style={{ width: 252, height: 522 }}
                            />
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
                <div className="mobcol">
                  {/* <div className="mobbox">
                    <div className="mobicon">
                      <img
                        src="./images/us-stock.webp"
                        alt="Us Stocks"
                        className="responsive"
                        style={{ width: 30, height: 29 }}
                      />
                    </div>
                    <div className="mobicon">
                      <h4>Us Stocks</h4>
                    </div>
                  </div> */}
                  <div className="mobbox">
                    <div className="mobicon">
                      <img
                        src="./images/comodities.webp"
                        alt="COMMODITIES"
                        className="responsive"
                        style={{ width: 30, height: 29 }}
                      />
                    </div>
                    <div className="mobicon">
                      <h4>COMMODITIES</h4>
                    </div>
                  </div>
                  <div className="mobbox">
                    <div className="mobicon">
                      <img
                        src="./images/mutual-fund.webp"
                        alt="Mutual Funds"
                        className="responsive"
                        style={{ width: 30, height: 29 }}
                      />
                    </div>
                    <div className="mobicon">
                      <h4>Mutual Funds</h4>
                    </div>
                  </div>
                  <div className="mobbox">
                    <div className="mobicon">
                      <img
                        src="./images/insurance.webp"
                        alt="Insurance"
                        className="responsive"
                        style={{ width: 30, height: 29 }}
                      />
                    </div>
                    <div className="mobicon">
                      <h4>Insurance</h4>
                    </div>
                  </div>
                  <div className="mobbox">
                    <div className="mobicon">
                      <img
                        src="./images/loan.webp"
                        alt="Loan"
                        className="responsive"
                        style={{ width: 30, height: 29 }}
                      />
                    </div>
                    <div className="mobicon">
                      <h4>Loan</h4>
                    </div>
                  </div>
                  {/* <div className="mobbox">
                    <div className="mobicon">
                      <img
                        src="./images/advisory.webp"
                        alt="Advisory Portfolio"
                        className="responsive"
                        style={{ width: 30, height: 29 }}
                      />
                    </div>
                    <div className="mobicon">
                      <h4>Advisory Portfolio</h4>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

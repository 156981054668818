import React, { useContext } from "react";
import { Context } from "../../../../context";
import { Helmet } from "react-helmet-async";
import { SEO_URL } from "../../../../utils/constants";

const StockDetailsSeo = ({ stockExchange, id }) => {
  const { bioData, setBioData, quatesData, setQuatesData } =
    useContext(Context);

  const image = "../../images/SMCNEWLOGO.webp";
  const url = `${
    stockExchange?.category_name === "ETF"
      ? `${SEO_URL}/invest/etf/${id}/${stockExchange?.company_name}`
      : `${SEO_URL}/invest/equities/${id}/${stockExchange?.company_name}-share-price`
  }`;
  const title = `${stockExchange?.company_name} Share Price Today | ${stockExchange?.company_name} Live Stock Price/${quatesData?.exchange}`;
  const description = `${stockExchange?.company_name} Share Price - Get ${stockExchange?.company_name} live ${quatesData?.exchange} stock price Today with Latest News, Performance Analysis, Fundamentals, Market Cap & other financial report on SMC`;
  const h1 = `${stockExchange?.company_name}`;
  function formatUrl(url) {
    let tempUrl = url.replace(/\s+/g, "-").toLowerCase();
    return tempUrl.replace(/-+/g, "-");
  }
  const formattedUrl = formatUrl(url);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={formattedUrl} />
        <meta property="og:title" content={title} data-react-helmet="true" />
        <meta property="og:site_name" content="SMC Global Securities" />
        <meta property="og:url" content={formattedUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        <meta name="twitter:url" content={formattedUrl} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:card" content="summary" />
        <meta name="description" content={description}></meta>
        <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>
    </>
  );
};

export default StockDetailsSeo;

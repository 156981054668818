import React from "react";
import Slider from "react-slick";
import Vinod from "../images/Vinod-Kumar-Atal.webp";
import Deepak from "../images/deepak-modi.webp";
import Ketan from "../images/ketan-manubhai.webp";
import Nilesh from "../images/nilesh.webp";
import Pankaj from "../images/pankaj-jain.webp";
import CarouselWebinar from "./CarouselWebinar";

export const Reviews = () => {
  var settings = {
    dots: false,
    navigation: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 8000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        className="webcent pt-0"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="minicontainer">
                <div className="title">
                  <h3>Our Clients. Speak.</h3>
                  <p>
                    You can feel the pride of being a SMC investor in their
                    words.
                  </p>
                </div>
                <CarouselWebinar />
                {/* <div className="clientreview">
                  <Slider {...settings}>
                    <div>
                      <div className="reviewbox">
                        <div className="commentcircle">
                          <div className="comment">
                            <div className="profile">
                              <div className="climg">
                                <img src={Nilesh} alt="" />
                              </div>
                              <div className="rating">
                                <ul className="d-flex">
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="commentcent">
                              <p>
                                The relationship managers are not only quick but
                                they also make that extra effort to resolve all
                                my queries properly. I also find the
                                recommendations quite handy. They have helped me
                                in each and every aspect of trading. Thanks SMC!
                              </p>
                            </div>
                            <div className="name">
                              <h4>Mr. Nilesh Jain</h4>
                              <h5>Mumbai</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="reviewbox">
                        <div className="commentcircle">
                          <div className="comment">
                            <div className="profile">
                              <div className="climg">
                                <img src={Vinod} alt="" />
                              </div>
                              <div className="rating">
                                <ul className="d-flex">
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="commentcent">
                              <p>
                                SMC Group is a complete solution for all finance
                                needs & goals, a supportive team which is always
                                available for any of our requirement and fully
                                dedicated to the customer’s needs. Truly, a
                                complete financial solution platform for
                                everyone's need. I am very happy and satisfied
                                with SMC. Thank You SMC for all the support.
                              </p>
                            </div>
                            <div className="name">
                              <h4>Mr. CA Vinod Kumar Atal</h4>
                              <h5>Ahemdabad</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="reviewbox">
                        <div className="commentcircle">
                          <div className="comment">
                            <div className="profile">
                              <div className="climg">
                                <img src={Deepak} alt="" />
                              </div>
                              <div className="rating">
                                <ul className="d-flex">
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="commentcent">
                              <p>
                                SMC is one of the best brokerage service
                                providers I have been associated with. I would
                                like to specifically mention their mobile
                                trading platform which is quick and efficient.
                                Trades can be executed and confirmed in no time
                                and the back office functions work very
                                smoothly.
                              </p>
                            </div>
                            <div className="name">
                              <h4>Mr. Deepak Modi</h4>
                              <h5> Jaipur</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="reviewbox">
                        <div className="commentcircle">
                          <div className="comment">
                            <div className="profile">
                              <div className="climg">
                                <img src={Ketan} alt="" />
                              </div>
                              <div className="rating">
                                <ul className="d-flex">
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="commentcent">
                              <p>
                                It has been a great experience of trading and
                                investment across all segments with SMC since
                                2011. SMC research has made my trading &
                                investment strategy far easier and helped me to
                                grow financially over these years. A very
                                positive, supportive and experienced staff has
                                always been there for any help and guidance
                              </p>
                            </div>
                            <div className="name">
                              <h4>Mr. Ketan Manubhai Dave</h4>
                              <h5> Ahemdabad</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="reviewbox">
                        <div className="commentcircle">
                          <div className="comment">
                            <div className="profile">
                              <div className="climg">
                                <img src={Pankaj} alt="" />
                              </div>
                              <div className="rating">
                                <ul className="d-flex">
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                  <li>
                                    <i className="fa fa-star"></i>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="commentcent">
                              <p>
                                SMC is a trusted name in the industry and they
                                strongly believe in customer relationship.
                                Besides, their robust trading platform, simple
                                and easy website, Portfolio Health Checkup and
                                customer support are the key USPs.
                              </p>
                            </div>
                            <div className="name">
                              <h4>Mr .Pankaj Jain</h4>
                              <h5>Delhi</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

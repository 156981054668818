import React from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import Investbanner from "../../../../images/mob-app.webp";
import { Link } from "react-router-dom";
import { Form } from "../../../../components/Form";
import Keyfeature from "../../../../images/smc-trade.webp";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Reviews } from "../../../../components/Reviews";
import { Faq } from "../../../../components/Faq";

export const WebApp = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Header />
      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Web App</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1 className="p-0">Web Trading for Pro Traders</h1>
                  <p>
                    Advance Charts, Jargon Free Reporting & Margin on Option
                    Buying and More.
                  </p>
                </div>
                <div className="bannergt">
                  <img src={Investbanner} alt="Hassle-free investing" />
                </div>
                <Form />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent p-0"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="keyfeaturerow">
                    <div className="keyfeaturecont">
                      <img src={Keyfeature} alt="key feature" />
                    </div>
                    <div className="keyfeaturecont">
                      <div className="title justify-content-start text-start">
                        <h3 className="lh-1">
                          Embrace the Future of Investing with #SMCACE
                        </h3>
                        <p className="w-100 mt-2">
                          SMC #SMCACE empowers you to diversify your portfolio
                          like a super pro. Be it Stocks, Mutual Funds, ETFs, US
                          Stocks, Currencies, Commodities, Futures & Options, or
                          Bonds - find them all in SMC Super App!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent p-100 grey-background"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="web-container">
                <div className="title">
                  <h3>Why Trade on SMC?</h3>
                </div>
                <div className="col-lg-10 m-auto col-12">
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="accordiancolum">
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChange("panel1")}
                          className="accordiansummery"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography>Trade from Charts</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordiandetails">
                            <Typography>
                              With SMC you have the best of both worlds in
                              Charting - Candlestick Precision through ChartIQ
                              and a wide range of Free & Advanced Indicators on
                              TradingView.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel2"}
                          onChange={handleChange("panel2")}
                          className="accordiansummery"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                          >
                            <Typography>Smart Orders</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordiandetails">
                            <Typography>
                              Stop Loss,GTT, Cover and more - With our advanced
                              order pad,You get more than 7 types of orders in
                              one place.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel3"}
                          onChange={handleChange("panel3")}
                          className="accordiansummery"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                          >
                            <Typography>Advanced Option Chain</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordiandetails">
                            <Typography>
                              We understand frequent traders like no one does!
                              You can navigate across charts, orders and Option
                              Chains very swiftly. You can glance through
                              OI,Greeks and Contracts across Puts & Calls in one
                              scroll. With SMC you can trade deep OTM or ITM
                              strikes directly from Option Chain.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel4"}
                          onChange={handleChange("panel4")}
                          className="accordiansummery"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                          >
                            <Typography>Margin Trading Facility</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordiandetails">
                            <Typography>
                              Get 4X leverage on 1000+ stocks.You can track all
                              your margin orders and related interest expenses
                              from the dedicated MTF section.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel5"}
                          onChange={handleChange("panel5")}
                          className="accordiansummery"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5bh-content"
                            id="panel5bh-header"
                          >
                            <Typography>Option Strategies</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordiandetails">
                            <Typography>
                              SMC ACE research team has simplified Option
                              trading for new traders using InstaTrade. You will
                              get Strategy builder, Free position analysis and
                              pay-off charts powered by Sensibull.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12">
                      <img src={Keyfeature} alt="key feature" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="keyfeatures">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title gap-2">
                      <h3>Witness the Dawn of a Super Investing Era!</h3>
                      <p>
                        Our brand film encapsulates the transformative shift
                        from outdated, glitchy investing apps to the
                        super-efficient, user-friendly <b>#SMCACE</b>. It's more
                        than a revolution, it's a super evolution!
                      </p>
                    </div>
                    <div className="stockslider">
                      <iframe
                        width="100%"
                        height="480"
                        src="https://www.youtube.com/embed/3LeZWpWdJbE?rel=0"
                        title="YouTube video player"
                        frameborder="0"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="gradient p-100 whitetext">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title gap-2">
                      <h3>Start your investment journey with SMC</h3>
                      <p className="text-white">
                        SMC provides you option to diversify your portfolio by
                        investing in Stocks, Mutual Funds, ETFs, US Stocks,
                        Currencies, Commodities, Futures & Options, Bonds etc
                      </p>
                    </div>
                    <div className="stockslider flex-center">
                      <div className="btnn bg-white">
                        <span className="text-black">
                          Get Free Demat Account
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Faq />
          <Reviews />
        </section>
      </main>

      <Footer />
    </>
  );
};

import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

export const Numbers = () => {
  const [state, setState] = useState({
    turnover: 1,
    workforce: 1,
    states: 1,
    cities: 1,
  });
  return (
    <>
      <div
        className="webcent gradient p-100"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="numbers">
                <div className="numberlft">
                  <h4> Trust. Rock solid. </h4>
                </div>
                <div className="numberrgt">
                  <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                    {({ isVisible }) => (
                      <div className="count">
                        <div className="App">
                          <div className="countnum">
                            {isVisible ? (
                              <CountUp
                                start={state.turnover === 1 ? 1 : 33}
                                end={33}
                                duration={3}
                                onEnd={() => setState({ turnover: 33 })}
                              />
                            ) : (
                              <CountUp start={1} end={23} duration={0} />
                            )}{" "}
                            +
                            <p style={{ textTransform: "none" }}>
                              Legacy of 33 years in The Industry
                            </p>
                          </div>
                        </div>
                        <div className="App">
                          <div className="countnum">
                            {isVisible ? (
                              <CountUp
                                start={state.turnover === 1 ? 1 : 2}
                                end={2}
                                duration={3}
                                onEnd={() => setState({ turnover: 2 })}
                              />
                            ) : (
                              <CountUp start={1} end={2} duration={0} />
                            )}{" "}
                            M+
                            <p style={{ textTransform: "none" }}>
                              Unique Customers
                            </p>
                          </div>
                        </div>
                        <div className="App">
                          <div className="countnum">
                            {isVisible ? (
                              <CountUp
                                start={state.states === 1 ? 1 : 2550}
                                end={2550}
                                duration={1}
                                onEnd={() => setState({ states: 2550 })}
                              />
                            ) : (
                              <CountUp start={1} end={2550} duration={0} />
                            )}
                            +
                            <p style={{ textTransform: "none" }}>
                              Authorized Person & Franchises
                            </p>
                          </div>
                        </div>

                        <div className="App">
                          <div className="countnum">
                            {isVisible ? (
                              <CountUp
                                start={state.workforce === 1 ? 1 : 4000}
                                end={4000}
                                duration={2}
                                onEnd={() => setState({ workforce: 4000 })}
                              />
                            ) : (
                              <CountUp start={1} end={4000} duration={0} />
                            )}
                            +
                            <p style={{ textTransform: "none" }}>
                              Lives Driving the Brand SMC
                            </p>
                          </div>
                        </div>
                        <div className="App">
                          <div className="countnum">
                            {isVisible ? (
                              <CountUp
                                start={state.cities === 1 ? 1 : 455}
                                end={455}
                                duration={3}
                                onEnd={() => setState({ cities: 455 })}
                              />
                            ) : (
                              <CountUp start={1} end={455} duration={0} />
                            )}
                            +
                            <p style={{ textTransform: "none" }}>
                              Cities Across India
                            </p>
                          </div>
                        </div>
                        <div className="App">
                          <div className="countnum">
                            {isVisible ? (
                              <CountUp
                                start={state.cities === 1 ? 1 : 13600}
                                end={13600}
                                duration={3}
                                onEnd={() => setState({ cities: 13600 })}
                              />
                            ) : (
                              <CountUp start={1} end={13600} duration={0} />
                            )}
                            +
                            <p style={{ textTransform: "none" }}>
                              Independent Distributors
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </VisibilitySensor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

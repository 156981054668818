import React from "react";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Header from "../../../components/Header";
import { Helmet } from "react-helmet";
import SEO from "../../../components/common/SEO";
import { SEO_URL } from "../../../utils/constants";
import { seoData } from "../../../utils/Data";
const image = "../../../images/SMCNEWLOGO.webp";

const InvestHolidaysMCXHolidays = () => {
  const mcxHolidayList = [
    {
      name: "Republic Day",
      date: "26-Jan-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Closed",
    },
    {
      name: "Maha Shivaratri",
      date: "08-Mar-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Holi",
      date: "25-Mar-2024",
      day: "Monday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Good Friday",
      date: "29-Mar-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Closed",
    },
    {
      name: "Eid Al Fitr",
      date: "11-Apr-2024",
      day: "Thursday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Ram Navami",
      date: "17-Apr-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Maharashtra Day",
      date: "01-May-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Id-Ul-Adha (Bakri Id)",
      date: "17-Jun-2024",
      day: "Monday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Muharram",
      date: "17-Jul-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Independence Day",
      date: "15-Aug-2024",
      day: "Thursday",
      morning: "Closed",
      evening: "Closed",
    },
    {
      name: "Mahatma Gandhi Jayanti",
      date: "02-Oct-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Closed",
    },
    {
      name: "Diwali-Laxmi Pujan",
      date: "01-Nov-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Gurunanak Jayanti",
      date: "15-Nov-2024",
      day: "Friday",
      morning: "Closed",
      evening: "Open",
    },
    {
      name: "Christmas",
      date: "25-Dec-2024",
      day: "Wednesday",
      morning: "Closed",
      evening: "Closed",
    },
  ];

  return (
    <>
      <Header />
      <SEO
        title={seoData.mcxHolidays.title}
        description={seoData.mcxHolidays.description}
        name="Company name."
        type="article"
        url={seoData.mcxHolidays.url}
        image={seoData.mcxHolidays.image}
      />

      <section id="banner">
        <div className="banner internal mpadding">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/invest">Invest</Link>
                </li>
                <li>MCX Holidays</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="col-lg-10 m-auto">
                    <div className="title">
                      <h3>MCX Holidays 2024</h3>
                    </div>
                    <div className="ipostable holidays">
                      <div className="tablist gap-sm-4 d-flex flex-wrap">
                        <div className="ipostn p-0">
                          <div className="ipotitle">
                            <h4>MCX Holidays 2024 list</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Holidays</th>
                                  <th>Date</th>
                                  <th>Day</th>
                                  <th>Morning session</th>
                                  <th>Evening session</th>
                                </tr>
                              </thead>
                              <tbody>
                                {mcxHolidayList.map((item) => (
                                  <tr>
                                    <td>{item?.name}</td>
                                    <td>{item?.date}</td>
                                    <td>{item?.day}</td>
                                    <td>{item?.morning}</td>
                                    <td>{item?.evening}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <p>
                              *Additionally, it is important to keep in mind
                              that the MCX may declare additional holidays
                              throughout the year due to unforeseen
                              circumstances. In such cases, the exchange will
                              issue a circular notifying traders and investors
                              of the holiday.
                            </p>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>
                              Following public holidays are falling on Saturday
                              / Sunday:
                            </h4>
                          </div>
                          {/* <div className="ipotable defaulttable">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Holiday</th>
                                  <th>Date</th>
                                  <th>Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div> */}
                          <div className="ipotable defaulttable">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Dr. Baba Saheb Ambedkar Jayanti</td>
                                  <td>14-Apr-2024</td>
                                  <td>Sunday</td>
                                </tr>
                                <tr>
                                  <td>Shri Mahavir Jayanti</td>
                                  <td>21-Apr-2024</td>
                                  <td>Sunday</td>
                                </tr>
                                <tr>
                                  <td>Ganesh Chaturthi</td>
                                  <td>07-Sep-2024</td>
                                  <td>Saturday</td>
                                </tr>
                                <tr>
                                  <td>Dussehra</td>
                                  <td>12-Oct-2024</td>
                                  <td>Saturday</td>
                                </tr>
                                <tr>
                                  <td>Diwali-Balipratipada</td>
                                  <td>02-Nov-2024</td>
                                  <td>Saturday</td>
                                </tr>
                              </tbody>
                            </table>
                            <p>Morning session: 09:00 AM to 05:00 PM</p>
                            <p>Evening session: 05:00 PM to 11:30/11:55 PM</p>

                            <small>
                              * The Muhurat Trading day timings will be notified
                              closer to Diwali by the Exchanges.
                              <br />
                            </small>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>MCX Holidays In January 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Republic Day</td>
                                  <td>26 January 2024 </td>
                                  <td>Friday</td>
                                </tr>
                              </tbody>
                            </table>{" "}
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>MCX Holidays In March 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Maha Shivaratri</td>
                                  <td>08-Mar-2024 </td>
                                  <td>Friday</td>
                                </tr>
                                <tr>
                                  <td>Holi </td>
                                  <td>25-Mar-2024 </td>
                                  <td>Monday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>MCX Holidays In April 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Eid Al Fitr</td>
                                  <td>11-Apr-2024</td>
                                  <td>Thursday</td>
                                </tr>
                                <tr>
                                  <td>Ram Navami</td>
                                  <td>17-Apr-2024</td>
                                  <td>Wednesday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>MCX Holidays In May 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Maharashtra Day</td>
                                  <td>01 May 2024</td>
                                  <td>Wednesday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>MCX Holidays In June 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Id-Ul-Adha (Bakri Id)</td>
                                  <td>17-Jun-2024</td>
                                  <td> Monday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>MCX Holidays In July 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Muharram</td>
                                  <td>17-Jul-2024</td>
                                  <td>Wednesday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>MCX Holidays In August 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Independence Day</td>
                                  <td>15 August 2024 </td>
                                  <td>Thursday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <div className="ipostn">
                          <div className="ipotitle">
                            <h4>MCX Holidays In September 2023</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Ganesh Chaturthi</td>
                                  <td>19 September 2023 </td>
                                  <td>Tuesday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div> */}
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>MCX Holidays In October 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Mahatma Gandhi Jayanti </td>
                                  <td>02 October 2024 </td>
                                  <td>Wednesday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>MCX Holidays In November 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Diwali-Laxmi Pujan</td>
                                  <td>01-Nov-2024</td>
                                  <td>Friday</td>
                                </tr>
                                <tr>
                                  <td>Gurunanak Jayanti </td>
                                  <td>15-Nov-2024</td>
                                  <td>Friday</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="ipostn">
                          <div className="ipotitle">
                            <h4>MCX Holidays In December 2024</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Holidays</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Christmas</td>
                                  <td>25 December 2024 </td>
                                  <td>Wednesday</td>
                                </tr>
                              </tbody>
                            </table>
                            <p>
                              *Additionally, it is important to keep in mind
                              that the BSE may declare additional
                              <br />
                              holidays throughout the year due to unforeseen
                              circumstances. In such cases, the exchange will
                              issue a circular notifying traders and investors
                              of the holiday.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Frequently Asked Questions</h3>
                  </div>
                  <div className="faqcont">
                    <Accordion allowZeroExpanded="true">
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What does the MCX holiday list entail?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The MCX holiday list outlines the days when
                              trading is not available on this commodity
                              exchange in India.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Is it possible to purchase or sell commodities on
                            MCX holidays?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              No, trading commodities is not possible on MCX
                              holidays in India as the exchange is closed on
                              those days.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Where can I locate the MCX holiday list?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The MCX holiday list is released by the exchange
                              and is accessible on their official website.
                              Additionally, it can be obtained from market
                              regulators, news websites, and authenticated
                              trading platforms.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What are the operating hours for the MCX?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Trading on the MCX occurs from Monday to Friday
                              with the following schedule:
                              <br /> • Morning session: 10:00 AM to 5:00 PM
                              (IST)
                              <br /> • Evening session: 5:00 PM to 11:55 PM
                              (IST)
                            </p>
                            <p>
                              Please be aware that trading hours may be adjusted
                              in accordance with regulatory guidelines.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Can I trade on the MCX on the weekends as well?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              No, you cannot trade on the MCX on Saturday and
                              Sunday as weekends are non-trading days
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                    <p className="mt-3 d-block col-12 pull-left">
                      *Please note that the MCX Holiday List may change from
                      year to year and it is recommended to check for the most
                      up-to-date information from reliable sources before making
                      any trading decisions or planning your investment
                      activities. Always consult with a financial advisor for
                      personalised investment advice.
                    </p>
                    <br />
                    <p>Happy investing!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default InvestHolidaysMCXHolidays;

import React from "react";
import { Link, NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const SiteMap = () => {
  return (
    <>
      <Header />

      <div className="container">
        <div className="bredcrum">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Sitemap</li>
          </ul>
        </div>
        <div
          className="webcent p-100 pt-0"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-duration="600"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"
        >
          <div className="minicontainer">
            <div className="ipodetail">
              <h3 style={{ color: "#4cafa7", fontWeight: 600, marginTop: 20 }}>
                Sitemap
              </h3>
              <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                <table style={{ width: "100%" }}>
                  <tr>
                    <th style={{ padding: "5px" }}>
                      <NavLink to="/invest">Invest</NavLink>
                    </th>
                    <th style={{ padding: "5px" }}>
                      {" "}
                      <NavLink to="/trade">Trade</NavLink>
                    </th>
                    <th style={{ padding: "5px" }}>Products</th>
                    <th style={{ padding: "5px" }}>
                      <NavLink to="/Research">Research</NavLink>
                    </th>
                    <th style={{ padding: "5px" }}>Learn</th>
                    <th style={{ padding: "5px" }}>Partner Us</th>
                    <th style={{ padding: "5px" }}>
                      <NavLink to="/contact-us">Contact Us</NavLink>
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        color: "#023b7e",
                        fontWeight: 600,
                      }}
                    >
                      Invest{" "}
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        color: "#023b7e",
                        fontWeight: 600,
                      }}
                    >
                      Tools
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        color: "#023b7e",
                        fontWeight: 600,
                      }}
                    >
                      Invest
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        color: "#023b7e",
                        fontWeight: 600,
                      }}
                    >
                      Research Reports
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        color: "#023b7e",
                        fontWeight: 600,
                      }}
                    >
                      Learning Center
                    </td>
                    <td style={{ padding: "5px", color: "#023b7e" }}>
                      <NavLink to="/partner-us/authorized-person">
                        Authorized Person
                      </NavLink>
                    </td>

                    <td style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/invest/refer-earn">Refer & Earn</NavLink>{" "}
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink
                        to="https://subscriptions.smcindiaonline.com:8880/autotrender"
                        target="_blank"
                      >
                        Autotrender
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/invest/refer-earn">Refer & Earn</NavLink>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/research/commodities-analysis/39">
                        Commodity Analysis
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/learning-centre/webinar">Webinar</NavLink>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/partner-us/become-distributor">
                        Become Distributor
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        color: "#023b7e",
                        fontWeight: 600,
                      }}
                    >
                      Holidays
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/trade/algo-trading-platform">
                        Algo Trading Platform
                      </NavLink>
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        color: "#023b7e",
                        fontWeight: 600,
                      }}
                    >
                      Tools
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/research/currency-analysis/40">
                        Currency Analysis
                      </NavLink>
                    </td>

                    <td style={{ padding: "5px" }}>
                      <NavLink
                        to="https://blog.smctradeonline.com:7171/"
                        target="_blank"
                      >
                        Blog
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px", color: "#023b7e" }}>
                      <NavLink to="/about-us">About Us</NavLink>
                    </td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/invest/trading-holidays-2024">
                        Trading Holidays
                      </NavLink>
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        color: "#023b7e",
                        fontWeight: 600,
                      }}
                    >
                      Telegram Channels
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink
                        to="https://subscriptions.smcindiaonline.com:8880/autotrender"
                        target="_blank"
                      >
                        Autotrender
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/research/derivatives-analysis/41">
                        Derivatives Analysis
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/invest/nse-holiday-2024">
                        NSE Holidays
                      </NavLink>{" "}
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink
                        to="https://subscriptions.smcindiaonline.com:8880/indexcalls"
                        target="_blank"
                      >
                        Index Trading
                      </NavLink>{" "}
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/trade/algo-trading-platform">
                        Algo Trading Platform
                      </NavLink>{" "}
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/research/equity-analysis/42">
                        Equity Analysis
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/invest/bse-holiday-2024">
                        BSE Holidays
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink
                        to="https://subscriptions.smcindiaonline.com:8880/equityfunda"
                        target="_blank"
                      >
                        Equity Ka Funda
                      </NavLink>
                    </td>

                    <td
                      style={{
                        padding: "5px",
                        // color: "#023b7e",
                        // fontWeight: 600,
                      }}
                    >
                      <NavLink
                        to="https://refer.smcindiaonline.org/refer-and-earn/?sourceId=1&destinationId=4&org=smc&mobile=null"
                        target="_blank"
                      >
                        Refer Subscription Products
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/research/ipo-reports/43">
                        IPO Reports
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/invest/mcx-holiday-2024">
                        MCX Holidays
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink
                        to="https://subscriptions.smcindiaonline.com:8880/technofunda"
                        target="_blank"
                      >
                        Techno Funda
                      </NavLink>
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        color: "#023b7e",
                        fontWeight: 600,
                      }}
                    >
                      Telegram Channels
                    </td>

                    <td style={{ padding: "5px" }}>
                      <NavLink to="/research/mutual-fund-analysis/44">
                        Mutual Fund Analysis
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/invest/muhurat-holiday-2024">
                        Muhurat Dates
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink
                        to="https://subscriptions.smcindiaonline.com:8880/commodity"
                        target="_blank"
                      >
                        Commodity Mantra
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink
                        to="https://subscriptions.smcindiaonline.com:8880/indexcalls"
                        target="_blank"
                      >
                        Index Trading
                      </NavLink>
                    </td>

                    <td style={{ padding: "5px" }}>
                      <NavLink to="/research/wise-money/45">Wise Money</NavLink>
                    </td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}>
                      <NavLink
                        to="https://subscriptions.smcindiaonline.com:8880/technofunda"
                        target="_blank"
                      >
                        Maha Combo
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <NavLink
                        to="https://subscriptions.smcindiaonline.com:8880/equityfunda"
                        target="_blank"
                      >
                        Equity Ka Funda
                      </NavLink>
                    </td>

                    <td
                      style={{
                        padding: "5px",
                        color: "#023b7e",
                        fontWeight: 600,
                      }}
                    >
                      Tools
                    </td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}>
                      <NavLink
                        to="https://subscriptions.smcindiaonline.com:8880/technofunda"
                        target="_blank"
                      >
                        Techno Funda
                      </NavLink>
                    </td>

                    <td style={{ padding: "5px" }}>
                      <NavLink
                        to="https://subscriptions.smcindiaonline.com:8880/autotrender"
                        target="_blank"
                      >
                        Autotrender
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}>
                      <NavLink
                        to="https://subscriptions.smcindiaonline.com:8880/commodity"
                        target="_blank"
                      >
                        Commodity Mantra
                      </NavLink>
                    </td>

                    <td style={{ padding: "5px" }}>
                      <NavLink to="/trade/algo-trading-platform">
                        Algo Trading Platform
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}>
                      <NavLink
                        to="https://subscriptions.smcindiaonline.com:8880/technofunda"
                        target="_blank"
                      >
                        Maha Combo
                      </NavLink>
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        color: "#023b7e",
                        fontWeight: 600,
                      }}
                    >
                      Research
                    </td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/research/fundamental-research">
                        Fundamental Research
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}>
                      <NavLink to="/research/technical-research">
                        Technical Research
                      </NavLink>
                    </td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                    <td style={{ padding: "5px" }}></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SiteMap;

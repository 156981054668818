import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const Copyright = () => {
  return (
    <>
      <Header />

      <div className="container">
        <div className="bredcrum">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Copyright</li>
          </ul>
        </div>
        <div
          className="webcent p-100 pt-0"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-duration="600"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"
        >
          <div className="minicontainer">
            <div className="ipodetail">
              <h3 style={{ color: "#4cafa7", fontWeight: 600, marginTop: 20 }}>
                Copyright
              </h3>
              <p style={{ fontSize: "medium" }}>
                SMC Global Securities Ltd. owns and maintains
                www.smctradeonlinecom / www.moneywisebewise.com
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                INTRODUCTION
              </h6>
              <p style={{ fontSize: "medium" }}>
                We are writing this section to bring to your notice your rights,
                limitations and obligations while using this website and to do
                away with the tedious task of looking up and understanding the
                law with respect to your use of our web site, thereby saving
                your precious time. The following are the terms and conditions,
                within the framework of which, if agreed upon by you, in its
                entirety, then only a relationship between us can be
                accomplished.
              </p>
              <h6 style={{ fontWeight: 600, color: "#023b7e" }}>
                USE OF THE WEBSITE
              </h6>
              <p style={{ fontSize: "medium" }}>
                Your registration with our web site and/or using of this site
                and the information and material contained therein implies and
                constitutes that you have understood, appreciated and
                voluntarily accepted the rights, limitations and obligations,
                which would exist between us thereafter, in totality. You
                understand and agree that no joint venture, partnership,
                employment or agency relationship exists between you and as a
                result of this agreement or on account of use of our web site.
              </p>
              <p style={{ fontSize: "medium" }}>
                We in order to protect yours as well as our interests, reserve
                the right to make modifications and alterations in the material
                contained at this site without any intimation to you of such
                modifications or alterations. Hence you are supposed to check
                this web page of this web site on a regular basis.
              </p>
              <p style={{ fontSize: "medium" }}>
                Any person can use and participate in our web site as long as he
                or she is a major i.e. attained the age of 18 years and capable
                of entering into a legal contract as per the laws in India.
              </p>
              <p style={{ fontSize: "medium" }}>
                You agree that neither us nor our affiliates, agents, associates
                and or subsidiaries and any of our respective officers,
                directors, employees1, or agents will be liable, whether in
                contract, tort, strict liability or otherwise, for any indirect,
                punitive, special, consequential, incidental or indirect damages
                (including without limitation lost profits, cost of procuring
                substitute service or lost opportunity) arising out of or in
                connection with the use of our web site and or a linked site, or
                with the delay or inability to use the site or a linked site,
                even if we have been made aware of the possibility of such
                damages. This limitation on liability includes, but is not
                limited to, the transmission of any viruses which may infect a
                user's equipment, failure of mechanical or electronic equipment
                or communication lines, telephone or other interconnect problems
                (e.g., you cannot access your internet service provider),
                unauthorized access, theft, operator errors, strikes or other
                labor problems or any force majeure, we cannot and do not
                guarantee continuous, uninterrupted or secure access to the
                internet, our web site or linked site.
              </p>
              <p style={{ fontSize: "medium" }}>
                If the foregoing limitation is held to be unenforceable, the
                maximum liability of SMC and its Associates shall not exceed the
                amount of fees paid by you for the products or services that you
                have ordered through the site.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                LINKS TO OTHER WEB SITES
              </h6>
              <p style={{ fontSize: "medium" }}>
                Through this web site you would be linked to other web sites,
                web pages and or offers which would require you to understand
                and accept additional rights and or obligations in order to
                participate. No judgment or warranty is made with respect to the
                accuracy, timeliness, or suitability of the content of other
                services or sites to which these screens link, and SMC and its'
                Associates and its subsidiaries and affiliates take no
                responsibility therefore. A link to a service or site outside of
                this site is not an endorsement of the service or site, its
                content, or its sponsoring organization. Unless mentioned
                otherwise herein or on the respective web site and or web pages,
                all the rights, obligations and limitations contained herein
                shall supersede all previous terms and conditions.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                YOUR INFORMATION
              </h6>
              <p style={{ fontSize: "medium" }}>
                The information provided by you, shall at all times be current,
                truthful, accurate, complete and updated by you regularly. The
                making of any false statements, non disclosure and or failure to
                provide timely updates of necessary information and facts would
                subject you to civil and criminal liabilities, penalties, non
                registration and or deactivation of your existing account with
                our web site without notice or intimation of any kind to you.
              </p>
              <p style={{ fontSize: "medium" }}>
                We reserve the right to make modifications and alterations in
                the material contained at this site without any intimation to
                you of such modifications or alterations and will not be held
                responsible for any actions that may subsequently culminate into
                any loss, damage and or liability.
              </p>
              <p style={{ fontSize: "medium" }}>
                We may be required to disclose your personal information or to
                adhere to the bonafide requests of the government and or law, in
                such cases we shall be bound and shall at all times have the
                legal right to disclose any or all of your personal information.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                USER NAME & PASSWORD
              </h6>
              <p style={{ fontSize: "medium" }}>
                You will be the only authorized user of this website through
                your User Name and Password. You will be solely responsible for
                all messages posted, statements made, or acts or omissions that
                occur within this website through the use of your User Name and
                Password. If you allow third parties to access this website
                through your User Name and Password, you will defend and
                indemnify us or our affiliates or any independent contractor
                engaged by us to assist in monitoring our website, against any
                liability, costs or damages arising out of claims or suits by
                such third parties based upon or relating to such access and
                use.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                ONLY ONE ACCOUNT PER USER
              </h6>
              <p style={{ fontSize: "medium" }}>
                To serve you in the best possible manner, you will be permitted
                to maintain only one account with us at all times. You shall not
                be allowed to open a new account unless a previous registration
                and or account of yours has been deactivated, invalid,
                inoperative or otherwise. If you fail to make such disclosure,
                your account will get deactivated and or you will be liable to
                be prosecuted under the civil and criminal procedures of law.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                LIMITATION ON USE OF INFORMAION
              </h6>
              <p style={{ fontSize: "medium" }}>
                SMC and its' Associates has always strived hard to address the
                needs of the clients and in its endeavor to provide the best of
                services, we are providing various information in our websites.
                To provide the latest and updated information on stocks we are
                associated with the best of the talents in the field who provide
                their contents, products and services. You should be aware and
                understand that SMC and its' Associates and/or its affiliates
                may have opinions regarding any matter contained in this site
                and these opinions may be different from those contained in this
                site or those formed by you after using the information
                contained in this site. You are also aware and understand that
                these opinions may change from time to time without any
                obligation to inform you of such change in opinion.
              </p>
              <p style={{ fontSize: "medium" }}>
                The Price and value of investments and the income derived from
                them can go down as well as up, and you may not get back the
                amount you invest. High Volatility may have an adverse effect on
                the value, price and income of investments. Past performance is
                not necessarily a guide to further performance.
              </p>
              <p style={{ fontSize: "medium" }}>
                You are aware and understand that the materials provided are for
                your personal information only and do not construe to be any
                kind of personal recommendation for investment, legal or
                taxation advice to you. The services and investments referred to
                in our Site may have tax consequences the level of taxation
                depends on individual circumstances and such levels and bases of
                taxation can change. You should consult your own tax advisor in
                order to understand any applicable tax consequences. Any action
                taken by you on the basis of the information contained in this
                site is your responsibility alone and we will not be liable in
                any manner for the consequences of such action taken by you.
              </p>
              <p style={{ fontSize: "medium" }}>
                You are aware and understand that SMC and its' Associates or its
                affiliates or employees shall not advise you on the nature,
                potential, value or suitability of any particular security,
                portfolio of securities, transaction, investment strategy or
                other matter and that mention of any particular security at this
                site does not constitute a recommendation to buy, sell, or hold
                that or any other security.
              </p>
              <p style={{ fontSize: "medium" }}>
                You are aware and understand that SMC and its' Associates and/or
                its affiliates and/or its employees may have interests,
                financial or otherwise in the securities mentioned at this site.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                THIRD PARTY CONTRIBUTORS
              </h6>
              <p style={{ fontSize: "medium" }}>
                We may display articles from third party contributors on our
                site. A third party contributor by definition excludes employees
                of SMC and its' Associates. The views carried in such third
                party contributions are of the contributors and not of SMC and
                its' Associates and its associates, employees or directors. We
                are not liable for the same. We also assume that the third party
                contributor has taken all legal clearances and does not violate
                any copyright laws. We are not liable for any such violation.
              </p>
              <p style={{ fontSize: "medium" }}>
                A possibility exists that the site could include inaccuracies or
                errors. Although SMC and its' Associates attempts to ensure the
                integrity, correctness and authenticity of the site, it makes no
                guarantees whatsoever as to its completeness, correctness or
                accuracy.
              </p>
              <p style={{ fontSize: "medium" }}>
                Hence it would be your responsibility to evaluate any
                inaccuracy, error, or delay in, or omission of
              </p>
              <p style={{ fontSize: "medium" }}>
                1. any such data, information, or message within our web site or
              </p>
              <p style={{ fontSize: "medium" }}>
                2. the transmission or delivery of any data, information, or
                message; or
              </p>
              <p style={{ fontSize: "medium" }}>
                3. any loss, expense, cost or damage arising from or occasioned
                by any such inaccuracy, error, delay, or omission,
                non-performance, or interruption of any data, information, or
                message, due either to any negligent act or omission by us or
                our affiliates, agents, associates and or subsidiaries , or
                "force majeure" (i.e., flood, extraordinary weather conditions,
                earthquake, or other act of God, fire, war, insurrection, riot,
                labor dispute, accident, action of government, communications
                power failure, or equipment or software malfunction) or any
                other cause or circumstances beyond the reasonable control of
                SMC and its' Associates.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                SERVICES NOT AVAILABLE IN FOREIGN JURISDICTION
              </h6>
              <p style={{ fontSize: "medium" }}>
                This Internet site is only available to residents in India and
                is presently not available to foreign residents including non
                resident Indians (NRIs) in foreign jurisdiction where the
                services cannot be offered without prior regulatory compliances.
                In such cases due efforts are being made to comply with
                regulatory requirements in various jurisdictions which shall be
                notified from time to time.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                COPYRIGHT/TRADEMARKS
              </h6>
              <p style={{ fontSize: "medium" }}>
                SMC and its' Associates shall not be liable if the Customer
                downloads any information or software from this Website.
                Further, SMC and its' Associates shall not be liable if the
                Customer makes a copy, modifies, uploads, downloads, transmits,
                (re) publishes it or removes or obscures any intellectual
                property, including trademarks, copyright or other notices or
                legends contained in any such information or otherwise
                distributes any service or content from this Website. SMC and
                its' Associates shall not bear the responsibility if the
                Customer modifies or uses the content or services he/she gets
                from this Website for any purpose other than those permitted,
                and in doing so violates the intellectual property including
                trademarks, copyright and other proprietary rights of SMC and
                its' Associates.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>COOKIES</h6>
              <p style={{ fontSize: "medium" }}>
                Tracking data is automatically gathered using "cookies". A
                cookie is a small file containing information that is written to
                the user's hard drive and used to track the pages visited. The
                individual information gathered in this manner is used for
                internal purposes only and is used to track traffic patterns on
                the site.{" "}
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                MISCELLANEOUS
              </h6>
              <p style={{ fontSize: "medium" }}>
                Our services may not be suitable for all customers and if you
                have any doubts, you should seek advice from an independent
                Financial Adviser.
              </p>
              <p style={{ fontSize: "medium" }}>
                Transactions between you and SMC and its' Associates shall be
                governed by and construed in accordance with the laws of India,
                without regard to the laws regarding conflicts of law. Any
                litigation regarding this agreement shall be subject to the
                jurisdiction of the Competent Courts of Delhi alone.
              </p>
              <p style={{ fontSize: "medium" }}>
                Unless otherwise expressly provided herein, this Agreement, its
                provisions and intentions constitutes the entire Agreement
                between you and us with respect to the use of these web sites of
                ours and it supersedes all prior or contemporaneous
                communications and proposals, whether electronic, oral or
                written, between the two of us.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Copyright;

import React, { useContext } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Link, NavLink } from "react-router-dom";
import Banner from "../../../images/downloads-banner.webp";
import { Form } from "../../../components/Form";
import Loader from "../../../components/Loader";
import { Download } from "../../../images/svg/Download";
import { getAllForm } from "../../../utils/APICollection";
import { Context } from "../../../context";
import { getFileUrl } from "../../../utils/APICollection";
import { AWS_S3_DOWNLOAD_FORM_FOLDER } from "../../../utils/constants";

export const Downloads = () => {
  const { formData, setFormData, loading, setLoading } = useContext(Context);

  const getForms = async () => {
    const data = await getAllForm();
    const newObject = {
      form_category_id: 13,
      category_name: "Online Trading",
      form_files: [
        {
          form_id: 90,
          form_name: "SMC Privilege",
          form_file: "http://sftp.smcindiaonline.org",
        },
      ],
    };

    // Find the index of "Additional Segment Activation form"
    const index = data?.data.data.findIndex(
      (item) => item.category_name === "Additional Segment Activation form"
    );

    // Insert the new object after that category
    data?.data.data.splice(index + 1, 0, newObject);
    setFormData(data?.data.data);
  };
  React.useEffect(() => {
    getForms();
  }, []);

  const getUrl = async (fileName) => {
    if (loading) {
      return;
    }
    setLoading(true);

    // Check if the link is an external URL
    if (fileName.startsWith("http://") || fileName.startsWith("https://")) {
      window.open(fileName, "_blank");
      setLoading(false);
      return;
    }

    try {
      const { data } = await getFileUrl({
        file_name: fileName,
        folder_name: AWS_S3_DOWNLOAD_FORM_FOLDER,
      });
      if (data?.statusCode === 200) {
        window.open(data?.data?.[0]?.file_url, "_blank");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const isMobile = window.innerWidth <= 540;
  return (
    <>
      <Header />

      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>Downloads</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1 className="p-0 mb-3">Downloads</h1>
                  <p>
                    To offer SMC Online clients the best-in-class services, we
                    have provided some useful change/subscription forms in pdf
                    format.
                  </p>
                </div>
                <div
                  className="bannergt"
                  style={{ marginTop: isMobile ? "-25px" : "" }}
                >
                  <img src={Banner} alt="Hassle-free investing" />
                </div>
                <Form catID="Contact" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          {formData?.map((item, index) => {
            const backgroundColorClass =
              index % 2 === 0 ? "grey-background p-100" : "";
            return (
              <div
                className={`webcent ${backgroundColorClass}`}
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-duration="600"
                data-aos-once="true"
                data-aos-easing="ease-in-sine"
              >
                <div className="container">
                  <div className="row">
                    <div className="title">
                      <h3>{item.category_name}</h3>
                    </div>
                    <div className="faqcont downloads">
                      <div className="downloadrow col-12 pull-left gap-2">
                        {item.form_files.map((item, index) => {
                          return (
                            <div
                              className="downloadbox"
                              onClick={() => getUrl(item.form_file)}
                            >
                              <div className="downloadcont">
                                <h3>{item.form_name}</h3>
                              </div>
                              <div
                                className="downloadicon"
                                style={{ cursor: "pointer" }}
                              >
                                <Download />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

import React, { useContext, useEffect } from "react";
import Footer from "../../../../components/Footer";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Icon01 from "../../../../images/Emotionless-disciplined.svg";
import Icon02 from "../../../../images/Speed.svg";
import Icon03 from "../../../../images/Scalable.svg";
import Icon04 from "../../../../images/less-chance-of-error.svg";
import Icon05 from "../../../../images/Backtested.svg";
import Icon06 from "../../../../images/less-man-power.svg";
import Icon07 from "../../../../images/LowImpact.svg";
import Icon08 from "../../../../images/feeling.svg";
import Icon09 from "../../../../images/limited-trade.svg";
import Icon10 from "../../../../images/limited-stock.svg";
import Icon11 from "../../../../images/more-chance-error.svg";
import Icon12 from "../../../../images/random-trading.svg";
import Icon13 from "../../../../images/more-man-power.svg";
import Icon14 from "../../../../images/high-cost.svg";
import Img01 from "../../../../images/algo-trading-01.webp";
import Img02 from "../../../../images/algo-trading-02.webp";
import Img03 from "../../../../images/algo-trading-03.webp";
import Img04 from "../../../../images/algo-trading-04.webp";
import Img05 from "../../../../images/algo-trading-05.webp";
import Img06 from "../../../../images/algo-treading-platfrom.35267.webp";
import plogo01 from "../../../../images/partners/Algobulls (1).webp";
import plogo02 from "../../../../images/partners/ValueStocks.webp";
import plogo03 from "../../../../images/partners/Quant.webp";
import plogo04 from "../../../../images/partners/Tradetron.webp";
import plogo05 from "../../../../images/partners/foxTraderLogo.webp";
import plogo06 from "../../../../images/partners/narnolia-logo.webp";
import plogo10 from "../../../../images/plogo10.webp";
import icon01 from "../../../../images/algoicon01.webp";
import icon02 from "../../../../images/algoicon02.webp";
import icon03 from "../../../../images/algoicon03.webp";
import subscribe from "../../../../images/subscribe.webp";
import axios from "axios";
import { sendEkycOtpApi, leadApi } from "../../../../utils/APICollection";
import { Context } from "../../../../context";
import Loader from "../../../../components/Loader";
import Header from "../../../../components/Header";
import SEO from "../../../../components/common/SEO";
import { seoData } from "../../../../utils/Data";
import { CAPTCHA_KEY, EKYC_CAPTCHA_KEY } from "../../../../utils/constants";
import {
  loadReCaptchaScript,
  loadReCaptchaScript1,
} from "../../../../components/common/FormattedDate";
import AlgoFaq from "./AlgoFaq";

export const AlgoTrading = ({ catID }) => {
  const [mobileNumber, setMobileNumber] = React.useState("");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("refcode");
  const {
    isDemat,
    setIsDemat,
    name,
    num,
    categoryID,
    setCategoryID,
    setNum,
    setName,
    ipAddress,
    email,
    city,
    state,
    setUrlData,
    setIPAddress,
    loading,
    setLoading,
    successMessage,
    setSuccessMessage,
    deviceID,
    setDeviceID,
    setLeadId,
    showPath,
    setShowPath,
    showRoute,
    setShowRoute,
    source,
    setSource,
  } = useContext(Context);

  const [mobileError, setMobileError] = React.useState();
  const handleNumChange = (e) => {
    setNum(e.target.value);
    setMobileError("");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);

    let mobErr = "";
    if (num?.toString().length !== 10) {
      mobErr = "Please fill out this field";
      setLoading(false);
    }
    if (mobErr) {
      setMobileError(mobErr);
    } else {
      try {
        // const { data, error } = await register({ mobile_no: num });
        // if (data?.stutus == "success") {
        getIP();

        // setCategoryID(catID);

        setUrlData({
          ref: searchParams.get("utm_refcode"),
          utm_source: searchParams.get("utm_source"),
          medium: searchParams.get("utm_medium"),
          campaign: searchParams.get("utm_campaign"),
        });
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(String(CAPTCHA_KEY), { action: "submit" })
            .then(async (token) => {
              const { data: data1, messagel } = await leadApi({
                mobile_no: num,
                captchaToken: token,
                leadInfo: [
                  {
                    user_name: name ? name : "",
                    contact_number: num,
                    email: email ? email : "",
                    city: city ? city : "",
                    state: state ? state : "",
                    category_code: "DEMAT",
                    country: "",
                    source: "Smc Website | DEMAT",
                    ref: searchParams.get("utm_refcode"),
                    utm_source: searchParams.get("utm_source"),
                    medium: searchParams.get("utm_medium"),
                    campaign: searchParams.get("utm_campaign"),
                    ip: ipAddress,
                    device: deviceID,
                    status: "Otp sent",
                    client_id: "",
                    sub_query: "",
                    existing_customer: "",
                    explain_query: "",
                  },
                ],
              });
              if (data1) {
                setLeadId(String(data1?.data[0]));
                window.grecaptcha.ready(() => {
                  window.grecaptcha
                    .execute(String(EKYC_CAPTCHA_KEY), { action: "submit" })
                    .then(async (token) => {
                      const data = await sendEkycOtpApi({
                        mobile_number: num,
                        source: "web",
                        partner_id: "smc",
                        device_code: "",
                        captcha_token: token,
                      });
                      if (data?.data?.status === "success") {
                        setLoading(false);
                        setCategoryID("DEMAT");
                        setUrlData({
                          ref: searchParams.get("utm_refcode"),
                          utm_source: searchParams.get("utm_source"),
                          medium: searchParams.get("utm_medium"),
                          campaign: searchParams.get("utm_campaign"),
                        });
                        setIsDemat(true);
                        navigate("/validateotp");
                      } else if (
                        data?.error?.response?.data?.error ===
                        "User details already exist"
                      ) {
                        setSuccessMessage("You are already registered with us");
                        setTimeout(() => {
                          setSuccessMessage("");
                        }, 2000);
                        setLoading(false);
                        setNum("");
                      } else if (
                        data?.error?.response?.data?.error ===
                        "Something went wrong"
                      ) {
                        setSuccessMessage(
                          "Uh - oh!, This was not supposed to happen. Please try after sometime"
                        );
                        setTimeout(() => {
                          setSuccessMessage("");
                        }, 2000);
                        setLoading(false);
                        setNum("");
                      } else {
                        setSuccessMessage(data?.error?.response?.data?.error);
                        setTimeout(() => {
                          setSuccessMessage("");
                        }, 2000);
                        setLoading(false);
                        setNum("");
                      }
                    });
                });
              }
            });
        });
        // }
      } catch (error) {
        setMobileError("");
      }
      // finally {
      //   setLoading(false);
      // }
    }
  };
  const getSourceByCategory = (categoryID) => {
    const categorySourceMap = {
      DEMAT: "SMC Website | DEMAT",
    };

    return categorySourceMap[categoryID] || "SMC Website | DEMAT";
  };
  const getIP = async () => {
    try {
      const userAgent = navigator.userAgent;
      const res = await axios.get("https://api.ipify.org?format=json");

      setIPAddress(res?.data?.ip);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let userAgent = navigator.userAgent;
    setSource("Smc Website | DEMAT");
    if (/Android/i.test(userAgent)) {
      setDeviceID("Android device.");
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setDeviceID("iOS device.");
    } else if (/Macintosh/i.test(userAgent)) {
      setDeviceID("macOS device.");
    } else if (/Windows/i.test(userAgent)) {
      setDeviceID("Windows device.");
    } else {
      setDeviceID("Unknown Device");
    }
    getIP();
    loadReCaptchaScript();
    loadReCaptchaScript1();
  }, []);

  React.useEffect(() => {
    getIP();
  }, []);

  // if (loading) {
  //   return <Loader />;
  // }

  return (
    <>
      <Header />
      <SEO
        title={seoData.algoTrading.title}
        description={seoData.algoTrading.description}
        name="Company name."
        type="article"
        url={seoData.algoTrading.url}
        image={seoData.algoTrading.image}
      />

      <section id="banner">
        <div className="banner internal bannercent">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                {showPath == "product" ? (
                  <li style={{ color: "initial" }}>Products</li>
                ) : showPath == "research" ? (
                  <li style={{ color: "initial" }}>Research</li>
                ) : (
                  <li>
                    <Link to="/trade">Trade</Link>
                  </li>
                )}

                <li>Algo Trading platform</li>
                {/* {showRoute ? (
                  <Link to="/trade">Trade</Link>
                ) : (
                  <li style={{ color: "initial" }}>Research</li>
                )} */}
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1>Automate your Trades with Algo Trading</h1>
                  <div className="creativehead">
                    <div className="demataccount m-0">
                      <form>
                        <div className="demataccountinput">
                          <div className="demataccountfrm">
                            <div className="forminputsite">
                              <div className="phonemange">
                                <span>
                                  <strong className="text-black">+91</strong>
                                </span>
                              </div>
                              <input
                                type="Number"
                                placeholder="Enter your mobile number"
                                value={num}
                                onChange={handleNumChange}
                                style={{
                                  borderColor: mobileError ? "red" : "",
                                }}
                                error={!!mobileError}
                                helperText={mobileError}
                                minLength={10}
                                maxLength={10}
                                //  required
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, e.target.maxLength);
                                }}
                                inputProps={{
                                  minLength: 10,
                                  maxLength: 10,
                                  type: "number",
                                }}
                                disabled={loading}
                              />
                            </div>
                            <button type="submit" onClick={onSubmit}>
                              Start Now
                            </button>
                          </div>
                        </div>
                      </form>
                      {mobileError && (
                        <p style={{ color: "red", marginLeft: "5rem" }}>
                          {mobileError}
                        </p>
                      )}
                      {successMessage && (
                        <p style={{ color: "red", marginLeft: "5rem" }}>
                          {successMessage}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bannergt">
                  <img src={Img06} alt="Hassle-free investing" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent grey-background p-100"
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3 style={{ lineHeight: "40px" }}>
                      What is Algorithmic Trading?
                    </h3>
                  </div>
                  <div className="algorithemictrading mflex-wrap col-12 pull-left d-flex justify-content-center">
                    <div className="circlebox">
                      <span>
                        <img src={icon01} alt="" />
                      </span>
                      <p>
                        Algorithmic Trading is the process of using of using
                        computers programmed to follow a defined set of
                        instructions
                      </p>
                    </div>
                    <div className="circlebox">
                      <span>
                        <img src={icon02} alt="" />
                      </span>
                      <p>
                        Algorithmic Trading is consist of computer codes and
                        chart analysis to enter and exit trades according to set
                        parameters
                      </p>
                    </div>
                    <div className="circlebox">
                      <span>
                        <img src={icon03} alt="" />
                      </span>
                      <p>
                        Once the current market conditions match any
                        predetermined criteria, algorithmic trading can execute
                        a buy or sell order on your behalf
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container overflow-hidden pb-1">
                  <div className="title bg-white position-relative z-order-1">
                    <h3>Why you should subscribe to it?</h3>
                  </div>
                  <div className="subscribestn d-flex mflex-wrap justify-content-center align-items-center m-auto col-lg-10 col-12 gap-5">
                    <div className="rightcross">
                      <img src={subscribe} alt="" />
                    </div>
                    <div className="rightcross ipoodlist m-0">
                      <ul>
                        <li>Trades are executed at thebest possible prices.</li>
                        <li>Instant and accurate trade order placement</li>
                        <li>Trades times correctly and instantly.</li>
                        <li>
                          Reduced transaction costsdue to lack of human
                          intervention.
                        </li>
                        <li>
                          Simultaneous automated checks on multiple market
                          conditions.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container overflow-hidden pb-1">
                  <div className="title bg-white position-relative z-order-1">
                    <h3> Advantages of Algo trading</h3>
                  </div>
                  <div className="algoadvantages col-12 pull-left mflex-wrap">
                    <div className="algobox">
                      <div className="algocontent">
                        <div className="algofeature">
                          <div className="alogofrow d-flex align-items-center">
                            <div className="algoleft col-2">
                              <span>
                                <img src={Icon01} />
                              </span>
                            </div>
                            <div className="algoright">
                              <p>Emotion Less / Disciplined</p>
                            </div>
                          </div>
                          <div className="alogofrow d-flex align-items-center">
                            <div className="algoleft col-2">
                              <span>
                                <img src={Icon02} />
                              </span>
                            </div>
                            <div className="algoright">
                              <p>Speed (Multiple Trade)</p>
                            </div>
                          </div>
                          <div className="alogofrow d-flex align-items-center">
                            <div className="algoleft col-2">
                              <span>
                                <img src={Icon03} />
                              </span>
                            </div>
                            <div className="algoright">
                              <p>Scalable</p>
                            </div>
                          </div>
                          <div className="alogofrow d-flex align-items-center">
                            <div className="algoleft col-2">
                              <span>
                                <img src={Icon04} />
                              </span>
                            </div>
                            <div className="algoright">
                              <p>Less Chances Of Errors</p>
                            </div>
                          </div>
                          <div className="alogofrow d-flex align-items-center">
                            <div className="algoleft col-2">
                              <span>
                                <img src={Icon05} />
                              </span>
                            </div>
                            <div className="algoright">
                              <p>Back Tested Strategies</p>
                            </div>
                          </div>
                          <div className="alogofrow d-flex align-items-center">
                            <div className="algoleft col-2">
                              <span>
                                <img src={Icon06} />
                              </span>
                            </div>
                            <div className="algoright">
                              <p>Less Man Power Required</p>
                            </div>
                          </div>
                          <div className="alogofrow d-flex align-items-center">
                            <div className="algoleft col-2">
                              <span>
                                <img src={Icon07} />
                              </span>
                            </div>
                            <div className="algoright">
                              <p>Low Impact Cost</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="algocircle">
                        <div className="headcircle">
                          <h2>
                            Algorithmic <br /> Trading
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="algobox">
                      <div className="algocontent">
                        <div className="algofeature pull-right">
                          <div className="alogofrow d-flex align-items-center">
                            <div className="algoleft col-2">
                              <span>
                                <img src={Icon08} />
                              </span>
                            </div>
                            <div className="algoright">
                              <p>Gut Feeling Involve</p>
                            </div>
                          </div>
                          <div className="alogofrow d-flex align-items-center">
                            <div className="algoleft col-2">
                              <span>
                                <img src={Icon09} />
                              </span>
                            </div>
                            <div className="algoright">
                              <p>Limited Trade At A Time</p>
                            </div>
                          </div>
                          <div className="alogofrow d-flex align-items-center">
                            <div className="algoleft col-2">
                              <span>
                                <img src={Icon10} />
                              </span>
                            </div>
                            <div className="algoright">
                              <p>Limited Stocks Scan</p>
                            </div>
                          </div>
                          <div className="alogofrow d-flex align-items-center">
                            <div className="algoleft col-2">
                              <span>
                                <img src={Icon11} />
                              </span>
                            </div>
                            <div className="algoright">
                              <p>More Chances Of Errors</p>
                            </div>
                          </div>
                          <div className="alogofrow d-flex align-items-center">
                            <div className="algoleft col-2">
                              <span>
                                <img src={Icon12} />
                              </span>
                            </div>
                            <div className="algoright">
                              <p>Random Trading</p>
                            </div>
                          </div>
                          <div className="alogofrow d-flex align-items-center">
                            <div className="algoleft col-2">
                              <span>
                                <img src={Icon13} />
                              </span>
                            </div>
                            <div className="algoright">
                              <p>More Man Power Required</p>
                            </div>
                          </div>
                          <div className="alogofrow d-flex align-items-center">
                            <div className="algoleft col-2">
                              <span>
                                <img src={Icon14} />
                              </span>
                            </div>
                            <div className="algoright">
                              <p>High Impact Cost</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="algocircle">
                        <div className="headcircle">
                          <h2>
                            Manual <br /> Trading
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent grey-background p-100"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Partners</h3>
                  </div>
                  <div className="plogos col-12 pull-left">
                    <ul className="d-flex flex-wrap justify-content-center col-12 col-lg-8 m-auto gap-2">
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img src={plogo01} alt="" />
                      </li>

                      <li
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={plogo03}
                          alt=""
                          // style={{ paddingTop: "1rem" }}
                        />
                      </li>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={plogo04}
                          alt=""
                          // style={{ paddingTop: "1rem" }}
                        />
                      </li>

                      <li
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img src={plogo06} alt="" />
                      </li>

                      {/* <li>
                        <img src={plogo07} alt="" />
                      </li>
                      <li>
                        <img src={plogo08} alt="" />
                      </li>
                      <li>
                        <img src={plogo09} alt="" />
                      </li> */}
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={plogo05}
                          alt=""
                          style={{
                            height: "40px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "100%",
                          }}
                        />
                      </li>
                      <li>
                        <img src={plogo10} alt="" />
                      </li>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={plogo02}
                          alt=""
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="partnerfeature">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>Process of Algo Trading</h3>
                    </div>
                    <div className="partnerlist">
                      <ul className="d-flex flex-nowrap mflex-wrap">
                        <li>
                          <span>
                            <img src={Img01} />
                          </span>
                          <p>
                            <strong>Conception</strong>
                          </p>
                          <p>Ideate and Conceptualize your trading strategy</p>
                        </li>
                        <li>
                          <span>
                            <img src={Img02} />
                          </span>
                          <p>
                            <strong>Access</strong>
                          </p>
                          <p>Define Rules, Risks and Return</p>
                        </li>
                        <li>
                          <span>
                            <img src={Img03} />
                          </span>
                          <p>
                            <strong>Build Strategy</strong>
                          </p>
                          <p>Use Ready Made or Custom strategy</p>
                        </li>
                        <li>
                          <span>
                            <img src={Img04} />
                          </span>
                          <p>
                            <strong>Strategy Testing</strong>
                          </p>
                          <p>Back Test and LiveTest your strategy</p>
                        </li>
                        <li>
                          <span>
                            <img src={Img05} />
                          </span>
                          <p>
                            <strong>Approval</strong>
                          </p>
                          <p>Fine tune your strategy as per testing reports</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AlgoFaq />
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

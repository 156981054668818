import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const Faq = () => {
  return (
    <>
      <div
        className="webcent"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Frequently Asked Questions</h3>
              </div>
              <div className="faqcont">
                <Accordion allowZeroExpanded="true">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is fundamental analysis?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Fundamental analysis is a method of evaluating a
                          security's intrinsic value by examining related
                          economic, financial, and other qualitative and
                          quantitative factors. It involves studying the
                          company's financial statements, industry trends,
                          economic indicators, and management quality.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the key financial statements used in
                        fundamental analysis?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The key financial statements include the income
                          statement, balance sheet, and cash flow statement.
                          These documents provide insights into a company's
                          profitability, financial health, and cash flow
                          dynamics.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How do I analyze a company's earnings?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Analyzing a company's earnings involves studying its
                          income statement. Key metrics include revenue growth,
                          net income, earnings per share (EPS), and profit
                          margins. Consistency and trends in these figures can
                          provide valuable insights.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the significance of the balance sheet in
                        fundamental analysis?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The balance sheet provides a snapshot of a company's
                          financial position at a specific point in time. It
                          includes assets, liabilities, and shareholders'
                          equity. Analysts assess liquidity, solvency, and
                          leverage ratios to understand a company's financial
                          health.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How can I evaluate a company's management team?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Assessing management involves examining their track
                          record, corporate governance practices, communication
                          with shareholders, and strategic decisions. Annual
                          reports, conference calls, and news about leadership
                          changes can provide valuable information.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What role do industry and economic factors play in
                        fundamental analysis?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Industry and economic factors can impact a company's
                          performance. Analysts consider industry trends,
                          competitive dynamics, and macroeconomic indicators to
                          gauge a company's position and potential challenges or
                          opportunities.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are some common profitability ratios?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Common profitability ratios include net profit margin,
                          return on equity (ROE), and return on assets (ROA).
                          These ratios assess a company's efficiency in
                          generating profits relative to its equity and assets.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;

import React, { useContext, useEffect } from "react";
import { Switch, Grid, Typography } from "@mui/material";
import { Context } from "../../../context";

export default function CustomSwitch() {
  const [state, setState] = React.useState(false);
  const { setExchange } = useContext(Context);

  const handleChange = (event) => {
    setState(event.target.checked);
  };

  useEffect(() => {
    if (state === false) {
      setExchange("NSE");
    } else {
      setExchange("BSE");
    }
  }, [state]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      width={170}
      marginLeft={"-10px"}
    >
      <Grid item>
        <Typography>NSE</Typography>
      </Grid>
      <Grid item>
        <Switch checked={state} onChange={handleChange} />
      </Grid>
      <Grid item>
        <Typography>BSE</Typography>
      </Grid>
    </Grid>
  );
}

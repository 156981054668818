import React, { useState, useEffect } from "react";
import { getCompanyWiseHolding } from "../../../../utils/APICollection";
const StockMfInvested = () => {
  const [mutualFundHoldingData, setMutualFundHoldingData] = useState([]);
  const fetchMutualFundHoldingData = async () => {
    try {
      const res = await getCompanyWiseHolding(
        window.location.pathname.split("/")[3]
      );
      setMutualFundHoldingData(res?.data?.data);
    } catch (err) {
      console.log("error", err);
    }
  };
  useEffect(() => {
    fetchMutualFundHoldingData();
  }, [window.location.pathname.split("/")[3]]);
  return (
    <div className="webcent">
      <div className="calheading">
        <h3>Top Mutual Funds Invested</h3>
      </div>
      <div className="coprofilecont m-0">
        <div className="ipoapplyfrm">
          <ul>
            <li>
              <span>FUND NAME </span>
              <span>AUM(%)</span>
            </li>
            {mutualFundHoldingData?.map((item) => (
              <li>
                <span>{item?.sch_Name}</span>
                <span>{item?.Perc_Hold.toFixed(2)}%</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StockMfInvested;

import React, { useContext } from "react";
import Investbanner from "../../images/webinar-banner.webp";
import { Link } from "react-router-dom";
import Icon04 from "../../images/webinar01.webp";
import Icon05 from "../../images/learning02.webp";
import Icon06 from "../../images/webinar02.webp";
import Icon07 from "../../images/webinar03.webp";
import Icon08 from "../../images/webinar04.webp";
import Icon09 from "../../images/webinar05.webp";
import Icon03 from "../../images/webinar06.webp";
import Icon02 from "../../images/webinar07.webp";
import Footer from "../../components/Footer";
import { Context } from "../../context";
import CarouselWebinar from "../../components/CarouselWebinar";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import SEO from "../../components/common/SEO";
import ExpertSlider from "../research/fundaMentalResearch/ExpertSlider";
import Faq from "./Faq";
import WebinarList from "./WebinarList";
import { seoData } from "../../utils/Data";

export const Webinar = () => {
  const { loading } = useContext(Context);

  return (
    <>
      <Header />
      <SEO
        title={seoData.webinar.title}
        description={seoData.webinar.description}
        name="Company name."
        type="article"
        url={seoData.webinar.url}
        image={seoData.webinar.image}
      />
      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li style={{ color: "black" }}>learn</li>
                <li>webinar</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1>Trading Insights. Webinars.</h1>
                </div>
                <div className="bannergt">
                  <img src={Investbanner} alt="Hassle-free investing" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <WebinarList />
              </div>
            </div>
          </div>
          <ExpertSlider />
          <div
            className="webcent grey-background"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="col-12 pull-left p-100 ">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>Webinars. Curated by Industry Experts.</h3>
                    </div>
                    <div className="iconrow">
                      <ul className="align-items-start">
                        <li>
                          <span>
                            <img src={Icon04} alt="Truths of Investing" />
                          </span>
                          <p>Truths of Investing</p>
                        </li>
                        <li>
                          <span>
                            <img src={Icon05} alt="Basics of Options Trading" />
                          </span>
                          <p>Basics of Options Trading</p>
                        </li>
                        <li>
                          <span>
                            <img
                              src={Icon06}
                              alt="Risk Management Techniques"
                            />
                          </span>
                          <p>Risk Management Techniques</p>
                        </li>
                        <li>
                          <span>
                            <img
                              src={Icon07}
                              alt="Technical Analysis made Simple"
                            />
                          </span>
                          <p>Technical Analysis made Simple</p>
                        </li>
                        <li>
                          <span>
                            <img src={Icon08} alt="Identifying Trends" />
                          </span>
                          <p>Identifying Trends</p>
                        </li>
                        <li>
                          <span>
                            <img src={Icon09} alt="Basics of Commodities" />
                          </span>
                          <p>Basics of Commodities</p>
                        </li>
                        <li>
                          <span>
                            <img src={Icon03} alt="Option Trading Strategies" />
                          </span>
                          <p>Option Trading Strategies</p>
                        </li>
                        <li>
                          <span>
                            <img
                              src={Icon02}
                              alt="Using Option Chain and Open Interest Data"
                            />
                          </span>
                          <p>Using Option Chain and Open Interest Data</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent pt-0"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="minicontainer">
                    <div className="title">
                      <h3>Our Work Speaks for Itself</h3>
                    </div>
                    <CarouselWebinar />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Faq />
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

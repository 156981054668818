import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

const contactDetails = [
  {
    title: "India",
    company: "SMC Global Securities Ltd",
    address: "11/68, Shanti Chamber, Pusa Road, New Delhi - 110005",
    email: "fpi@smcindiaonline.com",
    phone: "+91 - 96509-88009",
  },
  {
    title: "Gift City",
    company: "SMC Global IFSC",
    address:
      "Unit No. 222, 2nd floor, Signature Building, Block No. 13B, Road 1C, Zone-I, Gift-SEZ, Gift City Gandhinagar, Gujarat - 382355",
    email: "fpi@smcifsc.com",
    phone: "+91 - 96509-88009",
  },
];

const ContactCard = () => {
  return (
    <Box sx={{ padding: 4, textAlign: "left", width: "100%" }}>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Typography variant="h4" gutterBottom textAlign={"center"}>
          Let&apos;s Connect
        </Typography>
        <Box
          sx={{
            backgroundColor: "#29AD89",
            height: 2,
            width: 70,
            mb: 3,
          }}
        />
      </Box>

      <Grid container justifyContent="center" alignItems={"center"}>
        <Grid item xs={12} md={8}>
          {/* <Card elevation={3}> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { md: "space-between", xs: "center" },
              gap: 10,
            }}
          >
            {contactDetails.map((detail, index) => (
              <Box key={index} sx={{ width: { md: "50%", xs: "100%" } }}>
                <Typography variant="h6" gutterBottom fontWeight={"bold"}>
                  {detail.title}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {detail.company}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mb: 1,
                  }}
                >
                  <LocationOnIcon sx={{ color: "#29AD89" }} />
                  <Typography variant="body2">{detail.address}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mb: 1,
                  }}
                >
                  <EmailIcon sx={{ color: "#29AD89" }} />
                  <Typography variant="body2">{detail.email}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <PhoneIcon sx={{ color: "#29AD89" }} />
                  <Typography variant="body2">{detail.phone}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          {/* </Card> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactCard;

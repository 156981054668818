import React from 'react'

export const Faqs = (props) => {
  return (
    <>
       <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="#fff"
      d="M13.252 6.877a4.456 4.456 0 0 1 2.348.27c.743.301 1.39.798 1.867 1.438a4.226 4.226 0 0 1 .361 4.493 4.328 4.328 0 0 1-1.617 1.71c-.183.108-.38.21-.54.293l-.127.065a2.772 2.772 0 0 0-.416.252.477.477 0 0 0-.142.157.653.653 0 0 0-.053.301v1.254a.6.6 0 0 1-.6.6h-.8a.6.6 0 0 1-.6-.6v-1.254c0-.442.086-.846.27-1.207.183-.36.435-.624.69-.824.246-.193.51-.338.721-.45l.181-.093c.146-.075.266-.137.39-.212.374-.223.675-.542.87-.918a2.227 2.227 0 0 0-.192-2.372 2.366 2.366 0 0 0-1.012-.778 2.456 2.456 0 0 0-1.294-.149 2.417 2.417 0 0 0-1.178.529 2.322 2.322 0 0 0-.447.498c-.184.275-.518.452-.832.347l-.76-.252c-.314-.105-.487-.447-.34-.744A4.308 4.308 0 0 1 11.1 7.845a4.416 4.416 0 0 1 2.153-.968ZM13.111 19.876a.5.5 0 0 1 .5-.5h.648a.5.5 0 0 1 .5.5v.648a.5.5 0 0 1-.5.5h-.648a.5.5 0 0 1-.5-.5v-.648Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M14 1C6.82 1 1 6.82 1 14s5.82 13 13 13 13-5.82 13-13S21.18 1 14 1ZM3 14C3 7.925 7.925 3 14 3s11 4.925 11 11-4.925 11-11 11S3 20.075 3 14Z"
      clipRule="evenodd"
    />
  </svg>
    </>
  )
}

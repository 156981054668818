import React, { useContext } from "react";
import Footer from "../../components/Footer";
import Banner from "../../images/research-banner.webp";
import Research02 from "../../images/simply03.webp";
import Research03 from "../../images/research-03.png";
import { Link, NavLink } from "react-router-dom";
import Stock from "../../images/Stocks.webp";
import Ipos from "../../images/research-telegram.webp";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Excellence } from "../../components/Excellence";
import Researchimg from "../../images/resarch-team.webp";
import { Shape } from "../../images/svg/Shape";
import ResearchForm from "../../components/ResearchForm";
import { Context } from "../../context";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import SEO from "../../components/common/SEO";
import NumberCount from "./NumberCount";
import ExpertSlider from "./ExpertSlider";
import { seoData } from "../../utils/Data";

export const Research = () => {
  const { loading } = useContext(Context);
  const researchData = localStorage.getItem("researchData");
  const finalCategory = JSON.parse(researchData);
  let filteredArr = finalCategory?.filter(
    (obj) =>
      obj.category_name !== "Fundamental Research" &&
      obj.category_name !== "Technical Research" &&
      obj.category_name !== "Wise Money" &&
      obj.category_name !== "Currency Analysis" &&
      obj.category_name !== "Commodity Analysis"
  );
  return (
    <>
      <Header />
      <SEO
        title={seoData.research.title}
        description={seoData.research.description}
        name="Company name."
        type="article"
        url={seoData.research.url}
        image={seoData.research.image}
      />
      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Research</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1>
                    We Research. <span>You Invest.</span>
                  </h1>
                  <p>
                    Detailed insights that help you make the right decisions.
                  </p>
                </div>
                <div className="bannergt">
                  <img src={Banner} alt="Our Research Your Way" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <NumberCount />
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <span>Research</span>
                    <h3>Insights. We Deliver.</h3>
                    <p>
                      Analysis reports curated by the award-winning team of
                      research experts of SMC.
                    </p>
                  </div>
                  <div className="tradebenefit fixtabs mb-5">
                    <Tabs>
                      <div className="tradingben m-0 gap-0">
                        <div className="iconrow noicon gap">
                          <TabList className="justify-content-center">
                            <Tab>
                              <h4>Research Reports</h4>
                            </Tab>
                            <Tab>
                              <h4>Telegram Channels</h4>
                            </Tab>
                          </TabList>
                        </div>
                      </div>
                      <TabPanel>
                        <div className="ipoodlist ">
                          <div className="d-flex benefitts gap-5 align-items-center">
                            <div className="col-6">
                              <img src={Stock} />
                            </div>
                            <div className="col-6">
                              <p>
                                Analyse the market trends with in-depth insights
                                on various stocks and derivatives with our
                                detailed Research Reports.
                              </p>
                              <ul>
                                {filteredArr?.map((category) => {
                                  return (
                                    <li>
                                      <NavLink
                                        to={`/research/${category?.category_name
                                          .replace(/\s+/g, "-")
                                          .toLowerCase()}/${
                                          category.research_category_master_id
                                        }`}
                                      >
                                        {category?.category_name ==
                                        "Mutual Fund Analysis"
                                          ? "Mutual Funds"
                                          : category?.category_name.split(
                                              " "
                                            )[0]}
                                      </NavLink>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="ipoodlist ">
                          <div className="d-flex benefitts gap-5 align-items-center">
                            <div className="col-6">
                              <img src={Ipos} />
                            </div>
                            <div className="col-6">
                              <p>
                                Join the Premium Telegram channels of SMC and
                                get regular market updates and research calls in
                                Nifty, Bank Nifty, Index Option, and Commodity
                                from our team of experts.
                              </p>
                              <ul>
                                <li>
                                  <NavLink
                                    to="https://subscriptions.smcindiaonline.com:8880/indexcalls"
                                    target="-blank"
                                  >
                                    Index Trading with Nitin Murarka
                                  </NavLink>
                                </li>
                                <li>
                                  {" "}
                                  <NavLink
                                    to="https://subscriptions.smcindiaonline.com:8880/equityfunda"
                                    target="_blank"
                                  >
                                    Equity Ka Funda
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to="https://subscriptions.smcindiaonline.com:8880/technofunda"
                                    target="_blank"
                                  >
                                    Techno Funda
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to="https://subscriptions.smcindiaonline.com:8880/commodity"
                                    target="_blank"
                                  >
                                    Commodity Mantra
                                  </NavLink>
                                </li>

                                <li>
                                  <NavLink
                                    to="https://subscriptions.smcindiaonline.com:8880/equityfunda"
                                    target="_blank"
                                  >
                                    Combo Plan
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                  <div className="reserchstn">
                    <div
                      className="researchbox"
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <div className="researchcircle">
                        <img src={Research02} alt="" />
                        <Shape />
                      </div>
                      <div className="researchcont">
                        <h4>Equity Picks</h4>
                        <p>
                          Stay up-to-date with best stock picks based on a
                          combination of fundamental and technical analysis.
                        </p>
                      </div>
                    </div>
                    <div
                      className="researchbox"
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <div className="researchcircle">
                        <img src={Research03} alt="" />
                        <Shape />
                      </div>
                      <div className="researchcont">
                        <h4>Tools</h4>
                        <p>
                          Make smarter trading decisions with SMC’s advanced
                          trading tools.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent p-100 gradient whitetext"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="col-12 pull-left">
                    <ResearchForm catID="Research" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Excellence />
          <ExpertSlider />
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="reserchteam">
                    <div className="title">
                      <h3>Meet Our Research Team</h3>
                      <h4>
                        Our Research Team Cover 214 Companies Across 24 Sectors{" "}
                      </h4>
                    </div>
                    <div className="researchteamimg">
                      <img src={Researchimg} alt="SMC Research Team" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

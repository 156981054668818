import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const Faq = () => {
  return (
    <>
      <div
        className="webcent"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Frequently Asked Questions</h3>
              </div>
              <div className="faqcont">
                <Accordion allowZeroExpanded="true">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the difference between saving and investing?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Saving is the act of setting aside money for future use.
                        You can save money in a savings account, money market
                        account, or certificate of deposit (CD). Savings
                        accounts typically offer a low-interest rate, but they
                        are very safe, guaranteeing your money.
                      </p>
                      <p>
                        Investing is putting your money into assets you believe
                        will increase in value over time. These assets include
                        stocks, bonds, mutual funds, real estate, and other
                        investments. Investing can be a riskier activity than
                        saving, but it also has the potential to generate higher
                        returns.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are investment benefits?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        There are many benefits to investing. A few of them are
                        mentioned below.
                        <br />
                        <br /> <strong>Potential for long-term growth</strong> -
                        The stock market has historically trended upwards over
                        the long term. This means that investing for an extended
                        period makes you more likely to see your money grow.
                        <br />
                        <br />
                        <strong>Beat inflation</strong> - Inflation is the rate
                        at which prices increase over time. This means that your
                        money loses value over time. Investing can help you beat
                        inflation by growing your money faster than inflation.
                        <br />
                        <br />
                        <strong>Earn income</strong> - Some investments, such as
                        stocks and bonds, can generate income through dividends
                        or interest payments. This can help you supplement your
                        income and reach your financial goals faster.
                        <br />
                        <br />
                        <strong>Build wealth </strong>- Investing over the long
                        term can help you build wealth. This means that you will
                        have more money available to you in the future for
                        retirement, a down payment on a house, or other
                        financial goals.
                        <br />
                        <br />
                        <strong>Diversify your portfolio</strong> - Investing in
                        various assets can reduce your risk. This is because not
                        all assets will perform the same way in the market.
                        <br />
                        <br />
                        <strong>Access to professional management</strong> -
                        Some investments, such as mutual funds and
                        exchange-traded funds (ETFs), are professionally
                        managed. This means you don't have to worry about making
                        investment decisions.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the best way to invest money?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The best way to invest money depends on your financial
                        situation and goals. However, some general principles
                        can help you make sound investment decisions.
                        <ul>
                          <li>
                            <strong>Understand your risk tolerance</strong>
                          </li>
                          <li>
                            <strong>Set your financial goals</strong>
                          </li>
                          <li>
                            <strong>Do your research</strong>
                          </li>
                          <li>
                            <strong>Diversify your portfolio</strong>
                          </li>
                          <li>
                            <strong>Rebalance your portfolio regularly</strong>
                          </li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How much does a Authorized Person earn through
                        commission?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A Authorized Person's commission depends on several
                        factors, including the brokerage firm they are
                        associated with, the type of transactions they
                        facilitate, and the volume of transactions they
                        generate. Authorized Person generally earn a percentage
                        of the brokerage fees charged to clients. The percentage
                        of commission that a Authorized Person earns can range
                        from 30% to 70%, depending on the brokerage firm.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the objective of investment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The investment objectives can vary depending on the
                        individual investor's circumstances and goals. However,
                        some of the most common investment objectives are given
                        below.
                        <br />
                        <br />
                        <strong>Growth</strong> - This is the goal of increasing
                        the value of your investment over time. This can be done
                        by investing in assets expected to appreciate, such as
                        stocks or real estate.
                        <br />
                        <br />
                        <strong>Income</strong> - This is the goal of generating
                        regular income from your investments. Investing in
                        assets that pay dividends or interest, such as stocks or
                        bonds, can do this.
                        <br />
                        <br />
                        <strong>Liquidity</strong> - This is the goal of
                        accessing your money quickly and easily. This is
                        important if you need to use your investment money for
                        an unexpected expense or to meet a financial goal.
                        <br />
                        <br /> <strong>Safety</strong> - This is the goal of
                        protecting your investment from losses. This can be done
                        by investing in assets that are considered to be
                        low-risk, such as government bonds.
                        <br />
                        <br />
                        <strong>Diversification</strong>- This is the goal of
                        spreading your investment risk across different assets.
                        This helps reduce the impact of losses if one asset
                        performs poorly.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How do you do stock trading and investment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Here are the steps on how to do stock trading and
                        investment.
                        <ul>
                          <li>Open a brokerage account</li>
                          <li>Fund your brokerage account</li>
                          <li>Choose your investments</li>
                          <li>Place a trade</li>
                          <li>Monitor your investments</li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Where can I invest safely in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        There are many safe investment options available in
                        India. Here are some of the most popular options.
                        <ul>
                          <li>Public Provident Fund (PPF)</li>
                          <li>National Pension System (NPS)</li>
                          <li>Senior Citizen Savings Scheme (SCSS)</li>
                          <li>Fixed Deposit (FD)</li>
                          <li>Recurring Deposit (RD)</li>
                          <li>National Savings Certificate (NSC)</li>
                          <li>Post Office Monthly Income Scheme (POMIS)</li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Where to invest money in the share market?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        There are many different places where you can invest
                        money in the share market in India. Here are some of the
                        most popular options.
                        <ul>
                          <li>
                            <strong>Stock exchanges</strong>
                          </li>
                          <li>
                            <strong>Direct equity</strong>
                          </li>
                          <li>
                            <strong>Mutual Funds</strong>
                          </li>
                          <li>
                            <strong>Exchange-traded funds (ETFs)</strong>
                          </li>
                          <li>
                            <strong>Investment apps</strong>
                          </li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the prerequisites to invest money in the equity
                        market?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The prerequisites to invest money in the equity market
                        are given below.
                        <ul>
                          <li>
                            <strong>Demat account and trading account</strong>
                          </li>
                          <li>
                            <strong>Funds to invest</strong>
                          </li>
                          <li>
                            <strong>Investment terminal</strong>
                          </li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the difference between investing and trading?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Investing is the process of buying assets to generate
                        long-term returns. Investors typically buy assets such
                        as stocks, bonds, and real estate and hold them for
                        years or even decades. They want to grow their wealth
                        over time, even if it means taking on some risk.
                        <br />
                        Trading is the process of buying and selling assets to
                        generate short-term profits. Traders typically buy
                        assets and sell them within a few days, weeks, or even
                        months. They are looking to capitalize on short-term
                        price movements and are willing to take on more risk
                        than investors.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Where to invest money to get good returns?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        There are many different investment avenues to invest
                        money to get good returns.
                        <br />
                        <br />
                        <strong>Stocks</strong> - Stocks represent ownership in
                        a company. When you buy stocks, you essentially buy a
                        piece of that company. Stocks can offer the potential
                        for high returns, but they also come with the risk of
                        losing money.
                        <br />
                        <br />
                        <strong> Bonds</strong> - Bonds are loans that you make
                        to a company or government. Bonds offer lower returns
                        than stocks, but they are also less risky.
                        <br />
                        <br />
                        <strong>Mutual funds</strong> - Mutual funds are baskets
                        of stocks or bonds that a professional manages. Mutual
                        funds offer diversification, which can help reduce risk.
                        <br />
                        <br />
                        <strong>Exchange-traded funds (ETFs)</strong> - ETFs are
                        similar to mutual funds, but they are traded on
                        exchanges like stocks. This makes them more liquid than
                        mutual funds.
                        <br />
                        <br />
                        <strong>Real estate</strong> - Real estate can be a good
                        investment for people looking for long-term growth.
                        However, it is essential to remember that real estate
                        can be illiquid and subject to market fluctuations.
                      </p>
                    </AccordionItemPanel>

                    <AccordionItemPanel></AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;

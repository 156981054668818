import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

export const Faq = () => {
  return (
    <>
      <div className="webcent">
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Frequently Asked Questions</h3>
              </div>
              <div className="faqcont">
                <Accordion allowZeroExpanded="true">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>What is NAV?</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The Net Asset Value (NAV) is the price per share of
                          any particular share at any given time. This does not
                          include any brokerage or overheads you may pay for the
                          share. It is updated every day at the close of the
                          market day.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Which is better - FD or Mutual Funds?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The Net Asset Value (NAV) is the price per share of
                          any particular share at any given time. This does not
                          include any brokerage or overheads you may pay for the
                          share. It is updated every day at the close of the
                          market day.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Are Mutual Funds safe?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The Net Asset Value (NAV) is the price per share of
                          any particular share at any given time. This does not
                          include any brokerage or overheads you may pay for the
                          share. It is updated every day at the close of the
                          market day.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I withdraw Mutual Funds anytime?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The Net Asset Value (NAV) is the price per share of
                          any particular share at any given time. This does not
                          include any brokerage or overheads you may pay for the
                          share. It is updated every day at the close of the
                          market day.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is SIP and Mutual Funds the same?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The Net Asset Value (NAV) is the price per share of
                          any particular share at any given time. This does not
                          include any brokerage or overheads you may pay for the
                          share. It is updated every day at the close of the
                          market day.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Are Mutual Funds tax free?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The Net Asset Value (NAV) is the price per share of
                          any particular share at any given time. This does not
                          include any brokerage or overheads you may pay for the
                          share. It is updated every day at the close of the
                          market day.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the minimum investment in Mutual Funds?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The Net Asset Value (NAV) is the price per share of
                          any particular share at any given time. This does not
                          include any brokerage or overheads you may pay for the
                          share. It is updated every day at the close of the
                          market day.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are high risk Mutual Funds?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The Net Asset Value (NAV) is the price per share of
                          any particular share at any given time. This does not
                          include any brokerage or overheads you may pay for the
                          share. It is updated every day at the close of the
                          market day.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How do I choose a Mutual Fund?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The Net Asset Value (NAV) is the price per share of
                          any particular share at any given time. This does not
                          include any brokerage or overheads you may pay for the
                          share. It is updated every day at the close of the
                          market day.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the average returns on Mutual Funds in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The Net Asset Value (NAV) is the price per share of
                          any particular share at any given time. This does not
                          include any brokerage or overheads you may pay for the
                          share. It is updated every day at the close of the
                          market day.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React from "react";
import Slider from "react-slick";
import BackgroundLetterAvatars from "./avatar/LetterAvatar";
import { Tooltip } from "@mui/material";

const StockSlider = ({ recoStocksData, selectedSearchItem }) => {
  var settings = {
    arrow: false,
    dots: false,
    navigation: true,
    loop: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {recoStocksData?.map((stock, index) => {
          return (
            <div key={index} style={{ width: 500 }}>
              <div
                className="slide"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  selectedSearchItem(
                    stock?.cmots_code,
                    stock?.code_symbol
                    // stock?.exchange
                  )
                }
              >
                <div
                  className="slidelogo"
                  style={{
                    width: 100,
                    height: 100,
                  }}
                >
                  {stock?.compay_logo_url ? (
                    <img
                      src={stock?.compay_logo_url}
                      alt={stock?.company_code}
                      width={100}
                      height={100}
                      className="responsive"
                    />
                  ) : (
                    <BackgroundLetterAvatars name={stock?.company_code} />
                  )}
                </div>
                <div>
                  <Tooltip title={stock?.company_name}>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: 13,
                        marginTop: 20,
                      }}
                    >
                      {stock?.company_code.length > 10
                        ? stock?.company_code.substring(0, 6) + "..."
                        : stock?.company_code}
                      <span
                        style={{
                          color:
                            stock?.stock_recommendation == "sell"
                              ? "#df3c27"
                              : "#45b6a7",
                        }}
                      >
                        ({stock?.stock_recommendation})
                      </span>
                    </p>
                  </Tooltip>
                  <div
                    className={
                      Number(stock?.change) < 0 ? "slidecont down" : "slidecont"
                    }
                  >
                    <div className="price">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>₹{stock?.Price ? stock?.Price : "NA"}-</span>
                        {stock?.Price ? (
                          <span className="priceup">
                            {" "}
                            ({Number(Math.abs(stock?.change)).toFixed(2)}
                            %)
                          </span>
                        ) : null}
                      </div>
                      <span>Stop/Loss Price - ₹{stock?.stop_loss_price}</span>
                      <br />
                      <span>Target Price - ₹{stock?.target_price}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
};

export default StockSlider;

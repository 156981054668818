import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const TermsCondition = () => {
  return (
    <>
      <Header />

      <div className="container">
        <div className="bredcrum">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/invest">invest</Link>
            </li>
            <li>
              <Link to="/invest/refer-earn">Refer & Earn</Link>
            </li>
            <li style={{ textTransform: "none" }}>Terms and Conditions</li>
          </ul>
        </div>
        <div
          className="webcent p-100 pt-0"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-duration="600"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"
        >
          <div className="minicontainer">
            <div className="ipodetail">
              <h3
                style={{
                  color: "#4cafa7",
                  fontWeight: 600,
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                Referral Scheme SMC (Benefit up to Rs 1000)
              </h3>
              <div>
                <p style={{ fontSize: "medium", paddingTop: "5px" }}>
                  <strong>Margin Based Referral Scheme</strong>
                </p>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Upfront Margin (In Rs)
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Referrer Benefit (Gift voucher amount)
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      AOC with 1st year AMC Free
                    </th>
                  </tr>

                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Nil (Basic Plan)
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Worth Rs 100
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Nil
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      10000
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Worth Rs 250
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Nil
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      50000
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Worth Rs 500
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Nil
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      100000
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Worth Rs 1000
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Nil
                    </td>
                  </tr>
                </table>
              </div>
              <div>
                <p style={{ fontSize: "medium", paddingTop: "10px" }}>
                  <strong>
                    Value added Subscription Plan (VASP) based Referral Scheme
                  </strong>
                </p>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        width: "50%",
                      }}
                    >
                      VASP (In Rs)
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Referrer Benefit (Gift voucher amount)
                    </th>
                  </tr>

                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      AS2500
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Worth Rs 500
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      AS5000
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Worth Rs 750
                    </td>
                  </tr>
                </table>
              </div>
              {/* <div>
                <p style={{ fontSize: "medium", paddingTop: "10px" }}>
                  <strong>Brokerage Slab</strong>
                </p>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Referral Scheme
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Delivery (in % age)
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Intraday & Futures (in % age)
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Options (Per lot)
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Commodity (In %age)
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Currency (Per lot)
                    </th>
                  </tr>

                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Upfront Margin 100000
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.16
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.016
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      30
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.016
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      8
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Upfront Margin 50000
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.20
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.020
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      40
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.020
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      10
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Upfront Margin 10000
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.35
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.035
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      100
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.035
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      18
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Upfront Margin 'Zero'
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.40
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.040
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      100
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.040
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      25
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      AS2500
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.30
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.030
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      75
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.030
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      15
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      AS5000
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.25
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.025
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      60
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      0.025
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      12
                    </td>
                  </tr>
                </table>
              </div> */}
              <div>
                <p style={{ fontSize: "medium", paddingTop: "10px" }}>
                  <strong>Terms & Conditions</strong>
                </p>
                <p>
                  * First trade should take place within 30 days of account
                  opening.
                </p>
                <p>
                  * All the rewards will be credited to the referrer & or
                  referee account within 45 days of account activation.
                </p>
                <p>
                  * VASP validity: 12 months from the date of account opening or
                  receipt of value added amount whichever is later.
                </p>
                <p>
                  * VASP amount is payable upfront & not refundable in any
                  circumstances. This cannot be used as a margin or for
                  calculating trading limit.
                </p>
                <p>* ODIN Diet Id Charges are NIL.</p>
                <p>
                  * Under VASP, Brokerage will be reversed generated up to
                  Subscription amount (Validity up to 12 Months).
                </p>
                <p>
                  * Under VASP, Reversal of brokerage should be done on monthly
                  basis up to the brokerage generated in that month & not
                  exceeding the Subscription Amount.
                </p>
                <p>
                  * SMC reserves the rights to discontinue the scheme if
                  circumstances beyond the control of SMC arise due to
                  regulatory actions.
                </p>
                <p>* Taxes and statutory charges are as per actual.</p>
                {/* <p>* Brokearge Charges as follows on SMC referral schemes.</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsCondition;

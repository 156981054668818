import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Context } from "../context";
import { getReportCategoryWithSubcategory } from "../utils/APICollection";

export const ResearchReport = ({ setActive, setIsOpen, menus }) => {
  const {
    isDemat,
    num,
    setIsDemat,
    setName,
    setNum,
    setEmail,
    setState,
    setCity,
    name,
    email,
    state,
    city,
    categoryID,
    urlData,
    ipAddress,
    researchCategory,
    setResearchCategory,
  } = useContext(Context);

  const emptyField = () => {
    setCity("");
    setEmail("");
    setName("");
    setState("");
    setNum("");
    setActive(!menus);
    setIsOpen(false);
  };
  const researchData = localStorage.getItem("researchData");
  const finalCategory = JSON.parse(researchData);
  let filteredArr = researchCategory?.filter(
    (obj) =>
      obj.category_name !== "Fundamental Research" &&
      obj.category_name !== "Technical Research"
  );

  const categoryOrder = [
    "Wise money",
    "Equity Analysis",
    "Derivative Analysis",
    "Commodities Analysis",
    "Commodity Analysis",
    "Currency Analysis",
    "IPO Reports",
    "Mutual Fund Analysis",
  ];

  React.useEffect(() => {
    if (researchCategory?.length < 1) {
      setResearchCategory(finalCategory);
    }
  }, []);

  filteredArr = filteredArr?.sort((a, b) => {
    return (
      categoryOrder.indexOf(a.category_name) -
      categoryOrder.indexOf(b.category_name)
    );
  });
  return (
    <>
      <ul>
        {filteredArr?.map((category, index) => {
          return (
            <>
            {category?.category_name === 'Stock Analysis'?
            <li key={index}>
              <NavLink
              to={`https://smcmj.smcindiaonline.org/Stocks?StockId=513374&Exchange=1#navSummary`}
                onClick={emptyField}
                target="_blank"
              >
                  {category.category_name}
              </NavLink>
            </li>
            :
            <li key={index}>
              <NavLink
                to={`/research/${category.category_name
                  .replace(/\s+/g, "-")
                  .toLowerCase()}/${category.research_category_master_id}`}
                onClick={emptyField}
              >
                {category.category_name}
              </NavLink>
            </li>

            }
            
            </>
            
          );
        })}
      </ul>
      {/* <ul>
        <li>
          <NavLink to="/research/equity-analysis" onClick={emptyField}>
            Equity Analysis
          </NavLink>
        </li>
        <li>
          <NavLink to="/research/derivative-analysis" onClick={emptyField}>
            Derivative Analysis
          </NavLink>
        </li>
        <li>
          <NavLink to="/research/commodity-analysis" onClick={emptyField}>
            Commodity Analysis
          </NavLink>
        </li>
        <li>
          <NavLink to="/research/currency-analysis" onClick={emptyField}>
            Currency Analysis
          </NavLink>
        </li>
        <li>
          <NavLink to="/research/ipo-reports" onClick={emptyField}>
            IPO Reports
          </NavLink>
        </li>
        <li>
          <NavLink to="/research/mf-analysis" onClick={emptyField}>
            Mutual Funds Analysis
          </NavLink>
        </li>
      </ul> */}
    </>
  );
};

import React from "react";
import Footer from "../../../../components/Footer";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Investbanner from "../../../../images/derivatives-banner.webp";
import { Form } from "../../../../components/Form";
import Header from "../../../../components/Header";

export const DerivativesMarketAnalysis = () => {
  const [years, setAge] = React.useState("");
  const year = (event) => {
    setAge(event.target.value);
  };
  const [months, setMonth] = React.useState("");
  const month = (event) => {
    setMonth(event.target.value);
  };
  const [periods, setPeriod] = React.useState("");
  const period = (event) => {
    setPeriod(event.target.value);
  };
  return (
    <>
      <Header />

      <section id="banner">
        <div className="banner internal nobanner">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/trade">Trade</Link>
                </li>
                <li>Futures and Options</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1>Trading Insights. Webinars.</h1>
                </div>
                <div className="bannergt">
                  <img src={Investbanner} alt="Hassle-free investing" />
                </div>
                <Form catID={"Trade"} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Derivatives Market Analysis</h3>
                  </div>
                  <div className="col-12 pull-left row derivativemarket m-0">
                    <div className="col-lg-4 col-sm-6 d-grid flex-wrap gap-4 col-12 mb-5">
                      <div className="topstockslist">
                        <div className="topstockleft">
                          <p>
                            <strong>NIFTY</strong>
                          </p>
                          <p>26-Oct-2023</p>
                        </div>
                        <div className="topstockright down">
                          <p className="priceup border-0">
                            <i className="finr"></i>
                            <span>19,530.70</span>
                          </p>
                          <p>18.80 (0.10%)</p>
                        </div>
                      </div>
                      <div className="topstockslist">
                        <div className="topstockleft">
                          <p>
                            <strong>NIFTY</strong>
                          </p>
                          <p>28-Sep-2023</p>
                        </div>
                        <div className="topstockright down">
                          <p className="priceup border-0">
                            <i className="finr"></i>
                            <span>19,445.05</span>
                          </p>
                          <p>23.80 (0.12%)</p>
                        </div>
                      </div>
                      <div className="topstockslist">
                        <div className="topstockleft">
                          <p>
                            <strong>NIFTY</strong>
                          </p>
                          <p>31-Aug-2023</p>
                        </div>
                        <div className="topstockright">
                          <p className="priceup border-0">
                            <i className="finr"></i>
                            <span>19,345.75</span>
                          </p>
                          <p>24.45 (0.13%)</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 d-grid flex-wrap gap-4 col-12 mb-5">
                      <div className="topstockslist">
                        <div className="topstockleft">
                          <p>
                            <strong>BANK NIFTY</strong>
                          </p>
                          <p>26-Oct-2023</p>
                        </div>
                        <div className="topstockright down">
                          <p className="priceup border-0">
                            <i className="finr"></i>
                            <span>45,002.95</span>
                          </p>
                          <p>32.90 (0.07%)</p>
                        </div>
                      </div>
                      <div className="topstockslist">
                        <div className="topstockleft">
                          <p>
                            <strong>NIFTY IT</strong>
                          </p>
                          <p>26-Oct-2023</p>
                        </div>
                        <div className="topstockright down">
                          <p className="priceup border-0">
                            <i className="finr"></i>
                            <span>19,530.70</span>
                          </p>
                          <p>18.80 (0.10%)</p>
                        </div>
                      </div>
                      <div className="topstockslist">
                        <div className="topstockleft">
                          <p>
                            <strong>IPC</strong>
                          </p>
                          <p>29-Aug-2023</p>
                        </div>
                        <div className="topstockright ">
                          <p className="priceup border-0">
                            <i className="finr"></i>
                            <span>53,442.64</span>
                          </p>
                          <p>251.11 (0.47%)</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 d-grid flex-wrap gap-4 col-12 mb-5">
                      <div className="topstockslist">
                        <div className="topstockleft">
                          <p>
                            <strong>ATX</strong>
                          </p>
                          <p>29-Aug-2023</p>
                        </div>
                        <div className="topstockright">
                          <p className="priceup border-0">
                            <i className="finr"></i>
                            <span>3,127.47</span>
                          </p>
                          <p>28.56 (0.92%)</p>
                        </div>
                      </div>
                      <div className="topstockslist">
                        <div className="topstockleft">
                          <p>
                            <strong>SET</strong>
                          </p>
                          <p>29-Aug-2023</p>
                        </div>
                        <div className="topstockright">
                          <p className="priceup border-0">
                            <i className="finr"></i>
                            <span>1,568.81</span>
                          </p>
                          <p>5.84 (0.37%)</p>
                        </div>
                      </div>
                      <div className="topstockslist">
                        <div className="topstockleft">
                          <p>
                            <strong>NIFTY</strong>
                          </p>
                          <p>31-Aug-2023</p>
                        </div>
                        <div className="topstockright">
                          <p className="priceup border-0">
                            <i className="finr"></i>
                            <span>19,345.75</span>
                          </p>
                          <p>24.45 (0.13%)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="webcent p-100 grey-background">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>F&O Market Snapshot</h3>
                  </div>
                  <div className="returncalulator">
                    <div className="howtoapply ">
                      <Tabs>
                        <div className="tradingben mt-0">
                          <div className="iconrow noicon mb-3 w-100">
                            <TabList className="justify-content-center m-0">
                              <Tab>
                                <h4>Index</h4>
                              </Tab>
                              <Tab>
                                <h4>Stock</h4>
                              </Tab>
                            </TabList>
                          </div>
                        </div>
                        <TabPanel>
                          <div className="col-12 pull-left">
                            <div className="pagefilter mb-2 mt-4 col-12 pull-left d-flex">
                              <div className="pfilter d-flex gap-3 align-items-start justify-content-center col-12">
                                <Box className="pfiltermange col-12 row">
                                  <div className="col-lg-2 col-sm-4 col-12">
                                    <FormControl className="w-100">
                                      <InputLabel id="demo-simple-select-label">
                                        Select Instrument
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={years}
                                        label="Year"
                                        onChange={year}
                                        className="border-bottom radius-0"
                                      >
                                        <MenuItem value={1}>FUTIDX</MenuItem>
                                        <MenuItem value={2}>OPTIDX</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                  <div className="col-lg-2 col-sm-4 col-12">
                                    <FormControl className="w-100">
                                      <InputLabel id="demo-simple-select-label">
                                        Select Symbol
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={months}
                                        label="Month"
                                        onChange={month}
                                        className="border-bottom radius-0"
                                      >
                                        <MenuItem value={1}>BankNifty</MenuItem>
                                        <MenuItem value={2}>Finnifty</MenuItem>
                                        <MenuItem value={3}>
                                          Midcpnifty
                                        </MenuItem>
                                        <MenuItem value={4}>NIFTY</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                  <div className="col-lg-2 col-sm-4 col-12">
                                    <FormControl className="w-100">
                                      <InputLabel id="demo-simple-select-label">
                                        Exp Date
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={periods}
                                        label="Month"
                                        onChange={period}
                                        className="border-bottom radius-0"
                                      >
                                        <MenuItem value={1}>
                                          31<sup>st</sup> Aug 2023
                                        </MenuItem>
                                        <MenuItem value={2}>
                                          28<sup>th</sup> Sep 2023
                                        </MenuItem>
                                        <MenuItem value={3}>
                                          26<sup>th</sup> Oct 2023
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                  <div className="col-lg-2 col-sm-4 col-12">
                                    <FormControl className="w-100">
                                      <InputLabel id="demo-simple-select-label">
                                        Option Type
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={periods}
                                        label="Month"
                                        onChange={period}
                                        className="border-bottom radius-0"
                                      >
                                        <MenuItem value={1}>
                                          31<sup>st</sup> Aug 2023
                                        </MenuItem>
                                        <MenuItem value={2}>
                                          28<sup>th</sup> Sep 2023
                                        </MenuItem>
                                        <MenuItem value={3}>
                                          26<sup>th</sup> Oct 2023
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                  <div className="col-lg-2 col-sm-4 col-12">
                                    <FormControl className="w-100">
                                      <InputLabel id="demo-simple-select-label">
                                        Strike Price
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={periods}
                                        label="Month"
                                        onChange={period}
                                        className="border-bottom radius-0"
                                      >
                                        <MenuItem value={1}>
                                          31<sup>st</sup> Aug 2023
                                        </MenuItem>
                                        <MenuItem value={2}>
                                          28<sup>th</sup> Sep 2023
                                        </MenuItem>
                                        <MenuItem value={3}>
                                          26<sup>th</sup> Oct 2023
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                  <div className="col-lg-2 col-sm-4 col-12">
                                    <div className="btnn w-100 text-center">
                                      <span>Search</span>
                                    </div>
                                  </div>
                                </Box>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Most Active Contracts</h3>
                  </div>

                  <div className="col-lg-10 m-auto col-12">
                    <Tabs>
                      <div className="tradingben m-0 mb-5 returncalulator">
                        <div className="howtoapply">
                          <div className="tradingben m-0 justify-content-center d-flex">
                            <div className="iconrow noicon w-auto m-auto overflow-auto">
                              <TabList className="m-0">
                                <Tab>
                                  <h4>GAINERS</h4>
                                </Tab>
                                <Tab>
                                  <h4>LOSERS</h4>
                                </Tab>
                                <Tab>
                                  <h4>VALUE TOPPERS</h4>
                                </Tab>
                                <Tab>
                                  <h4>VOLUME TOPPERS</h4>
                                </Tab>
                              </TabList>
                            </div>
                          </div>
                        </div>
                      </div>
                      <TabPanel>
                        <div className="col-12 pull-left">
                          <Tabs>
                            <div className="tradingben m-0 mb-4 row">
                              <div className="col-lg-9 col-12">
                                <div className="iconrow noicon">
                                  <TabList className="justify-content-start gap-2 flex-nowrap">
                                    <Tab>
                                      <h4>INDEX FUTURE</h4>
                                    </Tab>
                                    <Tab>
                                      <h4>INDEX OPTION</h4>
                                    </Tab>
                                    <Tab>
                                      <h4>STOCK FUTURE</h4>
                                    </Tab>
                                    <Tab>
                                      <h4>STOCK OPTION</h4>
                                    </Tab>
                                  </TabList>
                                </div>
                              </div>
                              <div className="col-lg-3 col-12">
                                <div className="justify-content-end col-12 text-end">
                                  <span>30-Aug-2023 | 15:29</span>
                                </div>
                              </div>
                            </div>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                          </Tabs>
                          <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                            <table className="table ">
                              <thead>
                                <tr className="gradient text-white">
                                  <th className="col-4">Contract Name</th>
                                  <th>
                                    LTP<i className="finr"></i>
                                  </th>
                                  <th>CHG%</th>
                                  <th>OI</th>
                                  <th>Volume</th>
                                </tr>
                              </thead>
                              <tbody>
                                {[1, 2, 3, 4, 5].map((item) => (
                                  <tr>
                                    <td className="col-4">NIFTY 28-Sep-23</td>
                                    <td>19,445.00</td>

                                    <td className="priceup border-0">+0.04%</td>
                                    <td>7645200</td>
                                    <td>40035 Cr</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-12 pull-left  d-flex justify-content-end">
                            <Link to="/derivatives-market-analysis/view-archives">
                              <div className="btnn">
                                <span>View Archives</span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3> FII Statistics</h3>
                  </div>

                  <div className="col-lg-10 m-auto col-12">
                    <Tabs>
                      <div className="tradingben m-0 mb-5 returncalulator">
                        <div className="howtoapply">
                          <div className="tradingben m-0 justify-content-center d-flex">
                            <div className="iconrow noicon w-auto m-auto overflow-auto"></div>
                          </div>
                        </div>
                      </div>
                      <TabPanel>
                        <div className="col-12 pull-left">
                          <Tabs>
                            <div className="tradingben m-0 mb-4 row">
                              <div className="col-lg-9 col-12">
                                <div className="iconrow noicon">
                                  <TabList className="justify-content-start gap-2 flex-nowrap">
                                    <Tab>
                                      <h4>INDEX FUTURE</h4>
                                    </Tab>
                                    <Tab>
                                      <h4>INDEX OPTION</h4>
                                    </Tab>
                                    <Tab>
                                      <h4>STOCK FUTURE</h4>
                                    </Tab>
                                    <Tab>
                                      <h4>STOCK OPTION</h4>
                                    </Tab>
                                  </TabList>
                                </div>
                              </div>
                            </div>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                          </Tabs>
                          <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                            <table className="table ">
                              <thead>
                                <tr className="gradient text-white">
                                  <th className="col-3">Date</th>
                                  <th className="col-2">Buy Amt(Rs Cr)</th>
                                  <th className="col-2">Sell Amt(Rs Cr)</th>
                                  <th className="col-3">
                                    Net Buy Sell Amt(Rs Cr)
                                  </th>
                                  <th className="col-2">Volume</th>
                                </tr>
                              </thead>
                              <tbody>
                                {[1, 2, 3, 4, 5].map((item) => (
                                  <tr>
                                    <td className="col-3">NIFTY 28-Sep-23</td>
                                    <td className="col-2">19,445.00</td>

                                    <td className="col-2 priceup border-0">
                                      +0.04%
                                    </td>
                                    <td className="col-3">7645200</td>
                                    <td className="col-2">40050 Cr</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-12 pull-left  d-flex justify-content-end">
                            <Link to="/derivatives-market-analysis/view-archives">
                              <div className="btnn">
                                <span>View Archives</span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3> Poisition Built Up</h3>
                  </div>

                  <div className="col-lg-10 m-auto col-12">
                    <Tabs>
                      <div className="tradingben m-0 mb-5 returncalulator">
                        <div className="howtoapply">
                          <div className="tradingben m-0 justify-content-center d-flex">
                            <div className="iconrow noicon w-auto m-auto overflow-auto">
                              <TabList className="m-0">
                                <Tab>
                                  <h4>LONG BUILD UP</h4>
                                </Tab>
                                <Tab>
                                  <h4>SHORT BUILD UP</h4>
                                </Tab>
                                <Tab>
                                  <h4>LONG UNWINDING</h4>
                                </Tab>
                                <Tab>
                                  <h4>SHORT COVERING</h4>
                                </Tab>
                              </TabList>
                            </div>
                          </div>
                        </div>
                      </div>
                      <TabPanel>
                        <div className="col-12 pull-left">
                          <Tabs>
                            <div className="tradingben m-0 mb-4 row">
                              <div className="col-lg-9 col-12">
                                <div className="iconrow noicon">
                                  <TabList className="justify-content-start gap-2 flex-nowrap">
                                    <Tab>
                                      <h4>INDEX FUTURE</h4>
                                    </Tab>
                                    <Tab>
                                      <h4>INDEX OPTION</h4>
                                    </Tab>
                                    <Tab>
                                      <h4>STOCK FUTURE</h4>
                                    </Tab>
                                    <Tab>
                                      <h4>STOCK OPTION</h4>
                                    </Tab>
                                  </TabList>
                                </div>
                              </div>
                            </div>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                          </Tabs>
                          <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                            <table className="table ">
                              <thead>
                                <tr className="gradient text-white">
                                  <th className="col-4">Contract Name</th>
                                  <th>
                                    LTP<i className="finr"></i>
                                  </th>
                                  <th>CHG%</th>
                                  <th>OI</th>
                                  <th>Volume</th>
                                </tr>
                              </thead>
                              <tbody>
                                {[1, 2, 3, 4, 5].map((item) => (
                                  <tr>
                                    <td className="col-4">NIFTY 28-Sep-23</td>
                                    <td>19,445.00</td>

                                    <td className="priceup border-0">+0.04%</td>
                                    <td>7645200</td>
                                    <td>35506 Cr</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-12 pull-left  d-flex justify-content-end">
                            <Link to="/derivatives-market-analysis/view-archives">
                              <div className="btnn">
                                <span>View Archives</span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Stocks in Premium Discount</h3>
                  </div>

                  <div className="col-lg-10 m-auto col-12">
                    <Tabs>
                      <div className="tradingben m-0 mb-5 returncalulator">
                        <div className="howtoapply">
                          <div className="tradingben m-0 justify-content-center d-flex">
                            <div className="iconrow noicon w-auto m-auto overflow-auto">
                              <TabList className="m-0">
                                <Tab>
                                  <h4>PREMIUM</h4>
                                </Tab>
                                <Tab>
                                  <h4>DISCOUNT</h4>
                                </Tab>
                              </TabList>
                            </div>
                          </div>
                        </div>
                      </div>
                      <TabPanel>
                        <div className="col-12 pull-left">
                          <Tabs>
                            <div className="tradingben m-0 mb-4 row">
                              <div className="col-lg-9 col-12">
                                <div className="iconrow noicon"></div>
                              </div>
                            </div>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                          </Tabs>
                          <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                            <table className="table ">
                              <thead>
                                <tr className="gradient text-white">
                                  <th className="col-4">Contract Name</th>
                                  <th>
                                    LTP<i className="finr"></i>
                                  </th>

                                  <th>Premium </th>
                                  <th>Discount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {[1, 2, 3, 4, 5].map((item) => (
                                  <tr>
                                    <td className="col-4">NIFTY 28-Sep-23</td>
                                    <td>19,445.00</td>

                                    <td>7645200</td>
                                    <td>3550 </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-12 pull-left  d-flex justify-content-end">
                            <Link to="/derivatives-market-analysis/view-archives">
                              <div className="btnn">
                                <span>View Archives</span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Option Chains</h3>
                  </div>

                  <div className="col-lg-10 m-auto col-12">
                    <Tabs>
                      <div className="tradingben m-0 mb-5 returncalulator">
                        <div className="howtoapply">
                          <div className="tradingben m-0 justify-content-center d-flex">
                            <div className="iconrow noicon w-auto m-auto overflow-auto"></div>
                          </div>
                        </div>
                      </div>
                      <TabPanel>
                        <div className="col-12 pull-left">
                          <Tabs>
                            <div className="tradingben m-0 mb-4 row">
                              <div className="col-lg-9 col-12">
                                <div className="iconrow noicon">
                                  <TabList className="justify-content-start gap-2 flex-nowrap">
                                    <Tab>
                                      <h4>PUT</h4>
                                    </Tab>
                                    <Tab>
                                      <h4>CALL</h4>
                                    </Tab>
                                  </TabList>
                                </div>
                              </div>
                            </div>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                          </Tabs>

                          <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                            <div style={{ display: "flex" }}>
                              <FormControl sx={{ m: 1, minWidth: 80 }}>
                                <InputLabel id="demo-simple-select-autowidth-label">
                                  Name
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-autowidth-label"
                                  id="demo-simple-select-autowidth"
                                  autoWidth
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  <MenuItem value={10}>TVSMOTOR</MenuItem>
                                  <MenuItem value={21}>TCS</MenuItem>
                                  <MenuItem value={22}>RELIANCE</MenuItem>
                                </Select>
                              </FormControl>
                              <FormControl sx={{ m: 1, minWidth: 80 }}>
                                <InputLabel id="demo-simple-select-autowidth-label">
                                  Date
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-autowidth-label"
                                  id="demo-simple-select-autowidth"
                                  autoWidth
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  <MenuItem value={10}>25-Jan-2024</MenuItem>
                                  <MenuItem value={21}>29-Feb-2024</MenuItem>
                                  <MenuItem value={22}>28-Mar-2024</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            <table className="table ">
                              <thead>
                                <tr className="gradient text-white">
                                  <th className="col-4">Strike Price(Rs)</th>
                                  <th>
                                    LTP<i className="finr"></i>
                                  </th>
                                  <th>CHG OI (%)</th>
                                  {/* <th>OI</th> */}
                                  <th>Volume (Nos)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {[1, 2, 3, 4, 5].map((item) => (
                                  <tr>
                                    <td className="col-4">NIFTY 28-Sep-23</td>
                                    <td>19,445.00</td>

                                    <td className="priceup border-0">+0.04%</td>

                                    <td>550 Cr</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-12 pull-left  d-flex justify-content-end">
                            <Link to="/derivatives-market-analysis/view-archives">
                              <div className="btnn">
                                <span>View Archives</span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Put Call Ratio & OI</h3>
                  </div>

                  <div className="col-lg-10 m-auto col-12">
                    <Tabs>
                      <div className="tradingben m-0 mb-5 returncalulator">
                        <div className="howtoapply">
                          <div className="tradingben m-0 justify-content-center d-flex">
                            <div className="iconrow noicon w-auto m-auto overflow-auto">
                              <TabList className="m-0">
                                <Tab>
                                  <h4>PUT CALL RATIO</h4>
                                </Tab>
                                <Tab>
                                  <h4>OI</h4>
                                </Tab>
                              </TabList>
                            </div>
                          </div>
                        </div>
                      </div>
                      <TabPanel>
                        <div className="col-12 pull-left">
                          <Tabs>
                            <div className="tradingben m-0 mb-4 row">
                              <div className="col-lg-9 col-12">
                                <div className="iconrow noicon">
                                  <TabList className="justify-content-start gap-2 flex-nowrap">
                                    <Tab>
                                      <h4>VOLUME</h4>
                                    </Tab>
                                    <Tab>
                                      <h4>OPEN INTREST</h4>
                                    </Tab>
                                  </TabList>
                                </div>
                              </div>
                            </div>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                          </Tabs>
                          <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                            <table className="table ">
                              <thead>
                                <tr className="gradient text-white">
                                  <th className="col-4">Symbol</th>

                                  <th>Put</th>
                                  <th>Call</th>
                                  <th>Total</th>
                                  <th>Ratio</th>
                                </tr>
                              </thead>
                              <tbody>
                                {[1, 2, 3, 4, 5].map((item) => (
                                  <tr>
                                    <td className="col-4">INFY 28-Mar-24</td>
                                    <td>9445.</td>

                                    <td>200</td>
                                    <td>550</td>
                                    <td>0.76</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-12 pull-left  d-flex justify-content-end">
                            <Link to="/derivatives-market-analysis/view-archives">
                              <div className="btnn">
                                <span>View Archives</span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="col-12 pull-left">
                          <Tabs>
                            <div className="tradingben m-0 mb-4 row">
                              <div className="col-lg-9 col-12">
                                <div className="iconrow noicon">
                                  <TabList className="justify-content-start gap-2 flex-nowrap">
                                    <Tab>
                                      <h4>FUTURE INCREASE</h4>
                                    </Tab>
                                    <Tab>
                                      <h4>FUTURE DECREASE</h4>
                                    </Tab>
                                    <Tab>
                                      <h4>OPTION INCREASE</h4>
                                    </Tab>
                                    <Tab>
                                      <h4>OPTION DECREASE</h4>
                                    </Tab>
                                  </TabList>
                                </div>
                              </div>
                            </div>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                          </Tabs>
                          <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                            <table className="table ">
                              <thead>
                                <tr className="gradient text-white">
                                  <th className="col-4">Contract Name</th>
                                  <th>
                                    LTP<i className="finr"></i>
                                  </th>
                                  <th>CHG%</th>
                                  <th>Change OI</th>
                                  <th>OI</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="col-4">NIFTY 28-Sep-23</td>
                                  <td>19,445.00</td>

                                  <td className="priceup border-0">+0.04%</td>
                                  <td>7645200</td>
                                  <td>4003550</td>
                                </tr>

                                <tr>
                                  <td className="col-4">NIFTY 26-Oct-23</td>
                                  <td>19,539.90</td>

                                  <td className="priceup border-0">+0.06%</td>
                                  <td>467500</td>
                                  <td>155950</td>
                                </tr>

                                <tr>
                                  <td className="col-4">
                                    MIDCPNIFTY 30-Oct-23
                                  </td>
                                  <td>8,892.25</td>

                                  <td className="priceup border-0">+0.34%</td>
                                  <td>1050</td>
                                  <td>1950</td>
                                </tr>

                                <tr>
                                  <td className="col-4">
                                    MIDCPNIFTY 30-Oct-23
                                  </td>
                                  <td>8,892.25</td>

                                  <td className="priceup border-0">+0.34%</td>
                                  <td>1050</td>
                                  <td>1950</td>
                                </tr>
                                <tr>
                                  <td className="col-4">NIFTY 28-Sep-23</td>
                                  <td>19,445.00</td>

                                  <td className="priceup border-0">+0.04%</td>
                                  <td>7645200</td>
                                  <td>4003550</td>
                                </tr>

                                <tr>
                                  <td className="col-4">NIFTY 26-Oct-23</td>
                                  <td>19,539.90</td>

                                  <td className="priceup border-0">+0.06%</td>
                                  <td>467500</td>
                                  <td>155950</td>
                                </tr>

                                <tr>
                                  <td className="col-4">
                                    MIDCPNIFTY 30-Oct-23
                                  </td>
                                  <td>8,892.25</td>

                                  <td className="priceup border-0">+0.34%</td>
                                  <td>1050</td>
                                  <td>1950</td>
                                </tr>

                                <tr>
                                  <td className="col-4">
                                    MIDCPNIFTY 30-Oct-23
                                  </td>
                                  <td>8,892.25</td>

                                  <td className="priceup border-0">+0.34%</td>
                                  <td>1050</td>
                                  <td>1950</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-12 pull-left  d-flex justify-content-end">
                            <Link to="/derivatives-market-analysis/view-archives">
                              <div className="btnn">
                                <span>View Archives</span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                      <TabPanel>
                        <h4 className="nodata">No Data Found</h4>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="webcent p-100 grey-background">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Indian Equities News</h3>
                  </div>
                  <div className="col-lg-10 col-12 m-auto">
                    <div className="tcsnews">
                      <div className="tcsnewsbox">
                        <h4>
                          Hold Tata Consultancy Services, target price Rs 3420:
                          JM Financial
                        </h4>
                        <p>
                          Hold Tata Consultancy Services, target price Rs 3420:
                          JM Financial Economic Times
                        </p>
                        <p>Publish Date 2023-05-09 T0 6:47:39.303</p>
                      </div>
                      <div className="tcsnewsbox">
                        <h4>
                          Hold Tata Consultancy Services, target price Rs 3420:
                          JM Financial
                        </h4>
                        <p>
                          Hold Tata Consultancy Services, target price Rs 3420:
                          JM Financial Economic Times
                        </p>
                        <p>Publish Date 2023-05-09 T0 6:47:39.303</p>
                      </div>
                    </div>
                    <div className="mbtn">
                      <Link to="/derivatives-market-analysis/news">
                        <div className="btnn">
                          <span>View All</span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Derivatives Dashboard</h3>
                  </div>

                  <div className="col-lg-10 m-auto col-12">
                    <div className="returncalulator">
                      <div className="howtoapply">
                        <Tabs>
                          <div className="tradingben mt-0 mb-4 row">
                            <div className="col-lg-8 col-sm-6 col-12">
                              <div className="iconrow noicon w-100">
                                <TabList className="justify-content-start gap-2 flex-nowrap">
                                  <Tab>
                                    <h4>FUTURES</h4>
                                  </Tab>
                                  <Tab>
                                    <h4>OPTIONS</h4>
                                  </Tab>
                                </TabList>
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="justify-content-end col-lg-8 col-12 pull-right">
                                <div className="pfilter d-flex gap-3 align-items-start justify-content-center col-12">
                                  <Box className="pfiltermange col-12 row">
                                    <FormControl className="w-100">
                                      <InputLabel id="demo-simple-select-label">
                                        Select Expiry Date
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={years}
                                        label="Year"
                                        onChange={year}
                                        className="border-bottom radius-0"
                                      >
                                        <MenuItem value={1}>
                                          31-Aug-2023
                                        </MenuItem>
                                        <MenuItem value={2}>
                                          28-Sep-2023
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </div>
                              </div>
                            </div>
                          </div>
                          <TabPanel>
                            <div className="col-12 pull-left row">
                              <div className="col-lg-6 col-12 mb-4">
                                <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                                  <div className="calheading gradient p-2">
                                    <h3 className="m-0 text-white">Gainers</h3>
                                  </div>
                                  <table className="table ">
                                    <thead>
                                      <tr className="grey-background">
                                        <th className="col-4">Symbol</th>
                                        <th>
                                          Last Price<i className="finr"></i>
                                        </th>
                                        <th>CHG%</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="mbtn m-0  justify-content-end">
                                  <div className="btnn">
                                    <span>View All</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-12 mb-4">
                                <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                                  <div className="calheading gradient p-2">
                                    <h3 className="m-0 text-white">Losers</h3>
                                  </div>
                                  <table className="table ">
                                    <thead>
                                      <tr className="grey-background">
                                        <th className="col-4">Symbol</th>
                                        <th>
                                          Last Price<i className="finr"></i>
                                        </th>
                                        <th>CHG%</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="mbtn m-0  justify-content-end">
                                  <div className="btnn">
                                    <span>View All</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-12 mb-4">
                                <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                                  <div className="calheading gradient p-2">
                                    <h3 className="m-0 text-white">
                                      Highest In OI
                                    </h3>
                                  </div>
                                  <table className="table ">
                                    <thead>
                                      <tr className="grey-background">
                                        <th className="col-4">Symbol</th>
                                        <th>
                                          Last Price<i className="finr"></i>
                                        </th>
                                        <th>CHG%</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="mbtn m-0  justify-content-end">
                                  <div className="btnn">
                                    <span>View All</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-12 mb-4">
                                <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                                  <div className="calheading gradient p-2">
                                    <h3 className="m-0 text-white">
                                      Lowest In OI
                                    </h3>
                                  </div>
                                  <table className="table ">
                                    <thead>
                                      <tr className="grey-background">
                                        <th className="col-4">Symbol</th>
                                        <th>
                                          Last Price<i className="finr"></i>
                                        </th>
                                        <th>CHG%</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="mbtn m-0  justify-content-end">
                                  <div className="btnn">
                                    <span>View All</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-12 mb-4">
                                <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                                  <div className="calheading gradient p-2">
                                    <h3 className="m-0 text-white">
                                      Top Traded Value{" "}
                                    </h3>
                                  </div>
                                  <table className="table ">
                                    <thead>
                                      <tr className="grey-background">
                                        <th className="col-4">Symbol</th>
                                        <th>
                                          Last Price<i className="finr"></i>
                                        </th>
                                        <th>CHG%</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="mbtn m-0  justify-content-end">
                                  <div className="btnn">
                                    <span>View All</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-12 mb-4">
                                <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                                  <div className="calheading gradient p-2">
                                    <h3 className="m-0 text-white">
                                      Top Traded Volume
                                    </h3>
                                  </div>
                                  <table className="table ">
                                    <thead>
                                      <tr className="grey-background">
                                        <th className="col-4">Symbol</th>
                                        <th>
                                          Last Price<i className="finr"></i>
                                        </th>
                                        <th>CHG%</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-4">
                                          NIFTY 28-Sep-23
                                        </td>
                                        <td>1</td>
                                        <td className="priceup border-0">
                                          +0.04%
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="mbtn m-0  justify-content-end">
                                  <div className="btnn">
                                    <span>View All</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <h4 className="nodata">No Data Found</h4>
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Heat Map</h3>
                  </div>
                  <div className="col-lg-10 col-12 m-auto">
                    <div className="col-12 pull-left">
                      <div className="pagefilter col-12 pull-left d-flex mb-4">
                        <div className="pfilter d-flex gap-3 align-items-start justify-content-center col-12">
                          <Box className="pfiltermange col-12 row">
                            <div className="col-lg-2 col-sm-4 col-12">
                              <FormControl className="w-100">
                                <InputLabel id="demo-simple-select-label">
                                  Select Sector
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={years}
                                  label="Year"
                                  onChange={year}
                                  className="border-bottom radius-0"
                                >
                                  <MenuItem value={1}>Week</MenuItem>
                                  <MenuItem value={2}>Month</MenuItem>
                                  <MenuItem value={2}>One Year</MenuItem>
                                  <MenuItem value={2}>Two Year</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col-lg-2 col-sm-4 col-12">
                              <FormControl className="w-100">
                                <InputLabel id="demo-simple-select-label">
                                  View Top 50
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={months}
                                  label="Month"
                                  onChange={month}
                                  className="border-bottom radius-0"
                                >
                                  <MenuItem value={1}>Long Short</MenuItem>
                                  <MenuItem value={2}>Long Term</MenuItem>
                                  {/* <MenuItem value={3}>Midcpnifty</MenuItem> */}
                                  {/* <MenuItem value={4}>Nifty</MenuItem> */}
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col-lg-2 col-sm-4 col-12">
                              <FormControl className="w-100">
                                <InputLabel id="demo-simple-select-label">
                                  Expiry
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={periods}
                                  label="Month"
                                  onChange={period}
                                  className="border-bottom radius-0"
                                >
                                  <MenuItem value={1}>
                                    31<sup>st</sup> Aug 2023
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    28<sup>th</sup> Sep 2023
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    26<sup>th</sup> Oct 2023
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </Box>
                        </div>
                      </div>
                      <Tabs>
                        <div className="tradingben m-0 gradient pt-3 ">
                          <div className="iconrow noicon">
                            <TabList className="justify-content-start border-0 gap-2 m-0">
                              <Tab>
                                <h4 className="text-white m-0">All</h4>
                              </Tab>
                              <Tab>
                                <h4 className="text-white m-0">
                                  Long Built-up
                                </h4>
                              </Tab>
                              <Tab>
                                <h4 className="text-white m-0">
                                  Short Covering
                                </h4>
                              </Tab>
                              <Tab>
                                <h4 className="text-white m-0">
                                  Long Unwinding
                                </h4>
                              </Tab>
                              <Tab>
                                <h4 className="text-white m-0">
                                  Short Built-up
                                </h4>
                              </Tab>
                            </TabList>
                          </div>
                        </div>
                        <TabPanel>
                          <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                            <table className="table chessbox">
                              <tbody>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                            <table className="table chessbox">
                              <tbody>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                            <table className="table chessbox">
                              <tbody>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="col-12 pull-left ipotable defaulttable ipodashboard">
                            <table className="table chessbox">
                              <tbody>
                                <tr>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                  <td>
                                    ESCORTS <br />
                                    3,249.00 <br />
                                    7.22%
                                  </td>
                                  <td>
                                    INDHOTEL <br />
                                    418.00 <br />
                                    6.19%
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <h4 className="nodata">No Data Found</h4>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

import React, { useState } from "react";
import Footer from "../../components/Footer";
import Banner from "../../images/partner-banner.webp";
import Icon01 from "../../images/benefit04.webp";
import Icon02 from "../../images/benefit02.webp";
import Icon03 from "../../images/benefit03.webp";
import Icon04 from "../../images/benefit01.webp";
import Slider from "react-slick";
import { Excellence } from "../../components/Excellence";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Link } from "react-router-dom";
import { Form } from "../../components/Form";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Img01 from "../../images/pkey01.webp";
import Img02 from "../../images/pkey02.webp";
import Img03 from "../../images/pkey03.webp";
import Img04 from "../../images/pkey04.webp";
import Img05 from "../../images/pkey05.webp";
import Img06 from "../../images/pkey06.webp";
import Img07 from "../../images/pkey07.webp";
import Img08 from "../../images/pkey08.webp";
import client01 from "../../images/client01.webp";
import client02 from "../../images/client02.webp";
import client03 from "../../images/client03.webp";
import client04 from "../../images/client04.webp";
import lslide01 from "../../images/lslide01.webp";
import lslide02 from "../../images/lslide02.webp";
import lslide03 from "../../images/lslide03.webp";
import lslide04 from "../../images/lslide04.webp";
import lslide05 from "../../images/lslide05.webp";
import lslide06 from "../../images/lslide06.webp";
import lslide07 from "../../images/lslide07.webp";
import Autotrender from "../../images/autotrender-black.webp";
import { height } from "@mui/system";
import Header from "../../components/Header";
import { Helmet } from "react-helmet";
import { Context } from "../../context";
import Loader from "../../components/Loader";
import SEO from "../../components/common/SEO";
import SMCNEWLOGO from "../../images/SMCNEWLOGO.webp";
import { SEO_URL } from "../../utils/constants";
import { seoData } from "../../utils/Data";
import AuthFaq from "./AuthFaq";

export const Partnerus = () => {
  const isMobile = window.innerWidth <= 540;
  const { loading, setLoading } = React.useContext(Context);
  const [state, setState] = useState({
    turnover: 1,
    workforce: 1,
    states: 1,
    cities: 1,
  });
  var settings2 = {
    dots: false,
    navigation: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings3 = {
    dots: false,
    navigation: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Header />
      <SEO
        title={seoData.partnerUs.title}
        description={seoData.partnerUs.description}
        name="Company name."
        type="article"
        url={seoData.partnerUs.url}
        image={seoData.partnerUs.image}
      />

      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/partner-us">Partner Us</Link>
                </li>
                <li>Authorized Person</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1 className=" p-0">Choose SMC Choose Growth</h1>
                  <p>Become an SMC business partner. </p>
                </div>
                <div className="bannergt">
                  <div className="mobileno">
                    <img src={Banner} alt="Hassle-free investing" />
                  </div>
                </div>
                {isMobile ? (
                  <div
                    style={{
                      marginTop: "-60px",
                      width: "100%",
                    }}
                  >
                    <Form catID="Subbroker" smsType={"sms_sub_broker"} />
                  </div>
                ) : (
                  <Form catID="Subbroker" smsType={"sms_sub_broker"} />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent p-0"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Partner Account. Multiple Benefits.</h3>
                  </div>
                  <div className="iconrow">
                    <VisibilitySensor
                      partialVisibility
                      offset={{ bottom: 200 }}
                    >
                      {({ isVisible }) => (
                        <ul>
                          <li>
                            <span>
                              <img
                                src={Icon01}
                                alt="Regular Training Program"
                              />
                            </span>
                            <div className="countnum">
                              {isVisible ? (
                                <CountUp
                                  start={state.turnover === 1 ? 1 : 2550}
                                  end={2550}
                                  duration={3}
                                  onEnd={() => setState({ turnover: 2550 })}
                                />
                              ) : (
                                <CountUp start={1} end={2550} duration={0} />
                              )}{" "}
                              +
                              <p style={{ textTransform: "none" }}>
                                Authorized Person & franchises
                              </p>
                            </div>{" "}
                          </li>
                          <li>
                            <span>
                              <img
                                src={Icon02}
                                alt="Advanced Software to Manage Clients"
                              />
                            </span>
                            <div className="countnum">
                              {isVisible ? (
                                <CountUp
                                  start={state.turnover === 1 ? 1 : 2}
                                  end={2}
                                  duration={3}
                                  onEnd={() => setState({ turnover: 20 })}
                                />
                              ) : (
                                <CountUp start={1} end={2} duration={0} />
                              )}{" "}
                              M+
                              <p style={{ textTransform: "none" }}>
                                Unique Customers
                              </p>
                            </div>{" "}
                          </li>
                          <li>
                            <span>
                              <img
                                src={Icon03}
                                alt="Multiple Product Offering For Cross Sell"
                              />
                            </span>{" "}
                            <div className="countnum">
                              {isVisible ? (
                                <CountUp
                                  start={state.states === 1 ? 1 : 455}
                                  end={455}
                                  duration={1}
                                  onEnd={() => setState({ states: 455 })}
                                />
                              ) : (
                                <CountUp start={1} end={455} duration={0} />
                              )}
                              +
                              <p style={{ textTransform: "none" }}>
                                Cities Across India
                              </p>
                            </div>{" "}
                          </li>
                          <li>
                            <span>
                              <img
                                src={Icon04}
                                alt="Regular Marketing Support"
                              />
                            </span>{" "}
                            <div className="countnum">
                              {isVisible ? (
                                <CountUp
                                  start={state.workforce === 1 ? 1 : 1500}
                                  end={1500}
                                  duration={2}
                                  onEnd={() => setState({ workforce: 3300 })}
                                />
                              ) : (
                                <CountUp start={1} end={1500} duration={0} />
                              )}
                              +<p>Seminars Held</p>
                            </div>{" "}
                          </li>
                        </ul>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="gradient p-100 whitetext textmanagemobile">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>Why Partner with us?</h3>
                      <p className="text-white">
                        Make the wise choice of business with SMC as your
                        partner.
                      </p>
                    </div>
                    <div className="sellearn">
                      <div className="sestn">
                        <div className="selbox">
                          <div className="selcont">
                            <p className="pselbox">
                              Regular training programs{" "}
                            </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selcont">
                            <p className="pselbox">
                              Multiple product offerings
                            </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selcont">
                            <p className="pselbox">
                              Award-winning, trusted brand
                            </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selcont">
                            <p className="pselbox">
                              Dedicated activation & re-activation desk
                            </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selcont">
                            <p className="pselbox">
                              Best in industry marketing support
                            </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selcont">
                            <p className="pselbox">Technology support</p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selcont">
                            <p className="pselbox">Research support </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selcont">
                            <p className="pselbox">
                              Investor awareness seminars
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="partnerfeature">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3 style={{ textTransform: "none" }}>
                        Key benefits of being an SMC partner
                      </h3>
                    </div>
                    <div className="partnerlist">
                      <ul>
                        <li>
                          <span>
                            <img src={Img01} />
                          </span>
                          <p>
                            <strong>Regular Training Program</strong>
                          </p>
                        </li>
                        <li>
                          <span>
                            <img src={Img02} />
                          </span>
                          <p>
                            <strong>Multiple Products Offerings</strong>
                          </p>
                        </li>
                        <li>
                          <span>
                            <img src={Img03} />
                          </span>
                          <p>
                            <strong>Award Winning & Trusted Brand</strong>
                          </p>
                        </li>
                        <li>
                          <span>
                            <img src={Img04} />
                          </span>
                          <p>
                            <strong>Dedicated Re-activation Desk</strong>
                          </p>
                        </li>
                        <li>
                          <span>
                            <img src={Img08} />
                          </span>
                          <p>
                            <strong>World Class Marketing Support</strong>
                          </p>
                        </li>
                        <li>
                          <span>
                            <img src={Img07} />
                          </span>
                          <p>
                            <strong>Technology Support</strong>
                          </p>
                        </li>
                        <li>
                          <span>
                            <img src={Img06} />
                          </span>
                          <p>
                            <strong>Investor Awareness Seminars</strong>
                          </p>
                        </li>
                        <li>
                          <span>
                            <img src={Img05} />
                          </span>
                          <p>
                            <strong>Research Support</strong>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="partnerfeature">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3 style={{ textTransform: "none" }}>
                        Who can partner with us?
                      </h3>
                    </div>
                    <div className="peoplepartner">
                      <Slider {...settings2}>
                        <div>
                          <div
                            className="peoplebox"
                            style={{ height: "25rem" }}
                          >
                            <img src={client01} />
                            <h4>Finance Enthusiasts </h4>
                            <p>
                              Passionate about finance and the stock market, you
                              find joy in helping others navigate investment
                              choices.
                            </p>
                          </div>
                        </div>
                        <div>
                          <div
                            className="peoplebox"
                            style={{ height: "25rem" }}
                          >
                            <img src={client02} />
                            <h4>Digital Influencers </h4>
                            <p>
                              You thrive as a digital trendsetter, dominating
                              the social media landscape. A substantial
                              following eagerly consume the content you create
                              and share.
                            </p>
                          </div>
                        </div>
                        <div>
                          <div
                            className="peoplebox"
                            style={{ height: "25rem" }}
                          >
                            <img src={client03} />
                            <h4>Self-driven minds </h4>
                            <p>
                              When your motto is “I’m responsible for myself”,
                              you are ready to take this road and make things
                              happen through your dedicated drive.
                            </p>
                          </div>
                        </div>
                        <div>
                          <div
                            className="peoplebox"
                            style={{ height: "25rem" }}
                          >
                            <img src={client04} />
                            <h4>Finance enthusiast</h4>
                            <p>
                              Your interest lies in finance and the stock
                              market. You take great pleasure in guiding others
                              with investment options.
                            </p>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="gradient p-100 whitetext ">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3 style={{ textTransform: "none" }}>
                        How to become a Authorized Person?
                      </h3>
                      {/* <p className="text-white">
                        You can become a Sub Broker in 5 easy steps
                      </p> */}
                    </div>
                    <div className="sellearn">
                      <div className="sestn">
                        <div className="selbox">
                          <div className="selimg">
                            <span>1</span>
                          </div>
                          <div className="selcont">
                            <p>Drop your enquiry</p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selimg">
                            <span>2</span>
                          </div>
                          <div className="selcont">
                            <p>Get a call from our helpdesk</p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selimg">
                            <span>3</span>
                          </div>
                          <div className="selcont">
                            <p>Provide your documents as per the checklist</p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selimg">
                            <span>4</span>
                          </div>
                          <div className="selcont">
                            <p>Submit the cheque for the registration amount</p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selimg">
                            <span>5</span>
                          </div>
                          <div className="selcont">
                            <p>Your Partner code will be generated</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="authorised">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3 style={{ textTransform: "none" }}>
                        How to become an authorised person with SMC
                      </h3>
                    </div>
                    <div className="sellearn">
                      <div className="sestn">
                        <div className="selbox">
                          <div className="selimg">
                            <span>1</span>
                          </div>
                          <div className="selcont">
                            <p>
                              {" "}
                              You need a minimum qualification of 10+2 or HSC or
                              two years of experience as an employee/dealer in
                              the capital market.
                            </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selimg">
                            <span>2</span>
                          </div>
                          <div className="selcont">
                            <p>
                              You should have profound knowledge of the
                              financial market, including equity, mutual funds
                              and more to become the best fit with us!
                            </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selimg">
                            <span>3</span>
                          </div>
                          <div className="selcont">
                            <p>
                              Having comprehensive knowledge of the functioning
                              of the Indian and International markets and
                              socio-economic climate is crucial for you to
                              understand the market well.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent p-100 grey-background"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3 style={{ textTransform: "none" }}>
                      Stay Ahead with Innovative tools
                    </h3>
                  </div>
                  <div className="awardslider">
                    <Slider {...settings2}>
                      <div>
                        <div className="awards">
                          <img src={lslide01} />
                          <p>Advanced Mobile Trading App</p>
                        </div>
                      </div>
                      <div>
                        <div className="awards">
                          <img src={lslide02} />
                          <p>Open an account in 15 minutes</p>
                        </div>
                      </div>
                      <div>
                        <div className="awards">
                          <img src={lslide03} />
                          <p>Invest online in 15 minutes</p>
                        </div>
                      </div>
                      <div>
                        <div className="awards">
                          <img src={lslide04} />
                          <p>
                            Easy to use back office application for SMC partners
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="awards">
                          <img src={lslide05} />
                          <p>Algorithm based automated trading platform</p>
                        </div>
                      </div>
                      <div>
                        <div className="awards">
                          <img src={Autotrender} />
                          <p>Research tool depicting market trends</p>
                        </div>
                      </div>
                      <div>
                        <div className="awards">
                          <img src={lslide07} />
                          <p>
                            Tie-ups with PNB, IOB, UBI & Federal to open 3-in-1
                            A/c
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Excellence />
          <AuthFaq />
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

import React from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Form } from "../../../../components/Form";
import banner from "../../../../images/Trade.webp";
import Icon01 from "../../../../images/fpiicon01.webp";
import Icon02 from "../../../../images/fpiicon02.webp";
import Icon03 from "../../../../images/fpiicon03.webp";
import Icon04 from "../../../../images/fpiicon04.webp";
import Icon05 from "../../../../images/fpiicon05.webp";
import Icon06 from "../../../../images/fpiicon09.webp";
import Icon07 from "../../../../images/fpiicon07.webp";
import Icon08 from "../../../../images/fpiicon08.webp";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Esteps from "../../../../images/telegram-steps.webp";
import Keyfeature from "../../../../images/keyfeature.webp";
import Img01 from "../../../../images/invest01.webp";
import Img02 from "../../../../images/invest02.webp";
import Img03 from "../../../../images/invest03.webp";
import Img04 from "../../../../images/invest04.webp";
import { NRIAndFPIForm } from "../../../../components/NRIAandFPIForm";
import SmcBenefits from "../smcBenefits";
import MediaCard from "../../../../components/MediaCard";
import { offeringList } from "../../../../utils/Data";

export const NRIDemat = () => {
  var settings = {
    dots: false,
    navigation: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 8000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings1 = {
    dots: false,
    navigation: true,
    loop: true,
    infinite: false,
    slidesPerRow: 3,
    rows: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesPerRow: 2,
          rows: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesPerRow: 1,
          rows: 1,
        },
      },
    ],
  };
  return (
    <>
      <Header />
      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>NRI Demat Account</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1>Bring home india's growth with SMC</h1>
                </div>
                <div className="bannergt">
                  <img src={banner} alt="Hassle-free investing" />
                </div>
                <NRIAndFPIForm catID="NRI" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="col-12 pull-left ">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>Why Invest in India?</h3>
                    </div>
                    <div className="learningcenter investinind">
                      <Slider {...settings1}>
                        <div>
                          <div
                            className="learningbox investbx bg-white border-1"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                          >
                            <div className="learningimg m-0">
                              <img
                                src={Icon01}
                                alt="Stable Government at the Centre"
                              />
                            </div>
                            <div className="learningcont text-center  p-3">
                              <p className="w-100 mb-1">
                                <strong>Stable Government at the Centre</strong>
                              </p>
                              <p className="w-100">
                                India is world’s largest democracy with a stable
                                government. In 2024 general election, the
                                incumbent government will come back for the
                                third time. This means confidence and continuity
                                for the policies and its implementations.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="learningbox investbx bg-white border-1"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                          >
                            <div className="learningimg m-0">
                              <img
                                src={Icon02}
                                alt="Big Focus on Development"
                              />
                            </div>
                            <div className="learningcont text-center  p-3 text-center">
                              <p className="w-100 mb-1">
                                <strong>Big Focus on the Development</strong>
                              </p>
                              <p className="w-100">
                                The GDP growth in 2021-22 stood at 9.1 per cent.
                                It grew at 7.2% in FY 2022-23, with strong
                                future growth prospects. World bank expects
                                Indian to grow at about 7 % for 2024-25.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="learningbox investbx bg-white border-1"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                          >
                            <div className="learningimg m-0">
                              <img
                                src={Icon03}
                                alt="One of the Leading Economies"
                              />
                            </div>
                            <div className="learningcont text-center  p-3 text-center">
                              <p className="w-100 mb-1">
                                <strong>One of the Leading Economies</strong>
                              </p>
                              <p className="w-100">
                                According to IMFs calculations, India will move
                                into fourth place in 2025 and into the third
                                place in 2027 as a USD 5.4 trillion economy.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="learningbox investbx bg-white border-1"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                          >
                            <div className="learningimg m-0">
                              <img src={Icon04} alt="Leading FPI Destination" />
                            </div>
                            <div className="learningcont text-center  p-3 text-center">
                              <p className="w-100 mb-1">
                                <strong>Leading FPI Destination</strong>
                              </p>
                              <p className="w-100">
                                Foreign direct investment (FDI) in India surged
                                by 26.4 percent, reaching US$22.4 billion,
                                during the period from April 2024 to June 2024
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="learningbox investbx bg-white border-1"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                          >
                            <div className="learningimg m-0">
                              <img src={Icon05} alt="Strong Growth Trends" />
                            </div>
                            <div className="learningcont text-center  p-3 text-center">
                              <p className="w-100 mb-1">
                                <strong> Manufacturing Destination</strong>
                              </p>
                              <p className="w-100">
                                China Plus One strategy has created a
                                significant opportunity for India to attract
                                foreign investment and become a preferred
                                destination for manufacturing.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="learningbox investbx bg-white border-1"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                          >
                            <div className="learningimg m-0">
                              <img
                                src={Icon06}
                                alt="Indian Bonds in Global Index"
                              />
                            </div>
                            <div className="learningcont text-center  p-3 text-center">
                              <p className="w-100 mb-1">
                                <strong>Indian Bonds in Global Index</strong>
                              </p>
                              <p className="w-100">
                                JPMorgan Chase & Co. has added Indian government
                                bonds to its benchmark emerging market Index
                                w.e.f June 2024 and Bloomberg will follow suit
                                from Jan 2025.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div></div>
                          <div></div>
                          <div
                            className="learningbox investbx bg-white border-1"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                          >
                            <div className="learningimg m-0">
                              <img src={Icon07} alt="Strong Forex Reserves" />
                            </div>
                            <div className="learningcont text-center  p-3">
                              <p className="w-100 mb-1">
                                <strong>Strong Forex Reserves</strong>
                              </p>
                              <p className="w-100">
                                India’s forex stands at healthy USD 692.3
                                billion as on Oct 4th 2024. It is more than 10
                                months merchandise import of India & more than
                                long term external debt of the country.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div></div>
                          <div
                            className="learningbox investbx bg-white border-1"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                          >
                            <div className="learningimg m-0">
                              <img
                                src={Icon08}
                                alt="Ease of Doing Business Leap"
                              />
                            </div>
                            <div className="learningcont text-center  p-3 text-center">
                              <p className="w-100 mb-1">
                                <strong>Ease of Doing Business Leap</strong>
                              </p>
                              <p className="w-100">
                                India jumps 79 positions from 142nd (2014) to
                                63rd (2022) in 'World Bank's Ease of Doing
                                Business Ranking 2022. Source: World Bank.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Get your NRI Demat Account</h3>
                  </div>
                  <div className="downloadapp easystep flex-direction-row">
                    <div className="apprgt">
                      <img src={Esteps} alt="easysteps" />
                    </div>
                    <div className="applef">
                      <div className="stepstn">
                        <ul>
                          <li>
                            <strong>Step 1 </strong>
                            <p>Fill-in the Details</p>
                          </li>
                          <li>
                            <strong>Step 2 </strong>
                            <p>
                              Submit all the Documents for Identity Proof Online
                            </p>
                          </li>
                          <li>
                            <strong>Step 3 </strong>
                            <p>Link Your NRE/NRO Bank Accounts</p>
                          </li>
                          <li>
                            <strong>Step 4</strong>
                            <p>
                              Sign your Application with Video Verification!
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Our Offerings</h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 20,
                      justifyContent: "center",
                    }}
                  >
                    {offeringList.map(({ image, title, content }) => {
                      return (
                        <MediaCard
                          key={title} // It's a good idea to add a unique key
                          title={title}
                          image={image}
                          content={content}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="keyfeatures">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <span>Documents required</span>
                    </div>
                    <div className="keyfeaturerow">
                      <div className="keyfeaturecont">
                        <img src={Keyfeature} alt="key feature" />
                      </div>
                      <div className="keyfeaturecont">
                        <ul>
                          <li>
                            <span>1</span>
                            <strong>Valid Indian Passport/PIO Card</strong>
                          </li>
                          <li>
                            <span>2</span>
                            <strong>PAN Card</strong>
                          </li>
                          <li>
                            <span>3</span>
                            <strong>
                              Residence Status of Proof (Visa, Work Permit,
                              Resident Card)
                            </strong>
                          </li>
                          <li>
                            <span>4</span>
                            <strong>
                              Overseas Address Proof ( Driver’s License,
                              Electricity or Telephone Bill)
                            </strong>
                          </li>
                          <li>
                            <span>5</span>
                            <strong>
                              Indian Address Proof (only in cases of African
                              Nations)
                            </strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Contact Us</h3>
                    <div className="contactoverview">
                      <div
                        className="col-lg-8 m-auto row"
                      // style={{
                      //   background: "#e2f4f2",
                      //   paddingBottom: 20,
                      //   borderRadius: 15,
                      // }}
                      >
                        <div className="contactbox col-lg-12 col-md-12 col-12">
                          <ul className="d-grid col-8 m-auto">
                            <li
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <span>Dubai</span>
                              <p>
                                2404, 1 Lake Plaza Tower , Cluster T, 4th
                                Jumeriah Lake Towers , PO Box 117210,Dubai, UAE
                              </p>
                            </li>
                            <li
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              {/* <span>Phone</span> */}
                              <p
                              // style={{
                              //   display: "flex",
                              //   justifyContent: "center",
                              //   gap: 3,
                              // }}
                              >
                                <a href="tel:97145139780">TEL: +97145139780</a>
                                <a href="/">FAX: +97145139781</a>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent p-100 grey-background"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Investment Guidelines</h3>
                  </div>
                  <div className="col-12 pull-left ipotable defaulttable bg-white processflow">
                    <table className="table m-0 border">
                      {/* <thead>
                        <tr className="gradient text-white">
                          <th>Particulars</th>
                          <th>For buying and selling Shares</th>
                          <th>For Investing in Mutual Funds and IPOs</th>
                          <th>
                            For trading in F&O and shares acquired as an Indian
                            Resident
                          </th>
                        </tr>
                      </thead> */}
                      <tbody>
                        <tr>
                          <td
                            className="gradient text-white"
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            What to invest in
                          </td>
                          <td>For buying and selling Shares</td>
                          <td>For Investing in Mutual Funds and IPOs</td>
                          <td>
                            For trading in F&O and shares acquired as an Indian
                            Resident
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="gradient text-white"
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            How to invest
                          </td>
                          <td>Investing with PIS</td>
                          <td>Investing with NRI Account</td>
                          <td>Investing with NRO Account</td>
                        </tr>
                        <tr>
                          <td
                            className="gradient text-white"
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            Type of Bank Account
                          </td>
                          <td>
                            NRE-PIS (Repatriable)
                            <hr
                              style={{ marginTop: "3px", marginBottom: "3px" }}
                            />
                            NRE-PIS (Repatriable)
                          </td>
                          <td>
                            NRI Saving A/c (Repatriable)
                            <hr
                              style={{ marginTop: "3px", marginBottom: "3px" }}
                            />
                            NRI Saving A/c (Non-Repatriable)
                          </td>
                          <td>NRO Saving A/c (Non-Repatriable)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SmcBenefits />

          {/* <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Frequently Asked Questions</h3>
                  </div>
                  <div className="faqcont">
                    <Accordion allowZeroExpanded="true">
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What are Derivatives?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              To understand Futures and Options, it is important
                              to have an understanding of Derivatives. In the
                              financial markets, a Derivative is a contract that
                              derives its value from underlying assets. These
                              assets can be stocks, bonds, gold, currencies,
                              market indices, commodities, etc. When you buy a
                              derivatives contract, you earn profits by
                              estimating the future price of the asset(s). There
                              are four types of derivative contracts:
                            </p>
                            <ul>
                              <li>Options</li>
                              <li>Futures</li>
                              <li>Forwards</li>
                              <li>Swaps</li>
                            </ul>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Who are the participants in the derivatives market?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              There are four kinds of participants in a
                              derivatives market. They are Hedgers, Speculators,
                              Arbitrageurs, and Margin Traders.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What are Futures?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Futures are derivative financial contracts that
                              obligate parties to buy or sell an asset at a
                              predetermined future date and price. The buyer
                              must purchase or the seller must sell the
                              underlying asset at the set price, regardless of
                              the current market price at the expiration date.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What are Options?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Options are a type of derivative product that
                              allow investors to speculate on or hedge against
                              the volatility of an underlying stock. Options are
                              divided into call options, which allow buyers to
                              profit if the price of the stock increases, and
                              put options, in which the buyer profits if the
                              price of the stock declines. 
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What are the different types of Options?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>There are two types of Options contracts:</p>
                            <p>The benefits of working with SMC are –</p>
                            <ul>
                              <li>
                                <strong>Call options </strong>afford the holder
                                the right, but not the obligation, to buy the
                                asset at a stated price within a specific
                                timeframe.
                              </li>
                              <li>
                                <strong>Put options</strong> afford the holder
                                the right, but not the obligation, to sell the
                                asset at a stated price within a specific
                                timeframe.
                              </li>
                            </ul>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What is the expiration date in an Option Contract?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The Expiration Date of an Option is the last date
                              by which the owner of the contract can exercise
                              the right to buy or sell the underlying asset. In
                              India, monthly Options Contracts expire on the
                              last working Thursday of the month. If that day is
                              a market holiday, then the Option expires on the
                              previous working day. In the case of weekly
                              Options, the contract expires every Thursday.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            How do I buy Options?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              You can buy/sell Options contracts through broking
                              firms that are registered members of the BSE or
                              NSE. SMC provide you an online platforms and/or
                              mobile applications allowing you to trade in all
                              derivatives contract.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            How do I use Call and/or Put Option?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              When you are looking at an Option, assess the
                              underlying asset(s) and try to estimate the
                              direction its price might take in the coming
                              month.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            If the stock price expected to go up...
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              In these cases, you can consider Buying a Call
                              Option or Selling a Put Option as it will put you
                              in a position to earn profits.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            If the stock price expected to go down...
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              In these cases, you can consider Selling a Call
                              Option or Buying a Put Option as it will put you
                              in a position to earn profits.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            How is the difference between Options and Futures?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              <strong>Options Contract</strong>
                            </p>
                            <ul>
                              <li>The buyer has no obligation </li>
                              <li>They are subject to limited risk</li>
                              <li>
                                It can reap either unlimited profit or loss
                              </li>
                              <li>Advance is paid in the form of premiums</li>
                            </ul>
                            <p>
                              <strong>Future Contract</strong>
                            </p>
                            <ul>
                              <li>
                                <strong>Buyers Obligation</strong> The buyer has
                                an obligation to execute the contract{" "}
                              </li>
                              <li>
                                <strong>Risk</strong> They are subject to high
                                risk
                              </li>
                              <li>
                                <strong>Level of Profit or Loss</strong> It can
                                also reap unlimited profit or loss
                              </li>
                              <li>
                                <strong>Advance Payment</strong> No advance
                                payment are made
                              </li>
                            </ul>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            How can I start trading on SMC trading app?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              First you need to open a Demat and trading account
                              in order to do stock trading online. You can open
                              a Demat and trading account with SMC by filling
                              out an application from the website. Further,
                              required documents like Aadhaar card, PAN, driving
                              license, and passport size photo must be provided
                              for verification. After successful verification
                              done by SMC, client id will be provided to start
                              trading.{" "}
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </section>
      </main>
      <Footer />
    </>
  );
};

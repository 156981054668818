import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { SEO_URL } from "../../../../utils/constants";
import { Context } from "../../../../context";

const MutualFundSEO = ({ id }) => {
  const { fundDetails, setFundDetails } = useContext(Context);
  const image = "../../images/SMCNEWLOGO.webp";
  const url = `${SEO_URL}/invest/mutual-funds/${id}/${fundDetails?.basic_name}`;
  const title = `${fundDetails?.basic_name} Nav, Review, Portfolio & Performance`;
  const description = `${fundDetails?.basic_name} Share Price - Get ${fundDetails?.basic_name} live BSE/NSE stock price Today with Latest News, Performance Analysis, Fundamentals, Market Cap & other financial report on SMC`;
  function formatUrl(url) {
    let tempUrl = url.replace(/\s+/g, "-").toLowerCase();
    return tempUrl.replace(/-+/g, "-");
  }
  const formattedUrl = formatUrl(url);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={formattedUrl} />
        <meta property="og:title" content={title} data-react-helmet="true" />
        <meta property="og:site_name" content="SMC Global Securities" />
        <meta property="og:url" content={formattedUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        <meta name="twitter:url" content={formattedUrl} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:card" content="summary" />
        <meta name="description" content={description}></meta>
        <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>
    </>
  );
};

export default MutualFundSEO;

import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Context } from "../context";

export const Partner_Drop = ({ setActive, setIsOpen, menus }) => {
  const {
    isDemat,
    num,
    setIsDemat,
    setName,
    setNum,
    setEmail,
    setState,
    setCity,
    name,
    email,
    state,
    city,
    categoryID,
    urlData,
    ipAddress,
  } = useContext(Context);

  const emptyField = () => {
    setCity("");
    setEmail("");
    setName("");
    setState("");
    setNum("");
    setActive(!menus);
    setIsOpen(false);
  };
  return (
    <>
      <div className="menubox">
        <div className="sitemapcol">
          <h4 style={{ cursor: "context-menu" }}>Partner Us</h4>
          <ul>
            <li>
              <NavLink to="/partner-us/authorized-person" onClick={emptyField}>
                Authorized Person
              </NavLink>
            </li>
            <li>
              <NavLink to="/partner-us/become-distributor" onClick={emptyField}>
                Become Distributor
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="sitemapcol">
          <ul style={{ marginTop: "-6px" }}>
            <li>
              <NavLink to="/about-us" onClick={emptyField}>
                <h4 style={{ cursor: "pointer" }}>About Us</h4>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import Icon1 from "../../../../images/svg/leading-economy.svg";
import Icon2 from "../../../../images/svg/fpi-destini.svg";
import Icon3 from "../../../../images/svg/indian-bond.svg";
import Icon4 from "../../../../images/svg/alternative.svg";
import Icon5 from "../../../../images/svg/strong-forex.svg";
import Icon6 from "../../../../images/svg/ease-of.svg";
import Icon7 from "../../../../images/svg/focus-on.svg";

const data = [
  {
    title: "Leading Economy",
    description:
      "India is the 5th largest economy globally, with a GDP of over $3.5 trillion.",
    icon: Icon1, // You can replace this with your preferred icon component
  },
  {
    title: "Favourite FPI Destination",
    description:
      "India attracted over $25 billion in Foreign Portfolio Investment in 2023.",
    icon: Icon2,
  },
  {
    title: "Indian Bonds in Global Index",
    description:
      "Indian government bonds are set to join the JP Morgan Global Bond Index in 2024.",
    icon: Icon3,
  },
  {
    title: "Alternative Manufacturing Hub Beyond China",
    description:
      "India is projected to become a $1 trillion manufacturing hub by 2030, benefiting from global supply chain diversification.",
    icon: Icon4,
  },
  {
    title: "Strong Forex Reserves",
    description:
      "India's foreign exchange reserves crossed the $600 billion mark in 2023.",
    icon: Icon5,
  },
  {
    title: "Ease of Doing Business",
    description:
      "Ranked 63rd in the World Bank's Ease of Doing Business Index in 2023.",
    icon: Icon6,
  },
  {
    title: "Focus on Development",
    description:
      "India plans to invest over $1 trillion in infrastructure projects under the National Infrastructure Pipeline (NIP) by 2025.",
    icon: Icon7,
  },
];

const EconomicAscent = () => {
  return (
    <Box sx={{ marginTop: { md: 5 } }}>
      {/* Header Section */}
      <div className="title">
        <h3>India's Economic Ascent</h3>
        <Typography variant="subtitle1" color="textSecondary" fontWeight="bold">
          Powerful Numbers Driving Growth, Global Investments, and Manufacturing
          Leadership
        </Typography>
      </div>

      {/* Grid Layout */}
      <Grid container spacing={3} justifyContent="center">
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box
              p={3}
              borderRadius={2}
              bgcolor="#fff"
              boxShadow={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100%"
              textAlign="center"
            >
              {/* Icon */}
              <img src={item.icon} alt={index} />

              {/* Title */}
              <Typography
                variant="h6"
                fontWeight="bold"
                gutterBottom
                sx={{ mt: 2 }}
              >
                {item.title}
              </Typography>

              {/* Description */}
              <Typography variant="body2" color="textSecondary">
                {item.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EconomicAscent;

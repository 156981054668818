import React, { useContext, useState } from "react";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import Banner from "../../images/contact-us.webp";
import { Faqs } from "../../images/svg/Faqs";
import { Download } from "../../images/svg/Download";
import { Holidays } from "../../images/svg/Holidays";
import { List } from "../../images/svg/List";
import ContactForm from "../../components/ContactForm";
import Loader from "../../components/Loader";
import { Context } from "../../context";
import Header from "../../components/Header";
import SEO from "../../components/common/SEO";
import { seoData } from "../../utils/Data";
import feedback from "./grievanceImages/feedback.svg";
import { Form } from "../../components/Form";
import DematForm from "../../components/DematForm";
import GrievancesForm from "../../components/GrievancesForm";
import GrievancesCard from "./GrievancesCard";
import GrievanceComplaint from "./GrievanceComplaint";
import GrievanceOtpDialog from "./GrievanceOtpDialog";
import RaiseComplaintDialog from "./RaiseComplaintDialog";
import TrackComplaintDialog from "./TrackComplaintDialog";
import SubmitRequestDialog from "./SubmitRequestDialog";
export const Grievances = () => {
    const { name, openOtpDialog,
        setOpenOtpDialog
    } = useContext(Context);
    // const [openOtpDialog, setOpenOtpDialog] = useState(false);
    const [openTrackComplaintDialog, setOpenTrackComplaintDialog] = useState(false);
    const [openRaiseComplaintDialog, setOpenRaiseComplaintDialog] = useState(false);
    const [openSubmitRequestDialog, setSubmitRequestDialog] = useState(false);
    const [clientInfoData, setClientInfoData] = useState([]);
    const [loggedInUser, setLoggedInUser] = useState(false);
    const { loading, setShowPath } = useContext(Context);

    return (
        <>
            <Header />
            <section id="banner">
                <div className="banner internal">
                    <div className="container">
                        <div className="bredcrum">
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>Support</li>
                                <li>Grievance Redressal Portal</li>
                            </ul>
                        </div>
                        {/* {Array.isArray(clientInfoData) && clientInfoData.length > 0 ? */}
                        {loggedInUser ?
                            <div className="tablisthead" style={{ borderRadius: "5px", marginTop: "5px" }}>
                                <div className="grievancesTab">
                                    {/* <div>
                            <img style={{ height: "50px", width: "50px", paddingLeft: "10px" }} src={feedback} alt="Hassle-free investing" />
                        </div> */}
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div>
                                            <p className="grievancesHeading">Hi, {name}</p>
                                        </div>
                                        <div style={{ display: "flex", padding: "10px" }}>
                                            <div
                                                onClick={() => setOpenRaiseComplaintDialog(true)}
                                                style={{ border: "1px solid white", padding: "10px", color: "white", borderRadius: "5px", fontSize: "12px", cursor: "pointer" }}
                                            >
                                                RAISE A COMPLAINT
                                            </div>
                                            {/* <div
                                                onClick={() => setOpenTrackComplaintDialog(true)}
                                                style={{ border: "1px solid white", padding: "10px", color: "white", marginLeft: "10px", borderRadius: "5px", fontSize: "12px", cursor: "pointer" }}
                                            >
                                                TRACK YOUR COMPLAINT
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div className="tablisthead" style={{ borderRadius: "5px" }}>
                                <div className="grievancesTab">
                                    <div>
                                        <p className="grievancesHeading">Need Help?</p>
                                        <p className="grievancesSubHeading">Dont worry,just ask and we will find the best solution for all your queries</p>
                                    </div>
                                </div>
                            </div>
                        }


                        <div className="grievancesContent">
                            <div className="left-div">
                                <GrievanceComplaint />
                            </div>
                            {
                                !loggedInUser &&
                                <div className="right-div">
                                    <GrievancesForm
                                        clientInfoData={clientInfoData}
                                        setClientInfoData={setClientInfoData}
                                    />
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </section>
            <GrievanceOtpDialog
                open={openOtpDialog}
                setOpen={setOpenOtpDialog}
                loggedInUser={loggedInUser}
                setLoggedInUser={setLoggedInUser}
            // data={dataExpense}
            // dotShow={true}
            />
            <RaiseComplaintDialog
                open={openRaiseComplaintDialog}
                setOpen={setOpenRaiseComplaintDialog}
                setSubmitRequestDialog={setSubmitRequestDialog}
            />
            <TrackComplaintDialog
                open={openTrackComplaintDialog}
                setOpen={setOpenTrackComplaintDialog}

            />
            <SubmitRequestDialog
                open={openSubmitRequestDialog}
                setOpen={setSubmitRequestDialog}
            />
            <Footer />
            {loading && <Loader />}
        </>
    );
};

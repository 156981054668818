import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import OTP from "../../components/OTP";

const GrievanceOtpDialog = ({ open, setOpen, loggedInUser, setLoggedInUser }) => {
    const handleClose = (event, reason) => {
        if (reason === "backdropClick") return; // Prevent closing when clicking outside
        setOpen(false);
    };

    return (
        <>
            <Dialog
                maxWidth="xs"
                fullWidth={false}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableEscapeKeyDown
            >
                <>
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{ overflow: "hidden", pb: 0, lineHeight: "0.2" }}
                    >
                        {/* Hello how are you */}
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ overflow: "hidden", lineHeight: "0.2" }}>
                        <DialogContentText
                            id="alert-dialog-description"
                            sx={{ overflow: "hidden" }}
                        >
                            <OTP grievanceOtpFlag={true} loggedInUser={loggedInUser} setLoggedInUser={setLoggedInUser} />
                        </DialogContentText>
                    </DialogContent>
                </>
            </Dialog>
        </>
    );
};
export default GrievanceOtpDialog;

import React from 'react'

export const Holidays = (props) => {
  return (
    <>
      <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={60}
    height={60}
    viewBox="0 0 64 64"
    {...props}
  >
    <g fill="#fff">
      <path d="M11 54h6a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4v-3zM23 54h6a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4v-3zM35 54h6a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4v-3zM11 43h6a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4v-3zM23 43h6a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4v-3zM35 43h6a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4v-3zM47 43h6a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4v-3zM11 32h6a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4v-3zM23 32h6a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4v-3zM35 32h6a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4v-3zM47 32h6a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4v-3z" />
      <path d="M60 4h-7V3a3 3 0 1 0-6 0v1H17V3a3 3 0 1 0-6 0v1H4C1.789 4 0 5.789 0 8v52c0 2.211 1.789 4 4 4h56c2.211 0 4-1.789 4-4V8c0-2.211-1.789-4-4-4zM49 3a1 1 0 1 1 2 0v7a1 1 0 1 1-2 0V3zM13 3a1 1 0 1 1 2 0v7a1 1 0 1 1-2 0V3zm49 57a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V17h60v43zm0-45H2V8a2 2 0 0 1 2-2h7v4a3 3 0 1 0 6 0V6h30v4a3 3 0 1 0 6 0V6h7a2 2 0 0 1 2 2v7z" />
    </g>
  </svg>
    </>
  )
}

const IpoStrength = ({ strength }) => {
    return (
        <div className="pros">
            <h6>Strengths and Opportunities</h6>
            <ul>
                {strength?.slice(0, 3)?.map((data) => (
                    <li>{data.StrengthDetails}</li>
                ))}
            </ul>
        </div>
    )
}

export default IpoStrength;
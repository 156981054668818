import { formatNumber } from "../../../../components/common";
const StocksOverview = ({ bioData, quatesData, historicalData, stockExchange }) => {
    return (
        <div className="coprofilecont">
            <div className="tablisthead">
                <ul>
                    <li>{stockExchange?.company_name} Quick Overview</li>
                </ul>
            </div>
            <div className="ipoapplyfrm">
                <ul>
                    <li>
                        <span>Market Cap</span>
                        <span>
                            <i className="finr"></i>
                            {quatesData?.mcap &&
                                formatNumber(quatesData?.mcap)}{" "}
                            Crore
                        </span>
                    </li>
                    <li>
                        <span>Open</span>

                        <span>
                            <i className="finr"></i>
                            {formatNumber(quatesData?.open_Price) ===
                                "NaN"
                                ? "NA"
                                : formatNumber(quatesData?.open_Price)}
                        </span>
                    </li>
                    <li>
                        <span>Prev Close</span>
                        <span>
                            <i className="finr"></i>
                            {formatNumber(quatesData?.Oldprice) ===
                                "NaN"
                                ? "NA"
                                : formatNumber(quatesData?.Oldprice)}
                        </span>
                    </li>
                    <li>
                        <span>Circuit Range</span>
                        <span>NA</span>
                    </li>
                    <li>
                        <span>Day Range</span>
                        <span>NA</span>
                    </li>
                    <li>
                        <span>Year Range</span>
                        <span>
                            <i className="finr"></i>
                            {historicalData?.["1yearlow"]}
                            {"-"}
                            <i className="finr"></i>
                            {historicalData?.["1yearhigh"]}
                        </span>
                    </li>
                    <li>
                        <span>Volume</span>
                        <span>NA</span>
                    </li>
                    <li>
                        <span>Avg. Traded</span>
                        <span>NA</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default StocksOverview;
import React from "react";
// import Footer from './components/Footer'
import { Link, useParams } from "react-router-dom";
import DematForm from "../../../../components/DematForm";
import { Form } from "../../../../components/Form";
import UpcomingIPOSeo from "./upComingIPOSeo";
import { useEffect, useState } from "react";
import Footer from "../../../../components/Footer";
import { Context } from "../../../../context";
import { useContext } from "react";
import Header from "../../../../components/Header";
import { Button } from "@mui/material";
import IpoOngoing from "./IpoOngoing";
import IpoTimeline from "./IpoTimeline";
import IpoSubscriptionDetails from "./IpoSubscriptionDetails";
import IpoSubscriptionStatus from "./IpoSubscriptionStatus";
import IpoStrength from "./IpoStrength";
import IpoRisk from "./IpoRisk";
import IpoCompetitivePeers from "./IpoCompetitivePeers";
import IpoFinancials from "./IpoFinancials";
import IpoRegistrarInfo from "./IpoRegistrarInfo";
import IpoAllotmnetStatus from "./IpoAllotmentStatus";
import IpoSubscriptionSummary from "./IpoSubscriptionSummary";
import {
  getSubscriptionStatus,
  getIpoTimeline,
  getStrength,
  getIpoAllocationDetails,
  getOpenIpo,
} from "../../../../utils/APICollection";
import IpoFaqDetails from "./IpoFaqDetails";

export const Ipodetail = () => {
  const { id } = useParams();
  const [sidebarTop, setSidebarTop] = useState();
  const [shouldAddClass, setShouldAddClass] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState();
  const [strength, setStrength] = useState([]);
  const [ipoTimeline, setIpoTimeline] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState([]);
  const [openIpoData, setOpenIpoData] = useState({});
  const [viewMore, setViewMore] = useState(false);
  const { exchange, subscriptionDetails, setSubscriptionDetails } =
    useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  let coCode = window.location.pathname.split("/")[3];

  const fetchSubscriptionStatus = async () => {
    try {
      const data = await getSubscriptionStatus(coCode);
      setSubscriptionStatus(data?.data?.data[0] || {});
    } catch (err) {
      console.log(err);
    }
  };
  const fetchIpoTimeline = async () => {
    try {
      const data = await getIpoTimeline(coCode);
      setIpoTimeline(data?.data?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchStrength = async () => {
    try {
      const data = await getStrength(coCode);
      setStrength(data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllocationDetails = async () => {
    try {
      const data = await getIpoAllocationDetails(coCode);
      setSubscriptionDetails(data?.data?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchOpenIpoData = async () => {
    setIsLoading(true);
    try {
      const data = await getOpenIpo(exchange);
      const result = data?.data?.data.filter(
        (item) => item.CO_CODE === Number(coCode)
      );
      setOpenIpoData(result[0]);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchStrength();
    fetchAllocationDetails();
    fetchIpoTimeline();
    fetchSubscriptionStatus();
    fetchOpenIpoData();
  }, []);

  const { openIpo } = useContext(Context);

  useEffect(() => {
    const sidebarEl = document.querySelector(".opendemat");

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const shouldAdd = scrollTop >= sidebarTop - 10;

      setShouldAddClass(shouldAdd);
    };

    if (sidebarEl) {
      const sidebarRect = sidebarEl.getBoundingClientRect();
      setSidebarWidth(sidebarRect.width);
      setSidebarTop(sidebarRect.top);

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [sidebarTop]);

  useEffect(() => {
    if (!sidebarTop) return;

    const handleScroll = () => {
      const sidebarEl = document.querySelector(".opendemat");
      const sectionEl = document.querySelector(".ipodashboard");
      const faq_cnt = document.querySelector(".faqcont");

      const iporow = document.querySelector(".ipodashboard .row");
      const scrollTop = window.scrollY;
      const shouldAdd = scrollTop >= sidebarTop - 10;

      setShouldAddClass(shouldAdd);

      if (shouldAdd) {
        sidebarEl.classList.add("is-sticky");
      } else {
        sidebarEl.classList.remove("is-sticky");
      }

      if (
        scrollTop >=
        sectionEl.offsetTop +
        sectionEl.offsetHeight -
        faq_cnt.offsetHeight / 1.8
      ) {
        sidebarEl.classList.remove("is-sticky");
        iporow.classList.add("flex-end");
      } else {
        iporow.classList.remove("flex-end");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sidebarTop]);

  const subscribedListValue = {
    QualifiedInstitutionalBuyers_QIB_Subscribed:
      "Qualified Institutional Buyers QIB Subscribed",
    NonInstitutionalInvestors_NII_Subscribed:
      "Non Institutional Investors NII Subscribed",
    RetailIndividualInvestors_RII_Subscribed:
      "Retail Individual Investors RII Subscribed",
    ReservationPortionShareholder_ExistingRetailShareholders_Subscribed:
      "Reservation Portion Shareholder Existing Retail Shareholders Subscribed",
    EmployeeReservation_Subscribed: "Employee Reservation Subscribed",
    GroupCompanyReservation_Subscribed: "Group Company Reservation Subscribed",
  };

  const isMobile = window.innerWidth <= 1040;

  return (
    <>
      <Header />
      <UpcomingIPOSeo id={id} />

      <section id="banner">
        <div className="banner internal bauto">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/invest/ipo">IPOs</Link>
                </li>
                <li>{subscriptionDetails?.CompanyName}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div className="ipoportfolio">
            <div className="container">
              <div className="web-container">
                <div className="ipodashboard">
                  <div className="row">
                    <div className="ipoleft ipo8box">
                      <div className="ipospace">
                        <div className="webcent">
                          <div className="ipostable">
                            <div className="tablisthead ipotable">
                              <ul>
                                <li>
                                  <div className="ipologo">
                                    {/* ipo logo will be used in future */}
                                    {/* <span>
                                      <img src={Logo01} alt="" />
                                    </span> */}
                                    <h1
                                      style={{
                                        fontSize: "19px",
                                        paddingTop: "5px",
                                      }}
                                    >
                                      {subscriptionDetails?.CompanyName}
                                    </h1>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="tablist">
                              <IpoOngoing
                                isLoading={isLoading}
                                openIpoData={openIpoData}
                                subscriptionDetails={subscriptionDetails}
                                ipoTimeline={ipoTimeline}
                                isMobile={isMobile}
                              />
                              <IpoTimeline
                                ipoTimeline={ipoTimeline}
                                isMobile={isMobile}
                              />
                            </div>
                          </div>
                        </div>
                        <IpoSubscriptionDetails
                          subscriptionDetails={subscriptionDetails}
                          subscriptionStatus={subscriptionStatus}
                          ipoTimeline={ipoTimeline}
                          isMobile={isMobile}
                        />
                        <IpoSubscriptionStatus
                          subscriptionDetails={subscriptionDetails}
                          subscriptionStatus={subscriptionStatus}
                          isMobile={isMobile}
                        />
                        <div className="webcent">
                          <div className="ipostable">
                            <div className="tablisthead ipotable">
                              <h4>Know Before Investing</h4>
                            </div>
                            <div className="tablist">
                              <div className="ipostn">
                                <div className="ipoadvantage">
                                  <IpoStrength strength={strength} />
                                  <IpoRisk />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="webcent">
                          <div className="tablisthead ipotable">
                            <h4>{subscriptionDetails?.CompanyName}</h4>
                          </div>
                          <div className="aboutcompany ipostn">
                            <IpoSubscriptionSummary
                              subscriptionDetails={subscriptionDetails}
                              ipoTimeline={ipoTimeline}
                              openIpoData={openIpoData}
                            />

                            {strength
                              ?.slice(3, strength.length)
                              ?.map((data) => (
                                <p>{data.StrengthDetails}</p>
                              ))}

                            <IpoCompetitivePeers isMobile={isMobile} />
                            <h3>
                              Noteworthy Highlights of{" "}
                              {subscriptionDetails?.CompanyName} Infra IP
                            </h3>
                            {/* IndustrySummary */}
                            <p>
                              {/* {subscriptionDetails?.IndustrySummary} */}
                              {viewMore
                                ? subscriptionDetails?.IndustrySummary
                                : subscriptionDetails?.IndustrySummary?.substring(
                                  0,
                                  500
                                ) + "..."}
                              <Button onClick={() => setViewMore(!viewMore)}>
                                {!viewMore ? "View More" : "view Less"}
                              </Button>
                            </p>

                            <h3>
                              {subscriptionDetails?.CompanyName} Infra IPO
                              Company Financials{" "}
                              <span style={{ fontSize: 15 }}>(Standalone)</span>
                            </h3>
                            <IpoFinancials />
                            <IpoRegistrarInfo />
                            <IpoAllotmnetStatus />
                          </div>
                        </div>
                        {isMobile && (
                          <div style={{ width: "100%" }}>
                            <Form catID="IPO" />
                          </div>
                        )}
                        <IpoFaqDetails
                          subscriptionDetails={subscriptionDetails}
                          ipoTimeline={ipoTimeline}
                          openIpoData={openIpoData}
                        />
                      </div>
                    </div>
                    {!isMobile && (
                      <div className="ipoleft ipo4box">
                        {/* <Form catID="IPO" /> */}
                        <DematForm
                          addClass={shouldAddClass}
                          sidebarWidth={sidebarWidth}
                          catID="IPO"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import styled from '@mui/system/styled';
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../context";

const SubmitRequestDialog = ({ open, setOpen }) => {
    const handleClose = (event, reason) => {
        if (reason === "backdropClick") return; // Prevent closing when clicking outside
        setOpen(false);
    };

    const navigate = useNavigate();
    const { interactionId,
        setInteractionId
    } = useContext(Context);
    return (
        <>
            <Dialog
                maxWidth="xs"
                fullWidth={false}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableEscapeKeyDown
            >
                <>
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{ overflow: "hidden", pb: 0, lineHeight: "1" }}
                    >
                        {/* <Typography variant="body1" >
                            Please enter your complaint ticket ID to know about the complaint status
                        </Typography> */}
                        {/* <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                padding: 'initial'
                            }}
                        >
                            <CloseIcon />
                        </IconButton> */}
                        <Typography align="center" sx={{ color: "green" }} variant="h6" >
                            Request submitted successfully!
                        </Typography>
                        <Typography align="center" sx={{ color: "grey" }} variant="body1" >
                            Complaint Id is {interactionId || "NA"}
                        </Typography>
                        <Box
                            p={2}
                            display="flex"
                            justifyContent="center"
                            gap={2}
                            mt={2}>
                            {/* <Button
                                sx={{
                                    fontSize: window.innerWidth <= 540 ? "8px" : "12px",
                                    background: "white",
                                    color: "black",
                                    '&:hover': {
                                        background: "white",
                                        color: "black"
                                    }
                                }}
                                variant="contained"
                            >
                                TRACK YOUR COMPLAINT
                            </Button> */}
                            <Button
                                sx={{ fontSize: window.innerWidth <= 540 ? "8px" : "12px", color: "white", background: 'linear-gradient(80deg, #405cb2, #44b6a7)' }}
                                variant="contained"
                                onClick={() => navigate("/")}
                            >
                                GO TO HOMEPAGE
                            </Button>
                        </Box>

                    </DialogTitle>

                </>
            </Dialog>
        </>
    );
};
export default SubmitRequestDialog;
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const CommonDialog = ({ open, setOpen, data, dotShow }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {data.map((item) => {
          return (
            <>
              <DialogTitle
                id="alert-dialog-title"
                sx={{ overflow: "hidden", pb: 2, lineHeight: "0.2" }}
              >
                {item.title}
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent sx={{ overflow: "hidden", lineHeight: "0.2" }}>
                <DialogContentText
                  id="alert-dialog-description"
                  sx={{ overflow: "hidden" }}
                >
                  {item.content}
                </DialogContentText>
              </DialogContent>
            </>
          );
        })}
      </Dialog>
    </>
  );
};
export default CommonDialog;

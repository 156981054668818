import React from "react";
import { Helmet } from "react-helmet-async";

import SMCNEWLOGO from "../../images/SMCNEWLOGO.webp";

export const SEO = ({ title, description, name, url, image }) => {
  function formatUrl(url) {
    let tempUrl = url.replace(/\s+/g, "-").toLowerCase();
    return tempUrl.replace(/-+/g, "-");
  }
  const formattedUrl = formatUrl(url);
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={formattedUrl} />
      <meta property="og:title" content={title} data-react-helmet="true" />
      <meta property="og:site_name" content="SMC Global Securities" />
      <meta property="og:url" content={formattedUrl} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta name="twitter:url" content={formattedUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:card" content="summary" />

      <meta name="description" content={description}></meta>
      <meta name="robots" content="index, follow"></meta>
    </Helmet>
  );
};
export default SEO;

import React from "react";
import Investbanner from "../../images/invest-tool.webp";
import { Benefits } from "../../components/Benefits";
import { Learning } from "../../components/Learning";
import { Form } from "../../components/Form";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import { Context } from "../../context";
import { useContext } from "react";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import SEO from "../../components/common/SEO";
import { SEO_URL } from "../../utils/constants";
import Faq from "./Faq";
import WelthAndInvest from "./WelthAndInvest";
import { seoData } from "../../utils/Data";

export const Invest = () => {
  const isMobile = window.innerWidth <= 540;
  const isMobileLandscape = window.innerWidth <= 1040;
  const { loading } = useContext(Context);

  return (
    <>
      <Header />
      <SEO
        title={seoData.invest.title}
        description={seoData.invest.description}
        name="Company name."
        type="article"
        url={seoData.invest.url}
        image={seoData.invest.image}
      />

      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Invest</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1>
                    Invest. <span>Hassle-Free.</span>
                  </h1>
                  <p>
                    Stocks, IPOs, Mutual Funds and other investments are just a
                    click away.
                  </p>
                </div>

                <div className="bannergt">
                  <div className="mobileno">
                    <img src={Investbanner} alt="Hassle-free investing" />
                  </div>
                </div>
                {isMobile ? (
                  <div
                    style={{
                      marginTop: "-60px",
                      width: "100%",
                    }}
                  >
                    <Form catID="Invest" />
                  </div>
                ) : (
                  <Form catID="Invest" />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <WelthAndInvest
            isMobile={isMobile}
            isMobileLandscape={isMobileLandscape}
          />
          <Benefits />
          <Learning />
          <Faq />
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

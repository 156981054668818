import { formatNumber } from "../../../../components/common";
const MfNavDetails = ({ fundDetails }) => {
    const getFormattedDate = (currentDate) => {
        let d = new Date(currentDate);
        let monthList = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        return d.getDate() + " " + monthList[d.getMonth()] + " " + d.getFullYear();
    };
    return (
        <div className="graphsvalue">
            <div className="graphsbox">
                <span>
                    NAV :{" "}
                    {fundDetails?.nav_date &&
                        getFormattedDate(fundDetails?.nav_date)}
                </span>
                <span>
                    <i className="finr"></i>
                    {Number(fundDetails?.latest_nav).toFixed(
                        2
                    ) === "NaN"
                        ? "NA"
                        : Number(
                            fundDetails?.latest_nav
                        ).toFixed(2)}
                </span>
            </div>
            <div className="graphsbox">
                <span>Rating</span>
                <span>
                    {fundDetails?.fund_rating}
                    <i className="fa fa-star"> </i>
                </span>
            </div>
            <div className="graphsbox">
                <span>Min: SIP amount</span>
                <span>
                    <i className="finr"></i>
                    {
                        fundDetails?.min_subsequent_sip_investment
                    }
                </span>
            </div>
            <div className="graphsbox">
                <span>Fund size</span>
                <span>
                    <i className="finr"></i>
                    {fundDetails?.latest_aum &&
                        formatNumber(
                            Number(
                                fundDetails?.latest_aum
                            ).toFixed(2)
                        )}
                    Cr
                </span>
            </div>
        </div>
    )
}

export default MfNavDetails;
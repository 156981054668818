import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import Vinod from "../images/Vinod-Kumar-Atal.webp";
import Deepak from "../images/deepak-modi.webp";
import Ketan from "../images/ketan-manubhai.webp";
import Nilesh from "../images/nilesh.webp";
import Pankaj from "../images/pankaj-jain.webp";
import left from "../images/larrow.webp";
import right from "../images/arrow.webp";

export default function CarouselWebinar() {
  const clientSpeakData = [
    {
      imageSrc: "../images/nilesh.webp",
      name: "Mr. Nilesh Awasthi",
      description:
        "I have been using SMC Global Securities for a few years now and I am very happy with their services. Their webinars have added to my knowledge about the market as a whole. The recorded sessions also help as a library available at all times.",
      location: "Mumbai",
    },
    {
      imageSrc: "../images/Vinod-Kumar-Atal.webp",
      name: "Mr. Arjun Sahu",
      description:
        "I have been using their services for a few years now and I have been happy with them overall. SMC Global Securities offers a good selection of research tools. Out of those, webinars are really outstanding because they have added to my trading skills very effectively.",
      location: "Bareilly",
    },
    {
      imageSrc: "../images/deepak-modi.webp",
      name: "Mr. Deepak Modi",
      description:
        "They have low brokerage rates, a user-friendly trading platform, and excellent customer support. I am an active trader, and I appreciate SMC Global Securities' low brokerage rates and fast execution. I also like their trading platform, which is very easy to use.",
      location: "Jaipur",
    },
  ];
  const isMobile = window.innerWidth <= 540;
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings = {
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false, // Set this to false
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
    beforeChange: (current, next) => setCurrentSlide(next),
    nextArrow: (
      <SampleNextArrow
        currentSlide={currentSlide}
        clientSpeakData={clientSpeakData}
      />
    ),
    prevArrow: (
      <SamplePrevArrow
        currentSlide={currentSlide}
        clientSpeakData={clientSpeakData}
      />
    ),
  };

  return (
    <div className="content">
      <Slider {...sliderSettings}>
        {clientSpeakData.map((item, index) => {
          return (
            <div key={index}>
              <div className="reviewbox">
                <div className="commentcircle">
                  <div className="comment">
                    <div className="profile">
                      <div
                        className="climg"
                        style={{ marginBottom: isMobile ? "-25px" : 0 }}
                      >
                        <img
                          src={item.imageSrc}
                          className="responsive"
                          style={{ height: 80, width: 80 }}
                          alt=""
                        />
                      </div>
                      <div className="rating">
                        <ul className="d-flex">
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="commentcent">
                      <p>{item.description}</p>
                    </div>
                    <div className="name">
                      <h4>{item.name}</h4>
                      <h5>{item.location}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

// Custom arrow components
function SampleNextArrow(props) {
  const { className, style, onClick, currentSlide, clientSpeakData } = props;
  return (
    <div
      className={className}
      style={{
        ...style,

        display: currentSlide === clientSpeakData.length - 3 ? "none" : "block",
      }}
      onClick={onClick}
    >
      <img src={right} alt="right" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick, currentSlide, hotelCards } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: currentSlide === 0 ? "none" : "block",
      }}
      onClick={onClick}
    >
      <img src={left} alt="left" />
    </div>
  );
}

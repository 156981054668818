import React, { useRef, useState } from "react";
import Footer from "../../../components/Footer";
import Banner from "../../../images/fundamental.webp";
import { Excellence } from "../../../components/Excellence";
import { Link, useParams } from "react-router-dom";
import { Form } from "../../../components/Form";
import Img01 from "../../../images/techicon01.webp";
import Img02 from "../../../images/techicon02.webp";
import Img03 from "../../../images/techicon03.webp";
import Img04 from "../../../images/techicon04.webp";
import Creative from "../../../images/fundamental-img.webp";
import { Context } from "../../../context";
import { getReportByCategory } from "../../../utils/APICollection";
import Carousel from "../../../components/Carousel";
import ResearchSlider from "../../../components/common/ResearchSlider";
import Header from "../../../components/Header";
import Loader from "../../../components/Loader";
import SEO from "../../../components/common/SEO";
import { SEO_URL } from "../../../utils/constants";
import NumberCount from "../fundaMentalResearch/NumberCount";
import ExpertSlider from "../fundaMentalResearch/ExpertSlider";
import Faq from "./Faq";

const TechnicalResearch = () => {
  const { setResearchData, loading, setLoading } = React.useContext(Context);
  const { id } = useParams();
  const researchCatData = localStorage.getItem("researchData");
  const finalCategory = JSON.parse(researchCatData);

  const getAllCategory = async () => {
    const filterCat = finalCategory?.filter(
      (item) => item.category_name == "Technical Research"
    );
    const { data } = await getReportByCategory(
      filterCat[0]?.research_category_master_id
    );
    if (data?.status == "success") {
      setResearchData(data?.data);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getAllCategory();
  }, []);

  const isMobile = window.innerWidth <= 540;
  const image = "../../../images/SMCNEWLOGO.webp";
  const url = `${SEO_URL}/research/technical-research`;
  const title = "Technical Research Report - SMC Global Securities";
  const description =
    "Get a technical research report of various companies by SMC Global. Make your trade on the basis of our technical research reports.";
  return (
    <>
      <Header />
      <SEO
        title={title}
        description={description}
        name="Company name."
        type="article"
        url={url}
        image={image}
      />
      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/Research">Research</Link>
                </li>
                <li>Technical Research</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1 className="p-0">Accurate. reports.</h1>
                </div>
                <div className="bannergt">
                  <div className="mobileno">
                    <img src={Banner} alt="Hassle-free investing" />
                  </div>
                </div>
                {isMobile ? (
                  <div
                    style={{
                      marginTop: "-60px",
                      width: "100%",
                    }}
                  >
                    <Form catID="Research" />
                  </div>
                ) : (
                  <Form catID="Research" />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <NumberCount />
          <div
            className="webcent p-100 graybg"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="col-lg-9 d-flex gap-5 mx-auto align-items-center flex-wrap text-cont-center">
                    <div className="col-lg-5">
                      <img src={Creative} alt="" />
                    </div>
                    <div className="col-lg-6">
                      <div className="title mb-2 justify-content-start text-start">
                        <h3 className="lh-1">Technical analysis report</h3>
                      </div>
                      <p>
                        SMC research team consists of 15 SEBI registered analyst
                        who excel in specific area of research within the three
                        broad segment of research (Equity, Commodity &
                        Derivatives). When it comes to trading, there are
                        several recommendations you should follow, including
                        preparing for the unexpected and avoiding big risks.
                        Every trader can reach temporary success but being
                        profitable in the long run requires a lot of knowledge.
                        Gaining insight from SMC Research can be of great
                        assistance, to learn what it takes to be a successful
                        trader and what practices are best avoided. SMC Research
                        Team assist its clients with dedication and integrity,
                        meet their expectations and build enduring relationship.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="partnerfeature">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>Benefits</h3>
                    </div>
                    <div className="partnerlist w-100 m-0">
                      <ul className="flex-nowrap gap-3 mb-3">
                        <li>
                          <span>
                            <img src={Img01} alt="" />
                          </span>
                          <p>
                            <strong>Insights on volume & volatility</strong>
                          </p>
                        </li>
                        <li>
                          <span>
                            <img src={Img02} alt="" />
                          </span>
                          <p>
                            <strong>
                              Entry and exit points with the help of charts and
                              patterns
                            </strong>
                          </p>
                        </li>
                        <li>
                          <span>
                            <img src={Img03} alt="" />
                          </span>
                          <p>
                            <strong>
                              Realtime information on stock price variation
                            </strong>
                          </p>
                        </li>
                        <li>
                          <span>
                            <img src={Img04} alt="" />
                          </span>
                          <p>
                            <strong>
                              Directional view on buy/sell decisions through
                              patterns
                            </strong>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3 className="lh-1">Technical Analysis Reports</h3>
                  </div>
                  <div className="reports d-flex justify-content-between col-12 pull-left gap-2 flex-wrap">
                    <ResearchSlider setLoading={setLoading} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Excellence />
          <ExpertSlider />
          <div
            className="webcent pt-0"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="minicontainer">
                    <div className="title">
                      <h3>Creating proud investors.</h3>
                    </div>
                    <Carousel />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Faq />
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

export default TechnicalResearch;

import React from 'react'

export const Updown = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="#4cafa7"
    className="mf11UnfoldMoreIcon"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M12 5.83 15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z" />
  </svg>
  )
}

import { useState } from "react";
const StockCompleteDetails = ({ bioData, text }) => {
    const [showAll, setShowAll] = useState(false);
    const toggleRows = () => {
        setShowAll(!showAll);
        // setShowAll(false);
    };
    return (
        <div className="webcent">
            <div className="returncalulator">
                <div className="calheading">
                    <h3>{text} {bioData?.LNAME}</h3>
                    <p>
                        {bioData?.MEMO?.substring(0, 285)}{" "}
                        {showAll && (
                            <>
                                {" "}
                                {bioData?.MEMO?.substring(
                                    286,
                                    bioData?.MEMO?.length
                                )}{" "}
                            </>
                        )}
                        {showAll ? (
                            <button
                                className="showallcont"
                                onClick={toggleRows}
                            >
                                Read Less
                            </button>
                        ) : (
                            <button
                                className="showallcont"
                                onClick={toggleRows}
                            >
                                Read More
                            </button>
                        )}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default StockCompleteDetails;
import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />

      <div className="container">
        <div className="bredcrum">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Privacy Policy</li>
          </ul>
        </div>
        <div
          className="webcent p-100 pt-0"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-duration="600"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"
        >
          <div className="minicontainer">
            <div className="ipodetail">
              <h3 style={{ color: "#4cafa7", fontWeight: 600, marginTop: 20 }}>
                Privacy Policy
              </h3>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Respecting your privacy and the law
              </h6>
              <p style={{ fontSize: "medium" }}>
                The privacy of your personal information has always been
                important to us at SMC. We are committed to respecting your
                right to privacy and protecting your personal information
                against unauthorized disclosure. Our staff is trained to respect
                your privacy in accordance with our standards, policies and
                procedures.
              </p>
              <h6 style={{ fontWeight: 600, color: "#023b7e" }}>
                Collecting your personal information
              </h6>
              <p style={{ fontSize: "medium" }}>
                If you are acquiring or have acquired a product or service from
                SMC, it will collect and hold your personal information for the
                purposes of:
              </p>
              <p style={{ fontSize: "medium" }}>
                <li>
                  providing you with the relevant product or service (including
                  assessing your application and identifying you)
                </li>
                <li>managing and administering the product or service</li>
                <li>
                  protecting against fraud where it is a product or service.
                </li>
              </p>
              <p style={{ fontSize: "medium" }}>
                SMC may also collect your personal information for the purpose
                of letting you know about products or services from SMC that
                might better serve your needs or promotions or other
                opportunities in which you may be interested.
              </p>

              <p style={{ fontSize: "medium" }}>
                The information collected may include your name, postal or email
                address, date of birth, financial details, PAN number or other
                information SMC is required by law to collect or it otherwise
                considers necessary.
              </p>
              <p style={{ fontSize: "medium" }}>
                We will, if it is reasonable or practicable to do so, collect
                your latest personal information from you request your regular
                updates. This may happen when you fill out a product or service
                application or an administrative form (e.g., a change of address
                form) or when you give us personal information over the
                telephone or counter, or through our website.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Using and disclosing your personal information
              </h6>
              <p style={{ fontSize: "medium" }}>
                In line with modern business practices common to many financial
                institutions and to meet your specific needs (such as where you
                have a financial adviser) we may disclose your personal
                information to the organisations described below:
              </p>
              <p style={{ fontSize: "medium" }}>
                <li>
                  involved in providing, managing or administering your product
                  or service such as third party suppliers, other Group
                  organisations, loyalty and affinity program partners,
                  printers, posting services, call centers and our advisers
                </li>
                <li>
                  Group organizations/Subsidiaries who wish to tell you about
                  their products or services that might better serve your needs
                  or promotions or other opportunities, and their related
                  service providers
                </li>
                <li>
                  involved in maintaining, reviewing and developing our business
                  systems, procedures and infrastructure including testing or
                  upgrading our computer systems
                </li>
                <li>involved in a corporate re-organization</li>
                <li>
                  involved in a transfer of all or part of the assets or
                  business of a Group organization
                </li>
                <li>involved in product planning and development</li>
                <li>
                  which are your representatives including your legal advisers
                  and bankers
                </li>
                <li>
                  in compliance of or as required or authorized by law or
                  regulations, for example, to government or regulatory bodies
                  for purposes related to public health or safety, prevention or
                  detection of unlawful or suspicious activities or to protect
                  public revenue.
                </li>
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Keeping your personal information accurate and up-to-date
              </h6>
              <p style={{ fontSize: "medium" }}>
                Our aim is to make sure that the personal information we
                collect, use or disclose is accurate, complete and up-to-date.
                This is the legal requirement and we can provide you better
                service. If you believe your personal information is not
                accurate, complete or up to date, please contact us for
                updating/correction. We solicit your co-operation in updating
                your correct information.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Protecting your personal information
              </h6>
              <p style={{ fontSize: "medium" }}>
                We store information in different ways, including in paper and
                electronic form. The security of your personal information is
                important to us and we take reasonable steps to protect it from
                misuse, loss, unauthorized access, modification or disclosure.
                Some of the ways we do this are:
              </p>
              <p style={{ fontSize: "medium" }}>
                <li>document storage security policies</li>
                <li>security measures for systems access</li>
                <li>
                  allowing access to personal information only where the
                  individual seeking access has satisfied our identification
                  requirements
                </li>
                <li>
                  access to staff on need to know basis and under
                  confidentiality obligation
                </li>
                <li>access control for our buildings</li>
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Your Privacy on the Internet
              </h6>
              <p style={{ fontSize: "medium" }}>
                We take care to ensure that the personal information you provide
                on our websites is protected. For example, our websites have
                electronic security systems in place, including the use of
                firewalls and data encryption. User identifiers, passwords or
                other access codes may also be used to control access to your
                personal information.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Links to Other Sites
              </h6>
              <p style={{ fontSize: "medium" }}>
                We take care to ensure that the personal information you provide
                on our websites is protected. For example, our websites have
                electronic security systems in place, including the use of
                firewalls and data encryption. User identifiers, passwords or
                other access codes may also be used to control access to your
                personal information.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Third-Party Advertisement Servers
              </h6>
              <p style={{ fontSize: "medium" }}>
                You may be able to access external websites by clicking on links
                we have provided. Those other websites are not subject to our
                privacy standards, policies and procedures. You will need to
                contact or review those websites directly to ascertain their
                privacy standards, policies and procedures.
              </p>
              <p style={{ fontSize: "medium" }}>
                SMC Group engages the services of an outside advertisement
                company to display advertisement on the Website. These
                advertisements may contain cookies. While SMC Group uses cookies
                in other parts of the Web Site, cookies received with banner
                advertisements are collected by the Advertisement company, and
                SMC does not have access to this information.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>eKYC</h6>
              <p style={{ fontSize: "medium" }}>
                SMC, on website ( www.smctradeonline.com ), has introduced eKYC
                for where clients can provide their UID / Aadhar Number to
                complete their Know Your Customer (KYC) requirement. SMC will
                act as KUA The clients will be able to link their UID / Aadhar
                Number to their account by Authenticating using OTP received
                from UIDAI. The data provided by visitor will be sent to UIDAI
                or its agencies in secured manner as prescribed in the
                guidelines by UIDAI. By entering UID / Aadhar Number, the
                visitor agrees to authorize SMC to validate the UID / Aadhar
                Number with UIDAI or it’s authorized agencies. SMC will store
                the authenticated UID / Aadhar Number and demographic
                information along with client’s profile in secured manner. Data
                collected for eKYC will not be used for any profiling purpose or
                marketing purpose.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Resolving your privacy issues
              </h6>
              <p style={{ fontSize: "medium" }}>
                If you have any issues you wish to raise with SMC, or would like
                to discuss any issues about our Privacy Policy, you may do so in
                a number of ways:
              </p>
              <p style={{ fontSize: "medium" }}>
                <li>
                  You are always welcome to speak directly to our staff at
                  Customer care numbers.
                </li>
                <li>You can email your concern on info@smcindiaonline.com.</li>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;

import React from "react";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Header from "../../../components/Header";
import { Helmet } from "react-helmet";
import SEO from "../../../components/common/SEO";
import SMCNEWLOGO from "../../../images/SMCNEWLOGO.webp";
import { SEO_URL } from "../../../utils/constants";
import { seoData } from "../../../utils/Data";

const InvestHolidaysMuhuratDates = () => {
  return (
    <>
      <Header />
      <SEO
        title={seoData.muhuratDates.title}
        description={seoData.muhuratDates.description}
        name="Company name."
        type="article"
        url={seoData.muhuratDates.url}
        image={seoData.muhuratDates.image}
      />

      <section id="banner">
        <div className="banner internal mpadding">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/invest">Invest</Link>
                </li>
                <li>Muhurat dates</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="col-lg-10 m-auto">
                    <div className="title">
                      <h3>Muhurat Trading session 2024</h3>
                    </div>
                    <div className="ipostable holidays">
                      <div className="tablist gap-sm-4 d-flex flex-wrap">
                        <div className="ipostn p-0">
                          <div className="ipotitle">
                            <h4>Muhurat Trading</h4>
                          </div>
                          <div className="ipotable defaulttable">
                            {/* <table className="table">
                              <thead>
                                <tr>
                                  <th>Event</th>
                                  <th>Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    Pre-open session
                                  </td>
                                  <td>26:00 PM – 6:08 PM IST</td>
                                </tr>
                                <tr>
                                  <td>
                                    <ul>
                                      <li>Muhurat Trading</li>
                                    </ul>
                                  </td>
                                  <td>6:15 PM – 7:15 PM IST</td>
                                </tr>

                                <tr>
                                  <td></td>
                                </tr>
                              </tbody>{" "}
                            </table> */}
                            <p>
                              Muhurat trading is a special trading session that
                              takes place on the occasion of Diwali, the
                              festival of lights, in India. During Muhurat
                              trading, the stock exchanges in India open for a
                              limited duration, usually an hour, allowing
                              traders and investors to conduct buy and sell
                              transactions in the equity market. In 2024,
                              Muhurat trading will take place on “01-November,
                              2024”. Pre-open session goes on from 6:00 PM to
                              6:08 PM. After this, there is a continuous trading
                              session that begins at 6:15 PM and finishes at
                              7:15 PM.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Frequently Asked Questions</h3>
                  </div>
                  <div className="faqcont">
                    <Accordion allowZeroExpanded="true">
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What is Muhurat Trading?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              It is a special one hour trading that is done on
                              the day of Diwali every year. Indian stock markets
                              open for only one hour on Diwali day.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Which stock exchanges in India participate in
                            Muhurat Trading?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Both NSE and BSE Participate in Muhurat Trading.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Who can participate in Muhurat Trading?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Anyone with an trading account with a registered
                              stockbroker can participate in Muhurat Trading.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Is intraday allowed in Muhurat Trading?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              Yes, you can carry out intraday trades during
                              Muhurat Trading, but plan ahead all your intraday
                              trades will be squared off 15 minutes prior to
                              market closing.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Are there any specific rituals or customs associated
                            with Muhurat Trading?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              While Muhurat Trading doesn't involve elaborate
                              rituals, many traders and investors perform a
                              symbolic puja or worship of their trading books
                              and equipment before the session begins. This act
                              is a way to seek divine blessings for profitable
                              and successful trading in the upcoming year.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                    <p className="mt-3 d-block col-12 pull-left">
                      *Please note that the Muhurat dates may change from year
                      to year and it is recommended to check for the most
                      up-to-date information from reliable sources before making
                      any trading decisions or planning your investment
                      activities. Always consult with a financial advisor for
                      personalised investment advice.
                    </p>
                    <br />
                    <p>Happy investing!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default InvestHolidaysMuhuratDates;

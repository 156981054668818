import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const DematFaq = () => {
  return (
    <>
      <div
        className="webcent"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Frequently Asked Questions</h3>
              </div>
              <div className="faqcont">
                <Accordion allowZeroExpanded="true">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is a Demat Account and why do you need it?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          A Demat account, short for dematerialization account,
                          is an electronic account that digitally holds your
                          shares and securities. It is necessary for anyone who
                          wants to trade shares or other securities in the
                          Indian stock market.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the documents required for opening a Demat
                        Account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The documents required for opening a Demat account in
                          India are given below.
                        </p>

                        <ul>
                          <li>
                            Proof of identity (POI): This could be a passport,
                            driver's license, Aadhaar card, or any other
                            government-issued ID with your photograph.
                          </li>
                          <li>
                            Proof of address (POA): This could be a utility
                            bill, bank statement, or any other document that
                            shows your current address.
                          </li>
                          <li>
                            PAN card: Your PAN card is a unique identification
                            number issued by the Income Tax Department. It is
                            mandatory to have a PAN card to open a Demat
                            account.
                          </li>
                          <li>
                            Signature: You must provide a scanned copy of your
                            signature on white paper.
                          </li>
                          <li>
                            Photograph: You must provide a scanned copy of your
                            recent passport-size photograph.
                          </li>
                        </ul>
                        <p>
                          In addition to these documents, some Demat account
                          providers may ask for additional documents, such as
                          proof of income or bank statements.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the difference between Demat and Trading
                        Account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          A Demat account is an electronic account that
                          digitally holds your shares and securities. A Trading
                          account is an account that you use to buy and sell
                          shares and securities. The main difference between a
                          Demat account and a Trading account is that a Demat
                          account holds your shares and securities digitally. In
                          contrast, a Trading account allows you to buy and sell
                          shares and securities.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I open a Demat and Trading account online?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Yes, you can open a Demat and Trading account online.
                          Many online brokers offer Demat and Trading accounts.
                          The process of opening an online Demat and Trading
                          account is very simple and straightforward.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is Demat account free?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Yes, Demat account opening is free of cost with a
                          margin deposit of only ₹5,000.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the minimum balance for Demat Account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          There is no minimum balance requirement for a Demat
                          account in India. You can open a Demat account with
                          zero balance. However, some Demat account providers
                          may charge a monthly maintenance fee, even if your
                          account balance is zero. A Demat account's annual
                          maintenance charge (AMC) varies from one Demat account
                          provider to another.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What happens if I don’t use my Demat account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          If you don't use your Demat account, it will become
                          dormant after a certain time. The dormancy period
                          varies from one Demat account provider to another,
                          typically around 12-24 months. Once your Demat account
                          becomes dormant, you cannot make any transactions in
                          the account. If you want to reactivate your Demat
                          account, you will need to submit a fresh KYC (Know
                          Your Customer) document.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can an NRI open a Demat account in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Yes, an NRI can open a Demat account in India.
                          However, there are some restrictions that NRIs need to
                          follow. NRIs can open two types of Demat accounts.
                          <ul>
                            <li>
                              • Repatriable Demat account: This type of account
                              allows NRIs to buy and sell shares and securities
                              and repatriate the proceeds back to their home
                              country.
                            </li>
                            <li>
                              • Non-repatriable Demat account: This type of
                              account allows NRIs to buy and sell shares and
                              securities, but the proceeds cannot be repatriated
                              back to their home country.
                            </li>
                          </ul>
                          <p>
                            Before opening a Demat account, NRIs must also get a
                            Portfolio Investment Scheme (PIS) approval from the
                            Reserve Bank of India (RBI). The PIS approval is a
                            one-time process, allowing NRIs to invest in Indian
                            securities.
                          </p>
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can Demat account be opened in Joint names?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Yes, a Demat account can be opened in joint names. A
                          joint Demat account allows two or more people to own
                          and operate the account. The account holders can be
                          individuals, HUFs, or companies.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is nomination facility available in Demat Account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Yes, the nomination facility is available in the Demat
                          account. A nomination is a document that allows you to
                          appoint one or more persons to receive your shares and
                          securities in the event of your death. The nomination
                          facility is available for all individual Demat
                          accounts. All respective Demat account holders should
                          avail of this facility. SEBI has mandated that if an
                          individual BO decides not to nominate, the account
                          holder(s) should give a written and signed
                          declaration.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DematFaq;

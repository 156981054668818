import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

export const ReturnCalculator = ({ amount, setAmount }) => {

  const [runningNumber, setRunningNumber] = useState(0);

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      setAmount(newValue);
    }
  };

  const valueLabelFormat = (value) => {
    return `₹${value.toLocaleString('en-IN')}`;
  };
  return (
    <>
      <Box>
        <Typography id="non-linear-slider" gutterBottom>
          <p className='text-black mb-3 fw-bold'>{valueLabelFormat(amount)} per month</p>
        </Typography>
        <Slider
          value={amount}
          min={1000}
          step={500}
          max={50000}
          valueLabelFormat={valueLabelFormat}
          onChange={handleChange}
          valueLabelDisplay="off"
          aria-labelledby="non-linear-slider"
          sx={{
            color: '#ecedef',
            '& .MuiSlider-track': {
              color: '#44b6a7',
            },
            '& .MuiSlider-thumb': {
              width: 21,
              height: 21,
              color: '#fff'
            },
          }}
        />
      </Box>
    </>
  )
}

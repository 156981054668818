import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

export const ShareholdingDonut = ({ data }) => {
  if (data === undefined) {
    return <h6>Data Not Available</h6>;
  }
  const { co_code, YRC, ...rest } = data || {};

  const state = {
    // series: [72.89, 4.87, 12.93, 9.04, 0.27],
    series: Object.values(rest).map((item) => Number(item.toFixed(2))),
    options: {
      chart: {
        type: "donut",
        toolbar: {
          show: false,
        },
      },
      colors: ["#207bf0", "#008000", "#0c48a1", "#d58622"],
      // labels: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
      labels: Object.keys(rest),
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(2) + "%";
        },
        style: {
          fontSize: "14px",
          fontWeight: "100",
        },
      },
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "10%",
            labels: {
              show: false,
            },
          },
        },
      },
      stroke: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
              height: "300px",
            },
          },
        },
      ],
    },
  };

  // const [state, setState] = useState({
  //   series: [72.89, 4.87, 12.93, 9.04, 0.27],
  //   // series: Object.values(filteredData[0]),
  //   options: {
  //     chart: {
  //       type: 'donut',
  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //     colors: ['#207bf0', '#008000', '#0c48a1', '#d58622'],
  //     // labels: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
  //     labels: Object.keys(rest),
  //     dataLabels: {
  //       enabled: true,
  //       formatter: function (val) {
  //         return val.toFixed(2) + '%';
  //       },
  //       style: {
  //         fontSize: '14px',
  //         fontWeight: '100',
  //       },
  //     },
  //     legend: {
  //       show: false,
  //     },
  //     plotOptions: {
  //       pie: {
  //         donut: {
  //           size: '10%',
  //           labels: {
  //             show: false,
  //           },
  //         },
  //       },
  //     },
  //     stroke: {
  //       show: false,
  //     },
  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             width: '100%',
  //             height: '300px',
  //           },
  //         },
  //       },
  //     ],
  //   },
  // });

  return (
    <div className="apexdonutchart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="donut"
        height={350}
        width={350}
      />
    </div>
  );
};

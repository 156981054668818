import React from "react";
import Chart from "react-apexcharts";

export const PieChart = ({ assetAllocationDetailsData, fundSize }) => {
  if (assetAllocationDetailsData.length < 1) {
    return <h4>No Data Found</h4>;
  }

  const series = assetAllocationDetailsData.map((asset) =>
    Number(Number(asset?.total_percentage).toFixed(2))
  );
  const options = {
    chart: {
      type: "donut",
      fontFamily: "AvertaStd, Arial, sans-serif",
      position: "right",
    },
    labels: assetAllocationDetailsData.map(
      (asset) =>
        asset?.asset_class + "  " + Number(asset?.total_percentage).toFixed(2)
    ),
    colors: ["#48ae9e", "#305398", "#00FFFF", "#90EE90"],
    legend: {
      fontSize: "18px",
      position: "left",
      offsetY: 100,
      offsetX: -20,
      margin: "10px",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 1480,
        options: {
          chart: {
            width: "100%",
            height: "300",
          },
          legend: {
            fontSize: "15px",
            offsetY: 80,
          },
          plotOptions: {
            pie: {
              donut: {
                size: "60%"
              },
            },
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
            height: "500px"
          },
          legend: {
            position: "bottom",
            fontSize: "14px",
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            showAlways: true,
            show: true,
            name: {
              formatter: () => "",
            },
            value: {
              formatter: () => `₹${fundSize}Cr` || "",
              offsetY: -10,
              fontFamily: "AvertaStd, Arial, sans-serif",
              fontWeight: "normal",
              color: "#000",
            },
            total: {
              show: true,
              showAlways: true,
              label: "Umsatz",
              color: "#7ebd0b",
              formatter: function () {
                return `₹${fundSize}Cr` || "";
              },
            },
          },
        },
      },
    },
  };
  return (
    <div className="equitypiechart">
      <Chart
        options={options}
        series={series}
        type="donut"
        className="donutchart2"
        height={"300"}
      />
    </div>
  );
};

import React, { useContext, useEffect } from "react";
import { Context } from "../../context";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { leadApi, sendEkycOtpApi } from "../../utils/APICollection";
import { CAPTCHA_KEY, EKYC_CAPTCHA_KEY } from "../../utils/constants";
import { loadReCaptchaScript, loadReCaptchaScript1 } from "./FormattedDate";

const SingleFieldForm = ({ catID }) => {
  const {
    setIsDemat,
    name,
    num,
    setCategoryID,
    setNum,
    ipAddress,
    email,
    city,
    state,
    setUrlData,
    setIPAddress,
    loading,
    setLoading,
    successMessage,
    setSuccessMessage,
    deviceID,
    setDeviceID,
    setLeadId,
    source,
    setSource,
  } = useContext(Context);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("refcode");
  const [mobileError, setMobileError] = React.useState();

  const handleNumChange = (e) => {
    setNum(e.target.value);
    setMobileError("");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);

    let mobErr = "";
    if (num?.toString().length !== 10) {
      mobErr = "Please fill out this field";
      setLoading(false);
    }
    if (mobErr) {
      setMobileError(mobErr);
    } else {
      try {
        getIP();
        setCategoryID(catID);
        setUrlData({
          ref: searchParams.get("utm_refcode"),
          utm_source: searchParams.get("utm_source"),
          medium: searchParams.get("utm_medium"),
          campaign: searchParams.get("utm_campaign"),
        });
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(String(CAPTCHA_KEY), { action: "submit" })
            .then(async (token) => {
              const { data: data1 } = await leadApi({
                mobile_no: num,
                captchaToken: token,
                leadInfo: [
                  {
                    user_name: name ? name : "",
                    contact_number: num,
                    email: email ? email : "",
                    city: city ? city : "",
                    state: state ? state : "",
                    category_code: "DEMAT",
                    country: "",
                    product: "DEMAT",
                    source: "Smc Website | DEMAT",
                    ref: searchParams.get("utm_refcode"),
                    utm_source: searchParams.get("utm_source"),
                    medium: searchParams.get("utm_medium"),
                    campaign: searchParams.get("utm_campaign"),
                    ip: ipAddress,
                    device: deviceID,
                    status: "Otp sent",
                    client_id: "",
                    sub_query: "",
                    existing_customer: "",
                    explain_query: "",
                  },
                ],
              });
              if (data1) {
                setLeadId(String(data1?.data[0]));
                window.grecaptcha.ready(() => {
                  window.grecaptcha
                    .execute(String(EKYC_CAPTCHA_KEY), { action: "submit" })
                    .then(async (token) => {
                      const data = await sendEkycOtpApi({
                        mobile_number: num,
                        source: "web",
                        partner_id: "smc",
                        device_code: "",
                        captcha_token: token,
                      });
                      if (data?.data?.status === "success") {
                        setLoading(false);
                        setCategoryID("DEMAT");
                        setUrlData({
                          ref: searchParams.get("utm_refcode"),
                          utm_source: searchParams.get("utm_source"),
                          medium: searchParams.get("utm_medium"),
                          campaign: searchParams.get("utm_campaign"),
                        });
                        setIsDemat(true);
                        navigate("/validateotp");
                      } else if (
                        data?.error?.response?.data?.error ===
                        "User details already exist"
                      ) {
                        setSuccessMessage("You are already registered with us");
                        setTimeout(() => {
                          setSuccessMessage("");
                        }, 2000);
                        setLoading(false);
                        setNum("");
                      } else if (
                        data?.error?.response?.data?.error ===
                        "Something went wrong"
                      ) {
                        setSuccessMessage(
                          "Uh - oh!, This was not supposed to happen. Please try after sometime"
                        );
                        setTimeout(() => {
                          setSuccessMessage("");
                        }, 2000);
                        setLoading(false);
                        setNum("");
                      } else {
                        setSuccessMessage(data?.error?.response?.data?.error);
                        setTimeout(() => {
                          setSuccessMessage("");
                        }, 2000);
                        setLoading(false);
                        setNum("");
                      }
                    });
                });
              }
            });
        });
      } catch (error) {
        setMobileError("");
        setLoading(false);
      }
    }
  };
  const getIP = async () => {
    try {
      const res = await axios.get("https://api.ipify.org?format=json");
      setIPAddress(res?.data?.ip);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let userAgent = navigator.userAgent;
    setSource("Smc Website | DEMAT");
    if (/Android/i.test(userAgent)) {
      setDeviceID("Android device.");
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setDeviceID("iOS device.");
    } else if (/Macintosh/i.test(userAgent)) {
      setDeviceID("macOS device.");
    } else if (/Windows/i.test(userAgent)) {
      setDeviceID("Windows device.");
    } else {
      setDeviceID("Unknown Device");
    }
    getIP();
    loadReCaptchaScript();
    loadReCaptchaScript1();
  }, []);
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="demataccountinput">
          <div className="demataccountfrm">
            <div className="forminputsite">
              <div className="phonemange">
                <span>
                  <strong className="text-black">+91</strong>
                </span>
              </div>
              <input
                type="Number"
                placeholder="Enter your mobile number"
                value={num}
                onChange={handleNumChange}
                style={{
                  borderColor: mobileError ? "red" : "",
                }}
                error={!!mobileError}
                helperText={mobileError}
                minLength={10}
                maxLength={10}
                //  required
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, e.target.maxLength);
                }}
                inputProps={{
                  minLength: 10,
                  maxLength: 10,
                  type: "number",
                }}
                disabled={loading}
              />
            </div>

            <button type="submit">Open Free Demat A/c</button>
          </div>
        </div>
      </form>
      {mobileError && (
        <p
          style={{
            color: "red",
            marginLeft: "5rem",
          }}
        >
          {mobileError}
        </p>
      )}
      {successMessage && (
        <p
          style={{
            color: "red",
            width: "100%",
            marginLeft: "2rem",
          }}
        >
          {successMessage}
        </p>
      )}
    </>
  );
};

export default SingleFieldForm;

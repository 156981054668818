import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { ResearchReport } from "./ResearchReport";
import TradeResearchViaTelegramFundamentalResearch from "../pages/trade/researchViaTelegram/FundametalResearch";
import TradeResearchViaTelegramTechnicalResearch from "../pages/trade/researchViaTelegram/TechnicalResearch";
import { Context } from "../context";

export const Research_Drop = ({ setActive, setIsOpen, menus }) => {
  const {
    isDemat,
    num,
    setIsDemat,
    setName,
    setNum,
    setEmail,
    setState,
    setCity,
    name,
    email,
    state,
    city,
    categoryID,
    urlData,
    ipAddress,
    showPath,
    setShowPath,
    showRoute,
    setShowRoute,
  } = useContext(Context);

  const emptyField = () => {
    setCity("");
    setEmail("");
    setName("");
    setState("");
    setNum("");
    setActive(!menus);
    setIsOpen(false);
  };
  return (
    <>
      <div className="menubox">
        {/* <div className="sitemapcol mobile-show">
          <h4>
            <NavLink to="/research" onClick={emptyField}>
              Research
            </NavLink>
          </h4>
        </div> */}
        <div className="sitemapcol">
          <h4 style={{ cursor: "context-menu" }}>Research reports</h4>
          <ResearchReport
            setActive={setActive}
            setIsOpen={setIsOpen}
            menus={menus}
          />
        </div>

        <div className="sitemapcol">
          <h4 style={{ cursor: "context-menu" }}>Tools</h4>
          <ul>
            <li>
              <NavLink
                to="https://autotrender.smcindiaonline.com:8790/"
                target="_blank"
                onClick={() => {
                  emptyField();
                }}
              >
                Autotrender
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/trade/algo-trading-platform"
                onClick={() => {
                  emptyField();
                  setShowPath("research");
                }}
              >
                Algo Trading Platform
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="sitemapcol">
          <h4 style={{ cursor: "context-menu" }}>Research</h4>
          <ul>
            <li>
              <Link to="/research/fundamental-research" onClick={emptyField}>
                Fundamental Research
              </Link>
            </li>
            <li>
              <Link to="/research/technical-research" onClick={emptyField}>
                Technical Research
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

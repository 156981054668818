import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";

const data = [
  {
    number: "01",
    title: "Clearing Members",
    description:
      "Over 328 clearing members, ensuring efficient transaction settlements and risk management.",
  },
  {
    number: "02",
    title: "Overall Clients",
    description:
      "More than 2 million clients, reflecting strong trust and a broad customer base.",
  },
  {
    number: "03",
    title: "Employees",
    description:
      "A dedicated workforce of 4250+ employees committed to delivering quality services.",
  },
  {
    number: "04",
    title: "5-Year PAT CAGR",
    description:
      "A remarkable 26.02% Compound Annual Growth Rate (CAGR) over the past five years, indicating consistent growth.",
  },
  {
    number: "05",
    title: "FPI/FDI Clients",
    description:
      "A growing base of FPI and Foreign Direct Investment clients, highlighting global engagement.",
  },
  {
    number: "06",
    title: "Network of Financial Distributors",
    description:
      "A vast network of 7,000+ financial distributors, enhancing market reach and accessibility.",
  },
  {
    number: "07",
    title: "APs and Branches",
    description:
      "Spanning through 2,500+ authorized participants (APs) and branches, enabling widespread service availability.",
  },
  {
    number: "08",
    title: "Cities Covered",
    description:
      "Present in over 486 cities, making services accessible across India.",
  },
  {
    number: "09",
    title: "Short-Term Rating",
    description:
      "Achieved a short-term rating of ICRA A1+, indicating strong credit quality.",
  },
  {
    number: "10",
    title: "Long-Term Ratings",
    description:
      "Holds long-term ratings of ICRA A (Stable) and CRISIL A (Stable), demonstrating financial stability and reliability.",
  },
  {
    number: "11",
    title: "Exchange Memberships",
    description:
      "Members of major exchanges including NSE, BSE, MCX, NCDEX, INX, NSX, and IEX, ensuring diverse trading opportunities.",
  },
];

const MilestonesGrid = () => {
  return (
    <div>
      {/* Header Section */}
      <div
        className="col-12 pull-left "
        style={{
          background: "linear-gradient(to right, #3F51B5 0%, #4DB6AC 100%)",
        }}
      >
        <div className="container">
          <Box textAlign="center" mb={4} color={"white"} pt={5}>
            <Typography variant="h4" gutterBottom>
              SMC : Remarkable Milestones
            </Typography>
            <Typography variant="subtitle1" color="white">
              Unveiling Our Operational Excellence and Market Leadership
            </Typography>
          </Box>

          {/* Grid Layout */}
          <Grid
            container
            spacing={3}
            marginBottom={5}
            justifyContent={{ xs: "center", md: "flex-start" }} // Center on mobile, left-align on larger screens
          >
            {data.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={index}
                display="flex"
                justifyContent="center" // Center the card itself on all screens
              >
                <Box
                  p={3}
                  borderRadius={2}
                  bgcolor="#FFF"
                  boxShadow={2}
                  display="flex"
                  flexDirection="column"
                  // alignItems="center" // Center content inside the box
                  height="100%"
                  // textAlign="center"
                >
                  {/* Number */}
                  <Box
                    sx={{
                      background:
                        "linear-gradient(to right, #3F51B5 0%, #4DB6AC 100%)",
                    }}
                    p={2}
                    borderRadius="50%"
                    width={30}
                    height={30}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                  >
                    <FlagIcon sx={{ color: "#FFF" }} />
                  </Box>

                  {/* Title */}
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    {item.title}
                  </Typography>

                  {/* Description */}
                  <Typography variant="body2" color="textSecondary">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default MilestonesGrid;

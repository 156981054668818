export const MAIN_API_HOST = process.env.REACT_APP_MAIN_URL;
export const TIMEOUT = process.env.TIMEOUT;
export const CMOTS_API_HOST = process.env.REACT_APP_CMOTS_API;
export const CMOTS_API_HOST_TOKEN = process.env.REACT_APP_CMOTS_API_TOKEN;
export const EKYC_API_HOST = process.env.REACT_APP_EKYC_SEND_OTP_API;
export const EKYC_API_VER_HOST = process.env.REACT_APP_EKYC_VER_OTP_API;
export const AWS_S3_DOWNLOAD_FORM_FOLDER = "download-forms";
export const AWS_S3_RESEARCH_REPORT_FOLDER = "research-reports";
export const AWS_S3_WEBINAR_FOLDER = "webinar";
export const AWS_S3_complience_doc = "complience-doc";
export const EKYC_REDIRECT_URL = process.env.REACT_APP_EKYC_REDIRECT_URL;
export const CAPTCHA_KEY = process.env.REACT_APP_SITE_KEY;
export const EKYC_CAPTCHA_KEY = process.env.REACT_APP_EKYC_SITE_KEY;
export const SEO_URL = process.env.REACT_APP_SEO_URL;
export const HOME_SEO_TITLE =
  "Online Stock Market Trading in India - SMC Global Online";
export const HOME_SEO_DESC =
  "SMC Global offers the best Online Share Trading platform in India. We provide top investment opportunities in Stocks, Foreign Exchange, Commodities, F&O, Mutual Funds, etc.";
export const X_API_KEY = process.env.REACT_APP_X_API_KEY;

import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';


export const InvestCalculor = () => {
    // State to store the current investment value
    const [value, setValue] = useState(5000);

    // Handle changes to the Slider component
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Handle changes to the TextField component
    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setValue(inputValue === '' ? '' : Number(inputValue));
    };

    return (
        <>
        <div className='col-lg-8 m-auto d-flex justify-content-center text-center'>
            <div className='rangeslidersip mt-5'>
                <div className='minvestment d-flex align-items-center mb-2'>
                    <i className='finr'></i>
                    <TextField
                        value={value}
                        disabled
                        onChange={handleInputChange}
                        type="number"
                        className='rangeman'
                        inputProps={{
                            min: 1000,
                            max: 25000,
                            step: 1,
                        }}
                    />
                    <h4 className='m-0 align-items-center d-flex'> in Microsoft Corporation</h4>
                </div>

                <Slider
                    className='textslider'
                    value={typeof value === 'number' ? value : 0}
                    onChange={handleChange}
                    min={1000}
                    max={25000}
                    step={1}
                    sx={{
                        color: '#ecedef',
                        '& .MuiSlider-track': {
                            color: '#44b6a7',
                        },
                        '& .MuiSlider-thumb': {
                            width: 40,
                            height: 40,
                            color: '#fff'
                        },
                    }}
                />

                <div className='rangesliderlabel fw-bold text-black'>
                    <span><i className='finr'></i>1</span>
                    <span><i className='finr'></i>25,000</span>
                </div>
                <div className="returncal mt-4 mb-4 w-auto m-auto float-none justify-content-center">
                    <span className="active">1 Year</span>
                    <span>3 Years</span>
                    <span>5 Years</span>
                </div>
                <div className='calculatedamount col-12 pull-left'>
                    <h4 className='position-static m-0'>You would have</h4>
                    <div className='d-flex align-items-center justify-content-center w-auto'>
                        <span><i className='finr'></i>78.47k</span>
                        <span>+213.87%</span>
                    </div>
                </div>
            </div>
            </div>
        </>
    );
};

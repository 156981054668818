import React, { forwardRef } from "react";
import { Box, Typography } from "@mui/material";

const InfoCard = forwardRef(({ title, desc, height }, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: 300,
        minHeight: height || "auto", // Sets a minimum height based on the tallest card
        py: 3,
        px: 3,
        borderRadius: "80px 10px 80px 10px",
        background: "linear-gradient(to right, #3F51B5 0%, #4DB6AC 100%)",
        boxShadow: "8px 8px 16px rgba(0, 0, 0, 0.15)",
        color: "white",
        textAlign: "center",
        border: "3px solid white",
      }}
    >
      <Typography
        variant="body1"
        fontWeight="bold"
        gutterBottom
        sx={{ fontSize: "1rem" }}
      >
        {title}
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
        {desc}
      </Typography>
    </Box>
  );
});

export default InfoCard;

import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { formatNumber } from "../../../../components/common";
import { ReturnCalculator } from "../../../../calculators/ReturnCalculator";


const MfReturnCalculator = ({ fundReturnsData }) => {
    const [amount, setAmount] = useState(5000);
    const [year, setYear] = useState(1);

    const percentageKey = {
        1: "ret_1year",
        3: "ret_3year",
        5: "ret_5year",
    };

    const totalInvestment = () => {
        return amount * 12 * year;
    };
    const lumSumCalculator = () => {
        if (fundReturnsData?.ret_1year === undefined) {
            return 0;
        }
        return (
            amount *
            Math.pow(1 + Number(fundReturnsData[percentageKey[year]]) / 100, year)
        );
    };

    const sipCalculator = () => {
        if (fundReturnsData?.ret_1year === undefined) {
            return 0;
        }
        let i = Number(fundReturnsData[percentageKey[year]]).toFixed(2) / 12 / 100;
        let n = year * 12;

        return (amount * (Math.pow(1 + i, n) - 1) * (1 + i)) / i;
    };

    const sipReturnPercentage = () => {
        return ((sipCalculator() - totalInvestment()) / totalInvestment()) * 100;
    };

    const lumReturnPercentage = () => {
        return ((lumSumCalculator() - amount) / amount) * 100;
    };

    return (
        <div className="webcent">
            <div className="returncalulator">
                <div className="calheading">
                    <h3>Return calculator</h3>
                </div>
                <div className="howtoapply">
                    <Tabs>
                        <div className="tradingben mt-0">
                            <div className="iconrow noicon">
                                <TabList>
                                    <Tab>
                                        <h4>Monthly SIP</h4>
                                    </Tab>
                                    <Tab>
                                        <h4>One-time</h4>
                                    </Tab>
                                </TabList>
                            </div>
                        </div>
                        <TabPanel>
                            <div className="ipoodlist">
                                <ReturnCalculator
                                    amount={amount}
                                    setAmount={setAmount}
                                />
                                <p className="mt-4">Over the past</p>
                                <div className="returncal">
                                    <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setYear(1)}
                                        className={year === 1 ? "active" : ""}
                                    >
                                        1 Year
                                    </span>
                                    <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setYear(3)}
                                        className={year === 3 ? "active" : ""}
                                    >
                                        3 Years
                                    </span>
                                    <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setYear(5)}
                                        className={year === 5 ? "active" : ""}
                                    >
                                        5 Years
                                    </span>
                                </div>
                                <div className="returnvalue">
                                    <p>
                                        Total investment of{" "}
                                        <span>
                                            <i className="finr"></i>
                                            {formatNumber(totalInvestment())}
                                        </span>
                                    </p>
                                    <p className="text-black">
                                        <strong>
                                            Would have become{" "}
                                            <span>
                                                <i className="finr"></i>
                                                {formatNumber(
                                                    sipCalculator().toFixed(2)
                                                )}
                                                {/* (+12.79%) */}
                                                {/* sip returns */}{" "}
                                                <span
                                                    className={
                                                        lumReturnPercentage() > 0
                                                            ? "profit"
                                                            : "loss"
                                                    }
                                                >
                                                    (
                                                    {formatNumber(
                                                        sipReturnPercentage().toFixed(2)
                                                    )}
                                                    %)
                                                </span>
                                            </span>
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="ipoodlist">
                                <ReturnCalculator
                                    amount={amount}
                                    setAmount={setAmount}
                                />
                                <p className="mt-4">Over the past</p>
                                <div className="returncal">
                                    <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setYear(1)}
                                        className={year === 1 ? "active" : ""}
                                    >
                                        1 Year
                                    </span>
                                    <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setYear(3)}
                                        className={year === 3 ? "active" : ""}
                                    >
                                        3 Years
                                    </span>
                                    <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setYear(5)}
                                        className={year === 5 ? "active" : ""}
                                    >
                                        5 Years
                                    </span>
                                </div>
                                <div className="returnvalue">
                                    <p>
                                        Total investment of{" "}
                                        <span>
                                            <i className="finr"></i>
                                            {formatNumber(amount)}
                                        </span>
                                    </p>
                                    <p className="text-black">
                                        <strong>
                                            Would have become{" "}
                                            <span>
                                                <i className="finr"></i>
                                                {formatNumber(
                                                    lumSumCalculator().toFixed(2)
                                                )}
                                                {/* (+12.79%) */}{" "}
                                                <span
                                                    className={
                                                        lumReturnPercentage() > 0
                                                            ? "profit"
                                                            : "loss"
                                                    }
                                                >
                                                    {" "}
                                                    (
                                                    {formatNumber(
                                                        lumReturnPercentage().toFixed(2)
                                                    )}
                                                    %)
                                                </span>
                                            </span>
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default MfReturnCalculator;
import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import StockIncomeStatements from "./StockIncomeStatements";
import StockBalanceSheet from "./StockBalanceSheet";
import StockCashFlow from "./StockCashFlow";
const data = ["Income Statement", "Balance Sheet", "Cash Flow"];
const StockFinance = () => {
    const [type, setType] = useState("Income Statement");
    const [financeType, setFinanceType] = useState("C");

    return (
        <div className="webcent">
            {/* <div className="calheading"> */}
            <div className="stockfinanceheading">
                <h3>
                    Financials
                </h3>
                <div className="stockfinancebuttons">
                    <span
                        className={financeType === "C" ? "stockfinancebuttondesign" : "unselectedstockfinancebuttondesign"}
                        onClick={() => setFinanceType("C")}
                    >
                        Consolidated
                    </span>
                    <span
                        className={financeType === "S" ? "stockfinancebuttondesign" : "unselectedstockfinancebuttondesign"}
                        onClick={() => setFinanceType("S")}
                    >
                        Standalone
                    </span>
                </div>
            </div>
            <div className="returncalulator">
                <div className="howtoapply mt-3">
                    <Tabs>
                        <div className="tradingben m-0">
                            <div className="iconrow noicon border-0" style={{ paddingBottom: "10px", width: "auto" }}>
                                <TabList style={{ gap: "10px" }}>
                                    {
                                        data?.map(
                                            (item, index) => (
                                                <Tab

                                                    onClick={() => setType(item)}
                                                >
                                                    <h4>
                                                        {item}
                                                    </h4>
                                                </Tab>
                                            )
                                        )}
                                </TabList>
                            </div>
                        </div>
                    </Tabs>
                </div>
            </div>
            {type === "Income Statement" && <StockIncomeStatements financeType={financeType} />}
            {type === "Balance Sheet" && <StockBalanceSheet financeType={financeType} />}
            {type === "Cash Flow" && <StockCashFlow financeType={financeType} />}
        </div>
    )
}

export default StockFinance;
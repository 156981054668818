import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
const IpoFaq = () => {
    return (
        <div className="webcent">
            <div className="title">
                <h3>Frequently Asked Questions</h3>
            </div>
            <div className="faqcont">
                <Accordion allowZeroExpanded="true">
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What is an IPO investment?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="accordiancont">
                                <p>
                                    An IPO investment is an investment in the
                                    shares of a company that is going public
                                    for the first time. IPOs can be a risky
                                    investment, but they can also be very
                                    profitable. An IPO's success depends on
                                    several factors, including the company's
                                    financial performance, the overall market
                                    conditions, and the demand for the shares.
                                </p>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What does IPO mean?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="accordiancont">
                                <p>
                                    When a company offers its shares for the
                                    first time to the public, it is released
                                    for Initial Public Offering (IPO), and the
                                    stock gets listed on the stock exchange,
                                    i.e., BSE or NSE. This allows the company
                                    to raise capital from a wider pool of
                                    investors and allows its shareholders to
                                    sell their shares on the open market.
                                </p>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What does upcoming IPOs mean?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="accordiancont">
                                <p>
                                    Upcoming IPOs refer to initial public
                                    offerings that are scheduled to take place
                                    in the near future. These IPOs are
                                    typically announced by the companies
                                    involved a few weeks or months in advance,
                                    allowing investors to buy shares in the
                                    company before it goes public.
                                </p>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What does recently listed IPOs mean?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="accordiancont">
                                <p>
                                    Recently Listed IPOs refer to stocks
                                    recently listed on a stock exchange. These
                                    IPOs are typically still in the early
                                    stages of their public life and may be
                                    more volatile than more established
                                    stocks.
                                </p>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What is issue size?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="accordiancont">
                                <p>
                                    Issue size is the total number of shares a
                                    company offers to the public in an initial
                                    public offering (IPO). It is calculated by
                                    multiplying the number of shares offered
                                    by the issue price per share.
                                </p>
                                <p>
                                    The issue size is a crucial factor in
                                    determining the success of an IPO. A large
                                    issue size can attract more investors and
                                    generate more interest in the company.
                                    However, a large issue size can also
                                    dilute the ownership of existing
                                    shareholders and make it more difficult
                                    for the company to control its stock
                                    price.
                                </p>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What is Price Band?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="accordiancont">
                                <p>
                                    A price band is a range of prices within
                                    which a company can sell its shares in an
                                    initial public offering (IPO). The company
                                    and its investment bankers typically set
                                    the price band, and it is based on several
                                    factors, including the company's financial
                                    performance, the overall market
                                    conditions, and the demand for the shares.
                                    The price band is important because it
                                    gives investors a range of prices to
                                    consider when bidding for shares in the
                                    IPO.
                                </p>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What is DRHP?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="accordiancont">
                                <p>
                                    DRHP stands for Draft Red Herring
                                    Prospectus. It is a document filed with
                                    the Securities and Exchange Board of India
                                    (SEBI) by a company planning to raise
                                    capital through an initial public offering
                                    (IPO). The DRHP provides investors with
                                    information about the company, such as its
                                    financial performance, management team,
                                    and business plan.
                                </p>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What is RHP?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="accordiancont">
                                <p>
                                    RHP stands for Red Herring Prospectus. It
                                    is a preliminary prospectus filed with
                                    SEBI by a company planning to raise
                                    capital through an initial public offering
                                    (IPO). The RHP provides investors with
                                    information about the company, such as its
                                    financial performance, management team,
                                    and business plan. The RHP is an important
                                    document for investors to read before
                                    deciding whether to invest in an IPO. It
                                    provides investors with the information
                                    they need to make an informed decision.
                                </p>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Who can invest in an IPO?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="accordiancont">
                                <p>
                                    In India, anyone can invest in an IPO,
                                    subject to specific eligibility criteria.
                                    These criteria are set by the Securities
                                    and Exchange Board of India (SEBI). You
                                    must be a resident of India and must have
                                    a valid PAN card to invest in an IPO.
                                </p>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    )
}
export default IpoFaq;
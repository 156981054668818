import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "@mui/material";
import ReferAndEarn from "../pages/commonpages/investList/ReferAndEarn";
import { Context } from "../context";

export const InvestDrop = ({
  handleMouseLeave,
  setActive,
  setIsOpen,
  menus,
}) => {
  React.useEffect(() => {
    return () => {
      window.removeEventListener("click", handleMouseLeave);
    };
  });

  const {
    isDemat,
    num,
    setIsDemat,
    setName,
    setNum,
    setEmail,
    setState,
    setCity,
    name,
    email,
    state,
    city,
    categoryID,
    urlData,
    ipAddress,
    setShowPath,
    showRoute,
    setShowRoute,
  } = useContext(Context);

  const emptyField = () => {
    setCity("");
    setEmail("");
    setName("");
    setState("");
    setNum("");
    setActive(!menus);
    setIsOpen(false);
  };
  return (
    <>
      <div className="menubox">
        <div className="sitemapcol">
          <h4 style={{ cursor: "context-menu" }}>Invest</h4>
          <ul>
            <li>
              <NavLink
                to="/invest/equities"
                onClick={() => {
                  emptyField();
                  setShowPath(false);
                }}
              >
                Equities
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/invest/mutual-funds"
                onClick={() => {
                  emptyField();
                  setShowPath(false);
                }}
              >
                Mutual Funds
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/invest/ipo"
                onClick={() => {
                  emptyField();
                  setShowPath(false);
                }}
              >
                IPOs
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/invest/us-stocks"
                onClick={() => {
                  emptyField();
                  setShowPath(false);
                }}
              >
                US Stocks
              </NavLink>
            </li> */}

            {/* <li>
              <NavLink
                to="/fixed-income"
                onClick={() => {
                  emptyField();
                  setShowPath(false);
                }}
              >
                Fixed Income
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                to="https://www.smctradeonline.com/investment-advisory-portfolios"
                target="_blank"
              >
                Intelligent Advisory Portfolio
              </NavLink>
            </li> */}

            <li>
              <NavLink
                to="/invest/refer-earn"
                onClick={() => {
                  emptyField();
                  setShowPath(false);
                }}
              >
                Refer & Earn
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="sitemapcol">
          <h4 style={{ cursor: "context-menu" }}>Holidays</h4>
          <ul>
            <li>
              <NavLink
                to="/invest/trading-holidays-2024"
                onClick={() => {
                  emptyField();
                  setShowPath(true);
                }}
              >
                Trading Holidays
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/invest/nse-holiday-2024"
                onClick={() => {
                  emptyField();
                  setShowPath(false);
                }}
              >
                NSE Holidays
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/invest/bse-holiday-2024"
                onClick={() => {
                  emptyField();
                  setShowPath(false);
                }}
              >
                BSE Holidays
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/invest/mcx-holiday-2024"
                onClick={() => {
                  emptyField();
                  setShowPath(false);
                }}
              >
                MCX Holidays
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/invest/muhurat-holiday-2024"
                onClick={() => {
                  emptyField();
                  setShowPath(false);
                }}
              >
                Muhurat Dates
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

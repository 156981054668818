import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import DematForm from "../../../components/DematForm";
import { useContext } from "react";
import { Context } from "../../../context";
import IPOCTAButton from "../../../components/common/IPOCTAButton";
import { Form } from "../../../components/Form";
import Header from "../../../components/Header";
import Loader from "../../../components/Loader";
import SEO from "../../../components/common/SEO";
import OpenIpo from "./OpenIpo";
import UpcomingIpos from "./UpcomingIpos";
import ListedIpo from "./ListedIpo";
import IpoUsp from "./IpoUsp";
import IpoFaq from "../../invest/investList/upComingIPODetais/IpoFaq";
import IpoApply from "./IpoApply";
import { SEO_URL } from "../../../utils/constants";

const Ipo = () => {
  const [sidebarTop, setSidebarTop] = useState();
  const [shouldAddClass, setShouldAddClass] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState();
  const { loading } = React.useContext(Context);
  const isMobile = window.innerWidth <= 1040;

  const { showPath } = useContext(Context);

  useEffect(() => {
    const sidebarEl = document.querySelector(".opendemat");

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const shouldAdd = scrollTop >= sidebarTop - 10;

      setShouldAddClass(shouldAdd);
    };

    if (sidebarEl) {
      const sidebarRect = sidebarEl.getBoundingClientRect();
      setSidebarWidth(sidebarRect.width);
      setSidebarTop(sidebarRect.top);

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [sidebarTop]);

  useEffect(() => {
    if (!sidebarTop) return;

    const handleScroll = () => {
      const sidebarEl = document.querySelector(".opendemat");
      const sectionEl = document.querySelector(".ipodashboard");
      const faq_cnt = document.querySelector(".faqcont");

      const iporow = document.querySelector(".ipodashboard .row");
      const scrollTop = window.scrollY;
      const shouldAdd = scrollTop >= sidebarTop - 10;

      setShouldAddClass(shouldAdd);

      if (shouldAdd) {
        sidebarEl.classList.add("is-sticky");
      } else {
        sidebarEl.classList.remove("is-sticky");
      }

      if (
        scrollTop >=
        sectionEl.offsetTop +
          sectionEl.offsetHeight -
          faq_cnt.offsetHeight / 1.8
      ) {
        sidebarEl.classList.remove("is-sticky");
        iporow.classList.add("flex-end");
      } else {
        iporow.classList.remove("flex-end");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sidebarTop]);

  const handleApply = () => {
    // window.open(`${IPO_URL}/ipo/?isin=${isinName}&exchange=${exchange}`);
    window.open(`https://ipo.smcindiaonline.org:8327/ipo`);
  };

  const title = "IPOs";
  const image = "../../images/SMCNEWLOGO.webp";
  const url = `${SEO_URL}/invest/ipo`;

  return (
    <>
      <Header />
      <SEO
        title={title}
        name="Company name."
        type="article"
        url={url}
        image={image}
      />

      <section id="banner">
        <div className="banner internal bauto">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                </li>
                {showPath ? (
                  <li style={{ color: "initial" }}>Products</li>
                ) : (
                  <li style={{ color: "initial" }}>
                    <Link to="/Invest">Invest</Link>
                  </li>
                )}
                <li>IPOs</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div className="ipoportfolio">
            <div className="container">
              <div className="web-container">
                <div className={isMobile ? "togglemobile" : "togglenew"}>
                  <div
                    className="title ipo-title"
                    style={{
                      justifyContent: isMobile && "flex-start",
                      textAlign: isMobile && "left",
                    }}
                  >
                    <h3>IPOs </h3>
                    <p>Bid for latest IPOs and create wealth for future.</p>
                  </div>
                  <div
                    className={isMobile ? "order-btn" : "order-btn-xl"}
                    // style={{ display: "flex", gap: 5, width: 500 }}
                  >
                    {/* <Switch checked={checked} onChange={handleChange} /> */}
                    <IPOCTAButton />
                    <div
                      onClick={handleApply}
                      style={{ cursor: "pointer" }}
                      className={
                        isMobile
                          ? "btnn col-3 text-center"
                          : "btnn col-12 text-center"
                      }
                    >
                      Apply Ipo
                    </div>
                    {/* <span className="sharepost"></span> */}
                  </div>
                </div>
                <div className="ipodashboard">
                  <div className="row">
                    <div className="ipoleft ipo8box">
                      <div className="ipospace">
                        <div className="webcent">
                          <div className="ipostable">
                            <div className="tablisthead">
                              <ul>
                                <li>IPO - Initial Public Offerings</li>
                              </ul>
                            </div>

                            <div className="tablist">
                              <OpenIpo />
                              <UpcomingIpos />
                              <ListedIpo />
                            </div>
                          </div>
                        </div>
                        <IpoUsp />
                        <IpoApply />
                        {/* Ipo Details may be added in future */}
                        {/* <div className="webcent">
                          <div className="aboutcompany">
                            <h5>Go Airlines</h5>
                            <p>
                              India’s popular budget airline Go Airlines aims to
                              rake up Rs.3.600 crores through its IPO. The face
                              value of each share will stand at Rs. 10. Go
                              Airlines has filed papers with SEBI for its
                              initial public offering.
                            </p>
                          </div>
                        </div> */}
                        {isMobile && (
                          <div style={{ width: "100%" }}>
                            <Form catID="IPO" />
                          </div>
                        )}
                        <IpoFaq />
                      </div>
                    </div>
                    {!isMobile && (
                      <div
                        className="ipoleft ipo4box"
                        style={{ marginTop: !isMobile && "-130px" }}
                      >
                        {/* <Form
                      catID="IPO"
                      addClass={shouldAddClass}
                      sidebarWidth={sidebarWidth}
                    /> */}
                        <DematForm
                          addclassName={shouldAddClass}
                          sidebarWidth={sidebarWidth}
                          catID={"IPO"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

export default Ipo;

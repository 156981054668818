import React, { useState } from "react";
import { Stack } from "@mui/system";
import Right from "../images/otp-illustrations.png";
import OTP from "./OTP";
import { useContext } from "react";
import { Context } from "../context";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { Box } from "@mui/material";

const ValidateOtp = () => {
  const navigate = useNavigate();
  const { loading, num } = useContext(Context);
  React.useEffect(() => {
    if (!num) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <Header />

      <section id="banner">
        <div className="banner internal mpadding"></div>
      </section>
      <main>
        <section id="main">
          <div className="container">
            <Stack
              direction={{ md: "row", sm: "column" }}
              rowGap={8}
              justifyContent={"space-evenly"}
              sx={{ mt: { md: "0px", xl: "5vh" } }}
            >
              <Box className="otp-main" width={{ md: "50%" }}>
                <h3 style={{ color: "#405bb2", marginBottom: "30px" }}>
                  Please Enter OTP Here
                </h3>
                <OTP />
              </Box>
              <Box width={{ md: "35%" }}>
                <img src={Right} alt="otp" />
              </Box>
            </Stack>
          </div>
        </section>
      </main>
      {loading && <Loader />}
    </>
  );
};

export default ValidateOtp;

import PaginationComponent from "../../../components/common/PaginationComponent";
import { Link, Route, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
const StocksCommonTable = ({
  stockData,
  ipoLoading,
  error,
  currentPage,
  setCurrentPage,
  exchange,
}) => {
  const navigate = useNavigate();
  if (!Array.isArray(stockData)) {
    return (
      <div style={{ textAlign: "center", paddingTop: "50px" }}>
        No Data Available
      </div>
    );
  }

  const getStockDetails = (id, name, price) => {
    navigate(
      `/invest/equities/${id}/${name
        ?.toLowerCase()
        .replaceAll(" ", "-")
        .replaceAll("&", "-")
        .replaceAll("(", "-")
        .replaceAll(")", "-")}-share-price`
    );
  };
  const isMobile = window.innerWidth <= 540;
  return (
    <Box>
      <div className="ipostn">
        <div className="ipotable defaulttable">
          {ipoLoading ? (
            <ThreeDots
              height="90"
              width="90"
              radius="10"
              color="#3AA29F"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              // wrapperClassName=""
              visible={true}
            />
          ) : (Array.isArray(stockData) && stockData.length > 0) && (
            <table className="table border border-bottom-0">
              <thead>
                <tr>
                  <th scope="col">Company</th>
                  {/* <th scope="col"></th> */}
                  <th scope="col" className="d-flex pl-2">
                    Market Price{" "}
                    {/* <p className="mobile-show">
                        <Updown />
                      </p> */}
                  </th>
                  <th scope="col">52W Low</th>
                  <th scope="col">52W High</th>
                </tr>
              </thead>

              <tbody>
                {stockData
                  ?.slice((currentPage - 1) * 10, currentPage * 10)
                  ?.map((stock) => {
                    return (
                      <tr>
                        <td
                          style={{ cursor: "pointer", width: "auto" }}
                          scope="row"
                          onClick={() =>
                            getStockDetails(
                              stock?.co_code || stock?.CO_CODE.toFixed(),
                              stock?.Lname || stock?.lname,
                              stock?.close_price ||
                              stock?.Price ||
                              stock?.Close_price
                            )
                          }
                        >
                          <p style={{ margin: "5px" }}>
                            {stock?.co_name ||
                              stock?.lname ||
                              stock?.companyname}
                          </p>
                        </td>
                        <td>
                          <strong>
                            <i className="finr"></i>
                            {stock?.close_price ||
                              stock?.Price ||
                              stock?.Close_price ||
                              stock?.currprice}
                          </strong>
                          {(stock?.perchg || stock?.pChange) && (
                            <div
                              className={
                                (stock?.perchg || stock?.pChange) < 0
                                  ? "tablevalue pricedown"
                                  : "tablevalue priceup"
                              }
                            >
                              {stock?.netchg > 0
                                ? "+" + stock?.netchg?.toFixed(2)
                                : stock?.netchg?.toFixed(2) ||
                                stock?.pricechange.toFixed(2) ||
                                "NA"}
                              (
                              {(stock?.perchg || stock?.pChange) > 0
                                ? stock?.perchg?.toFixed(2) ||
                                stock?.pChange?.toFixed(2)
                                : JSON.stringify(stock?.perchg)?.substring(
                                  1,
                                  5
                                ) ||
                                JSON.stringify(stock?.pChange)?.substring(
                                  1,
                                  5
                                )}
                              %)
                            </div>
                          )}
                        </td>
                        <td>
                          <strong>
                            <i className="finr"></i>
                            {stock?.["52WeekLow"] || stock?.latlow}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <i className="finr"></i>
                            {stock?.["52WeekHigh"] || stock?.lathigh}
                          </strong>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {stockData.length > 0 && (
        <PaginationComponent
          data={stockData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </Box>
  );
};

export default StocksCommonTable;

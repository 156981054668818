import React, { useContext } from "react";
import Footer from "../../../components/Footer";
import Banner from "../../../images/us-stock-banner.webp";
import { Link } from "react-router-dom";
import { Form } from "../../../components/Form";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Logo2 from "../../../images/tata.webp";
import Slider from "react-slick";
import Img01 from "../../../images/TDOC@2x.jpg";
import Img02 from "../../../images/XPEV@2x.png";
import Img03 from "../../../images/WAL@2x.png";
import Img04 from "../../../images/RPM@2x.png";
import Img05 from "../../../images/NIO@2x.png";
import Img06 from "../../../images/UNP@2x.png";
import Img07 from "../../../images/DOMA@2x.png";
import Img08 from "../../../images/TENB@2x.jpg";
import Img09 from "../../../images/LIVN@2x.png";
import Img10 from "../../../images/LAD@2x.png";
import profitimg from "../../../images/profitable-mega-cap2x.png";
import profitimg01 from "../../../images/undervalued-tech2x.png";
import profitimg02 from "../../../images/analyst-picks.png";
import profitimg03 from "../../../images/high-dividend2x.png";
import profitimg04 from "../../../images/low2x.png";
import profitimg05 from "../../../images/revenue-rich2x.png";
import Icon01 from "../../../images/simply03.webp";
import Icon02 from "../../../images/etf.webp";
import Icon03 from "../../../images/mutualfund-white.webp";
import Icon04 from "../../../images/fast-easy.webp";
import Icon05 from "../../../images/secure.webp";
import Icon06 from "../../../images/flexible.webp";
import Icon07 from "../../../images/no-limit.webp";
import Icon08 from "../../../images/easy-transfer.webp";
import Icon09 from "../../../images/thematic-investment.webp";
import Esteps from "../../../images/4-steps.webp";
import Vinod from "../../../images/Vinod-Kumar-Atal.webp";
import Deepak from "../../../images/deepak-modi.webp";
import Ketan from "../../../images/ketan-manubhai.webp";
import Nilesh from "../../../images/nilesh.webp";
import Pankaj from "../../../images/pankaj-jain.webp";
import { Usinvest } from "../../../components/Usinvest";
import Stockicon01 from "../../../images/Mega-caps.png";
import Stockicon02 from "../../../images/Large-Cap.png";
import Stockicon03 from "../../../images/Mid-Cap.png";
import Stockicon04 from "../../../images/Small-caps.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Context } from "../../../context";
import Header from "../../../components/Header";

const USStock = () => {
  const { showPath, setShowPath } = useContext(Context);
  var settings = {
    dots: false,
    navigation: true,
    loop: false,
    infinite: false,
    slidesToShow: 8,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3.2,
        },
      },
    ],
  };
  var settings3 = {
    dots: false,
    navigation: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 8000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Header />

      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              {
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  {showPath ? (
                    <li>Product</li>
                  ) : (
                    <li>
                      <Link to="/invest">Invest</Link>
                    </li>
                  )}
                  <li>US Stock</li>
                </ul>
              }
            </div>

            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1>Invest in US. from India.</h1>
                </div>
                <div className="bannergt">
                  <img src={Banner} alt="Hassle-free investing" />
                </div>
                <Form catID="USStocks" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="col-lg-10 m-auto">
                    <div className="returncalulator usstocks">
                      <div className="howtoapply">
                        <Tabs>
                          <div className="tradingben m-0">
                            <div className="iconrow noicon">
                              <TabList className="gap-3">
                                <Tab>
                                  <h4>Top Gainers</h4>
                                </Tab>
                                <Tab>
                                  <h4>Top Losers</h4>
                                </Tab>
                                <Tab>
                                  <h4>At 52 week high</h4>
                                </Tab>
                                <Tab>
                                  <h4>At 52 week low</h4>
                                </Tab>
                              </TabList>
                            </div>
                          </div>
                          <TabPanel>
                            <div className="ipoodlist">
                              <div className="stockslider w-100 m-0 newslide">
                                <div className="row">
                                  <Slider {...settings}>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img01} alt="Yes Bank" />
                                        </div>
                                        <div className="slidecont down">
                                          <h5>TDOC</h5>
                                          <div className="price">
                                            <span className="inr">18.25</span>
                                          </div>
                                          <span className="priceup">4.58%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img02} alt="TATA" />
                                        </div>
                                        <div className="slidecont">
                                          <h5>XPEV</h5>
                                          <div className="price">
                                            <span className="inr">105.05</span>
                                          </div>
                                          <span className="priceup">1.06%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img03} alt="WAL" />
                                        </div>
                                        <div className="slidecont">
                                          <h5>WAL</h5>
                                          <div className="price">
                                            <span className="inr">2336.65</span>
                                          </div>
                                          <span className="priceup">0.61%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img04} alt="RPM" />
                                        </div>
                                        <div className="slidecont down">
                                          <h5>RPM</h5>
                                          <div className="price">
                                            <span className="inr">18.25</span>
                                          </div>
                                          <span className="priceup">4.58%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img05} alt="NIO" />
                                        </div>
                                        <div className="slidecont">
                                          <h5>NIO</h5>
                                          <div className="price">
                                            <span className="inr">105.05</span>
                                          </div>
                                          <span className="priceup">1.06%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img06} alt="UNP" />
                                        </div>
                                        <div className="slidecont">
                                          <h5>UNP</h5>
                                          <div className="price">
                                            <span className="inr">2336.65</span>
                                          </div>
                                          <span className="priceup">0.61%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img07} alt="DOMA" />
                                        </div>
                                        <div className="slidecont down">
                                          <h5>DOMA</h5>
                                          <div className="price">
                                            <span className="inr">18.25</span>
                                          </div>
                                          <span className="priceup">4.58%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img08} alt="TENB" />
                                        </div>
                                        <div className="slidecont">
                                          <h5>TENB</h5>
                                          <div className="price">
                                            <span className="inr">105.05</span>
                                          </div>
                                          <span className="priceup">1.06%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img09} alt="LIVN" />
                                        </div>
                                        <div className="slidecont">
                                          <h5>LIVN</h5>
                                          <div className="price">
                                            <span className="inr">2336.65</span>
                                          </div>
                                          <span className="priceup">0.61%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img10} alt="LAD" />
                                        </div>
                                        <div className="slidecont down">
                                          <h5>LAD</h5>
                                          <div className="price">
                                            <span className="inr">18.25</span>
                                          </div>
                                          <span className="priceup">4.58%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Logo2} alt="BA" />
                                        </div>
                                        <div className="slidecont">
                                          <h5>BA</h5>
                                          <div className="price">
                                            <span className="inr">105.05</span>
                                          </div>
                                          <span className="priceup">1.06%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img01} alt="Yes Bank" />
                                        </div>
                                        <div className="slidecont down">
                                          <h5>TDOC</h5>
                                          <div className="price">
                                            <span className="inr">18.25</span>
                                          </div>
                                          <span className="priceup">4.58%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img02} alt="TATA" />
                                        </div>
                                        <div className="slidecont">
                                          <h5>XPEV</h5>
                                          <div className="price">
                                            <span className="inr">105.05</span>
                                          </div>
                                          <span className="priceup">1.06%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img03} alt="WAL" />
                                        </div>
                                        <div className="slidecont">
                                          <h5>WAL</h5>
                                          <div className="price">
                                            <span className="inr">2336.65</span>
                                          </div>
                                          <span className="priceup">0.61%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img04} alt="RPM" />
                                        </div>
                                        <div className="slidecont down">
                                          <h5>RPM</h5>
                                          <div className="price">
                                            <span className="inr">18.25</span>
                                          </div>
                                          <span className="priceup">4.58%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img05} alt="NIO" />
                                        </div>
                                        <div className="slidecont">
                                          <h5>NIO</h5>
                                          <div className="price">
                                            <span className="inr">105.05</span>
                                          </div>
                                          <span className="priceup">1.06%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img06} alt="UNP" />
                                        </div>
                                        <div className="slidecont">
                                          <h5>UNP</h5>
                                          <div className="price">
                                            <span className="inr">2336.65</span>
                                          </div>
                                          <span className="priceup">0.61%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img07} alt="DOMA" />
                                        </div>
                                        <div className="slidecont down">
                                          <h5>DOMA</h5>
                                          <div className="price">
                                            <span className="inr">18.25</span>
                                          </div>
                                          <span className="priceup">4.58%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img08} alt="TENB" />
                                        </div>
                                        <div className="slidecont">
                                          <h5>TENB</h5>
                                          <div className="price">
                                            <span className="inr">105.05</span>
                                          </div>
                                          <span className="priceup">1.06%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img09} alt="LIVN" />
                                        </div>
                                        <div className="slidecont">
                                          <h5>LIVN</h5>
                                          <div className="price">
                                            <span className="inr">2336.65</span>
                                          </div>
                                          <span className="priceup">0.61%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Img10} alt="LAD" />
                                        </div>
                                        <div className="slidecont down">
                                          <h5>LAD</h5>
                                          <div className="price">
                                            <span className="inr">18.25</span>
                                          </div>
                                          <span className="priceup">4.58%</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="slide">
                                        <div className="slidelogo">
                                          <img src={Logo2} alt="BA" />
                                        </div>
                                        <div className="slidecont">
                                          <h5>BA</h5>
                                          <div className="price">
                                            <span className="inr">105.05</span>
                                          </div>
                                          <span className="priceup">1.06%</span>
                                        </div>
                                      </div>
                                    </div>
                                  </Slider>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <h4 className="nodata">No Data Found</h4>
                          </TabPanel>
                          <TabPanel>
                            <h4 className="nodata">No Data Found</h4>
                          </TabPanel>
                          <TabPanel>
                            <h4 className="nodata">No Data Found</h4>
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="col-lg-10 m-auto">
                    <div className="returncalulator">
                      <div className="howtoapply">
                        <Tabs className="d-flex gap-2 justify-content-between">
                          <div className="tradingben m-0 w-auto mwidthtab">
                            <div className="iconrow noicon">
                              <TabList className="gap-2 flex-nowrap d-grid">
                                <Tab>
                                  <h4 className="m-0">
                                    Explore US Stocks Screener
                                  </h4>
                                </Tab>
                                <Tab>
                                  <h4 className="m-0">
                                    Explore US Stocks Collections
                                  </h4>
                                </Tab>
                                <Tab>
                                  <h4 className="m-0">
                                    Explore Themes in US Stocks
                                  </h4>
                                </Tab>
                                <Tab>
                                  <h4 className="m-0">
                                    Explore US Stocks Categories
                                  </h4>
                                </Tab>
                              </TabList>
                            </div>
                          </div>
                          <TabPanel>
                            <div className="stockslider w-100 m-0">
                              <div className="row">
                                <div className="explorestn d-flex gap-4 flex-wrap align-items-start justify-content-center">
                                  <div className="explore">
                                    <div className="explorecircle">
                                      <img src={profitimg} alt="" />
                                    </div>
                                    <div className="explorecont">
                                      <span>Invest in </span>
                                      <p>
                                        <strong>Profitable Mega Cap</strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="explore">
                                    <div className="explorecircle">
                                      <img src={profitimg01} alt="" />
                                    </div>
                                    <div className="explorecont">
                                      <span>Invest in </span>
                                      <p>
                                        <strong>Undervalued Tech</strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="explore">
                                    <div className="explorecircle">
                                      <img src={profitimg02} alt="" />
                                    </div>
                                    <div className="explorecont">
                                      <span>Invest in </span>
                                      <p>
                                        <strong>High Dividend</strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="explore">
                                    <div className="explorecircle">
                                      <img src={profitimg03} alt="" />
                                    </div>
                                    <div className="explorecont">
                                      <span>Invest in </span>
                                      <p>
                                        <strong>Analyst Picks</strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="explore">
                                    <div className="explorecircle">
                                      <img src={profitimg04} alt="" />
                                    </div>
                                    <div className="explorecont">
                                      <span>Invest in </span>
                                      <p>
                                        <strong>Revenue Rich</strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="explore">
                                    <div className="explorecircle">
                                      <img src={profitimg05} alt="" />
                                    </div>
                                    <div className="explorecont">
                                      <span>Invest in </span>
                                      <p>
                                        <strong>Mega Cap Low P/E</strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <h4 className="nodata">No Data Found</h4>
                          </TabPanel>
                          <TabPanel>
                            <h4 className="nodata">No Data Found</h4>
                          </TabPanel>
                          <TabPanel>
                            <h4 className="nodata">No Data Found</h4>
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent p-100 graybg"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>With SMC. Go International</h3>
                    <p>
                      SMC Global brings you an opportunity to invest in US
                      stocks directly without any hassles, with security and
                      from the comfort of your homes. You can invest upto USD
                      250k per annum under LRS route (Liberalised Remittance
                      Scheme).
                    </p>
                  </div>
                  <div className="iconrow">
                    <ul className="justify-content-center">
                      <li>
                        <span>
                          <img src={Icon01} alt="Stocks" />
                        </span>
                        <p>
                          <strong>Stocks</strong>
                        </p>
                      </li>
                      <li>
                        <span>
                          <img src={Icon02} alt="ETFs" />
                        </span>
                        <p>
                          <strong>ETFs</strong>
                        </p>
                      </li>
                      <li>
                        <span>
                          <img src={Icon03} alt="Mutual Funds" />
                        </span>
                        <p>
                          <strong>Mutual Funds</strong>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Why invest with SMC</h3>
                  </div>
                  <div className="iconrow">
                    <ul className="align-items-start">
                      <li>
                        <span>
                          <img src={Icon04} alt="Fast & Easy" />
                        </span>
                        <p>
                          <strong>Fast & Easy</strong>
                          <label>
                            Account opening with no minimum ticket size
                          </label>
                        </p>
                      </li>
                      <li>
                        <span>
                          <img src={Icon05} alt="Secure" />
                        </span>
                        <p>
                          <strong>Secure</strong>
                          <label>
                            investment protected up to USD 500000 through SIPC
                          </label>
                        </p>
                      </li>
                      <li>
                        <span>
                          <img src={Icon06} alt="Flexible" />
                        </span>
                        <p>
                          <strong>Flexible</strong>
                          <label>
                            Option to buy fractional stocks (1/10th, 1/100th of
                            any stock).
                          </label>
                        </p>
                      </li>
                      <li>
                        <span>
                          <img src={Icon07} alt="No Limit" />
                        </span>
                        <p>
                          <strong>No Limit</strong>
                          <label>
                            Seamless & hasslefree fund transfer to your a/c in
                            US dollars
                          </label>
                        </p>
                      </li>
                      <li>
                        <span>
                          <img src={Icon08} alt="Easy Transfer" />
                        </span>
                        <p>
                          <strong>Easy Transfer</strong>
                          <label>Trade for as low as 1 cent</label>
                        </p>
                      </li>
                      <li>
                        <span>
                          <img src={Icon09} alt="Thematic Investment" />
                        </span>
                        <p>
                          <strong>Thematic Investment</strong>
                          <label>
                            Customized portfolio built by experts to invest in
                            winning themes
                          </label>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="pricingstn">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>Pricing Plans</h3>
                    </div>
                    <div className="pricetabs">
                      <div className="howtoapply"></div>
                      <div className="planslider">
                        <Slider {...settings3}>
                          <div>
                            <div className="planslidebox">
                              <div className="planshead bg-green bradius25">
                                <h4 className="pb-0">
                                  Basic{" "}
                                  <span>
                                    <i className="finr"></i>0
                                  </span>
                                </h4>
                                <p className="text-white">
                                  Subscription Fees Per Year
                                </p>
                              </div>
                              <div className="plansbody bg-white text-green-first">
                                <ul>
                                  <li>
                                    <strong>$2.99 Per Trade*</strong>
                                  </li>
                                  <li>
                                    <i className="finr"></i>0 Account Opening
                                    Fees
                                  </li>
                                  <li>Unlimited Investing Power</li>
                                  <li>Email Support</li>
                                  <li>
                                    *$2.99 Upto 299 Shares, $.01 Per Share
                                    Beyond
                                  </li>
                                  <li></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="planslidebox">
                              <div className="planshead bg-orange bradius25">
                                <h4 className="pb-0 bg-orange">
                                  Silver{" "}
                                  <span>
                                    <i className="finr"></i>3,999
                                  </span>
                                </h4>
                                <p className="text-white">
                                  Subscription Fees Per Year
                                </p>
                              </div>
                              <div className="plansbody bg-white text-green-first">
                                <ul>
                                  <li>
                                    <strong>$2.99 Per Trade*</strong>
                                  </li>
                                  <li>
                                    <i className="finr"></i>0 Account Opening
                                    Fees
                                  </li>
                                  <li>Unlimited Investing Power</li>
                                  <li>Email Support</li>
                                  <li>24X7 Support</li>
                                  <li>
                                    *$2.99 Upto 299 Shares, $.01 Per Share
                                    Beyond
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="planslidebox">
                              <div className="planshead bg-blue bradius25">
                                <h4 className="pb-0 bg-blue">
                                  Gold{" "}
                                  <span>
                                    <i className="finr"></i>13,999
                                  </span>
                                </h4>
                                <p className="text-white">
                                  Subscription Fees Per Year
                                </p>
                              </div>
                              <div className="plansbody bg-white text-green-first">
                                <ul>
                                  <li>
                                    <strong>1 Cent Per Share</strong>
                                  </li>
                                  <li>
                                    <i className="finr"></i>0 Account Opening
                                    Fees
                                  </li>
                                  <li>Unlimited Investing Power</li>
                                  <li>Email Support</li>
                                  <li>24X7 Support</li>
                                  <li>Dedicated Hotline</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>How it works</h3>
                  </div>
                  <div className="downloadapp easystep flex-direction-row">
                    <div className="apprgt">
                      <img src={Esteps} alt="easysteps" />
                    </div>
                    <div className="applef">
                      <div className="stepstn">
                        <ul>
                          <li>
                            <strong>Step 1 </strong>
                            <p>Select your plan</p>
                          </li>
                          <li>
                            <strong>Step 2 </strong>
                            <p>Make payment</p>
                          </li>
                          <li>
                            <strong>Step 3 </strong>
                            <p>Activate Account</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Usinvest />
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Types of US Stocks</h3>
                    <p>
                      US stocks can be categorized into four types based on
                      their market capitalization or market cap. Each of these
                      categories has a threshold based on market cap. As the
                      market cap is calculated using stock price, these
                      thresholds change based on changing stock prices:
                    </p>
                  </div>
                  <div className="reports d-flex justify-content-between col-12 pull-left gap-2 flex-wrap">
                    <div className="reportdtn">
                      <div className="reporthead">
                        <img
                          src={Stockicon01}
                          alt="Mega Cap"
                          className="mb-2"
                        />
                        <h4>Mega Cap</h4>
                      </div>
                      <div className="reportcont">
                        <p>
                          Mega cap stocks represent the largest companies in
                          terms of market cap. Usually, mega-cap companies have
                          a market cap of above $200 billion.
                        </p>
                      </div>
                    </div>
                    <div className="reportdtn">
                      <div className="reporthead">
                        <img
                          src={Stockicon02}
                          alt="Large Cap"
                          className="mb-2"
                        />
                        <h4>Large Cap</h4>
                      </div>
                      <div className="reportcont">
                        <p>
                          Large-cap stocks have a market cap of above $10
                          billion. Large-cap stocks, or big caps, are
                          established companies with stable revenues and
                          profits. Large-cap stocks are usually very liquid. Due
                          to their size and stability, large-cap stocks are
                          suited for risk-averse investors.
                        </p>
                      </div>
                    </div>
                    <div className="reportdtn">
                      <div className="reporthead">
                        <img src={Stockicon03} alt="Mid Cap" className="mb-2" />
                        <h4>Mid Cap</h4>
                      </div>
                      <div className="reportcont">
                        <p>
                          Midcap companies have market caps in the range of $2
                          billion and $10 billion. Mid-caps are high-potential
                          companies, expecting growth in revenues and profits.
                          Midcap stocks are riskier than mega-cap and large-cap
                          stocks and are suited for investors with a moderate
                          risk appetite.
                        </p>
                      </div>
                    </div>
                    <div className="reportdtn">
                      <div className="reporthead">
                        <img
                          src={Stockicon04}
                          alt="Small Cap"
                          className="mb-2"
                        />
                        <h4>Small Cap</h4>
                      </div>
                      <div className="reportcont">
                        <p>
                          Small-cap stocks have a market capitalization of
                          between $300 million and $2 billion. Small-cap stocks
                          offer a high potential for growth but carry greater
                          risk than other categories. Small caps are suited for
                          investors with a high-risk appetite and those who are
                          willing to invest time in researching good quality
                          stocks.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="minicontainer">
                    <div className="title">
                      <h3>Listen. To Experts.</h3>
                    </div>
                    <div className="clientreview">
                      <Slider {...settings3}>
                        <div>
                          <div className="reviewbox">
                            <div className="commentcircle">
                              <div className="comment">
                                <div className="profile">
                                  <div className="climg">
                                    <img src={Nilesh} alt="" />
                                  </div>
                                  <div className="rating">
                                    <ul className="d-flex">
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star-o"></i>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="commentcent">
                                  <p>
                                    The relationship managers are not only quick
                                    but they also make that extra effort to
                                    resolve all my queries properly. I also find
                                    the recommendations quite handy. They have
                                    helped me in each and every aspect of
                                    trading. Thanks SMC!
                                  </p>
                                </div>
                                <div className="name">
                                  <h4>Mr. Nilesh Jain</h4>
                                  <h5>Mumbai</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="reviewbox">
                            <div className="commentcircle">
                              <div className="comment">
                                <div className="profile">
                                  <div className="climg">
                                    <img src={Vinod} alt="" />
                                  </div>
                                  <div className="rating">
                                    <ul className="d-flex">
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star-half-o"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star-o"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star-o"></i>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="commentcent">
                                  <p>
                                    SMC Group is a complete solution for all
                                    finance needs & goals, a supportive team
                                    which is always available for any of our
                                    requirement and fully dedicated to the
                                    customer’s needs. Truly, a complete
                                    financial solution platform for everyone's
                                    need. I am very happy and satisfied with
                                    SMC. Thank You SMC for all the support.
                                  </p>
                                </div>
                                <div className="name">
                                  <h4>Mr. CA Vinod Kumar Atal</h4>
                                  <h5>Ahemdabad</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="reviewbox">
                            <div className="commentcircle">
                              <div className="comment">
                                <div className="profile">
                                  <div className="climg">
                                    <img src={Deepak} alt="" />
                                  </div>
                                  <div className="rating">
                                    <ul className="d-flex">
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star-half-o"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star-o"></i>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="commentcent">
                                  <p>
                                    SMC is one of the best brokerage service
                                    providers I have been associated with. I
                                    would like to specifically mention their
                                    mobile trading platform which is quick and
                                    efficient. Trades can be executed and
                                    confirmed in no time and the back office
                                    functions work very smoothly.
                                  </p>
                                </div>
                                <div className="name">
                                  <h4>Mr. Deepak Modi</h4>
                                  <h5> Jaipur</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="reviewbox">
                            <div className="commentcircle">
                              <div className="comment">
                                <div className="profile">
                                  <div className="climg">
                                    <img src={Ketan} alt="" />
                                  </div>
                                  <div className="rating">
                                    <ul className="d-flex">
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star-half-o"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star-o"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star-o"></i>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="commentcent">
                                  <p>
                                    It has been a great experience of trading
                                    and investment across all segments with SMC
                                    since 2011. SMC research has made my trading
                                    & investment strategy far easier and helped
                                    me to grow financially over these years. A
                                    very positive, supportive and experienced
                                    staff has always been there for any help and
                                    guidance
                                  </p>
                                </div>
                                <div className="name">
                                  <h4>Mr. Ketan Manubhai Dave</h4>
                                  <h5> Ahemdabad</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="reviewbox">
                            <div className="commentcircle">
                              <div className="comment">
                                <div className="profile">
                                  <div className="climg">
                                    <img src={Pankaj} alt="" />
                                  </div>
                                  <div className="rating">
                                    <ul className="d-flex">
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star-half-o"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star-o"></i>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="commentcent">
                                  <p>
                                    SMC is a trusted name in the industry and
                                    they strongly believe in customer
                                    relationship. Besides, their robust trading
                                    platform, simple and easy website, Portfolio
                                    Health Checkup and customer support are the
                                    key USPs.
                                  </p>
                                </div>
                                <div className="name">
                                  <h4>Mr .Pankaj Jain</h4>
                                  <h5>Delhi</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Frequently Asked Questions</h3>
                  </div>
                  <div className="faqcont">
                    <Accordion allowZeroExpanded="true">
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What is the difference between saving and investing?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            Saving is putting money aside for future use and it
                            is available when we need it. It's important to save
                            so you can cover fixed expenses, like annual
                            vacation or rent payments, and to make sure you're
                            prepared for emergencies.
                          </p>
                          <p>
                            Investing is when you put your money to work for
                            you. You buy an investment, like a stock or mutual
                            fund, with the hope that its value will increase
                            over time. It also has the potential for greater
                            returns than you’d receive by putting your money in
                            a saving account.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What are investment benefits?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            Investment helps in growing wealth over time. It is
                            a way to earn extra income, reach financial goals,
                            plan for retirement and save on taxes.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What is the best way to invest money?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            The best way to invest money depends on your goals,
                            income, age and risk tolerance. Mutual Funds,
                            Stocks, and IPOs are a few ways for investing money.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            How much a Sub Broker earns through commission?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            The sub broker commission might be between 40% and
                            80% of the total business transaction amount.
                            Moreover, the commission rate can be high if you
                            sell more products to your customer.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What are the objective of investment?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            Following are some of the primary objective of
                            investment.
                          </p>
                          <ul>
                            <li>To keep fund safe and secure</li>
                            <li>To grow your funds exponentially</li>
                            <li>To earn additional source of income</li>
                            <li>To meet future financial goals</li>
                            <li>Retirement planning</li>
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            How to do stock trading and investment?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            To do stock trading and stock investment, you need
                            to open your Demat account and Trading account. If
                            you wish to buy or sell shares, you can log in to
                            your trading account. After ensuring you have enough
                            funds in your account, you can start investing or
                            trading.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Where can I invest safely in India?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            You can invest money in financial markets through
                            Upstox effortlessly. Investing money with Upstox
                            ensures that your funds are in reliable hands.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Where to invest money in the share market?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            It is better to invest money in the share market as
                            per your goals, risk tolerance, age and investment
                            horizon. You can download SMC Ace App to invest
                            money in the share market today.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            How to invest money in equity market?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            To invest money in equity market, you need to open
                            your Demat account and complete the online
                            registration process with SMC. After verification,
                            your account will be opened, and you can invest
                            money through SMC.{" "}
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What is the difference between investing and
                            trading?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            Investing is the way to accumulate wealth in the
                            long run or earn passive income for day-to-day
                            operations. Trading refers to the frequent buying
                            and selling of securities to make quick gains.{" "}
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Where to invest money to get good returns?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            Mutual funds, stocks, IPOs and NPS are some of the
                            options to invest money to get a high return on
                            investment. Depending on your goals and needs, you
                            can decide which factor has more significant to for
                            long-term investment.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            How to invest in the stock market for beginners?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            If you are a beginner and wish to invest in the
                            stock market, you need to learn the process of
                            buying and selling securities. After this, you can
                            open your demat account and choose the type of
                            investment among multiple available options such as
                            stocks, mutual funds, bonds, derivatives etc.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default USStock;

import React from 'react'

export const Attachement = (props) => {
  return (
   <>
      <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    className="icon flat-line"
    data-name="Flat Line"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M10 6h5a6 6 0 0 1 6 6h0a6 6 0 0 1-6 6H7a4 4 0 0 1-4-4h0a4 4 0 0 1 4-4h8a2 2 0 0 1 2 2h0a2 2 0 0 1-2 2H7"
      style={{
        fill: "none",
        stroke: "#4db4a8",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 1,
      }}
    />
  </svg>
   </>
  )
}

import React from "react";
import Footer from "../../../components/Footer";
import Banner from "../../../images/fundamental-research.webp";
import { Excellence } from "../../../components/Excellence";
import { Link } from "react-router-dom";
import { Form } from "../../../components/Form";
import Img01 from "../../../images/Fbenefit01.webp";
import Img02 from "../../../images/Fbenefit02.webp";
import Img03 from "../../../images/Fbenefit03.webp";
import Img04 from "../../../images/Fbenefit04.webp";
import Img05 from "../../../images/Fbenefit05.webp";
import Creative from "../../../images/fundamental-img.webp";
import { Context } from "../../../context";
import { getReportByCategory } from "../../../utils/APICollection";
import Carousel from "../../../components/Carousel";
import ResearchSlider from "../../../components/common/ResearchSlider";
import Header from "../../../components/Header";
import Loader from "../../../components/Loader";
import SEO from "../../../components/common/SEO";
import { SEO_URL } from "../../../utils/constants";
import ExpertSlider from "./ExpertSlider";
import NumberCount from "./NumberCount";
import Faq from "./Faq";

const FundamentalResearch = () => {
  const { setResearchData, loading, setLoading } = React.useContext(Context);
  const researchCatData = localStorage.getItem("researchData");
  const finalCategory = JSON.parse(researchCatData);
  const isMobile = window.innerWidth <= 540;

  const getAllCategory = async () => {
    const filterCat = finalCategory?.filter(
      (item) => item.category_name == "Fundamental Research"
    );
    const { data } = await getReportByCategory(
      filterCat[0]?.research_category_master_id
    );
    if (data?.status == "success") {
      setResearchData(data?.data);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getAllCategory();
  }, []);

  const image = "../../../images/SMCNEWLOGO.webp";
  const url = `${SEO_URL}/research/fundamental-research`;
  const title = "Fundamental Research Report - SMC Global Securities";
  const description =
    "Get a Fundamental research report of various companies by SMC Global. Make your trade on the basis of our Fundamental research reports.";
  return (
    <>
      <Header />
      <SEO
        title={title}
        description={description}
        name="Company name."
        type="article"
        url={url}
        image={image}
      />

      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/Research">Research</Link>
                </li>
                <li>Fundamental Research</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1 className=" p-0">Accurate. reports.</h1>
                </div>
                <div className="bannergt">
                  <div className="mobileno">
                    <img src={Banner} alt="Hassle-free investing" />
                  </div>
                </div>
                {isMobile ? (
                  <div
                    style={{
                      marginTop: "-60px",
                      width: "100%",
                    }}
                  >
                    <Form catID="Research" />
                  </div>
                ) : (
                  <Form catID="Research" />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <NumberCount />
          <div
            className="webcent p-100 graybg"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="col-lg-9 d-flex gap-5 mx-auto align-items-center flex-wrap text-cont-center">
                    <div className="col-lg-5">
                      <img src={Creative} alt="" />
                    </div>
                    <div className="col-lg-6">
                      <div className="title mb-2 justify-content-start text-start">
                        <h3 className="lh-1">Fundamental analysis report</h3>
                      </div>
                      <p>
                        Download Accurate-unbiased research report of the
                        trending stock picks with buy, hold and sell
                        recommendations along with share price targets forecast
                        and upside. SMC research team consists of 23 SEBI
                        registered analyst who excel in specific area of
                        research within the three broad segment of research
                        (Equity, Commodity & Derivatives).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="partnerfeature">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>Benefits</h3>
                    </div>
                    <div className="partnerlist w-100 m-0">
                      <ul className="flex-nowrap gap-3 mb-3">
                        <li>
                          <span>
                            <img src={Img01} alt="" />
                          </span>
                          <p>
                            <strong>
                              In-depth Research of Company’s Financial Data
                            </strong>
                          </p>
                        </li>
                        <li>
                          <span>
                            <img src={Img02} alt="" />
                          </span>
                          <p>
                            <strong>
                              Analysis based upon Long-term Economic trends
                            </strong>
                          </p>
                        </li>
                        <li>
                          <span>
                            <img src={Img03} alt="" />
                          </span>
                          <p>
                            <strong>
                              Systematic Approach using Statistical and
                              Analytical tools
                            </strong>
                          </p>
                        </li>
                        <li>
                          <span>
                            <img src={Img04} alt="" />
                          </span>
                          <p>
                            <strong>
                              Develop Thorough Understanding of Company’s
                              Business
                            </strong>
                          </p>
                        </li>
                        <li>
                          <span>
                            <img src={Img05} alt="" />
                          </span>
                          <p>
                            <strong>
                              Help Uncover Companies with Valuable Assets
                            </strong>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3 className="lh-1">Fundamental Analysis Reports</h3>
                  </div>
                  <div className="reports d-flex justify-content-between col-12 pull-left gap-6 flex-wrap">
                    <ResearchSlider setLoading={setLoading} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Excellence />
          <ExpertSlider />
          <div
            className="webcent pt-0"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="minicontainer">
                    <div className="title">
                      <h3>Creating proud investors.</h3>
                    </div>
                    <Carousel />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Faq />
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

export default FundamentalResearch;

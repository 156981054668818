import React, { useContext, useState } from "react";
import nodata from "../../images/nodata.webp";
import parse from "html-react-parser";
import {
  formatMyDate,
  formatTime,
} from "../../components/common/FormattedDate";
import { getAllWebinar } from "../../utils/APICollection";
import { Context } from "../../context";
import { Link } from "react-router-dom";
import PaginationComponent from "../../components/common/PaginationComponent";

const WebinarList = () => {
  const { showWebinar, setShowWebinar } = useContext(Context);
  const [currentPage, setCurrentPage] = useState(1);

  const getWebinar = async () => {
    const response = await getAllWebinar();
    setShowWebinar(response?.data?.data);
    setCurrentPage(1);
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const [hasScroll, setHasScroll] = useState(false);

  React.useEffect(() => {
    if (hasScroll) {
      setHasScroll(false);
      setScrollPosition(360);
    }
    window.scrollTo(0, scrollPosition);
  }, [scrollPosition]);
  React.useEffect(() => {
    getWebinar();
  }, []);
  let now = new Date();
  let currentDate = now.toISOString().split("T")[0];

  return (
    <>
      <div className="web-container">
        <div className="title">
          <h3>Webinars </h3>
          <p>List of all upcoming and completed webinars</p>
        </div>
        {showWebinar?.length > 0 ? (
          <div className="webinarstn pull-left col-12 gap-4 d-flex flex-wrap">
            {showWebinar
              ?.slice((currentPage - 1) * 8, currentPage * 8)
              ?.map((data) => {
                return (
                  <div
                    className="webinarrow grey-background"
                    style={{ width: "100%" }}
                  >
                    <div className="webinartitle">
                      <div className="title m-0">
                        <h3 className="fs-4">{data?.category_name}</h3>
                      </div>
                      <ul>
                        <li>{formatMyDate(data?.date)}</li>
                        <li>{formatTime(data?.time)} IST</li>
                        <li>
                          <strong>{data?.speaker_name}</strong>
                        </li>
                      </ul>
                    </div>
                    <div className="webinarcont">
                      <h5>{data?.title}</h5>
                      <div
                        style={{
                          marginBottom: "0.5rem",
                          wordWrap: "break-word",
                        }}
                      >
                        {parse(data?.description)}
                      </div>
                      {new Date(data?.date) < new Date(currentDate) &&
                      data?.youtube_link ? (
                        <Link to={data?.youtube_link} target="_blank">
                          <div className="btnn">
                            <span>Watch Now</span>
                          </div>
                        </Link>
                      ) : new Date(data?.date) < new Date(currentDate) &&
                        data?.youtube_link == "" ? (
                        <Link
                          to={`/learning-centre/webinar/webinar-details/${data?.webinar_master_id}`}
                        >
                          <div className="btnn">
                            <span>View details</span>
                          </div>
                        </Link>
                      ) : (
                        <Link
                          to={`/learning-centre/webinar/webinar-details/${data?.webinar_master_id}`}
                          onClick={() => {
                            localStorage.setItem(
                              "webinarDetail",
                              JSON.stringify(
                                showWebinar?.filter(
                                  (item) =>
                                    item.webinar_master_id ==
                                    data?.webinar_master_id
                                )
                              )
                            );
                          }}
                        >
                          <div className="btnn">
                            <span>Register Now</span>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                );
              })}

            {showWebinar?.length > 0 && (
              <PaginationComponent
                data={showWebinar}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                defaultValue={8}
              />
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={nodata} width={100} height={100} />
            <h4>Happy to see you</h4>
            <p style={{ textAlign: "center" }}>
              We are working on it, Please come back later
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default WebinarList;

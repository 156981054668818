import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "../../../components/Form";
import Banner from "../../../images/partner-distributor.webp";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Icon01 from "../../../images/unique-clients.webp";
import Icon02 from "../../../images/employees.webp";
import Icon03 from "../../../images/cities.webp";
import Icon04 from "../../../images/authorised-person.webp";
import Icon05 from "../../../images/network.webp";
import Icon06 from "../../../images/loan-provider.webp";
import Slider from "react-slick";
import { Excellence } from "../../../components/Excellence";
import pro01 from "../../../images/bmutual-fund.webp";
import pro02 from "../../../images/bcorporate.webp";
import pro03 from "../../../images/bbond.webp";
import pro04 from "../../../images/bfloating.webp";
import pro05 from "../../../images/bncd.webp";
import pro06 from "../../../images/bipo.webp";
import pro07 from "../../../images/binsurance.webp";
import pro08 from "../../../images/bsecondary.webp";
import pro09 from "../../../images/bmortage.webp";
import pro10 from "../../../images/brealty.webp";
import { Infographic } from "../../../charts/Infographic";
import Vinod from "../../../images/Vinod-Kumar-Atal.webp";
import Deepak from "../../../images/deepak-modi.webp";
import Ketan from "../../../images/ketan-manubhai.webp";
import Nilesh from "../../../images/nilesh.webp";
import Pankaj from "../../../images/pankaj-jain.webp";
import Footer from "../../../components/Footer";
import Carousel from "../../../components/Carousel";
import Header from "../../../components/Header";
import { Helmet } from "react-helmet";
import { Context } from "../../../context";
import Loader from "../../../components/Loader";
import SEO from "../../../components/common/SEO";
import SMCNEWLOGO from "../../../images/SMCNEWLOGO.webp";
import { SEO_URL } from "../../../utils/constants";
import { seoData } from "../../../utils/Data";
import DistFaq from "./DistFaq";

export const Distributor = () => {
  const isMobile = window.innerWidth <= 540;
  const { loading, setLoading } = React.useContext(Context);
  const [state, setState] = useState({
    turnover: 1,
    workforce: 1,
    states: 1,
    cities: 1,
  });
  var settings2 = {
    dots: false,
    navigation: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings3 = {
    dots: false,
    navigation: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Header />
      <SEO
        title={seoData.distributor.title}
        description={seoData.distributor.description}
        name="Company name."
        type="article"
        url={seoData.distributor.url}
        image={seoData.distributor.image}
      />

      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/partner-us">Partner Us</Link>
                </li>
                <li>Become Distributor</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1>Become an SMC distributor</h1>
                  <p>
                    Access to all the financial products under a single umbrella
                  </p>
                  <ul>
                    <li>
                      Wide range of <strong>Financial</strong> products
                    </li>
                    <li>
                      <strong>An add-on</strong> income opportunity
                    </li>
                    <li>
                      <strong>Business</strong> tracking on the go
                    </li>
                  </ul>
                </div>
                <div className="bannergt">
                  <div className="mobileno">
                    <img src={Banner} alt="Hassle-free investing" />
                  </div>
                </div>
                {isMobile ? (
                  <div
                    style={{
                      marginTop: "-60px",
                      width: "100%",
                    }}
                  >
                    <Form catID={"Distributor"} smsType={"sms_distributor"} />
                  </div>
                ) : (
                  <Form catID={"Distributor"} smsType={"sms_distributor"} />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent p-0"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Trust. Rock. Solid</h3>
                  </div>
                  <div className="iconrow">
                    <VisibilitySensor
                      partialVisibility
                      offset={{ bottom: 200 }}
                    >
                      {({ isVisible }) => (
                        <ul>
                          <li>
                            <span>
                              <img
                                src={Icon01}
                                alt="Regular Training Program"
                              />
                            </span>
                            <div className="countnum">
                              {isVisible ? (
                                <CountUp
                                  start={state.turnover === 1 ? 1 : 2}
                                  end={2}
                                  duration={3}
                                  onEnd={() => setState({ turnover: 2 })}
                                />
                              ) : (
                                <CountUp start={1} end={2} duration={0} />
                              )}{" "}
                              M
                              <p style={{ textTransform: "none" }}>
                                unique customers
                              </p>
                            </div>{" "}
                          </li>
                          <li>
                            <span>
                              <img
                                src={Icon02}
                                alt="Advanced Software to Manage Clients"
                              />
                            </span>
                            <div className="countnum">
                              {isVisible ? (
                                <CountUp
                                  start={state.workforce === 1 ? 1 : 4000}
                                  end={4000}
                                  duration={2}
                                  onEnd={() => setState({ workforce: 4000 })}
                                />
                              ) : (
                                <CountUp start={1} end={4000} duration={0} />
                              )}
                              +
                              <p style={{ textTransform: "none" }}>
                                lives driving the brand SMC
                              </p>
                            </div>{" "}
                          </li>
                          <li>
                            <span>
                              <img
                                src={Icon03}
                                alt="Multiple Product Offering For Cross Sell"
                              />
                            </span>
                            <div className="countnum">
                              {isVisible ? (
                                <CountUp
                                  start={state.cities === 1 ? 1 : 455}
                                  end={455}
                                  duration={3}
                                  onEnd={() => setState({ cities: 455 })}
                                />
                              ) : (
                                <CountUp start={1} end={455} duration={0} />
                              )}
                              +
                              <p style={{ textTransform: "none" }}>
                                cities across India
                              </p>
                            </div>
                          </li>
                          <li>
                            <span>
                              <img
                                src={Icon04}
                                alt="Regular Marketing Support"
                              />
                            </span>
                            <div className="countnum">
                              {isVisible ? (
                                <CountUp
                                  start={state.states === 1 ? 1 : 2550}
                                  end={2550}
                                  duration={1}
                                  onEnd={() => setState({ states: 2550 })}
                                />
                              ) : (
                                <CountUp start={1} end={2550} duration={0} />
                              )}
                              +
                              <p style={{ textTransform: "none" }}>
                                sub-brokers & franchises
                              </p>
                            </div>{" "}
                          </li>
                          <li>
                            <span>
                              <img
                                src={Icon05}
                                alt="Regular Marketing Support"
                              />
                            </span>
                            <div className="countnum">
                              {isVisible ? (
                                <CountUp
                                  start={state.cities === 1 ? 1 : 13600}
                                  end={13600}
                                  duration={3}
                                  onEnd={() => setState({ cities: 13600 })}
                                />
                              ) : (
                                <CountUp start={1} end={13600} duration={0} />
                              )}
                              +
                              <p style={{ textTransform: "none" }}>
                                independent distributors
                              </p>
                            </div>
                          </li>
                          <li>
                            <span>
                              <img
                                src={Icon06}
                                alt="Regular Marketing Support"
                              />
                            </span>
                            <div className="countnum">
                              {isVisible ? (
                                <CountUp
                                  start={state.turnover === 1 ? 1 : 2200}
                                  end={2200}
                                  duration={3}
                                  onEnd={() => setState({ turnover: 2200 })}
                                />
                              ) : (
                                <CountUp start={1} end={20} duration={0} />
                              )}{" "}
                              +
                              <p style={{ textTransform: "none" }}>
                                cumulative loans disbursed by NBFC segment
                              </p>
                            </div>{" "}
                          </li>
                        </ul>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Our Products</h3>
                  </div>
                  <div className="partnerpro">
                    <div className="partnerbox">
                      <div className="partnerimg">
                        <img src={pro01} />
                      </div>
                      <div className="partnercont">
                        <h4>Mutual Funds</h4>
                      </div>
                    </div>
                    <div className="partnerbox">
                      <div className="partnerimg">
                        <img src={pro02} />
                      </div>
                      <div className="partnercont">
                        <h4>Corporate Fixed Deposits</h4>
                      </div>
                    </div>
                    <div className="partnerbox">
                      <div className="partnerimg">
                        <img src={pro03} />
                      </div>
                      <div className="partnercont">
                        <h4>Capital Gain Bonds</h4>
                      </div>
                    </div>
                    <div className="partnerbox">
                      <div className="partnerimg">
                        <img src={pro04} />
                      </div>
                      <div className="partnercont">
                        <h4>Floating Rate Savings Bonds</h4>
                      </div>
                    </div>
                    <div className="partnerbox">
                      <div className="partnerimg">
                        <img src={pro05} />
                      </div>
                      <div className="partnercont">
                        <h4>NCD</h4>
                      </div>
                    </div>
                    <div className="partnerbox">
                      <div className="partnerimg">
                        <img src={pro06} />
                      </div>
                      <div className="partnercont">
                        <h4>IPO</h4>
                      </div>
                    </div>
                    <div className="partnerbox">
                      <div className="partnerimg">
                        <img src={pro07} />
                      </div>
                      <div className="partnercont">
                        <h4>Insurance</h4>
                      </div>
                    </div>
                    <div className="partnerbox">
                      <div className="partnerimg">
                        <img src={pro08} />
                      </div>
                      <div className="partnercont">
                        <h4>Secondary Debt</h4>
                      </div>
                    </div>
                    <div className="partnerbox">
                      <div className="partnerimg">
                        <img src={pro09} />
                      </div>
                      <div className="partnercont">
                        <h4>Mortgage</h4>
                      </div>
                    </div>
                    <div className="partnerbox">
                      <div className="partnerimg">
                        <img src={pro10} />
                      </div>
                      <div className="partnercont">
                        <h4>Realty</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Infographic />
          <div
            className="webcent pt-0"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="minicontainer">
                    <div className="title">
                      <h3>Defining Value. Diverse Clientele</h3>
                    </div>
                    <Carousel />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Excellence />
          <DistFaq />
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

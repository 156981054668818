import React from "react";
import Support from "../images/sales-support.webp";
import Marketing from "../images/marketing-support.webp";
import Technology from "../images/technology-solution.webp";
import Training from "../images/training-support.webp";
import Reseach from "../images/research-support.webp";
import Customer from "../images/customer-support.webp";

export const Infographic = () => {
  return (
    <>
      <div
        className="webcent"
        data-="fade-up"
        data--offset="200"
        data--duration="600"
        data--once="true"
        data--easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>SMC 360 Degree Support</h3>
              </div>
              <div className="infographic">
                <div className="infobox">
                  <div className="infoslide">
                    <div className="infoimg">
                      <img src={Support} />
                    </div>
                    <div className="infocont">
                      <p>SALES SUPPORT</p>
                    </div>
                  </div>
                  <div className="infoslide">
                    <div className="infoimg">
                      <img src={Marketing} />
                    </div>
                    <div className="infocont">
                      <p>MARKETING SUPPORT</p>
                    </div>
                  </div>
                  <div className="infoslide">
                    <div className="infoimg">
                      <img src={Technology} />
                    </div>
                    <div className="infocont">
                      <p>TECHNOLOGY SOLUTIONS</p>
                    </div>
                  </div>
                </div>
                <div className="infocircle">
                  <div className="dottedcircle">
                    <div className="dottedbox">
                      <div className="leftdots">
                        <div className="dots"></div>
                        <div className="dots"></div>
                        <div className="dots"></div>
                      </div>
                      <div className="rightdots">
                        <div className="dots"></div>
                        <div className="dots"></div>
                        <div className="dots"></div>
                      </div>
                    </div>
                    <div className="shadowcircle">
                      <div className="title">
                        <h3>
                          360<sup>0</sup> <br /> Support
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="infobox">
                  <div className="infoslide">
                    <div className="infoimg">
                      <img src={Training} />
                    </div>
                    <div className="infocont">
                      <p>TRAINING SUPPORT</p>
                    </div>
                  </div>
                  <div className="infoslide">
                    <div className="infoimg">
                      <img src={Reseach} />
                    </div>
                    <div className="infocont">
                      <p>RESEARCH SUPPORT</p>
                    </div>
                  </div>
                  <div className="infoslide">
                    <div className="infoimg">
                      <img src={Customer} />
                    </div>
                    <div className="infocont">
                      <p>CUSTOMER SUPPORT</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

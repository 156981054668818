const IpoSubscriptionDetails = ({
  subscriptionDetails,
  subscriptionStatus,
  ipoTimeline,
  isMobile,
}) => {
  return (
    <div className="webcent">
      <div className="ipostable">
        <div className="tablisthead ipotable">
          <h4>{subscriptionDetails?.CompanyName} Subscription Details</h4>
        </div>
        <div className="tablist">
          <div className="ipostn">
            <div className={isMobile ? "ipotable defaulttable" : "ipotable"}>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Listing Date</th>
                    <th scope="col">QIB Subscribed</th>
                    <th scope="col">Total Subscribed</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {/* <strong className="mobile-show">
                                            Listing Date
                                          </strong> */}
                      {/* {convertDate(
                                            subscriptionDetails
                                              ?.CommencementoftradingoftheEquitySharesontheStockExchanges
                                          )} */}
                      {ipoTimeline?.ListingDate}
                    </td>
                    <td>
                      {/* <strong className="mobile-show">
                                            QIB Subscribed
                                          </strong> */}
                      {
                        subscriptionStatus?.QualifiedInstitutionalBuyers_QIB_Subscribed
                      }
                    </td>
                    <td>
                      {/* <strong className="mobile-show">
                                            Total Subscribed
                                          </strong> */}
                      {/* {subscriptionDetails?.Total_NoofShares} */}
                      {subscriptionStatus?.Total_Subscribed}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IpoSubscriptionDetails;

import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import '../charts/css/chart.css'

export const FinancialChart = ({ graphData = [], filter }) => {
    const filteredData = graphData?.map(item => {
        return {
            x: JSON.stringify(item.yrc).slice(0, 4),
            y: Number(item[filter]).toFixed(2)
        }
    })
    const state = {

        series: [
            {
                name: '',
                data: filteredData
            }
        ],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                stacked: false,
                fontFamily: 'AvertaStd, Arial, sans-serif',
                sparkline: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
                float: 'right'
            },
            subtitle: {
                text: 'All values are in Cr',
                offsetX: 10,
                offsetY: 35,
                style: {
                    fontSize: '17px',
                    fontWeight: 'normal',
                    fontFamily: undefined,
                    color: '#000'
                },
            },
            grid: {
                show: false
            },
            yaxis: {
                show: false
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%'
                }
            },
            colors: ['#44b6a7'],
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    gradientToColors: ['#405cb2'],
                    shadeIntensity: 10,
                    type: 'vertical',
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100, 100, 100]
                },
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            }
        },

    }
    return (
        <>
            <ReactApexChart options={state.options} series={state.series} type="bar" height={450} />
        </>
    )
}

import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import validator from "validator";
import { useNavigate, useSearchParams } from "react-router-dom";
import { leadApi, sendOtpApi } from "../utils/APICollection";
import { useContext } from "react";
import { Context } from "../context";
import axios from "axios";
import { CAPTCHA_KEY } from "../utils/constants";
import { loadReCaptchaScript } from "./common/FormattedDate";
import CountrySearch from "./common/CountrySearch";
export const NRIAndFPIForm = ({ catID, smsType }) => {
  const navigate = useNavigate();
  const {
    num,
    setNum,
    name,
    setName,
    email,
    country,
    setCountry,
    setEmail,
    state,
    setCategoryID,
    setUrlData,
    ipAddress,
    setIPAddress,
    loading,
    setLoading,
    setLeadId,
    deviceID,
    setDeviceID,
    setSource,
    webinarDetail,
    setWebinarDetail,
  } = useContext(Context);
  const [mobileError, setMobileError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [countryError, setCountryError] = React.useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("refcode");
  const categoryName = webinarDetail?.[0]?.category_name || "";
  const title = webinarDetail?.[0]?.title || "";
  const handleNumChange = (e) => {
    setNum(e.target.value);
    setMobileError("");
  };
  const handleName = (e) => {
    setName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
    setNameError("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };
  const handleCountry = (e) => {
    setCountry(e.target.value);
    setCountryError("");
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    let mobErr = "";
    let namErr = "";
    let emailErr = "";
    let countryErr = "";
    if (!validator.isEmail(email)) {
      emailErr = "Enter a Valid Email ID";
    }
    if (!name || name.trim() === "" || name?.length < 3 || name?.length > 50) {
      namErr = "Please fill out this field";
    }
    if (num?.toString().length !== 10) {
      mobErr = "Please fill out this field";
    }
    if (country == "") {
      countryErr = "Please fill out this field";
    }
    if (emailErr || mobErr || namErr || countryErr) {
      setEmailError(emailErr);
      setMobileError(mobErr);
      setNameError(namErr);
      setCountryError(countryErr);
      setLoading(false);
    } else {
      try {
        // const { data, error } = await register({ mobile_no: num });
        // if (data?.stutus == "success") {
        getIP();
        setCategoryID(catID);
        setUrlData({
          ref: searchParams.get("utm_refcode"),
          utm_source: searchParams.get("utm_source"),
          medium: searchParams.get("utm_medium"),
          campaign: searchParams.get("utm_campaign"),
        });
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(String(CAPTCHA_KEY), { action: "submit" })
            .then(async (token) => {
              const { data: data1 } = await leadApi({
                mobile_no: num,
                captchaToken: token,
                leadInfo: [
                  {
                    user_name: name,
                    contact_number: num,
                    email: email,
                    city: "NA",
                    state: state,
                    category_code: catID,
                    source: `Smc Website | ${catID}`,
                    ref: searchParams.get("utm_refcode"),
                    utm_source: searchParams.get("utm_source"),
                    medium: searchParams.get("utm_medium"),
                    campaign: searchParams.get("utm_campaign"),
                    product: catID,
                    country: country,
                    ip: ipAddress,
                    device: deviceID,
                    status: "Otp sent",
                    client_id: "",
                    sub_query: "",
                    existing_customer: "",
                    explain_query: "",
                    // about_lead: `${categoryName} | ${title}`,
                  },
                ],
              });
              if (data1) {
                setLeadId(String(data1?.data[0]));
                window.grecaptcha.ready(() => {
                  window.grecaptcha
                    .execute(String(CAPTCHA_KEY), { action: "submit" })
                    .then(async (token) => {
                      const { data: data2 } = await sendOtpApi({
                        mobile_no: num,
                        captchaToken: token,
                        sms_type: smsType ? smsType : "sms_common",
                        user_name: name,
                      });
                      if (data2) {
                        setLoading(false);
                        navigate("/validateotp");
                      } else {
                        setLoading(false);
                      }
                    });
                });
              } else {
                setLoading(false);
              }
            });
        });
        // } else {
        //   setLoading(false);
        //   console.log(error);
        // }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      // finally {
      //   setLoading(false);
      // }
    }
  };
  const getIP = async () => {
    try {
      const res = await axios.get("https://api.ipify.org?format=json");
      setIPAddress(res?.data?.ip);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let userAgent = navigator.userAgent;
    setSource(`Smc Website | ${catID}`);
    if (/Android/i.test(userAgent)) {
      setDeviceID("Android device.");
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setDeviceID("iOS device.");
    } else if (/Macintosh/i.test(userAgent)) {
      setDeviceID("macOS device.");
    } else if (/Windows/i.test(userAgent)) {
      setDeviceID("Windows device.");
    } else {
      setDeviceID("Unknown Device");
    }
    getIP();
    loadReCaptchaScript();
  }, []);
  const ALPHA_REGEX = /^[a-zA-Z ]+$/;

  return (
    <>
      <div className={catID == "FPI" ? "none" : `form`}>
        <div className="formbg">
          <h4>Fill your details</h4>
          <Box component="form" autoComplete="off" onSubmit={onSubmit}>
            <ul>
              <li>
                <TextField
                  id="filled-basic"
                  label="Name"
                  variant="standard"
                  error={!!nameError}
                  helperText={nameError}
                  value={name}
                  onChange={handleName}
                  inputProps={{
                    maxLength: 50,
                    type: "text",
                  }}
                  onKeyDown={(event) => {
                    if (!ALPHA_REGEX.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  disabled={loading}
                />
              </li>
              <li>
                <TextField
                  id="filled-basic"
                  label="Email"
                  variant="standard"
                  value={email}
                  type="text"
                  error={!!emailError}
                  helperText={emailError}
                  onChange={handleEmail}
                  disabled={loading}
                />
              </li>
              <li>
                <TextField
                  id="filled-basic"
                  label="Mobile No."
                  variant="standard"
                  type="number"
                  onChange={handleNumChange}
                  value={num}
                  error={!!mobileError}
                  helperText={mobileError}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, e.target.maxLength);
                  }}
                  inputProps={{
                    minLength: 10,
                    maxLength: 10,
                    type: "number",
                  }}
                  disabled={loading}
                />
              </li>
              <li>
                <CountrySearch
                  countryError={countryError}
                  setCountryError={setCountryError}
                  color={"grey"}
                />
              </li>
              <li>
                <button type="submit">Start Now</button>
              </li>
            </ul>
          </Box>
        </div>
      </div>
    </>
  );
};

import React from "react";
import { NavLink } from "react-router-dom";
import ImportantLinks from "./ImportantLinks";

const ReferAndEarn = ({ isMobile }) => {
  return (
    <>
      <div
        className="webcent"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="refernearn mainsection">
                <div className="refernimg leftsection dshow">
                  <img
                    src="./images/refer-n-earn.webp"
                    alt=""
                    // style={{ width: isMobile ? 350 : 402, height: 376 }}
                    className="responsive"
                  />
                </div>
                <div className="referntitle rightsection">
                  <div className="title">
                    <span style={{ marginLeft: "-2px" }}>REFER & EARN</span>
                    <h3>Refer More. Earn More.</h3>
                  </div>
                  <h4>
                    Refer your friend & earn <br />
                    <span className="finr"></span>1000 Amazon gift voucher for
                    both.{" "}
                  </h4>
                  <div className="mbtn">
                    <div className="btnn">
                      <span>
                        <NavLink to="/invest/refer-earn">Earn Now</NavLink>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="webcent pt-0"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Need Support. Our Assistance.</h3>
              </div>
              <div className="supportbox">
                <div className="supportcont">
                  <ul>
                    <li>
                      <span>
                        <img
                          src="./images/call.webp"
                          alt="Call"
                          style={{ width: 44, height: 44 }}
                          className="responsive"
                        />
                      </span>
                      <p>
                        <a href="tel:1800110909">Toll Free: 1800 11 0909</a>
                      </p>
                      |
                      <p>
                        <a href="tel:01130111000">Tel: 011-30111000</a>
                      </p>
                    </li>

                    <li>
                      <span>
                        <img
                          src="./images/mail.webp"
                          alt="Email"
                          className="responsive"
                          style={{
                            transform: "rotate(180deg)",
                            width: 47,
                            height: 33,
                          }}
                        />
                      </span>
                      <p>
                        <a href="mailto:smc.care@smctradeonline.com">
                          smc.care@smcindiaonline.com
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferAndEarn;

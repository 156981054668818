import React from "react";
import Icon01 from "../images/icon01.webp";
import Icon02 from "../images/icon02.webp";
import Icon03 from "../images/icon03.webp";
import Icon04 from "../images/icon04.webp";

export const Benefits = () => {
  return (
    <>
      <div
        className="webcent"
        id="Account"
        data-aos="fade-in"
        data-aos-offset="10"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>One account. Multiple benefits.</h3>
              </div>
              <div className="iconrow">
                <ul>
                  <li>
                    <span>
                      <img
                        src="./images/icon01.webp"
                        alt="AMC for Lifetime"
                        className="responsive"
                        style={{ width: 55, height: 54 }}
                      />{" "}
                    </span>
                    <p>
                      <strong>Free</strong>AMC (for 1st Year)
                    </p>
                  </li>
                  <li>
                    <span>
                      <img
                        src="./images/icon04.webp"
                        alt="Premium Telegram Channel Subscription"
                        className="responsive"
                        style={{ width: 55, height: 54 }}
                      />
                    </span>
                    <p>
                      <strong>Free</strong>Research advisory
                      <br />
                      for better decisions
                    </p>
                  </li>
                  <li>
                    <span>
                      <img
                        src="./images/icon02.webp"
                        alt="Research Webinars"
                        className="responsive"
                        style={{ width: 55, height: 54 }}
                      />
                    </span>
                    <p>
                      <strong>Free</strong>research webinars
                      <br />
                      for knowledge enhancement
                    </p>
                  </li>
                  <li>
                    <span>
                      <img
                        src="./images/icon03.webp"
                        alt="Dedicated RM Support"
                        className="responsive"
                        style={{ width: 55, height: 54 }}
                      />
                    </span>
                    <p>
                      <strong>Free</strong>dedicated RM <br />
                      for better money management
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

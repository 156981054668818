import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import styled from '@mui/system/styled';
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context";
import { leadApi } from "../../utils/APICollection";
import { CAPTCHA_KEY } from "../../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import validator from "validator";
import axios from "axios";
import { grievanceLeadApi } from "../../utils/APICollection";
import { ThreeDots } from "react-loader-spinner";

const RaiseComplaintDialog = ({ open, setOpen, catID = " Grievance Redressal", setSubmitRequestDialog }) => {
    const [natureOfQuery, setNatureOfQuery] = React.useState("");
    const [describeQuery, setDescribeQuery] = React.useState("");
    const [dotsLoader, setDotsLoader] = useState(false);
    const {
        num,
        setNum,
        name,
        setName,
        email,
        setEmail,
        state,
        city,
        setCategoryID,
        setUrlData,
        ipAddress,
        setIPAddress,
        loading,
        setLoading,
        setLeadId,
        deviceID,
        setDeviceID,
        setSource,
        interactionId,
        setInteractionId
    } = useContext(Context);
    const [mobileError, setMobileError] = React.useState("");
    const [emailError, setEmailError] = React.useState("");
    const [nameError, setNameError] = React.useState("");

    const [cityError, setCityError] = React.useState("");

    const [searchParams, setSearchParams] = useSearchParams();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    searchParams.get("refcode");
    const handleClose = (event, reason) => {
        if (reason === "backdropClick") return; // Prevent closing when clicking outside
        setOpen(false);
    };

    const getIP = async () => {
        try {
            const res = await axios.get("https://api.ipify.org?format=json");

            setIPAddress(res?.data?.ip);
        } catch (error) {
            console.log(error);
        }
    };
    const StyledHelperText = styled('p')({
        margin: 0,
        fontSize: '14px',
        fontWeight: 500,
        position: 'absolute',
        bottom: '15px', // Position the text below the input
        right: window.innerWidth <= 540 ? '100px' : '30px',      // Align it to the right
        color: 'black',   // Style it as needed
    });
    // const onSubmit = async (e) => {
    //     e.preventDefault();
    //     // if (loading) {
    //     //     return;
    //     // }
    //     setLoading(true);

    //     let mobErr = "";
    //     let namErr = "";
    //     let cityErr = "";
    //     let emailErr = "";

    //     if (false) {
    //         // emailErr = "Enter a Valid Email ID";
    //     }

    //     // if (!name || name.trim() === "" || name?.length < 3 || name?.length > 50) {
    //     //     namErr = "Please fill out this field";
    //     // }

    //     // if (num?.toString().length !== 10) {
    //     //     mobErr = "Please fill out this field";
    //     // }

    //     // if (city == "") {
    //     //     cityErr = "Please fill out this field";
    //     // }

    //     // if (emailErr || mobErr || namErr || cityErr) {
    //     //     setEmailError(emailErr);
    //     //     setMobileError(mobErr);
    //     //     setNameError(namErr);
    //     //     setCityError(cityErr);
    //     //     setLoading(false);
    //     // } 
    //     else {
    //         try {
    //             // const { data, error } = await register({ mobile_no: num });
    //             // if (data?.stutus == "success") {
    //             getIP();

    //             setCategoryID(catID);

    //             setUrlData({
    //                 ref: searchParams.get("utm_refcode"),
    //                 utm_source: searchParams.get("utm_source"),
    //                 medium: searchParams.get("utm_medium"),
    //                 campaign: searchParams.get("utm_campaign"),
    //             });
    //             window.grecaptcha.ready(() => {
    //                 window.grecaptcha
    //                     .execute(String(CAPTCHA_KEY), { action: "submit" })
    //                     .then(async (token) => {
    //                         const { data: data1 } = await leadApi({
    //                             mobile_no: num,
    //                             captchaToken: token,
    //                             leadInfo: [
    //                                 {
    //                                     user_name: name,
    //                                     contact_number: num,
    //                                     email: email,
    //                                     city: city,
    //                                     state: state,
    //                                     category_code: "NRI",
    //                                     source: `Smc Website | ${catID}`,
    //                                     ref: searchParams.get("utm_refcode"),
    //                                     utm_source: searchParams.get("utm_source"),
    //                                     medium: searchParams.get("utm_medium"),
    //                                     campaign: searchParams.get("utm_campaign"),
    //                                     country: "",
    //                                     product: "NRI",
    //                                     ip: ipAddress,
    //                                     device: deviceID,
    //                                     status: "Otp sent",
    //                                     client_id: "",
    //                                     sub_query: natureOfQuery,
    //                                     existing_customer: "",
    //                                     explain_query: describeQuery,
    //                                 },
    //                             ],
    //                         });
    //                         setLoading(false);
    //                         setSubmitRequestDialog(true);
    //                         handleClose();
    //                         // if (data1) {
    //                         //     setLeadId(String(data1?.data[0]));
    //                         //     window.grecaptcha.ready(() => {
    //                         //         window.grecaptcha
    //                         //             .execute(String(CAPTCHA_KEY), { action: "submit" })
    //                         //             .then(async (token) => {
    //                         //                 const { data: data2 } = await sendOtpApi({
    //                         //                     mobile_no: num,
    //                         //                     sms_type: smsType ? smsType : "sms_common",
    //                         //                     user_name: name,
    //                         //                     captchaToken: token,
    //                         //                 });
    //                         //                 if (data2) {
    //                         //                     // setLoading(false);
    //                         //                     // navigate("/validateotp");
    //                         //                 } else {
    //                         //                     // setLoading(false);
    //                         //                 }
    //                         //             });
    //                         //     });
    //                         // } else {
    //                         //     // setLoading(false);
    //                         // }
    //                     });
    //             });
    //             // } else {
    //             //   setLoading(false);
    //             // }
    //         } catch (error) {
    //             console.log(error);
    //             // setLoading(false);
    //         }
    //         // finally {
    //         //   setLoading(false);
    //         // }
    //     }
    // };
    const onSubmit = async (e) => {
        e.preventDefault();
        // if (loading) {
        //     return;
        // }
        setDotsLoader(true);

        let mobErr = "";
        let namErr = "";
        let cityErr = "";
        let emailErr = "";

        const griveanceLeadPayload = {
            user_name: name,
            contact_number: num,
            email: email,
            sub_query: natureOfQuery,
            explain_query: describeQuery,
        }

        if (false) {
            // emailErr = "Enter a Valid Email ID";
        }

        // if (!name || name.trim() === "" || name?.length < 3 || name?.length > 50) {
        //     namErr = "Please fill out this field";
        // }

        // if (num?.toString().length !== 10) {
        //     mobErr = "Please fill out this field";
        // }

        // if (city == "") {
        //     cityErr = "Please fill out this field";
        // }

        // if (emailErr || mobErr || namErr || cityErr) {
        //     setEmailError(emailErr);
        //     setMobileError(mobErr);
        //     setNameError(namErr);
        //     setCityError(cityErr);
        //     setLoading(false);
        // } 
        else {
            try {
                // const { data, error } = await register({ mobile_no: num });
                // if (data?.stutus == "success") {

                const { data } = await grievanceLeadApi(griveanceLeadPayload);
                if (data.statusCode == 200) {
                    setInteractionId(data?.data[0])
                    setDotsLoader(false);
                    setSubmitRequestDialog(true);
                    handleClose();
                }
                // else {
                //     handleClose();
                //     setDotsLoader(false);
                // }

                // setLoading(false);
                // setSubmitRequestDialog(true);
                // handleClose();
                // if (data1) {
                //     setLeadId(String(data1?.data[0]));
                //     window.grecaptcha.ready(() => {
                //         window.grecaptcha
                //             .execute(String(CAPTCHA_KEY), { action: "submit" })
                //             .then(async (token) => {
                //                 const { data: data2 } = await sendOtpApi({
                //                     mobile_no: num,
                //                     sms_type: smsType ? smsType : "sms_common",
                //                     user_name: name,
                //                     captchaToken: token,
                //                 });
                //                 if (data2) {
                //                     // setLoading(false);
                //                     // navigate("/validateotp");
                //                 } else {
                //                     // setLoading(false);
                //                 }
                //             });
                //     });
                // } else {
                //     // setLoading(false);
                // }
                // });
                // });
                // } else {
                //   setLoading(false);
                // }
            } catch (error) {
                console.log(error);
                // setLoading(false);
            }
            finally {
                //   setLoading(false);
                handleClose();
                setDotsLoader(false);
            }
        }
    };
    return (
        <>
            <Dialog
                maxWidth="xs"
                fullWidth={false}
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {dotsLoader ? <>
                    <ThreeDots
                        height="100"
                        width="100"
                        radius="10"
                        color="#3AA29F"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                        visible={true}
                    />
                </> :
                    <>
                        <DialogTitle
                            id="alert-dialog-title"
                            sx={{ overflow: "hidden", pb: 0, lineHeight: "0.2" }}
                        >
                            {/* Hello how are you */}
                            <IconButton
                                aria-label="close"
                                onClick={() => {
                                    handleClose();
                                    setDescribeQuery("");
                                    setNatureOfQuery("");
                                }}
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    padding: 'initial'
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <Typography align="center" variant="body1" >
                            Please enter following details to raise your concern:
                        </Typography>
                        <DialogContent sx={{ overflow: "hidden", lineHeight: "0.2" }}>
                            <DialogContentText
                                id="alert-dialog-description"
                                sx={{ overflow: "hidden" }}
                            >
                                <Box
                                    component="form"
                                    sx={{ position: 'relative', display: 'inline-block', '& .MuiTextField-root': { m: 1, width: '45ch' } }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        id="filled-multiline-static"
                                        // label="Multiline"
                                        inputProps={{ maxLength: 250 }}
                                        multiline
                                        rows={7}
                                        placeholder="Nature of Query*"
                                        variant="filled"
                                        onChange={(e) => setNatureOfQuery(e.target.value)}
                                        InputProps={{
                                            style: {
                                                fontSize: 10,
                                                marginRight: 18
                                            },
                                            disableUnderline: true, // Disables the underline
                                        }}
                                    />
                                    <StyledHelperText><span style={{ color: "#44b6a7" }}>{natureOfQuery.length}/250</span> words</StyledHelperText>
                                </Box>
                                <Box
                                    component="form"
                                    sx={{ position: 'relative', display: 'inline-block', '& .MuiTextField-root': { m: 1, width: '45ch' } }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        id="filled-multiline-static"
                                        // label="Multiline"
                                        inputProps={{ maxLength: 250 }}
                                        multiline
                                        rows={9}
                                        placeholder="Describe Your Concern*"
                                        variant="filled"
                                        onChange={(e) => setDescribeQuery(e.target.value)}
                                        InputProps={{
                                            style: {
                                                fontSize: 10,
                                                marginRight: 18
                                            },
                                            disableUnderline: true, // Disables the underline
                                        }}
                                    />
                                    <StyledHelperText><span style={{ color: "#44b6a7" }}>{describeQuery.length}/250</span> words</StyledHelperText>
                                </Box>

                                {/* <div
                                    className="btnn"
                                    style={{ width: "-webkit-fill-available", textAlign: "center" }}
                                    onClick={onSubmit}
                                >
                                    <span >SUBMIT</span>
                                </div> */}
                                <Button
                                    sx={{
                                        fontSize: window.innerWidth <= 540 ? "8px" : "12px",
                                        color: "white",
                                        background: 'linear-gradient(80deg, #405cb2, #44b6a7)',
                                        width: "-webkit-fill-available"
                                    }}
                                    variant="contained"
                                    onClick={onSubmit}
                                    disabled={!natureOfQuery || !describeQuery}
                                >
                                    SUBMIT
                                </Button>
                            </DialogContentText>
                        </DialogContent>
                    </>
                }

            </Dialog >
        </>
    );
};
export default RaiseComplaintDialog;

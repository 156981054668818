import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import { useNavigate, useSearchParams } from "react-router-dom";
import { leadApi, sendOtpApi } from "../utils/APICollection";
import { clientApi } from "../utils/APICollection";
import { Context } from "../context";
import axios from "axios";
import CitySearch from "./common/CitySearch";
import validator from "validator";
import { CAPTCHA_KEY } from "../utils/constants";
import { loadReCaptchaScript } from "./common/FormattedDate";

const GrievancesForm = ({ sidebarWidth,
    catID,
    smsType,
    clientInfoData,
    setClientInfoData }) => {

    const navigate = useNavigate();
    const {
        num,
        setNum,
        name,
        setName,
        email,
        setEmail,
        state,
        city,
        setCategoryID,
        setUrlData,
        ipAddress,
        setIPAddress,
        loading,
        setLoading,
        setLeadId,
        deviceID,
        setDeviceID,
        setSource,
        openOtpDialog,
        setOpenOtpDialog
    } = useContext(Context);

    const [mobileError, setMobileError] = React.useState("");
    const [emailError, setEmailError] = React.useState("");
    const [nameError, setNameError] = React.useState("");
    const [cityError, setCityError] = React.useState("");

    const [searchParams, setSearchParams] = useSearchParams();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    searchParams.get("refcode");

    const handleNumChange = (e) => {
        setNum(e.target.value);
        setMobileError("");
    };

    const handleName = (e) => {
        setName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
        setNameError("");
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    }

    // const getClientInfo = async () => {
    //     const data = await clientApi({ "mobile": ["8806965644"] });
    //     console.log("client info-----", data);
    // }
    // useEffect(() => {
    //     getClientInfo();
    // }, []);

    const handleEmail = (e) => {
        setEmail(e.target.value);
        setEmailError("");
    };

    const onSubmit = async (e) => {
        console.log("on submit");
        e.preventDefault();
        if (loading) {
            return;
        }
        setLoading(true);

        let mobErr = "";
        let namErr = "";
        let emailErr = "";

        if (!validator.isEmail(email)) {
            emailErr = "Enter a Valid Email ID";
        }

        if (!name || name.trim() === "" || name?.length < 3 || name?.length > 50) {
            namErr = "Please fill out this field";
        }

        if (num?.toString().length !== 10) {
            mobErr = "Please fill out this field";
        }

        if (emailErr || mobErr || namErr) {
            setEmailError(emailErr);
            setMobileError(mobErr);
            setNameError(namErr);
            setLoading(false);
        } else {
            try {
                // const { data, error } = await register({ mobile_no: num });
                // if (data?.stutus == "success") {
                getIP();

                setCategoryID(catID);

                setUrlData({
                    ref: searchParams.get("utm_refcode"),
                    utm_source: searchParams.get("utm_source"),
                    medium: searchParams.get("utm_medium"),
                    campaign: searchParams.get("utm_campaign"),
                });
                window.grecaptcha.ready(() => {
                    window.grecaptcha
                        .execute(String(CAPTCHA_KEY), { action: "submit" })
                        .then(async (token) => {
                            const { data } = await clientApi({ "mobile": [num.toString()] });
                            // const data = {
                            //     "clientInfo": {
                            //         "data": [
                            //             {
                            //                 "clCode": "MDZ9358",
                            //                 "name": "SURAJ PRABHAKAR BACHHAV",
                            //                 "pan": "AUPPB6261R",
                            //                 "mobile": "8806965644",
                            //                 "email": "SURAJ17BACHHAV@GMAIL.COM",
                            //                 "clType": "CLI",
                            //                 "dob": "1989-06-17",
                            //                 "sex": "M"
                            //             }
                            //         ],
                            //         "meta": {
                            //             "data_load_time": "2024-11-01 18:57:39",
                            //             "message": "Data retrieved successfully for all requested items."
                            //         }
                            //     }
                            // }
                            setClientInfoData(data?.clientInfo?.data);
                            if (data?.clientInfo?.data?.length > 0) {
                                window.grecaptcha.ready(() => {
                                    window.grecaptcha
                                        .execute(String(CAPTCHA_KEY), { action: "submit" })
                                        .then(async (token) => {
                                            const { data: data2 } = await sendOtpApi({
                                                mobile_no: num,
                                                sms_type: smsType ? smsType : "sms_common",
                                                user_name: name,
                                                captchaToken: token
                                                // captchaToken: process.env.NODE_ENV,
                                            });
                                            if (data2) {
                                                setLoading(false);
                                                setOpenOtpDialog(true);
                                                // navigate("/validateotp");
                                            } else {
                                                setLoading(false);
                                                setOpenOtpDialog(true);
                                            }
                                        });
                                });


                            } else {
                                console.log("you are not a registered user")
                                setLoading(false);
                                setOpenSnackbar(true);
                            }
                            // const { data: data1 } = await leadApi({
                            //     mobile_no: num,
                            //     captchaToken: token,
                            //     leadInfo: [
                            //         {
                            //             user_name: name,
                            //             contact_number: num,
                            //             email: email,
                            //             city: city,
                            //             state: state,
                            //             category_code: catID,
                            //             source: `Smc Website | ${catID}`,
                            //             ref: searchParams.get("utm_refcode"),
                            //             utm_source: searchParams.get("utm_source"),
                            //             medium: searchParams.get("utm_medium"),
                            //             campaign: searchParams.get("utm_campaign"),
                            //             country: "",
                            //             product: catID,
                            //             ip: ipAddress,
                            //             device: deviceID,
                            //             status: "Otp sent",
                            //             client_id: "",
                            //             sub_query: "",
                            //             existing_customer: "",
                            //             explain_query: "",
                            //         },
                            //     ],
                            // });

                            // if (data) {
                            //     setLeadId(String(data?.data[0]));
                            //     window.grecaptcha.ready(() => {
                            //         window.grecaptcha
                            //             .execute(String(CAPTCHA_KEY), { action: "submit" })
                            //             .then(async (token) => {
                            //                 const { data: data2 } = await sendOtpApi({
                            //                     mobile_no: num,
                            //                     sms_type: smsType ? smsType : "sms_common",
                            //                     user_name: name,
                            //                     captchaToken: token,
                            //                 });
                            //                 if (data2) {
                            //                     setLoading(false);
                            //                     navigate("/validateotp");
                            //                 } else {
                            //                     setLoading(false);
                            //                 }
                            //             });
                            //     });
                            // } else {
                            //     setLoading(false);
                            // }
                        });
                });
                // } else {
                //   setLoading(false);
                // }
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
            // finally {
            //   setLoading(false);
            // }
        }
    };

    const getIP = async () => {
        try {
            const res = await axios.get("https://api.ipify.org?format=json");

            setIPAddress(res?.data?.ip);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        let userAgent = navigator.userAgent;
        setSource(`Smc Website | ${catID}`);
        if (/Android/i.test(userAgent)) {
            setDeviceID("Android device.");
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            setDeviceID("iOS device.");
        } else if (/Macintosh/i.test(userAgent)) {
            setDeviceID("macOS device.");
        } else if (/Windows/i.test(userAgent)) {
            setDeviceID("Windows device.");
        } else {
            setDeviceID("Unknown Device");
        }
        getIP();
        loadReCaptchaScript();
    }, []);

    const ALPHA_REGEX = /^[a-zA-Z ]+$/;
    return (
        <div
            className="opendemat formbg bg-white rounded-0 "
        // style={containerStyle}
        >
            <Snackbar
                message="You are not a Registered User"
                autoHideDuration={3000}
                open={openSnackbar}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                sx={{ backgroundColor: '#3498db' }}
            />


            <h3 className="m-0">Please enter following details to raise your concern</h3>
            <Box component="form" autoComplete="off" onSubmit={onSubmit}>
                <ul>
                    <li>
                        <TextField
                            id="filled-basic"
                            label="Name*"
                            variant="standard"
                            error={!!nameError}
                            helperText={nameError}
                            value={name}
                            onChange={handleName}
                            inputProps={{
                                maxLength: 50,
                                type: "text",
                            }}
                            onKeyDown={(event) => {
                                if (!ALPHA_REGEX.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            disabled={loading}
                        />
                    </li>
                    <li>
                        <TextField
                            id="filled-basic"
                            label="Email*"
                            variant="standard"
                            value={email}
                            type="text"
                            error={!!emailError}
                            helperText={emailError}
                            onChange={handleEmail}
                            disabled={loading}
                        />
                    </li>
                    <li>
                        <TextField
                            id="filled-basic"
                            label="Mobile No.*"
                            variant="standard"
                            type="number"
                            onChange={handleNumChange}
                            value={num}
                            error={!!mobileError}
                            helperText={mobileError}
                            onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value))
                                    .toString()
                                    .slice(0, e.target.maxLength);
                            }}
                            inputProps={{
                                minLength: 10,
                                maxLength: 10,
                                type: "number",
                            }}
                            disabled={loading}
                        />
                    </li>
                    <li>
                        <button type="submit" style={{ marginTop: 40 }}>
                            SUBMIT
                        </button>
                    </li>
                </ul>
            </Box>
        </div>
    );
};

export default GrievancesForm;

import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ShareholdingDonut } from "../../../../charts/ShareholdingDonut";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { getShareHolding } from "../../../../utils/APICollection";

const StockShareHoldings = () => {
  const [shareHoldingData, setShareHoldingData] = useState([]);
  const [index, setIndex] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#44b6a7" : "#308fe8",
    },
  }));
  console.log("stock share holding---", shareHoldingData);
  const getMonth = (index) => {
    const monthList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthList[index - 1];
  };
  const fetchShareHoldingData = async () => {
    try {
      const data = await getShareHolding(
        window.location.pathname.split("/")[3]
      );
      setShareHoldingData(data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchShareHoldingData();
  }, [window.location.pathname.split("/")[3]]);

  const toggleRows1 = () => {
    setShowAll(!showAll);
  };
  return (
    <div className="webcent">
      <div className="returncalulator">
        <div className="calheading">
          <h3>Shareholding Pattern</h3>
        </div>
        {(Array.isArray(shareHoldingData) && shareHoldingData.length > 0) ? (
          <div className="howtoapply mt-3">
            <Tabs>
              <div className="tradingben m-0">
                <div className="iconrow noicon border-0">
                  <TabList>
                    {shareHoldingData === null ? (
                      <>NoData Available</>
                    ) : (
                      shareHoldingData?.map((data, index) => (
                        <Tab onClick={() => setIndex(index)}>
                          <h4>
                            {getMonth(
                              Number(JSON.stringify(data?.YRC).substring(4, 6))
                            ) +
                              " " +
                              JSON.stringify(data?.YRC).substring(0, 4)}
                          </h4>
                        </Tab>
                      ))
                    )}
                  </TabList>
                </div>
              </div>
              <TabPanel>
                <div className="ipoodlist">
                  <div className="numberslines">
                    {Object.keys(shareHoldingData[0] || [])
                      .slice(2, 6)
                      .map((data) => (
                        <div className="numberlinerow">
                          {data}

                          <BorderLinearProgress
                            variant="determinate"
                            value={shareHoldingData[index]?.[data]}
                          />

                          {/* <div className="progress-bar">
                                              <div
                                                className={`progress w-50`}
                                              ></div>
                                            </div> */}
                          <div className="processpercent">
                            {shareHoldingData[index]?.[data].toFixed(2)}%
                          </div>
                        </div>
                      ))}
                  </div>
                  {showAll ? (
                    <button
                      className="showallcont"
                      onClick={toggleRows1}
                      style={{ visibility: "hidden" }}
                    >
                      See Less
                    </button>
                  ) : (
                    <button
                      className="showallcont"
                      onClick={toggleRows1}
                      style={{ visibility: "hidden" }}
                    >
                      See All
                    </button>
                  )}
                  <ShareholdingDonut data={shareHoldingData[0]} />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="ipoodlist">
                  <div className="numberslines">
                    {Object.keys(shareHoldingData[0] || [])
                      .slice(2, 6)
                      .map((data) => (
                        <div className="numberlinerow">
                          {data}
                          {/* <div className="progress-bar ">
                                              <div className="progress w-75"></div>
                                            </div> */}
                          <BorderLinearProgress
                            variant="determinate"
                            value={shareHoldingData[index]?.[data]}
                          />
                          <div className="processpercent">
                            {shareHoldingData[index]?.[data].toFixed(2)}%
                          </div>
                        </div>
                      ))}
                  </div>
                  {showAll ? (
                    <button
                      className="showallcont"
                      onClick={toggleRows1}
                      style={{ visibility: "hidden" }}
                    >
                      See Less
                    </button>
                  ) : (
                    <button
                      className="showallcont"
                      onClick={toggleRows1}
                      style={{ visibility: "hidden" }}
                    >
                      See All
                    </button>
                  )}
                  <ShareholdingDonut data={shareHoldingData[1]} />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="ipoodlist">
                  <div className="numberslines">
                    {Object.keys(shareHoldingData[0] || [])
                      .slice(2, 6)
                      .map((data) => (
                        <div className="numberlinerow">
                          {data}
                          <BorderLinearProgress
                            variant="determinate"
                            value={shareHoldingData[index]?.[data]}
                          />
                          {/* <div className="progress-bar ">
                                              <div className="progress w-75"></div>
                                            </div> */}
                          <div className="processpercent">
                            {shareHoldingData[index]?.[data].toFixed(2)}%
                          </div>
                        </div>
                      ))}
                  </div>
                  {showAll ? (
                    <button
                      className="showallcont"
                      onClick={toggleRows1}
                      style={{ visibility: "hidden" }}
                    >
                      See Less
                    </button>
                  ) : (
                    <button
                      className="showallcont"
                      onClick={toggleRows1}
                      style={{ visibility: "hidden" }}
                    >
                      See All
                    </button>
                  )}
                  <ShareholdingDonut data={shareHoldingData[2]} />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="ipoodlist">
                  <div className="numberslines">
                    {Object.keys(shareHoldingData[0] || [])
                      .slice(2, 6)
                      .map((data) => (
                        <div className="numberlinerow">
                          {data}
                          <BorderLinearProgress
                            variant="determinate"
                            value={shareHoldingData[index]?.[data]}
                          />
                          {/* <div className="progress-bar ">
                                              <div className="progress w-75"></div>
                                            </div> */}
                          <div className="processpercent">
                            {shareHoldingData[index]?.[data].toFixed(2)}%
                          </div>
                        </div>
                      ))}
                  </div>
                  {showAll ? (
                    <button
                      className="showallcont"
                      onClick={toggleRows1}
                      style={{ visibility: "hidden" }}
                    >
                      See Less
                    </button>
                  ) : (
                    <button
                      className="showallcont"
                      onClick={toggleRows1}
                      style={{ visibility: "hidden" }}
                    >
                      See All
                    </button>
                  )}
                  <ShareholdingDonut data={shareHoldingData[3]} />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="ipoodlist">
                  <div className="numberslines">
                    {Object.keys(shareHoldingData[0] || [])
                      .slice(2, 6)
                      .map((data) => (
                        <div className="numberlinerow">
                          {data}
                          <BorderLinearProgress
                            variant="determinate"
                            value={shareHoldingData[index]?.[data]}
                          />
                          {/* <div className="progress-bar ">
                                              <div className="progress w-75"></div>
                                            </div> */}
                          <div className="processpercent">
                            {shareHoldingData[index]?.[data].toFixed(2)}%
                          </div>
                        </div>
                      ))}
                  </div>
                  {showAll ? (
                    <button
                      className="showallcont"
                      onClick={toggleRows1}
                      style={{ visibility: "hidden" }}
                    >
                      See Less
                    </button>
                  ) : (
                    <button
                      className="showallcont"
                      onClick={toggleRows1}
                      style={{ visibility: "hidden" }}
                    >
                      See All
                    </button>
                  )}
                  <ShareholdingDonut data={shareHoldingData[4]} />
                </div>
              </TabPanel>
            </Tabs>
          </div>
        ) : <>Share Holding Data Not Available</>}
      </div>
    </div>
  );
};

export default StockShareHoldings;

import { Grid, Box, Typography } from "@mui/material";
const GrievanceComplaint = () => {
    return (
        <>
            <Grid container spacing={3} marginBottom={5}>
                <Grid item xs={12} sm={6} md={12} >
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="black"
                        sx={{ pl: 1 }}
                    // gutterBottom
                    >
                        Get in touch with these easy steps to raise a complaint
                    </Typography>
                    <Box
                        p={1}
                        borderRadius={2}
                        bgcolor="#FFF"
                        display="flex"
                        flexDirection="row"
                        // alignItems="center"
                        height="auto"
                    // textAlign="center"
                    >
                        {/* Number */}
                        <Box
                            bgcolor="#C6F5EE"
                            p={2}
                            borderRadius="50%"
                            width={30}
                            height={30}
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                        >
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="#058C7A"

                            // gutterBottom
                            >
                                1
                            </Typography>
                        </Box>

                        {/* Title */}
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="inherit" fontWeight="bold" gutterBottom={false}>
                                Go to the Support Section:
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Visit the SMC Trade Online website.
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Hover over the Support section on the website header.
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Click on Grievance Redressal Portal.
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        p={1}
                        borderRadius={2}
                        bgcolor="#FFF"
                        display="flex"
                        flexDirection="row"
                        // alignItems="center"
                        height="auto"
                    // textAlign="center"
                    >
                        {/* Number */}
                        <Box
                            bgcolor="#C6F5EE"
                            p={2}
                            borderRadius="50%"
                            width={30}
                            height={30}
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                        >
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="#058C7A"
                            // gutterBottom
                            >
                                2
                            </Typography>
                        </Box>

                        {/* Title */}
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="inherit" fontWeight="bold" gutterBottom={false}>
                                Authenticate with Mobile Number:
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                On the Grievance Redressal Portal page, enter your mobile number, name, and email.
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Click Submit to receive an OTP (One-Time Password) via SMS.
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        p={1}
                        borderRadius={2}
                        bgcolor="#FFF"
                        display="flex"
                        flexDirection="row"
                        // alignItems="center"
                        height="auto"
                    // textAlign="center"
                    >
                        {/* Number */}
                        <Box
                            bgcolor="#C6F5EE"
                            p={2}
                            borderRadius="50%"
                            width={30}
                            height={30}
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                        >
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="#058C7A"
                            // gutterBottom
                            >
                                3
                            </Typography>
                        </Box>

                        {/* Title */}
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="inherit" fontWeight="bold" gutterBottom={false}>
                                Enter OTP:
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Enter the 6-digit OTP sent to your registered mobile number.
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                If the OTP is not received, click Resend OTP after 60 seconds.
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        p={1}
                        borderRadius={2}
                        bgcolor="#FFF"
                        display="flex"
                        flexDirection="row"
                        // alignItems="center"
                        height="auto"
                    // textAlign="center"
                    >
                        {/* Number */}
                        <Box
                            bgcolor="#C6F5EE"
                            p={2}
                            borderRadius="50%"
                            width={30}
                            height={30}
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                        >
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="#058C7A"
                            // gutterBottom
                            >
                                4
                            </Typography>
                        </Box>

                        {/* Title */}
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="inherit" fontWeight="bold" gutterBottom={false}>
                                Access Complaint Form:
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                After successful OTP verification, the complaint form will appear.
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Fill in the Nature of Query and Detailed Remarks to describe your issue.
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        p={1}
                        borderRadius={2}
                        bgcolor="#FFF"
                        display="flex"
                        flexDirection="row"
                        // alignItems="center"
                        height="auto"
                    >
                        <Box
                            bgcolor="#C6F5EE"
                            p={2}
                            borderRadius="50%"
                            width={30}
                            height={30}
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                        >
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="#058C7A"
                            // gutterBottom
                            >
                                5
                            </Typography>
                        </Box>
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="inherit" fontWeight="bold" gutterBottom={false}>
                                Submit Complaint:
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Click Submit to register your complaint.
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                A confirmation message with a unique complaint ID will be displayed.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
export default GrievanceComplaint;
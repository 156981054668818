import React from "react";
import AjaySir from "../../../images/Ajay-Sir-01.png";

const AboutBanner = ({ isMobile, isMobileLandscape }) => {
  return (
    <>
      <div className="about-banner">
        <h1 style={{ fontSize: isMobile && "23px" }}>
          Redefining Financial Services
        </h1>
        <h2 style={{ fontSize: isMobile && "23px" }}>
          with Customer-Centric Approach
        </h2>
        <p style={{ fontSize: isMobile && "14px", width: isMobile && "100%" }}>
          We aspire to be a global organization having dominant position in
          financial & investment services through customer centric approach.
        </p>
        {isMobile && (
          <>
            <h6 className="greenbgm">SMC is at your Service</h6>
            <h6 className="bluebgm">for all your Investment Needs</h6>
          </>
        )}
      </div>
      <div className={isMobile ? "ceodetailm" : "ceodetail"}>
        <img src={AjaySir} alt="ajaysir" />
        <div>
          {!isMobile && <p className="greenbgabout">SMC is at your Service</p>}
          {!isMobile && (
            <p className="bluebgabout">for all your Investment Needs</p>
          )}
          <p className="ceoname" style={{ fontSize: isMobile && "22px" }}>
            Ajay Garg
          </p>
          <p style={{ fontWeight: "bold", fontSize: isMobile && "13px" }}>
            Director & CEO
          </p>
          <p
            style={{
              fontWeight: "bold",
              // lineHeight: "0.1rem",
              fontSize: isMobile && "11px",
            }}
          >
            SMC Global Securities Ltd.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutBanner;

import { formatNumber } from "../../../../components/common";
const MinInvAmt = ({ fundDetails }) => {
    return (
        <div className="returncalulator">
            <div className="calheading">
                <p className="text-black">
                    <strong>Minimum investments amounts</strong>
                </p>
            </div>
            <div className="enquitytable">
                <ul>
                    <li>
                        <span>Min. for 1st investment</span>
                        <span>
                            <strong>
                                <i className="finr"></i>
                                {fundDetails?.min_initial_investment &&
                                    formatNumber(
                                        fundDetails?.min_initial_investment
                                    )}
                            </strong>
                        </span>
                    </li>
                    <li>
                        <span>Min. for 2nd investment onwards</span>
                        <span>
                            <strong>
                                <i className="finr"></i>
                                {fundDetails?.min_subsequent_investment &&
                                    formatNumber(
                                        fundDetails?.min_subsequent_investment
                                    )}
                            </strong>
                        </span>
                    </li>
                </ul>

                <ul>
                    <li>
                        <span>Min. for SIP</span>
                        <span>
                            <strong>
                                <i className="finr"></i>
                                {fundDetails?.min_subsequent_sip_investment &&
                                    formatNumber(
                                        fundDetails?.min_subsequent_sip_investment
                                    )}
                            </strong>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default MinInvAmt;
const IpoSubscriptionSummary = ({ subscriptionDetails, ipoTimeline, openIpoData }) => {
    return (
        <>
            <p>{subscriptionDetails?.BusinessSummary}</p>

            <p>
                {subscriptionDetails?.CompanyName} Infra IPO will
                be a fresh issue. The net proceeds raised through
                this IPO will be used to fund the company’s
                working capital requirements and for general
                corporate purposes.
            </p>
            <h3>{subscriptionDetails?.CompanyName}</h3>
            <h5>{subscriptionDetails?.CompanyName} IPO date</h5>

            <p>
                Investors who have been allotted shares can expect
                them to be credited to their demat account on{" "}
                {ipoTimeline?.CreditofsharestoDemataccountDate}.
                The{subscriptionDetails?.CompanyName} Infra IPO
                listing date is {ipoTimeline?.ListingDate}. The
                listing date is the date on which the shares of a
                company get listed on the bourses – NSE and BSE.{" "}
            </p>
            <h5>
                {subscriptionDetails?.CompanyName} IPO Price Band
            </h5>
            <p>
                The entire IPO is a book-built issue. The
                {subscriptionDetails?.CompanyName} Infra IPO price
                band has been set between ₹
                {subscriptionDetails?.PriceRangeFrom} and ₹
                {subscriptionDetails?.PriceRangeTo} per share.
                Interested investors can choose a price within
                this band to apply for the IPO.{" "}
            </p>
            <p>
                The{subscriptionDetails?.CompanyName} Infra IPO
                listing price will be determined on{" "}
                {ipoTimeline?.ListingDate}. The listing price is
                the price at which a company’s shares debut on the
                stock exchanges.
            </p>
            <h5>
                {subscriptionDetails?.CompanyName} Infra Limited
                IPO Lot size
            </h5>
            <p>
                The{subscriptionDetails?.CompanyName}Infra IPO
                details have been declared. The lot size for the
                IPO has been set at {openIpoData?.MIN_APPLN}{" "}
                shares and investors can apply for a minimum of 1
                lot. The issue size for the{" "}
                {subscriptionDetails?.CompanyName}
                Infra IPO is approx ₹ {
                    openIpoData?.IssueSize
                }{" "}
                crore.{" "}
            </p>
            <h3>
                {subscriptionDetails?.CompanyName} IPO Objective
            </h3>
            <h5>
                The net from the fresh issues will be utilised for
            </h5>
            <p>{subscriptionDetails?.CompanyHistory}</p>

            <h3>
                Should you invest in{" "}
                {subscriptionDetails?.CompanyName} Infra IPO?
            </h3>
            <h5>
                Here are the top reasons to consider befor
                investing in the IPO :
            </h5>
        </>
    )
}

export default IpoSubscriptionSummary;
import React from "react";
import Chart from "react-apexcharts";
import { ThreeDots } from "react-loader-spinner";

export const CandleStick = ({ tenure, priceData, isLoading }) => {
  const formatedData = priceData?.map((item) => {
    return {
      x: item?.upd_Date,
      y: [
        item?.Open || item?.open,
        item?.high,
        item?.low,
        item?.price || item?.Price,
      ],
    };
  });
  const series = [
    {
      data: formatedData,
      // sample data format
      // data: [
      //   {
      //     x: new Date(1538778600000),
      //     y: [6629.81, 6650.5, 6623.04, 6633.33]
      //   },
      //   {
      //     x: new Date(1538780400000),
      //     y: [6632.01, 6643.59, 6620, 6630.11]
      //   },
      //   {
      //     x: new Date(1538782200000),
      //     y: [6630.71, 6648.95, 6623.34, 6635.65]
      //   },
      //   {
      //     x: new Date(1538784000000),
      //     y: [6635.65, 6651, 6629.67, 6638.24]
      //   }
      // ]
      // data: [
      //   [
      //     1706067900,
      //     165.0,
      //     165.8,
      //     151.1,
      //     152.35
      //   ],
      //   [
      //     1706068200,
      //     152.7,
      //     161.5,
      //     152.55,
      //     160.3
      //   ],
      //   [
      //     1706068500,
      //     160.25,
      //     166.0,
      //     159.55,
      //     165.0
      //   ],
      //   [
      //     1706068800,
      //     165.5,
      //     167.75,
      //     164.45,
      //     166.2
      //   ],
      //   [
      //     1706069100,
      //     166.15,
      //     167.9,
      //     164.1,
      //     167.25
      //   ],
      //   [
      //     1706069400,
      //     167.3,
      //     168.3,
      //     166.4,
      //     168.2
      //   ],
      //   [
      //     1706069700,
      //     168.15,
      //     171.9,
      //     167.6,
      //     171.5
      //   ],
      //   [
      //     1706070000,
      //     171.55,
      //     173.15,
      //     169.6,
      //     170.7
      //   ],
      //   [
      //     1706070300,
      //     170.75,
      //     171.4,
      //     169.35,
      //     170.75
      //   ],
      //   [
      //     1706070600,
      //     170.7,
      //     171.25,
      //     167.65,
      //     168.5
      //   ],
      //   [
      //     1706070900,
      //     168.35,
      //     169.8,
      //     167.35,
      //     167.45
      //   ],
      //   [
      //     1706071200,
      //     167.35,
      //     169.0,
      //     167.2,
      //     169.0
      //   ],
      //   [
      //     1706071500,
      //     168.9,
      //     169.25,
      //     167.75,
      //     168.3
      //   ],
      //   [
      //     1706071800,
      //     168.1,
      //     169.25,
      //     168.0,
      //     168.95
      //   ],
      //   [
      //     1706072100,
      //     169.0,
      //     170.35,
      //     168.7,
      //     169.9
      //   ],
      //   [
      //     1706072400,
      //     170.1,
      //     170.7,
      //     169.55,
      //     169.85
      //   ],
      //   [
      //     1706072700,
      //     169.9,
      //     170.4,
      //     169.25,
      //     170.15
      //   ],
      //   [
      //     1706073000,
      //     170.2,
      //     170.35,
      //     169.25,
      //     169.35
      //   ],
      //   [
      //     1706073300,
      //     169.25,
      //     169.7,
      //     168.6,
      //     169.25
      //   ],
      //   [
      //     1706073600,
      //     169.35,
      //     170.25,
      //     169.25,
      //     170.2
      //   ],
      //   [
      //     1706073900,
      //     170.15,
      //     170.75,
      //     169.75,
      //     169.8
      //   ],
      //   [
      //     1706074200,
      //     169.9,
      //     170.3,
      //     169.5,
      //     169.7
      //   ],
      //   [
      //     1706074500,
      //     169.6,
      //     169.8,
      //     168.7,
      //     169.1
      //   ],
      //   [
      //     1706074800,
      //     169.0,
      //     169.3,
      //     167.6,
      //     168.4
      //   ],
      //   [
      //     1706075100,
      //     168.3,
      //     169.5,
      //     168.15,
      //     169.35
      //   ],
      //   [
      //     1706075400,
      //     169.45,
      //     169.45,
      //     168.55,
      //     168.8
      //   ],
      //   [
      //     1706075700,
      //     168.75,
      //     168.9,
      //     166.65,
      //     167.1
      //   ],
      //   [
      //     1706076000,
      //     167.05,
      //     167.15,
      //     165.3,
      //     166.8
      //   ],
      //   [
      //     1706076300,
      //     166.85,
      //     168.2,
      //     166.7,
      //     167.4
      //   ],
      //   [
      //     1706076600,
      //     167.5,
      //     167.8,
      //     166.8,
      //     167.4
      //   ],
      //   [
      //     1706076900,
      //     167.3,
      //     167.45,
      //     166.0,
      //     166.35
      //   ],
      //   [
      //     1706077200,
      //     166.2,
      //     167.1,
      //     165.9,
      //     166.9
      //   ],
      //   [
      //     1706077500,
      //     167.0,
      //     167.4,
      //     166.3,
      //     166.65
      //   ],
      //   [
      //     1706077800,
      //     166.75,
      //     166.75,
      //     164.35,
      //     164.8
      //   ],
      //   [
      //     1706078100,
      //     164.75,
      //     165.6,
      //     164.0,
      //     165.2
      //   ],
      //   [
      //     1706078400,
      //     165.25,
      //     166.1,
      //     165.0,
      //     165.55
      //   ],
      //   [
      //     1706078700,
      //     165.5,
      //     166.6,
      //     165.15,
      //     166.6
      //   ],
      //   [
      //     1706079000,
      //     166.7,
      //     167.1,
      //     166.25,
      //     166.5
      //   ],
      //   [
      //     1706079300,
      //     166.4,
      //     166.65,
      //     165.7,
      //     166.35
      //   ],
      //   [
      //     1706079600,
      //     166.4,
      //     166.8,
      //     165.9,
      //     166.3
      //   ],
      //   [
      //     1706079900,
      //     166.25,
      //     166.65,
      //     165.7,
      //     165.8
      //   ],
      //   [
      //     1706080200,
      //     165.9,
      //     166.7,
      //     165.75,
      //     166.2
      //   ],
      //   [
      //     1706080500,
      //     166.25,
      //     167.95,
      //     166.05,
      //     167.65
      //   ],
      //   [
      //     1706080800,
      //     167.75,
      //     168.85,
      //     167.4,
      //     167.8
      //   ],
      //   [
      //     1706081100,
      //     167.9,
      //     168.1,
      //     167.5,
      //     168.1
      //   ],
      //   [
      //     1706081400,
      //     168.15,
      //     168.45,
      //     167.75,
      //     168.05
      //   ],
      //   [
      //     1706081700,
      //     168.15,
      //     168.2,
      //     167.6,
      //     167.85
      //   ],
      //   [
      //     1706082000,
      //     167.75,
      //     168.25,
      //     167.7,
      //     168.2
      //   ],
      //   [
      //     1706082300,
      //     168.15,
      //     168.25,
      //     167.4,
      //     168.0
      //   ],
      //   [
      //     1706082600,
      //     167.95,
      //     168.15,
      //     167.5,
      //     167.95
      //   ],
      //   [
      //     1706082900,
      //     167.9,
      //     168.35,
      //     167.8,
      //     167.85
      //   ],
      //   [
      //     1706083200,
      //     167.8,
      //     168.15,
      //     167.6,
      //     168.1
      //   ],
      //   [
      //     1706083500,
      //     168.0,
      //     168.75,
      //     167.9,
      //     168.45
      //   ],
      //   [
      //     1706083800,
      //     168.35,
      //     168.7,
      //     168.15,
      //     168.45
      //   ],
      //   [
      //     1706084100,
      //     168.55,
      //     169.35,
      //     168.3,
      //     168.75
      //   ],
      //   [
      //     1706084400,
      //     168.8,
      //     168.95,
      //     167.9,
      //     168.2
      //   ],
      //   [
      //     1706084700,
      //     168.15,
      //     168.3,
      //     167.6,
      //     167.8
      //   ],
      //   [
      //     1706085000,
      //     167.85,
      //     168.2,
      //     167.75,
      //     168.1
      //   ],
      //   [
      //     1706085300,
      //     168.0,
      //     168.7,
      //     167.9,
      //     168.2
      //   ],
      //   [
      //     1706085600,
      //     168.1,
      //     169.6,
      //     167.95,
      //     168.95
      //   ],
      //   [
      //     1706085900,
      //     169.0,
      //     169.3,
      //     168.4,
      //     168.8
      //   ],
      //   [
      //     1706086200,
      //     168.85,
      //     169.7,
      //     168.8,
      //     169.15
      //   ],
      //   [
      //     1706086500,
      //     169.1,
      //     170.5,
      //     169.05,
      //     170.3
      //   ],
      //   [
      //     1706086800,
      //     170.15,
      //     171.8,
      //     170.15,
      //     171.5
      //   ],
      //   [
      //     1706087100,
      //     171.45,
      //     172.4,
      //     171.3,
      //     172.1
      //   ],
      //   [
      //     1706087400,
      //     172.05,
      //     172.45,
      //     170.9,
      //     171.05
      //   ],
      //   [
      //     1706087700,
      //     170.9,
      //     171.0,
      //     169.45,
      //     170.55
      //   ],
      //   [
      //     1706088000,
      //     170.5,
      //     171.25,
      //     170.1,
      //     171.0
      //   ],
      //   [
      //     1706088300,
      //     171.05,
      //     171.25,
      //     170.35,
      //     171.0
      //   ],
      //   [
      //     1706088600,
      //     171.1,
      //     171.35,
      //     170.6,
      //     170.9
      //   ],
      //   [
      //     1706088900,
      //     170.95,
      //     171.6,
      //     170.8,
      //     171.35
      //   ],
      //   [
      //     1706089200,
      //     171.45,
      //     172.45,
      //     171.15,
      //     172.25

      //   ],
      //   [
      //     1706089500,
      //     172.15,
      //     172.3,
      //     171.75,
      //     172.05

      //   ],
      //   [
      //     1706089800,
      //     172.1,
      //     172.5,
      //     171.85,
      //     172.25
      //   ],
      //   [
      //     1706090100,
      //     172.35,
      //     172.45,
      //     172.05,
      //     172.4
      //   ]
      // ]
    },
  ];

  const timeOptions = {
    chart: {
      type: "candlestick",
      fontFamily: "AvertaStd, Arial, sans-serif",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: "#44b6a7",
          downward: "#e75d41",
        },
      },
      bar: {
        horizontal: false,
        columnWidth: "5px",
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: false,
        datetimeUTC: false,
      },
      // labels: {
      //   format: 'dd/MMM',
      // }
    },

    stroke: {
      show: true,
      width: 1,
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      inverseOrder: false,
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
      x: {
        show: true,
        format: "dd MMM yyyy HH:mm TT",
        // format: "dd MMM yyyy H:mm",
      },
      y: {
        formatter: function (value) {
          return `<strong> <font>₹</font>${value}</strong>`;
        },
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      marker: {
        show: false,
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
    },
  };

  const dateOptions = {
    chart: {
      type: "candlestick",
      fontFamily: "AvertaStd, Arial, sans-serif",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: "#44b6a7",
          downward: "#e75d41",
        },
      },
      bar: {
        horizontal: false,
        columnWidth: "5px",
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: false,
        datetimeUTC: false,
      },
      // labels: {
      //   format: 'dd/MMM',
      // }
    },

    stroke: {
      show: true,
      width: 1,
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      inverseOrder: false,
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
      x: {
        show: true,
        format: "dd MMM yyyy",
        // format: "dd MMM yyyy H:mm",
      },
      y: {
        formatter: function (value) {
          return `<strong> <font>₹</font>${value}</strong>`;
        },
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      marker: {
        show: false,
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
    },
  };
  // old options
  // const options = {
  //   chart: {
  //     type: "candlestick",
  //     fontFamily: "AvertaStd, Arial, sans-serif",
  //     toolbar: {
  //       show: false,
  //     },
  //     zoom: {
  //       enabled: false,
  //     },
  //   },
  //   plotOptions: {
  //     candlestick: {
  //       colors: {
  //         upward: "#44b6a7",
  //         downward: "#e75d41",
  //       },
  //     },
  //     bar: {
  //       horizontal: false,
  //       columnWidth: "5px",
  //     },
  //   },
  //   xaxis: {
  //     type: "datetime",
  //     labels: {
  //       show: false,
  //     },
  //     tooltip: {
  //       enabled: false,
  //       fixed: {
  //         enabled: true,
  //         position: "topRight",
  //         offsetX: 0,
  //         offsetY: 0,
  //       },
  //     },
  //   },
  //   stroke: {
  //     show: true,
  //     width: 1,
  //   },
  //   yaxis: {
  //     show: false,
  //   },
  //   grid: {
  //     show: false,
  //   },
  //   legend: {
  //     show: false,
  //   },
  //   tooltip: {
  //     enabled: true,
  //   },
  // };

  // const options = {
  //   chart: {
  //     height: 350,
  //     type: 'line',
  //     zoom: {
  //       enabled: false
  //     }
  //   },
  //   dataLabels: {
  //     enabled: false
  //   },
  //   stroke: {
  //     curve: 'straight'
  //   },
  //   title: {
  //     text: 'Product Trends by Month',
  //     align: 'left'
  //   },
  //   grid: {
  //     row: {
  //       colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
  //       opacity: 0.5
  //     },
  //   },
  //   xaxis: {
  //     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
  //   }
  // }

  return (
    <>
      <div className="apexchart">
        {isLoading ? (
          <ThreeDots
            height="100"
            width="100"
            radius="10"
            color="#3AA29F"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
            }}
            // wrapperClassName=""
            visible={true}
          />
        ) : (
          <Chart
            options={tenure === "1D" ? timeOptions : dateOptions}
            series={series}
            type="candlestick"
            height={350}
          />
        )}
      </div>
    </>
  );
};

import React, { useState } from "react";
import Chart from "react-apexcharts";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { formatNumber } from "../components/common";

export const SipCalculor = ({ calculation }) => {
  const [investmentValue, setInvestmentValue] = useState(500);
  const [intrestRate, setIntrestRate] = useState(6);
  const [years, setYears] = useState(3);

  const updateInvestmentValue = (event) => {
    const inputValue = event.target.value;
    //setInvestmentValue(inputValue > 0 ? Number(inputValue) : "");
    setInvestmentValue(inputValue > 1000000 ? 1000000 : inputValue);
  };

  const updateIntrestRate = (event) => {
    const inputValue = event.target.value;
    setIntrestRate(inputValue > 50 ? 50 : inputValue);
  };

  const updateYears = (event) => {
    const inputValue = event.target.value;
    setYears(inputValue > 30 ? 30 : inputValue);
  };

  const investedAmount = () => {
    return investmentValue * years * 12;
  };

  const estimatedReturns = () => {
    return (Math.round(sipCalculator()) - investedAmount());
  };

  const sipCalculator = () => {
    return (investmentValue * (Math.pow(1 + intrestRate / 1200, years * 12) - 1) * (1 + intrestRate / 1200)) / (intrestRate / 1200);
  };

  const lumSumCalculator = () => {
    let annualRateDecimal = intrestRate / 100;
    let futureValue = investmentValue * Math.pow(1 + annualRateDecimal, years);
    return futureValue;
  };


  const series = [investedAmount(), estimatedReturns()];
  const options = {
    chart: {
      type: "donut",
      fontFamily: "AvertaStd, Arial, sans-serif",
      color: "#fff",
      labels: {
        style: {
          colors: ["#FF0000", "#00FF00"],
          align: "center",
        },
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    labels: ["Invested Amount", "Estimated Returns"],
    colors: ["#48ad9e", "#e79d0d"],
    legend: {
      fontSize: "18px",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: "#305297",
    },
    responsive: [
      {
        breakpoint: 1440,
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          legend: {
            fontSize: "14px",
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };


  return (
    <>
      <div className="rangesliderbox">
        <div className="rangeslidercolumn">
          <div className="rangeslidersip">
            <h4>Monthly Investment</h4>
            <div className="inputtypefiled">
              <TextField
                value={investmentValue}
                onChange={updateInvestmentValue}
                type="number"
                inputProps={{
                  min: 500,
                  max: 5000000,
                  step: 1,
                }}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </div>
            <Slider
              value={investmentValue}
              onChange={updateInvestmentValue}
              min={500}
              max={1000000}
              step={1}
              sx={{
                color: "#ecedef",
                "& .MuiSlider-track": {
                  color: "#44b6a7",
                },
                "& .MuiSlider-thumb": {
                  width: 20,
                  height: 20,
                  color: "#fff",
                },
              }}
            />
            <div className="rangesliderlabel fw-bold text-black">
              <span>
                <i className="finr"></i>500
              </span>
              <span>
                <i className="finr"></i>10,00,000
              </span>
            </div>
          </div>
          <div className="rangeslidersip">
            <h4>Expected Return Rate</h4>
            <div className="inputtypefiled">
              <TextField
                value={intrestRate}
                onChange={updateIntrestRate}
                type="number"
                inputProps={{
                  min: 1,
                  max: 50,
                  step: 1,
                }}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </div>
            <Slider
              value={intrestRate}
              onChange={updateIntrestRate}
              aria-label="Invested Amount"
              min={1}
              max={50}
              step={1}
              sx={{
                color: "#ecedef",
                "& .MuiSlider-track": {
                  color: "#44b6a7",
                },
                "& .MuiSlider-thumb": {
                  width: 20,
                  height: 20,
                  color: "#fff",
                },
              }}
            />
            <div className="rangesliderlabel fw-bold text-black">
              <span>1%</span>
              <span>50%</span>
            </div>
          </div>

          <div className="rangeslidersip">
            <h4>Time Period</h4>
            <div className="inputtypefiled">
              <TextField
                value={years}
                onChange={updateYears}
                type="number"
                inputProps={{
                  min: 1,
                  max: 30,
                  step: 1,
                }}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">yrs.</InputAdornment>
                  ),
                }}
              />
            </div>
            <Slider
              value={years}
              onChange={updateYears}
              aria-label="Estimated Returns"
              min={1}
              max={30}
              step={1}
              sx={{
                color: "#ecedef",
                "& .MuiSlider-track": {
                  color: "#44b6a7",
                },
                "& .MuiSlider-thumb": {
                  width: 20,
                  height: 20,
                  color: "#fff",
                },
              }}
            />
            <div className="rangesliderlabel fw-bold text-black">
              <span>1yrs</span>
              <span>30yrs</span>
            </div>
          </div>
        </div>
        <div className="rangeslidercolumn">
          <div className="rangeslidercolor">
            <div className="sipcalculation">
              <h4>The total value of your investment will be</h4>
              <h2>
                <i className="finr"></i>{" "}
                {calculation === "sip"
                  ? formatNumber(Math.round(sipCalculator() || 0))
                  : lumSumCalculator().toFixed(2)}
              </h2>
              <div className="sipcalcurow">
                <div className="sipcalcucolumn">
                  <h4>Invested Amount</h4>
                  <span>
                    <i className="finr"></i>{" "}
                    {calculation === "sip" ? formatNumber(investedAmount()) : investmentValue}{" "}
                  </span>
                </div>
                <div className="sipcalcucolumn">
                  <h4>Est. Returns</h4>
                  <span>
                    <i className="finr"></i> {formatNumber(estimatedReturns() || 0)}
                  </span>
                </div>
              </div>
              <Chart
                options={options}
                series={series}
                type="donut"
                className="sipcalculatorchart"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const Conditions = () => {
  return (
    <>
      <Header />

      <div className="container">
        <div className="bredcrum">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Terms & Conditions</li>
          </ul>
        </div>
        <div
          className="webcent p-100 pt-0"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-duration="600"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"
        >
          <div className="minicontainer">
            <div className="ipodetail">
              <h3 style={{ color: "#4cafa7", fontWeight: 600, marginTop: 20 }}>
                TERMS & CONDITIONS
              </h3>
              <b style={{ fontSize: "16px" }}>
                SMC Global Securities Compliance Statement
              </b>
              <br />

              <h6
                style={{
                  color: "#023b7e",
                  fontWeight: 600,
                  marginTop: ".7rem",
                }}
              >
                INTRODUCTION
              </h6>
              <p style={{ fontSize: "medium" }}>
                SMC Global Securities Ltd ("We" or "Us") provides a platform
                ("Platform") for you ("You") to access investment products and
                services offered by SMC Global and its affiliates. We are a
                registered mutual fund distributor, stock broker, depository
                participant, and research licensee but we do not provide
                investment advice or manage mutual funds ourselves.
              </p>
              <h6 style={{ fontWeight: 600, color: "#023b7e" }}>
                Platform Use of Mutual Fund
              </h6>
              <p style={{ fontSize: "medium" }}>
                <li>You can use the Platform to:</li>
                <br />

                <p>○ Subscribe and redeem mutual funds.</p>
                <p>○ Communicate with SMC Global regarding these services.</p>
                <p>
                  ○ Access a record of your personal information and financial
                  transactions.
                </p>

                <li>
                  We offer a limited, non-exclusive license for Platform use
                  solely for accessing SMC Global's services.
                </li>
              </p>

              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Investment Disclaimer
              </h6>
              <p style={{ fontSize: "medium" }}>
                <p>
                  * We may not offer all available mutual fund schemes or
                  investment advisory services.
                </p>
                <p>
                  * The Platform data and information do not constitute
                  investment advice.
                </p>
                <p>
                  * You are solely responsible for all investment decisions made
                  on the Platform.
                </p>
                <p>
                  * We are not liable for any losses or damages arising from
                  your use of the Platform or your investment decisions.
                </p>
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Feedback and Complaints
              </h6>
              <p style={{ fontSize: "medium" }}>
                <p>
                  * You may be requested to provide feedback on the Platform
                  services.
                </p>
                <p>
                  * In case of dissatisfaction, you must first file a formal
                  complaint with SMC Global's customer service.
                </p>
                <p>
                  * You agree to cooperate with the investigation and abide by
                  SMC Global's final decision.
                </p>
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Tax Information
              </h6>
              <p style={{ fontSize: "medium" }}>
                <p>
                  * Profit & Loss reports and other reports provided are for
                  informational purposes only and not for tax calculations.
                </p>
                <p>
                  * You are responsible for consulting a tax advisor to verify
                  these reports and determine your tax liability.
                </p>
                <p>
                  * We are not liable for any tax-related losses or damages
                  arising from your use of these reports.
                </p>
              </p>

              <p style={{ color: "black", fontWeight: 600 }}>
                By using the Platform, you acknowledge and agree to these terms.
              </p>
              <h6
                style={{
                  color: "#023b7e",
                  fontWeight: 600,
                }}
              >
                INITIAL PUBLIC OFFERINGS
              </h6>
              <b style={{ fontSize: "16px" }}>
                SMC Global Securities Compliance Statement
              </b>
              <h6
                style={{
                  color: "#023b7e",
                  fontWeight: 600,
                  marginTop: ".7rem",
                }}
              >
                INTRODUCTION
              </h6>
              <p style={{ fontSize: "medium" }}>
                SMC Global Securities Ltd ("We" or "Us") provides a platform
                ("Platform") for you ("You") to access investment products and
                services offered by SMC Global and its affiliates. We are a
                registered mutual fund distributor, stock broker, depository
                participant, and research licensee but we do not provide
                investment advice or manage IPOs ourselves.
              </p>
              <h6 style={{ fontWeight: 600, color: "#023b7e" }}>
                Platform Use
              </h6>
              <p style={{ fontSize: "medium" }}>
                <li>You can use the Platform to:</li>
                <br />

                <p>○ Subscribe IPOs.</p>
                <p>○ Communicate with SMC Global regarding these services.</p>
                <p>○ Access a record of your previous IPO investments.</p>

                <li>
                  We offer a limited, non-exclusive license for Platform use
                  solely for accessing SMC Global's services
                </li>
              </p>

              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Investment Disclaimer
              </h6>
              <p style={{ fontSize: "medium" }}>
                <p>
                  * The Platform data and information do not constitute
                  investment advice.
                </p>
                <p>
                  * You are solely responsible for all investment decisions made
                  on the Platform.
                </p>

                <p>
                  * We are not liable for any losses or damages arising from
                  your use of the Platform or your investment decisions.
                </p>
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Feedback and Complaints
              </h6>
              <p style={{ fontSize: "medium" }}>
                <p>
                  * You may be requested to provide feedback on the Platform
                  services.
                </p>
                <p>
                  * In case of dissatisfaction, you must first file a formal
                  complaint with SMC Global's customer service.
                </p>
                <p>
                  * You agree to cooperate with the investigation and abide by
                  SMC Global's final decision.
                </p>
              </p>

              <p style={{ color: "black", fontWeight: 600 }}>
                By using the Platform, you acknowledge and agree to these terms.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                SMC SUBSCRIPTIONS
              </h6>
              <b style={{ fontSize: "16px" }}>
                SMC Global Securities Compliance Statement
              </b>
              <h6
                style={{
                  color: "#023b7e",
                  fontWeight: 600,
                  marginTop: ".7rem",
                }}
              >
                INTRODUCTION
              </h6>
              <p style={{ fontSize: "medium" }}>
                SMC Global Securities Ltd ("We" or "Us") provides a platform
                ("Platform") for you ("You") to access investment products and
                services offered by SMC Global and its affiliates. We are a
                registered mutual fund distributor, stock broker, depository
                participant, and research licensee but we do not provide
                investment advice or manage financial products ourselves.
              </p>
              <h6 style={{ fontWeight: 600, color: "#023b7e" }}>
                Platform Use
              </h6>
              <p style={{ fontSize: "medium" }}>
                <b>
                  AutoTrender, Index Trading, Equity ka Funda, Techno Funda and
                  Commodity Mantra
                </b>
                <br />
                <br />
                <li>You can use the Platform to:</li>
                <br />
                <p>○ Make informed decisions in the market.</p>
                <p>○ Communicate with SMC Global regarding these services.</p>
                <li>
                  We offer a limited, exclusive license for Platform use solely
                  for accessing SMC Global's services over the subscription.
                </li>
              </p>

              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Investment Disclaimer
              </h6>
              <p style={{ fontSize: "medium" }}>
                <p>
                  * We may not offer all available investment advisory services.
                </p>
                <p>
                  * The Platform data and information do not constitute
                  investment advice.
                </p>

                <p>
                  * You are solely responsible for all investment decisions made
                  on the Platform.
                </p>
                <p>
                  * We are not liable for any losses or damages arising from
                  your use of the Platform or your investment decisions.
                </p>
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                Feedback and Complaints
              </h6>
              <p style={{ fontSize: "medium" }}>
                <p>
                  * You may be requested to provide feedback on the Platform
                  services.
                </p>
                <p>
                  * In case of dissatisfaction, you must first file a formal
                  complaint with SMC Global's customer service.
                </p>
                <p>
                  * You agree to cooperate with the investigation and abide by
                  SMC Global's final decision.
                </p>
              </p>

              <p style={{ color: "black", fontWeight: 600 }}>
                By using the Platform, you acknowledge and agree to these terms.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>SMC EKYC</h6>
              <b style={{ fontSize: "16px" }}>
                SMC Global Securities Compliance Statement
              </b>

              <p style={{ fontSize: "medium", marginTop: ".5rem" }}>
                SMC Global Securities Limited (hereafter referred to as "SMC")
                has prescribed specific eligibility criteria and terms and
                conditions to be followed by the client before opening an online
                trading and Demat account through the website of SMC. The Client
                must go through every condition mentioned below and verify if
                he/she is eligible to open an online account with SMC. The
                customer needs to adopt an offline/physical mode of Account
                opening in case any of the terms & conditions are not acceptable
                to the client. If all terms and conditions are acceptable to the
                client, he/she should mark in the "I agree and authorize" box to
                open online trading and a Demat account with SMC.
              </p>
              <p style={{ fontSize: "medium" }}>
                Individual Citizens of India and resident Indians are permitted
                to open accounts and avail of the services through this process.
                At present, NRIs cannot open accounts through the E-KYC process.
                If the Client is a Non-Resident Indian residing in non-compliant
                countries and Territories (NCCTs)/overseas Corporate Bodies
                (OCBs), then the Client shall be required to opt for Physical
                KYC process and submit a duly signed FATCA Declaration. The
                client is required to upload all documents, records, etc., which
                shall be verified with the originals/authenticated as per
                prevailing regulatory guidelines as stipulated in Know Your
                Client documents to open an account.
              </p>
              <p style={{ fontSize: "medium" }}>
                The client shall be required to affix his digital
                signature/e-sign on the relevant pages of the account opening
                form to enable SMC to open the said account. The Client must
                fill necessary details and make payment online through his
                registered Bank Account or Net banking facility. SMC, after
                receipt of the payment towards account opening charges for
                Trading Account, etc., shall send the account opening document
                at the registered Email ID furnished by the client.
              </p>
              <p style={{ fontSize: "medium" }}>
                SMC shall reject the application if it is found that the client
                is barred from trading or any investigation from SEBI or any
                other regulatory authorities is pending against the said client.
                The decision taken by SMC shall be final and binding upon the
                client. SMC does not guarantee that the account opening form
                submitted by the client shall be duly accepted, as the company
                reserves its right to reject the client's account opening
                application without assigning any reason.
              </p>
              <p style={{ fontSize: "medium" }}>
                Name of Client at the time of Account Opening – I confirm and
                understand that my name, as per the IT department, will be
                recorded in SMC records as the Account name. In case of any
                mismatch in name as per Income Tax records and name as per KRA /
                AADHAR / Bank Records.
              </p>
              <p style={{ fontSize: "medium" }}>
                Login Confidentiality – The client agrees and confirms that any
                instructions set via an online trading account, which requires
                the client to enter login credentials, are known only to the
                client and no other person. The client must not share trading /
                Demat account details/login credentials with anyone else. If the
                client shares any such login details with another person, all
                risks/liabilities taking place from the client's account for
                instructions placed by any such third person from the account
                will be indemnified by the client himself. SMC or any of its
                agents or employees will not ask for any login details or
                Passwords from you in any manner whatsoever.
              </p>
              <p style={{ fontSize: "medium" }}>
                I confirm that I am not an Illiterate, Disabled Person, Blind
                person, or Person with mental disability.
              </p>
              <p style={{ fontSize: "medium" }}>
                I confirm that I have read and understood the contents of Rights
                & Obligations, DOs & DON’Ts for the investors, Guidance Notes,
                Risk Disclosure documents of all the segments I availed, and
                Policies & Procedure document & Voluntary clauses.
              </p>
              <p style={{ fontSize: "medium" }}>
                I wish to activate the Margin Trading Facility provided by SMC
                in my trading Account. I have gone through the entire Rights and
                Obligations document with regard to the same and further confirm
                that I shall agree and abide by the same. Applicable Brokerage,
                DP Tariff & other charges as relevant to my account is explained
                to me, and I am aware that the same can be modified under
                intimation to me.
              </p>
              <b style={{ fontSize: "16px" }}>Consent Form</b>
              <br />
              <br />
              <p style={{ fontSize: "medium" }}>
                I authorize SMC Global Securities Limited. (SMC) to access my
                data, information, photographs, etc., from UIDAI for opening a
                Trading and Demat Account.
              </p>
              <p style={{ fontSize: "medium" }}>
                I hereby confirm that the data available in AADHAR is accurate,
                correct, and updated. If there will be any modification in
                AADHAR data, the same will be intimated to SMC separately.
              </p>
              <p style={{ fontSize: "medium" }}>
                I confirm that I am an Indian Resident, born in India and my
                sole country of tax residency is India.
              </p>
              <p style={{ fontSize: "medium" }}>
                I hereby give my consent for allowing me to trade in all
                Exchanges in various permitted Segments i.e. Equity, Mutual
                Fund, Debt, Equity Derivatives, Currency Segment and Commodity
                Derivatives. I select these segments as my trading preference
                for investment / trading purposes. I further understand that I
                will be permitted to trade in the Derivative segment once I
                submit financial details.
              </p>
              <p style={{ fontSize: "medium" }}>
                My Correspondence address is the same as the Permanent address,
                which is recorded in the Aadhar database.
              </p>
              <p style={{ fontSize: "medium" }}>
                I am neither a politically exposed person, nor related to one.
              </p>
              <p style={{ fontSize: "medium" }}>
                I wish to avail the facility of Internet and Mobile trading and
                also want to receive all communication, standard documents and
                Contract Note in electronic form on Email id provided by me at
                the time of Account opening and mentioned on KRA page.
              </p>
              <p style={{ fontSize: "medium" }}>
                I wish to execute Demat Debit and Pledge Instruction (DDPI)
                Authorization in favor of SMC for the purpose of ease of
                operation considering the benefits related to the same. My
                primary purpose of dealing in equity is Intraday & investment
                and the primary purpose of dealing in derivatives is Trading as
                well as investment.
              </p>
              <p style={{ fontSize: "medium" }}>
                I have no GST registration and GST is not applicable to me.
                There are no action/proceedings initiated/pending/taken by
                FMC/SEBI/Stock exchange/any other authority against me for
                dealing in securities markets during the last 3 years.
              </p>
              <p style={{ fontSize: "medium" }}>
                I intend to invest in the stock/commodity market with Own Funds
                only. (Not Applicable for Minor)
              </p>
              <p style={{ fontSize: "medium" }}>
                I have no social media accounts on Facebook, Twitter and
                Linkedin.
              </p>
              <p style={{ fontSize: "medium" }}>
                I wish to receive DP A/C statements on a monthly basis and
                request to receive Electronic Transaction cum holding Statement
                at my Email ID.
              </p>
              <p style={{ fontSize: "medium" }}>
                I instruct the DP to receive each and every credit in my / our
                account only.
              </p>
              <p style={{ fontSize: "medium" }}>
                I wish to opt for Electronic Access to Securities Information
                (EASI) facilities.
              </p>
              <p style={{ fontSize: "medium" }}>
                I wish to receive dividend / interest directly into my bank
                account through ECS.
              </p>
              <p style={{ fontSize: "medium" }}>
                I would like to share the email id with the Registrar & Transfer
                Agent (RTA).
              </p>
              <p style={{ fontSize: "medium" }}>
                I would like to receive the Annual Report through electronic
                mode only.
              </p>
              <p style={{ fontSize: "medium" }}>
                Currently I do not wish to opt for BSDA scheme although my Demat
                account may be eligible for the same in future and I request SMC
                to continue my Demat account in normal scheme as availed by me
                at the time of account opening / modified (if any) to avail
                regular DP Services.
              </p>
              <p style={{ fontSize: "medium" }}>
                I wish to avail TRUST facility. This facility will allow us to
                meet the securities settlement obligations by confirming an SMS
                received by CDSL to debit my Demat account. DIS booklet- I do
                not wish to obtain DIS for time being.
              </p>
              <p style={{ fontSize: "medium" }}>
                Authority to retain funds as a Running account- I wish to settle
                with you once in a calendar quarter and not once in a calendar
                month. Further I can request for the release of pay-out any time
                after adjusting all dues towards SMC. I am entitled to revoke
                this Running Account Authorization at any time.
              </p>
              <p style={{ fontSize: "medium" }}>
                I am not having Depository Account outside SMC
              </p>
              <p style={{ fontSize: "medium" }}>
                I am not dealing with any other Stock Broker/ Authorized Person.
              </p>
              <p style={{ fontSize: "medium" }}>
                I have not been introduced by any Introducer
              </p>
              <p style={{ fontSize: "medium" }}>
                I hereby request you to consider the data like Proof of Identity
                (POI) and Proof of address (P0A) and any other information
                downloaded from other sources like KRA / CERSAI.
              </p>
              <p style={{ fontSize: "medium" }}>
                I hereby confirm that data available with KRA/CERSAI is true,
                correct and updated. If there will be any modification in above
                data, the same will be intimated to SMC by me. I hereby
                authorize SMC to pledge / Repledge my shares/securities with
                Clearing Member/Clearing Corporation/ Clearing House in
                accordance with regulatory guidelines. I confirm that before
                e-signing the E- KYC documents, I have gone through the entire
                KYC and agree that all my information & details are captured
                properly on the account opening documents.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                TERMS & CONDITIONS FOR OPEN FREE DEMAT & TRADING ACCOUNT
              </h6>
              <b style={{ fontSize: "16px" }}>
                Please read the following terms and conditions carefully before
                proceeding with the offer to open a Free Demat and Trading
                Account:
              </b>
              <br />
              <br />

              <b style={{ fontSize: "16px" }}>Offer Details:</b>
              <br />
              <p style={{ fontSize: "medium" }}>
                a. You can open a Demat and Trading Account with us at no cost.
                <br />
                b. Enjoy free research calls with weekly stock picks based on
                fundamental and technical analysis. Please note these are for
                educational purposes only; consult a financial advisor before
                making any investment decisions.
              </p>
              <b style={{ fontSize: "16px" }}>Benefits:</b>
              <p style={{ fontSize: "medium" }}>
                * Zero Annual Maintenance Charges (AMC) for the first year.
                <br />
                * Free Trading APIs to enhance your trading experience.
                <br />
                * Margin Trading Facility (MTF) up to 4 times your deposited
                funds.
                <br />
                * Access to a Dedicated Relationship Manager for personalized
                assistance.
                <br />* Free Call & Trade Service for convenient transactions.
              </p>
              <b style={{ fontSize: "16px" }}>Offer Validity:</b>
              <p style={{ fontSize: "medium" }}>
                * This offer is valid until 31st Dec 2024.
                <br />
                * It is exclusive to direct clients and not applicable to SMC AP
                or SMC AP’s clients.
                <br />* This scheme cannot be combined with any other ongoing
                offers.
              </p>
              <b style={{ fontSize: "16px" }}>Charges and Compliance:</b>
              <p style={{ fontSize: "medium" }}>
                * Please refer to the KYC tariff sheet for detailed charges.
                <br />* Brokerage charges will not exceed the limits prescribed
                by SEBI regulations.
              </p>
              <b style={{ fontSize: "16px" }}>Changes and Disputes:</b>
              <p style={{ fontSize: "medium" }}>
                SMC Global Securities Ltd. reserves the right to change, alter,
                or discontinue the offer and modify these terms and conditions
                at its discretion, with adequate notice to clients.
                <br />
                In case of any dispute, the decision of SMC Global management
                shall be final and binding.
              </p>

              <p style={{ fontSize: "medium" }}>
                By proceeding to open a Demat and Trading account under this
                offer, you acknowledge that you have reviewed and agreed to
                these terms and conditions. If you do not agree with any part of
                these terms, please refrain from availing this offer.
                <br />
                For further assistance or clarifications, please contact our
                customer support team.
              </p>
              <b style={{ fontSize: "16px" }}>Note:</b>
              <p style={{ fontSize: "medium" }}>
                Ensure you have read and understood our Privacy Policy and Risk
                Disclosure Statement available on our website.
              </p>
              <h6 style={{ color: "#023b7e", fontWeight: 600 }}>
                SMC REFUND & CANCELATION POLICY
              </h6>
              <b style={{ fontSize: "16px" }}>
                SMC Global Securities Compliance Statement
              </b>
              <p style={{ fontSize: "medium", marginTop: ".5rem" }}>
                Any transaction on the platform with respect to any platform
                services will be completed after successful transfer of money
                from your bank account to SMC. You hereby agree and acknowledge
                that the transactions on the platform once completed cannot be
                canceled. Any deduction of amount from your bank account in case
                of failed transaction would be refunded with the relevant time
                period as per your bank policy and SMC shall not be responsible
                for the same.
              </p>
              <p style={{ fontSize: "medium" }}>
                No refund of account opening charges shall be made once the
                Trading and Demat account is opened with us. Refund shall be
                provided only for any excess payment of account opening charges
                made to us during application of your Trading and Demat Account
                and confirmation from the banking partner with respect to
                receipt of such payment from you.
              </p>
              <p style={{ fontSize: "medium" }}>
                Once your Demat account is opened any payments made thereafter
                will be credited towards your account and there shall be no
                refunds. To withdraw any amount from such an account you may
                place a withdrawal request through our SMC Ace App or Web.
              </p>
              <b style={{ fontSize: "16px" }}>Limitation of Liability</b>
              <br />
              <br />
              <p style={{ fontSize: "medium" }}>
                SMC will not be responsible for lost profits, revenues, or data;
                financial losses; or indirect, special, consequential,
                exemplary, or punitive damages when permitted by law. In all
                cases, SMC will not be held liable for expense, loss, or damage
                that is not reasonably foreseeable.
              </p>
              <b style={{ fontSize: "16px" }}>Intellectual Property Rights</b>
              <br />
              <br />
              <p style={{ fontSize: "medium" }}>
                All intellectual property, including in relation to the
                Platforms, including any software, techniques and processes
                used, and any trademarks, logos, images, material, content,
                designs, information and other content available on the Platform
                belongs exclusively to SMC. By no means is any proprietary right
                or license in any intellectual property impliedly or expressly
                granted by SMC to the Client or any other user of the Platform
                (together “Users”) through the User’s use of the Platform.
              </p>
              <b style={{ fontSize: "16px" }}>Violation of Terms</b>
              <br />
              <br />
              <p style={{ fontSize: "medium" }}>
                By accepting these Terms of Use, you acknowledge that any breach
                on your part will be considered an unjust and detrimental
                business practice. Such actions will inflict irreparable damage
                upon the Company and/or its associated entities and affiliates.
                Monetary compensation alone would be insufficient to remedy such
                harm. Therefore, you consent to the Company seeking any
                necessary injunctive or equitable remedies in such instances.
                These remedies are supplementary to any other legal or equitable
                recourse available to the Company. In the event that the Company
                initiates legal proceedings against you due to your violation of
                these Terms of Use, they reserve the right to recover all
                reasonable legal fees and expenses incurred, and you agree to
                compensate accordingly, in addition to any other granted relief.
              </p>
              <b style={{ fontSize: "16px" }}>
                Suspension and Termination of Account
              </b>
              <br />
              <br />
              <p style={{ fontSize: "medium" }}>
                The Terms of Use shall remain in effect until either you or the
                Company decides to terminate them, as outlined below. If you
                disagree with the Terms of Use or are unsatisfied with the
                Platform, your only options are to (i) close your Account on the
                Platform, and/or (ii) cease accessing the Platform. If you
                breach these Terms of Use or any applicable laws, the Company
                reserves the right, at its discretion, to terminate your Account
                and/or restrict your access to the Platform and its services.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Conditions;

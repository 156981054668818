import React from "react";
import FandO from "../../images/F&O_img.png";
import Currency from "../../images/Currencies_img.png";
import Commodity from "../../images/commodities_img.png";
import Futureimg from "../../images/future_img.webp";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const RoadsAndTradeEasy = () => {
  return (
    <>
      <div
        className="webcent pb-0"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <b>Different Roads to Trading</b>
              </div>

              <div className="tradebenefit">
                <Tabs>
                  <div className="tradingben m-0">
                    <div className="iconrow noicon">
                      <TabList clasName="justify-content-between">
                        <Tab>
                          <h4>Futures</h4>
                        </Tab>
                        <Tab>
                          <h4>Options</h4>
                        </Tab>
                        <Tab>
                          <h4>Currencies</h4>
                        </Tab>
                        <Tab>
                          <h4>Commodities</h4>
                        </Tab>
                      </TabList>
                    </div>
                  </div>
                  <TabPanel>
                    <div className="ipoodlist ">
                      <div className="d-flex benefitts gap-5 align-items-center">
                        <div className="col-6">
                          <img src={Futureimg} />
                        </div>
                        <div className="col-6">
                          <ul>
                            <li>
                              Leverage futures contracts to predict the
                              direction of an underlying asset
                            </li>
                            <li>
                              Place orders quickly without any adverse impact on
                              the price
                            </li>
                            <li>
                              Get the brokerage and margin benefits for trade
                              benefits
                            </li>
                            <li>
                              Manage foreign exchange risk and price risk for
                              trade aggregates
                            </li>
                            <li>
                              Margin calculator, brokerage calculator, and much
                              more
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="ipoodlist ">
                      <div className="d-flex benefitts gap-5 align-items-center">
                        <div className="col-6">
                          <img src={FandO} />
                        </div>
                        <div className="col-6">
                          <ul>
                            <li>
                              Cost savings with greater leveraging power of the
                              underlying assets
                            </li>
                            <li>
                              Potential to bring significant returns in the long
                              as well as short term
                            </li>
                            <li>
                              Advantage of reduced financial commitment as
                              compared to equities
                            </li>
                            <li>
                              Higher percentage of returns against lesser
                              investment
                            </li>
                            <li>
                              Flexibility of Options for diversified trading
                              modes
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="ipoodlist ">
                      <div className="d-flex benefitts gap-5 align-items-center">
                        <div className="col-6">
                          <img src={Currency} />
                        </div>
                        <div className="col-6">
                          <ul>
                            <li>
                              Leverage access to the expansive foreign exchange
                              market
                            </li>
                            <li>
                              Round-the-clock trading at your fingertips with a
                              global reach
                            </li>
                            <li>
                              Take advantage of the external factors such as
                              economic stability, trade deals, geopolitics, and
                              policies
                            </li>
                            <li>
                              Try your hand at directional trading without any
                              restrictions in the forex market
                            </li>
                            <li>
                              Trade with a small capital amount with Margin
                              Trading against a higher position
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="ipoodlist ">
                      <div className="d-flex benefitts gap-5 align-items-center">
                        <div className="col-6">
                          <img src={Commodity} />
                        </div>
                        <div className="col-6">
                          <ul>
                            <li>
                              Turn inflation in your favor with increased
                              interest rates and increased cost of borrowing
                            </li>
                            <li>
                              Leverage the benefit of transparency in
                              commodities exchange
                            </li>
                            <li>
                              Pay an upfront margin of the total contract value
                              and increase trading positions in commodities with
                              Margin Trading
                            </li>
                            <li>
                              Diversify your trading portfolio with commodities
                              as they are not correlated with other asset
                              classes, such as stocks and bonds
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoadsAndTradeEasy;

import React from "react";
import { SEO_URL } from "../../../utils/constants";
import SEO from "../../../components/common/SEO";
import { Context } from "../../../context";
import { Link, useParams } from "react-router-dom";
import { getReportCategoryWithSubcategory } from "../../../utils/APICollection";

const ResearchSeo = () => {
  const { id } = useParams();
  const {
    researchCategory,
    setResearchCategory,
    categoryName,
    setCategoryName,
  } = React.useContext(Context);
  const [fileLoading, setFileLoading] = React.useState(false);
  const researchData = localStorage.getItem("researchData");
  const getAllCategory = async () => {
    const { data } = await getReportCategoryWithSubcategory();
    if (data?.status == "success") {
      localStorage.setItem("researchData", JSON.stringify(data?.data));
      setResearchCategory(data?.data);
    }
  };

  //   const categoryName = researchCategory
  //     ?.filter((item) => item.research_category_master_id == id)
  //     .map((item) => item.category_name);
  React.useEffect(() => {
    if (researchCategory.length !== JSON.parse(researchData).length) {
      getAllCategory();
    }
  }, []);

  React.useEffect(() => {
    const category = researchCategory?.find(
      (item) => item.research_category_master_id == id
    )?.category_name;

    if (category) {
      setCategoryName(category);
    }
  }, [researchCategory, id, setCategoryName]);

  let title = "";
  let description = "";
  let url = "";
  let h1 = "";
  let image = "../../../images/SMCNEWLOGO.webp";
  if (categoryName == "Commodities Analysis") {
    title = "Detailed Commodities Analysis Research Report BY SMC Global";
    description =
      "Detailed Commodities Market Analysis Research Report BY SMC Global Securities.";
    url = `${SEO_URL}/research/commodities-analysis/70`;
    image = "../../../images/SMCNEWLOGO.webp";
    <h1>Commodities Analysis Research Reports</h1>;
  } else if (categoryName == "Derivative Analysis") {
    title = "Detailed Derivative Analysis Research Report BY SMC Global";
    description =
      "Detailed Derivative Market Analysis Research Report BY SMC Global Securities.";
    url = `${SEO_URL}/research/derivative-analysis/114`;
    <h1>Derivative Analysis Research Reports</h1>;
    image = "../../../images/SMCNEWLOGO.webp";
  } else if (categoryName == "Equity Analysis") {
    title = "Detailed Equity Analysis Research Report BY SMC Global";
    description =
      "Detailed Equity Market Analysis Research Report BY SMC Global Securities.";
    url = `${SEO_URL}/research/equity-analysis/119`;
    <h1>Equity Analysis Research Reports</h1>;
    image = "../../../images/SMCNEWLOGO.webp";
  } else if (categoryName == "Mutual Fund Analysis") {
    title = "Mutual Funds Market Analysis & Live Updates | SMC Global Online";
    description =
      "Get the latest live reports & updates about Mutual Funds, including a stock performance for Equity, Debt & hybrid funds, MF activity, largest AMC at SMC Global Online.";
    url = `${SEO_URL}/research/mutual-fund-analysis/116`;
    <h1>Best Mutual Funds to Buy</h1>;
    image = "../../../images/SMCNEWLOGO.webp";
  } else if (categoryName == "IPO Reports") {
    title =
      "Get Detailed Research Reports on Upcoming IPO by SMC Global Securities.";
    description =
      "Detailed IPO research report to make a insightful decision to buy IPO - SMC Global Securities.";
    url = `${SEO_URL}/research/ipo-reports/43`;
    <h1>IPO Research Report</h1>;
    image = "../../../images/SMCNEWLOGO.webp";
  } else if (categoryName == "Currency Analysis") {
    title =
      "Get Detailed Currency Analysis Research Reports by SMC Global Securities.";
    description =
      "Detailed Currency Analysis research report to make a insightful decision to buy IPO - SMC Global Securities.";
    url = `${SEO_URL}/research/currency-analysis/120`;
    <h1>Currency Analysis Research Report</h1>;
    image = "../../../images/SMCNEWLOGO.webp";
  }
  React.useEffect(() => {
    if (researchCategory?.length !== researchData?.length) {
      getAllCategory();
    }
  }, []);
  return (
    <>
      <SEO
        title={title}
        description={description}
        name="Company name."
        type="article"
        url={url}
        image={image}
      />
    </>
  );
};

export default ResearchSeo;

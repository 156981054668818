import React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Img01 from '../images/TDOC@2x.jpg'
import Img02 from '../images/XPEV@2x.png'
import Img03 from '../images/WAL@2x.png'
import Img04 from '../images/RPM@2x.png'
import Img05 from '../images/NIO@2x.png'
import Img06 from '../images/UNP@2x.png'
import Img07 from '../images/DOMA@2x.png'
import Img08 from '../images/TENB@2x.jpg'
import Img09 from '../images/LIVN@2x.png'
import Img10 from '../images/LAD@2x.png'
import Logo2 from '../images/tata.webp'
import backgroundImageRupeeUrl from '../images/rupee.svg'
import Slider from "react-slick";
import { InvestCalculor } from '../calculators/InvestCalculator';

export const Usinvest = () => {
    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 70,
        height: 34,
        padding: 7,
        '& .MuiSwitch-switchBase': {
          margin: 1,
          padding: 0,
          transform: 'translateX(30px)',
          '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(6px)',
            '& .MuiSwitch-thumb:before': {
              backgroundImage: `url(${backgroundImageRupeeUrl})`,
            },
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2378fa' : '#2378fa',
          width: 32,
          height: 32,
          '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 10 30"><path fill="${encodeURIComponent(
              '#fff',
            )}" d="M4.88 15.24V10.6c.88.32 1.52 1.2 1.52 2.2 0 .48.36.84.84.84s.84-.36.84-.84c0-1.92-1.36-3.56-3.2-3.96v-.88c0-.48-.36-.84-.84-.84s-.84.36-.84.84v.88c-1.84.4-3.2 2-3.2 3.96s1.36 3.56 3.2 3.96v4.64c-.88-.32-1.52-1.2-1.52-2.2 0-.48-.36-.84-.84-.84s-.84.36-.84.84c0 1.92 1.36 3.56 3.2 3.96v.88c0 .48.36.84.84.84s.84-.36.84-.84v-.88c1.84-.4 3.2-2 3.2-3.96s-1.4-3.56-3.2-3.96zm-3.2-2.44c0-1 .64-1.88 1.52-2.2V15c-.88-.32-1.52-1.2-1.52-2.2zm3.2 8.6V17c.88.32 1.52 1.2 1.52 2.2s-.64 1.84-1.52 2.2z"/></svg>')`,
          },
        },
        '& .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
          borderRadius: 20 / 2,
        },
      }));
    var settings = {
        dots: false,
        navigation: true,
        loop: false,
        infinite: false,
        slidesToShow: 12,
        slidesToScroll: 4,
        initialSlide: 0,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 5000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.2,
                }
            }
        ]
    };
  return (
    <>
     <div className='webcent' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                        <div className='container'>
                                <div className='row'>
                                        <div className='web-container'>
                                            <div className='col-lg-10 m-auto'>
                                                <div className='returncalulator usstocks position-relative'>
                                                    <div className='title mb-1'>
                                                    <h3>What if you had invested</h3>
                                                </div>
                                                    <div className='switch position-absolute end-0'>
                                                    <FormGroup>
      <FormControlLabel
        control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
      />
    </FormGroup>
                                                    </div>                                                
                                               
                                                <div className='ipoodlist'>
                                                            <div className='stockslider w-100 m-0 newslide shadow-none'>
                                                                <div className='row'>
                                                                    <Slider {...settings}>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img01} alt='Yes Bank' />
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img02} alt='TATA' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img03} alt='WAL' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img04} alt='RPM' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img05} alt='NIO' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img06} alt='UNP' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img07} alt='DOMA' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img08} alt='TENB' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img09} alt='LIVN' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img10} alt='LAD' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Logo2} alt='BA' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img01} alt='Yes Bank' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img02} alt='TATA' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img03} alt='WAL' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img04} alt='RPM' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img05} alt='NIO' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img06} alt='UNP' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img07} alt='DOMA' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img08} alt='TENB' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img09} alt='LIVN' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Img10} alt='LAD' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='slide'>
                                                                                <div className='slidelogo'>
                                                                                    <img src={Logo2} alt='BA' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Slider>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='clearfix'></div>
                                                <InvestCalculor />
                                                <p className='mt-3 lh-1'><small>*Disclaimer: Returns shown above are on the basis of back tested data</small></p>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                        </div>
                    </div>
    </>
  )
}

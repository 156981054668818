import React from "react";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
import Header from "./Header";

const Error = () => {
  return (
    <>
      <Header />
      <div id="notfound">
        <div className="container">
          <div className="web-container">
            <div className="webcent">
              <div className="minicontainer text-center">
                <div className="notfound">
                  <div className="notfound-404">
                    <h1>404</h1>
                  </div>
                  <h2>WE ARE SORRY, PAGE NOT FOUND!</h2>
                  <p>
                    THE PAGE YOU ARE LOOKING FOR MIGHT HAVE BEEN REMOVED HAD ITS
                    NAME CHANGED OR IS TEMPORARILY UNAVAILABLE.
                  </p>
                  <NavLink to="/">back to homepage</NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Error;

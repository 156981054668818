import { FinancialChart } from "../../../../charts/FinancialChart";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useState, useEffect } from "react";
import { getIpoFinancials } from "../../../../utils/APICollection";
const IpoFinancials = () => {
  const [graphData, setGraphData] = useState([]);
  const fetchRevenue = async () => {
    try {
      const data = await getIpoFinancials(
        window.location.pathname.split("/")[3]
      );
      setGraphData(data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchRevenue();
  }, []);
  return (
    <div className="companytabs fixtabs">
      <Tabs>
        <div className="tradingben mt-0">
          <div className="iconrow noicon">
            <TabList>
              <Tab>
                <h4>Revenue</h4>
              </Tab>
              <Tab>
                <h4>Total Assets</h4>
              </Tab>
              <Tab>
                <h4>Profit</h4>
              </Tab>
            </TabList>
          </div>
        </div>
        <TabPanel>
          <div className="ipoodlist">
            <FinancialChart graphData={graphData} filter={"TotalRevenue"} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="ipoodlist">
            {/* <h4 className="nodata">No Data Found</h4> */}
            <FinancialChart graphData={graphData} filter={"TotalAssets"} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="ipoodlist">
            {/* <h4 className="nodata">No Data Found</h4> */}
            <FinancialChart graphData={graphData} filter={"Profit"} />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default IpoFinancials;

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useContext, useState } from "react";
import { HTML } from "../../images/svg/HTML";
import { PDF } from "../../images/svg/PDF";
import left from "../../images/larrow.webp";
import right from "../../images/arrow.webp";
import nodata from "../../images/nodata.webp";
import { Context } from "../../context";
import { Link } from "react-router-dom";
import { formatedDate } from "./FormattedDate";
import { getFileUrl } from "../../utils/APICollection";
import { AWS_S3_RESEARCH_REPORT_FOLDER } from "../../utils/constants";
import { Tooltip } from "@mui/material";

export default function ResearchSlider({ setLoading }) {
  const { researchData, setResearchData, researchCategory } =
    useContext(Context);

  let finalData = researchData?.flatMap((obj) => obj.files);
  const [currentSlide, setCurrentSlide] = useState(0);

  const getUrl = async (fileName) => {
    setLoading(true);
    try {
      const { data } = await getFileUrl({
        file_name: fileName,
        folder_name: AWS_S3_RESEARCH_REPORT_FOLDER,
      });
      if (data?.statusCode === 200) {
        window.open(data?.data[0]?.file_url);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const sliderSettings = {
    arrows: true,
    slidesToShow: finalData?.length < 3 ? finalData?.length : 3,
    slidesToScroll: 1,
    infinite: false, // Set this to false
    autoplay: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
    beforeChange: (current, next) => setCurrentSlide(next),
    nextArrow: (
      <SampleNextArrow
        currentSlide={currentSlide}
        clientSpeakData={finalData}
      />
    ),
    prevArrow: (
      <SamplePrevArrow
        currentSlide={currentSlide}
        clientSpeakData={finalData}
      />
    ),
  };

  return (
    <>
      {/* {researchData?.map((item) => {
        return ( */}
      {finalData?.length < 1 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: "100vh",
            margin: "1px auto",
            flexDirection: "column",
          }}
        >
          <img src={nodata} style={{ width: "300px", alignSelf: "center" }} />
          <h3>No Data Found</h3>
        </div>
      ) : (
        <Slider {...sliderSettings}>
          {finalData?.map((item) => {
            return (
              <div className="reportdtn">
                <div className="reporthead">
                  <span>{formatedDate(item?.date)}</span>
                  <h4>{item?.title}</h4>
                </div>
                <div className="reportcont">
                  <p>{item?.description}</p>
                </div>
                <div className="reportbtm">
                  <span>
                    View Report
                    {item?.english_pdf_file ? (
                      <Tooltip title="English PDF" arrow>
                        <span style={{ cursor: "pointer" }}>
                          <PDF onClick={() => getUrl(item.english_pdf_file)} />
                        </span>
                      </Tooltip>
                    ) : // <Link to={item?.english_pdf_file} target="_blank">
                    //   <PDF />
                    // </Link>
                    null}
                    {item?.hindi_pdf_file ? (
                      <Tooltip title="Hindi PDF" arrow>
                        <span style={{ cursor: "pointer" }}>
                          <PDF onClick={() => getUrl(item.hindi_pdf_file)} />
                        </span>
                      </Tooltip>
                    ) : // <Link to={item?.hindi_pdf_file}>
                    //   <PDF />
                    // </Link>
                    null}
                    {item?.html_hindi_file ? (
                      <Tooltip title="HINDI HTML" arrow>
                        <span style={{ cursor: "pointer" }}>
                          <HTML onClick={() => getUrl(item.html_hindi_file)} />
                        </span>
                      </Tooltip>
                    ) : // <Link to={item?.html_hindi_file} target="_blank">
                    //   <HTML />
                    // </Link>
                    null}
                    {item?.html_english_file ? (
                      <Tooltip title="English HTML" arrow>
                        <span style={{ cursor: "pointer" }}>
                          <HTML
                            onClick={() => getUrl(item.html_english_file)}
                          />
                        </span>
                      </Tooltip>
                    ) : // <Link to={item?.html_english_file} target="_blank">
                    //   <HTML />
                    // </Link>
                    null}
                  </span>
                </div>
              </div>
            );
          })}
        </Slider>
      )}
      {/* //   );
      // })} */}
    </>
  );
}

// Custom arrow components
function SampleNextArrow(props) {
  const { className, style, onClick, currentSlide, clientSpeakData } = props;
  return (
    <div
      className={className}
      style={{
        ...style,

        display: currentSlide === clientSpeakData.length - 3 ? "none" : "block",
      }}
      onClick={onClick}
    >
      <img src={right} alt="right" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick, currentSlide, hotelCards } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: currentSlide === 0 ? "none" : "block",
      }}
      onClick={onClick}
    >
      <img src={left} alt="left" />
    </div>
  );
}

import Icon01 from "../../../images/investing-icon01.webp";
import Icon02 from "../../../images/investing-icon02.webp";
import Icon03 from "../../../images/investing-icon03.webp";
import Icon04 from "../../../images/investing-icon04.webp";
const IpoUsp = () => {
    return (
        <div className="webcent">
            <div className="aboutcompany">
                <h3>USP of investing IPOs with Us</h3>
                <p>
                    Experience a new-age approach to IPO investing
                    with our platform, unlocking a plethora of
                    exclusive benefits that set us apart from the
                    competition. Enjoy a seamless and user-friendly
                    process, complete with 0% commission and
                    research-backed advisory services, making us the
                    ideal choice for your investment needs. From
                    account opening to executing trades, every step is
                    designed to be quick and effortless, allowing you
                    to focus on what truly matters. Bid farewell to
                    hidden fees and charges with our transparent 0%
                    commission structure. Trust lies at the core of
                    our platform, built upon principles of
                    transparency and security, ensuring the safety of
                    your investments. Whether you're a seasoned
                    investor or just starting out, our platform is the
                    ultimate destination for IPO investments.
                </p>

                <div className="investingipo">
                    <div className="investingbox">
                        <img src={Icon01} alt="0% Commission" />
                        <div className="investingcont">
                            <h4>0% Commission</h4>
                            <p>
                                Choose from our vast investment portfolio at
                                0% commission and enjoy all the benefits!
                            </p>
                        </div>
                    </div>
                    <div className="investingbox">
                        <img src={Icon02} alt="Trust" />
                        <div className="investingcont">
                            <h4>Trust</h4>
                            <p>
                                We have gained the faith of 20+lakh happy
                                customers over the last 30 years.
                            </p>
                        </div>
                    </div>
                    <div className="investingbox">
                        <img src={Icon03} alt="Seamless Investing" />
                        <div className="investingcont">
                            <h4>Seamless Investing</h4>
                            <p>
                                Enjoy hassle-free investment processes. Open
                                your account with SMC in just a few minutes
                                and start investing!
                            </p>
                        </div>
                    </div>
                    <div className="investingbox">
                        <img
                            src={Icon04}
                            alt="Research Backed Recommendations"
                        />
                        <div className="investingcont">
                            <h4>Research Backed Recommendations</h4>
                            <p>
                                Our advisory is backed by top-grade research
                                & analysis. We are always happy to help you.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IpoUsp;
import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useLocation } from "react-router-dom";
import {
  getFundRankingsData,
  getPlanCategoryReturnsData,
} from "../../../../utils/APICollection";

const ReturnsAndRankings = ({ fundDetails, fundReturnsData, setOpen }) => {
  const [planCategoryReturnsData, setPlanCategoryReturnsData] = useState({});
  const [fundRankings, setFundRankings] = useState({});
  const location = useLocation();
  const fetchPlanCategoryReturns = async () => {
    try {
      const res = await getPlanCategoryReturnsData(
        window.location.pathname.split("/")[3]
      );
      setPlanCategoryReturnsData(res?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFundRankings = async () => {
    try {
      const res = await getFundRankingsData(
        window.location.pathname.split("/")[3]
      );
      //console.log("res", res?.data[0]);
      setFundRankings(res?.data[0]);
      //setPlanCategoryReturnsData(res?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPlanCategoryReturns();
    fetchFundRankings();
  }, [location.pathname]);

  return (
    <div className="returncalulator ipotable mmanagetable">
      <div className="calheading">
        <p className="text-black">
          <strong>Returns and rankings</strong>
        </p>
        <p>Category:{fundDetails?.asset_class} </p>
      </div>
      <div className="howtoapply">
        <Tabs>
          <div className="tradingben m-0">
            <div className="iconrow noicon reports mb-2">
              <TabList className="justify-content-end">
                <Tab>
                  <h4>Annualised returns</h4>
                </Tab>
                {/* will be used in future */}
                {/* <Tab>
                                          <h4>Absolute rerturns</h4>
                                        </Tab> */}
              </TabList>
            </div>
          </div>
          <TabPanel>
            <div className="ipotable">
              <table className="table">
                <thead className="bg-white">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">1Y</th>
                    <th scope="col">3Y</th>
                    <th scope="col">5Y</th>
                    <th scope="col">All</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="row">Funds returns</td>
                    <td>
                      {fundReturnsData?.ret_1year &&
                        Number(fundReturnsData?.ret_1year).toFixed(2)}{" "}
                      %
                    </td>
                    <td>
                      {fundReturnsData?.ret_3year &&
                        Number(fundReturnsData?.ret_3year).toFixed(2)}{" "}
                      %
                    </td>
                    <td>
                      {fundReturnsData?.ret_5year &&
                        Number(fundReturnsData?.ret_5year).toFixed(2)}{" "}
                      %
                    </td>
                    <td>
                      {fundReturnsData?.ret_since_launch &&
                        Number(fundReturnsData?.ret_since_launch).toFixed(
                          2
                        )}{" "}
                      %
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">Category average</td>
                    <td>
                      {Number(planCategoryReturnsData?.ret_1yr).toFixed(2) ===
                        "NaN"
                        ? "NA"
                        : Number(planCategoryReturnsData?.ret_1yr).toFixed(
                          2
                        )}{" "}
                      %
                    </td>
                    <td>
                      {Number(planCategoryReturnsData?.ret_3yr).toFixed(2) ===
                        "NaN"
                        ? "NA"
                        : Number(planCategoryReturnsData?.ret_3yr).toFixed(
                          2
                        )}{" "}
                      %
                    </td>
                    <td>
                      {Number(planCategoryReturnsData?.ret_5yr).toFixed(2) ===
                        "NaN"
                        ? "NA"
                        : Number(planCategoryReturnsData?.ret_5yr).toFixed(
                          2
                        )}{" "}
                      %
                    </td>
                    <td>
                      {Number(
                        planCategoryReturnsData?.ret_since_launch
                      ).toFixed(2) === "NaN"
                        ? "NA"
                        : Number(
                          planCategoryReturnsData?.ret_since_launch
                        ).toFixed(2)}{" "}
                      %
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">Rank with in Category </td>
                    <td>{fundRankings?.rank_1year}</td>
                    <td>{fundRankings?.rank_3year}</td>
                    <td>{fundRankings?.rank_5year}</td>
                    <td>NA</td>
                  </tr>
                  <tr>
                    <td scope="row">
                      Understand term{" "}
                      <i
                        className="question"
                        onClick={() => setOpen(true)}
                        style={{ cursor: "pointer" }}
                      >
                        i
                      </i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </TabPanel>
          <TabPanel>
            <h4 className="nodata">No Data Found</h4>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default ReturnsAndRankings;

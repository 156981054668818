import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Context } from "../context";

export const Support_Drop = ({ setActive, setIsOpen, menus }) => {
    const {
        isDemat,
        num,
        setIsDemat,
        setName,
        setNum,
        setEmail,
        setState,
        setCity,
        name,
        email,
        state,
        city,
        categoryID,
        urlData,
        ipAddress,
    } = useContext(Context);

    const emptyField = () => {
        setCity("");
        setEmail("");
        setName("");
        setState("");
        setNum("");
        setActive(!menus);
        setIsOpen(false);
    };
    return (
        <>
            <div className="menubox">
                <div className="sitemapcol">
                    {/* <h4 style={{ cursor: "context-menu" }}>Grievance Redressal Portal</h4> */}
                    <ul>
                        <li style={{ lineHeight: 1 }}>
                            <NavLink to="/grievances" onClick={emptyField}>
                                Grievance Redressal Portal
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="sitemapcol">
                    {/* <h4 style={{ cursor: "context-menu" }}>Contact Us</h4> */}
                    <ul>
                        <li>
                            <NavLink to="/contact-us" onClick={emptyField}>
                                Contact Us
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

import React from 'react'

export const Trend = (props) => {
  return (
    <>
     <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 25 25"
    {...props}
  >
    <path stroke="#44475b" strokeWidth={1.2} d="m4.5 18.5 7-8 2 4 7-8" />
    <path stroke="#44475b" strokeWidth={1.2} d="M20.5 11V6.5H16" />
  </svg>
    </>
  )
}

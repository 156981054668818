import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import Icon01 from "../../../images/fundamental-01.webp";
import Icon02 from "../../../images/fundamental-02.webp";
import Icon03 from "../../../images/fundamental-03.webp";
import Icon04 from "../../../images/fundamental-04.webp";

const NumberCount = () => {
  const [state, setState] = useState({
    turnover: 1,
    workforce: 1,
    states: 1,
    cities: 1,
  });
  return (
    <>
      <div
        className="webcent"
        data-aos="fade-up"
        data-aos-offset="80"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Trust. Rock Solid.</h3>
              </div>
              <div className="iconrow">
                <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
                  {({ isVisible }) => (
                    <ul>
                      <li>
                        <span>
                          <img src={Icon01} alt="Regular Training Program" />
                        </span>
                        <div className="countnum">
                          {isVisible ? (
                            <CountUp
                              start={state.turnover === 1 ? 1 : 31000}
                              end={31000}
                              duration={3}
                              onEnd={() => setState({ turnover: 30 })}
                            />
                          ) : (
                            <CountUp start={1} end={31000} duration={0} />
                          )}{" "}
                          +<p>Research Report</p>
                        </div>{" "}
                      </li>
                      <li>
                        <span>
                          <img
                            src={Icon02}
                            alt="Advanced Software to Manage Clients"
                          />
                        </span>
                        <div className="countnum">
                          {isVisible ? (
                            <CountUp
                              start={state.turnover === 1 ? 1 : 260}
                              end={260}
                              duration={3}
                              onEnd={() => setState({ turnover: 20 })}
                            />
                          ) : (
                            <CountUp start={1} end={260} duration={0} />
                          )}{" "}
                          +<p>Stocks Covered</p>
                        </div>{" "}
                      </li>
                      <li>
                        <span>
                          <img
                            src={Icon03}
                            alt="Multiple Product Offering For Cross Sell"
                          />
                        </span>{" "}
                        <div className="countnum">
                          {isVisible ? (
                            <CountUp
                              start={state.states === 1 ? 1 : 21}
                              end={21}
                              duration={1}
                              onEnd={() => setState({ states: 2500 })}
                            />
                          ) : (
                            <CountUp start={1} end={21} duration={0} />
                          )}
                          +<p>Sectors Covered</p>
                        </div>{" "}
                      </li>
                      <li>
                        <span>
                          <img src={Icon04} alt="Regular Marketing Support" />
                        </span>{" "}
                        <div className="countnum">
                          {isVisible ? (
                            <CountUp
                              start={state.workforce === 1 ? 1 : 40}
                              end={40}
                              duration={2}
                              onEnd={() => setState({ workforce: 3300 })}
                            />
                          ) : (
                            <CountUp start={1} end={40} duration={0} />
                          )}
                          +<p>Report Types</p>
                        </div>{" "}
                      </li>
                    </ul>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NumberCount;

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import Vinod from "../images/Vinod-Kumar-Atal.webp";
import Deepak from "../images/deepak-modi.webp";
import Ketan from "../images/ketan-manubhai.webp";
import Nilesh from "../images/nilesh.webp";
import Pankaj from "../images/pankaj-jain.webp";
import left from "../images/larrow.webp";
import right from "../images/arrow.webp";

export default function Carousel() {
  const clientSpeakData = [
    {
      imageSrc: Nilesh,
      name: "Sarita Kanaujiya",
      description:
        "Becoming a distributor with SMC was a really good decision. I was just a housewife and now i handle clients personally. I manage an AUM of 7-8 crores now. All this has been made possible with the support and easy to use platform of SMC Global. If you wish to start your journey as a Distributor, SMC is the best choice for you.",
    },
    {
      imageSrc: Vinod,
      name: "Prem Shankar Mishra",
      description:
        "It has been 10 years now since I have been associated with SMC and it has been a very good journey. Coming from a tier 2 city, I can say with confidence that SMC makes it easy to understand the process with proper training and their platforms are easy to use even for a beginner. I am highly satisfied with their products and services and look forward to a longer assosciation with SMC.",
    },
    {
      imageSrc: Deepak,
      name: "Amit Shukla",
      description:
        "As a distributor, my journey with SMC Global has been nothing short of gratifying. From the moment I partnered with them, I've experienced unparalleled support and guidance. Their comprehensive training programs have equipped me with the knowledge and skills necessary to excel in the financial industry. I am thoroughly satisfied with the diverse range of products and services offered by SMC Global, and I anticipate a continued association with them.",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings = {
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true, // Set this to false
    // autoplay: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
    beforeChange: (current, next) => setCurrentSlide(next),
    nextArrow: (
      <SampleNextArrow
        currentSlide={currentSlide}
        clientSpeakData={clientSpeakData}
      />
    ),
    prevArrow: (
      <SamplePrevArrow
        currentSlide={currentSlide}
        clientSpeakData={clientSpeakData}
      />
    ),
  };

  return (
    <div className="content">
      <Slider {...sliderSettings}>
        {clientSpeakData.map((item, index) => {
          return (
            <div key={index}>
              <div className="reviewbox">
                <div className="commentcircle">
                  <div className="comment">
                    <div className="profile">
                      <div className="climg">
                        <img
                          src={item.imageSrc}
                          width={65}
                          height={65}
                          alt=""
                        />
                      </div>
                      <div className="rating">
                        <ul className="d-flex">
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                          <li>
                            <i className="fa fa-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="commentcent">
                      <p>{item.description}</p>
                    </div>
                    <div className="name">
                      <h4>{item.name}</h4>
                      <h5>{item.location}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

// Custom arrow components
function SampleNextArrow(props) {
  const { className, style, onClick, currentSlide, clientSpeakData } = props;
  return (
    <div
      className={className}
      style={{
        ...style,

        display: currentSlide === clientSpeakData.length - 3 ? "none" : "block",
      }}
      onClick={onClick}
    >
      <img src={right} alt="right" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick, currentSlide, hotelCards } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: currentSlide === 0 ? "none" : "block",
      }}
      onClick={onClick}
    >
      <img src={left} alt="left" />
    </div>
  );
}

import { CAPTCHA_KEY, EKYC_CAPTCHA_KEY } from "../../utils/constants";

function getOrdinalSuffix(day) {
  let suffix = "th";
  if (day % 10 === 1 && day !== 11) {
    suffix = "st";
  } else if (day % 10 === 2 && day !== 12) {
    suffix = "nd";
  } else if (day % 10 === 3 && day !== 13) {
    suffix = "rd";
  }
  return day + suffix;
}

export function formatMyDate(dateString) {
  let date = new Date(dateString);
  return `${getOrdinalSuffix(date.getDate())} ${date.toLocaleString("default", {
    month: "short",
  })} ${date.getFullYear()}`;
}

export function formatTime(timeString) {
  let [hours, minutes] = timeString.split(":").map(Number);
  let date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  });
}

export function formatDate(inputDate) {
  // Create a new Date object
  let date = new Date(inputDate);

  // Get the day, month, and year
  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();

  // Array of month names
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Format the date
  let formattedDate =
    (day < 10 ? "0" : "") + day + "-" + monthNames[monthIndex] + "-" + year;

  return formattedDate;
}

export function formatedDate(dateString) {
  let date = new Date(dateString);
  let options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
}

export const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber).toLocaleString("en-IN");
  return formetedNumber;
};

export const loadReCaptchaScript = () => {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=${String(
    CAPTCHA_KEY
  )}`;
  script.async = true;
  document.body.appendChild(script);
};

export const loadReCaptchaScript1 = () => {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=${String(
    EKYC_CAPTCHA_KEY
  )}`;
  script.async = true;
  document.body.appendChild(script);
};

import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { getCountryImport } from "../../utils/APICollection";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context";

export default function CountrySearch({
  countryError,
  setCountryError,
  color,
}) {
  const { country, setCountry } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState(""); // New state for country code

  const ALPHA_REGEX = /^[a-zA-Z ]+$/;

  useEffect(() => {
    let active = true;
    if (!open || country.length < 3) {
      return;
    }

    setLoading(true);

    (async () => {
      try {
        const { data } = await getCountryImport(country);
        console.log(data);

        if (data?.data.length > 0) {
          setOptions(data.data);
        } else {
          setOptions(["Other"]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [country]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <>
      <Autocomplete
        id="Country"
        sx={{
          ".MuiAutocomplete-input": {
            color: { color },
            fontSize: "0.813rem",
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        isOptionEqualToValue={(option, value) =>
          option.country_name === value.country_name
        }
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.country_name
        }
        options={options}
        loading={loading}
        onInputChange={(event, newInputValue) => {
          setCountry(newInputValue);
          setCountryCode(""); // Reset country code when input changes
          if (newInputValue) {
            setOpen(true);
          } else {
            setOpen(false);
          }
          if (newInputValue !== "") {
            setCountryError("");
          }
        }}
        onChange={(event, newValue) => {
          if (newValue && typeof newValue !== "string") {
            setCountryCode(newValue.country_code || ""); // Set country code based on selection
          } else {
            setCountryCode("");
          }
        }}
        filterOptions={(x) => x}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Country"
            variant="standard"
            error={!!countryError}
            helperText={countryError}
            onKeyDown={(event) => {
              if (!ALPHA_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
            InputLabelProps={{
              style: { fontSize: "13px" },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <TextField
        label="Country Code"
        variant="standard"
        value={countryCode}
        InputProps={{
          readOnly: true,
        }}
        sx={{ marginTop: 2, width: "100%" }} // Adjust styling as needed
      />
    </>
  );
}

import React from 'react'

export const Shape = (props) => {
  return (
    <>
        <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Elements"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 496.11 309.39",
    }}
    viewBox="0 0 496.11 309.39"
    {...props}
  >
   
    <g id="XMLID_110_">
      <path
        id="XMLID_2631_"
        d="M194.76 96.77c1.68 6.21-4.65 9.62-9.6 8.08-2.21-.68-3.33-2.77-3.95-4.84-.29-.98-.51-2.12-.14-3.41"
      
      />
      <path
        id="XMLID_2630_"
        d="M236.36 122.47c-2.21 2.04-4.67 2.11-7.39.75-2.64-1.31-3.74-3.31-3.72-6.13"
      
      />
      <path
        id="XMLID_2628_"
        d="M202.33 102.82c3.47 1.8 4.59 5.17 2.81 8.84-1.82 3.75-5.18 4.87-9 3.01-3.71-1.81-4.73-5.15-2.92-9.05.77-1.67 1.9-2.71 3.6-3.35"
      
      />
      <path
        id="XMLID_2626_"
        d="M213.75 112.83c3.53 1.73 4.63 5.13 2.85 8.81-1.82 3.77-5.17 4.9-8.99 3.04-3.72-1.81-4.81-5.18-2.93-9.03 1.8-3.68 5.17-4.73 9.07-2.82"
      
      />
      <path
        id="XMLID_2625_"
        d="M245.48 121.56c3.49 1.56 4.74 5.63 3.14 8.93-1.55 3.15-5.45 4.81-8.99 3.01-3.32-1.69-4.75-5.65-2.88-9.01"
      
      />
      <path
        id="XMLID_2624_"
        d="M180.89 103.58c3.18 1.54 4.82 5.44 3.06 8.98-1.62 3.25-5.58 4.78-9 2.94-3.39-1.83-4.53-5.26-2.98-8.73 1.64-3.67 5.81-4.7 8.92-3.19z"
      
      />
      <path
        id="XMLID_2623_"
        d="M270.04 129.07c-1.62 3.25-5.58 4.78-9 2.94-3.39-1.83-4.53-5.26-2.98-8.73"
      
      />
      <path
        id="XMLID_2622_"
        d="M215.52 125.39c1.77-3.31 5.67-4.68 9.03-2.85 3.46 1.88 4.5 5.69 2.77 9.06-1.58 3.11-5.61 4.39-8.82 2.79-3.2-1.58-4.73-5.49-2.98-9z"
      
      />
      <path
        id="XMLID_2621_"
        d="M160.53 96.69c1.54-3.18 5.44-4.82 8.98-3.06 3.25 1.62 4.78 5.58 2.94 9-1.83 3.39-5.26 4.53-8.73 2.98-3.67-1.64-4.71-5.81-3.19-8.92z"
      
      />
      <path
        id="XMLID_2620_"
        d="M158.19 83.74c3.46 1.95 4.42 5.29 2.87 8.76-1.42 3.16-5.36 4.82-8.96 3.06-3.26-1.67-4.74-5.6-2.93-9.01 1.8-3.4 5.65-4.71 9.02-2.81"
      
      />
      <path
        id="XMLID_2619_"
        d="M150.52 82.1c-1.63 3.66-5.8 4.7-8.92 3.19-3.2-1.55-4.81-5.45-3.06-8.97"
      
      />
      <path
        id="XMLID_2618_"
        d="M333.33 103.98c-1.37 2.88-5.9 4.6-8.71 3.29"
      
      />
      <path
        id="XMLID_2617_"
        d="M186.47 125.55c-2.97-1.47-4.45-6.06-2.92-9.09 1.4-2.78 6.01-4.26 8.87-2.85 2.97 1.47 4.45 6.06 2.92 9.09-1.4 2.78-6.01 4.26-8.87 2.85z"
      
      />
      <path
        id="XMLID_2616_"
        d="M139.19 71.9c-1.42 3.11-5.92 4.86-8.91 3.46-2.94-1.38-4.68-5.85-3.38-8.68"
      
      />
      <path
        id="XMLID_2614_"
        d="M319.97 106.5c2.93 1.22 4.71 5.94 3.37 8.94-1.25 2.79-5.79 4.5-8.71 3.29-2.93-1.22-4.71-5.94-3.37-8.94"
      
      />
      <path
        id="XMLID_2611_"
        d="M248.07 134.67c1.33-2.8 5.84-4.44 8.75-3.2 2.93 1.25 4.67 6.03 3.27 8.97-1.33 2.8-5.84 4.44-8.75 3.2-2.92-1.25-4.66-6.03-3.27-8.97z"
      
      />
      <path
        id="XMLID_2610_"
        d="M197.08 135.88c-2.92-1.44-4.51-6.27-2.99-9.07 1.51-2.78 6.07-4.28 8.85-2.91 2.92 1.44 4.51 6.27 2.99 9.07-1.51 2.78-6.07 4.28-8.85 2.91z"
      
      />
      <path
        id="XMLID_2609_"
        d="M230.01 144.42c-2.93-1.42-4.54-6.25-3.02-9.06 1.51-2.79 6.04-4.29 8.84-2.94 2.93 1.42 4.54 6.25 3.02 9.06-1.51 2.79-6.04 4.29-8.84 2.94z"
      
      />
      <path
        id="XMLID_2608_"
        d="M281.67 134.47c.11 1.52.43 3.42-.3 4.51-.9 1.34-2.15 2.83-4.04 3.26-1.68.38-3.27-.16-4.91-.23-.85-1.54-2.87-2.31-3.02-4.27-.12-1.52.05-3.07.09-4.55"
      
      />
      <path
        id="XMLID_2606_"
        d="M216.83 142.57c-1.79 3.67-4.32 4.5-8.13 2.64-3.62-1.77-4.41-4.32-2.53-8.17 1.68-3.44 4.33-4.29 7.91-2.56 3.68 1.78 4.56 4.37 2.75 8.09z"
      
      />
      <path
        id="XMLID_2605_"
        d="M159.45 105.79c3.5 1.72 4.23 4.35 2.3 8.25-1.67 3.37-4.3 4.14-7.99 2.34-3.62-1.77-4.41-4.33-2.52-8.17 1.74-3.55 4.35-4.32 8.21-2.42z"
      
      />
      <path
        id="XMLID_2603_"
        d="M165.28 126.39c-3.66-1.78-4.47-4.32-2.6-8.14 1.75-3.58 4.34-4.37 8.18-2.49 3.42 1.67 4.26 4.34 2.52 7.92-1.79 3.69-4.35 4.54-8.1 2.71z"
      
      />
      <path
        id="XMLID_2602_"
        d="M309.29 118.36c3.59 1.74 4.57 4.35 2.93 7.78-1.85 3.86-4.37 4.84-7.96 3.12-3.83-1.84-4.77-4.34-3.01-8"
      
      />
      <path
        id="XMLID_2600_"
        d="M142.83 105.89c-3.73-1.8-4.64-4.37-2.86-8.05 1.78-3.68 4.37-4.56 8.09-2.75 3.58 1.74 4.49 4.28 2.78 7.83-1.83 3.81-4.34 4.74-8.01 2.97z"
      
      />
      <path
        id="XMLID_2599_"
        d="M184.06 133.47c.04 2.14-1.95 2.62-3.22 3.16-1.41.6-3.18.28-4.88-.48-1.71-.77-2.58-2.17-3.03-3.48-.5-1.45-.35-3.2.56-4.9.86-1.6 2.2-2.51 3.52-2.98 1.33-.47 2.98-.24 4.59.46 1.71.75 2.65 2.11 3.12 3.4.5 1.45.98 3.41-.66 4.82z"
      
      />
      <path
        id="XMLID_2596_"
        d="M139.11 93.18c.04 2.15-1.95 2.63-3.22 3.16-1.41.59-3.18.3-4.88-.48-1.7-.78-2.58-2.17-3.03-3.48-.5-1.46-.35-3.2.56-4.9.85-1.6 2.2-2.5 3.52-2.97 1.33-.48 2.98-.24 4.59.47 1.71.75 2.65 2.11 3.12 3.41.51 1.42.99 3.38-.66 4.79z"
      
      />
      <path
        id="XMLID_2595_"
        d="M364.31 86.76c.51 1.43.99 3.39-.66 4.8.04 2.14-1.95 2.63-3.22 3.16-1.41.59-3.18.3-4.88-.48-1.7-.78-2.58-2.17-3.03-3.48"
      
      />
      <path
        id="XMLID_2594_"
        d="M241.84 153.73c-2.13-.15-2.87-2.02-3.4-3.45-.48-1.31-.3-2.97.44-4.59.77-1.68 2.07-2.63 3.39-3.15 1.46-.58 3.18-.3 4.9.41 1.76.73 2.63 2.15 3.07 3.44.44 1.28.62 2.95-.3 4.6-.84 1.52-1.95 2.8-3.29 3.22-1.46.46-3.37 1.13-4.81-.48z"
      
      />
      <path
        id="XMLID_2592_"
        d="M299.63 129.93c2 .56 2.07 2.29 2.79 3.71.81 1.58.27 2.83-.3 4.31-.69 1.79-1.97 2.42-3.6 2.92-2.37.72-2.35.79-4.83-.42-1.82-.19-1.98-1.98-2.66-3.45-.79-1.69-.24-3.03.31-4.62"
      
      />
      <path
        id="XMLID_2591_"
        d="M187.58 146.09c-2.89-1.44-4.08-5.19-2.59-8.15 1.42-2.83 5.23-3.98 8.18-2.48 2.67 1.36 3.87 5.15 2.51 7.93-1.42 2.93-5.15 4.17-8.1 2.7z"
      
      />
      <path
        id="XMLID_2590_"
        d="M345.96 105.75c-2.98-1.4-4.34-5.1-2.94-8.03 1.34-2.81 5.04-4.22 7.77-2.97 3.01 1.38 4.49 5.09 3.16 7.95-1.4 3.02-5.07 4.42-7.99 3.05z"
      
      />
      <path
        id="XMLID_2589_"
        d="M262.89 152.94c-2.92-1.43-4.16-5.16-2.69-8.11 1.44-2.89 5.19-4.07 8.15-2.58 2.7 1.36 3.94 5.12 2.61 7.89-1.4 2.92-5.15 4.22-8.07 2.8z"
      
      />
      <path
        id="XMLID_2587_"
        d="M333.05 109.14c1.42-2.92 5.16-4.2 8.09-2.75 2.89 1.43 4.12 5.19 2.64 8.13-1.38 2.75-5.07 4-7.87 2.68-2.95-1.42-4.27-5.14-2.86-8.06z"
      
      />
      <path
        id="XMLID_2586_"
        d="M220.31 155.28c-2.92-1.42-4.2-5.16-2.75-8.09 1.43-2.89 5.19-4.11 8.13-2.64 2.75 1.38 4 5.07 2.67 7.87-1.4 2.95-5.12 4.28-8.05 2.86z"
      
      />
      <path
        id="XMLID_2582_"
        d="M284.31 151.46c-2.89-1.43-4.11-5.19-2.64-8.13 1.44-2.88 5.18-4.04 8.17-2.53 2.67 1.35 3.9 5.14 2.56 7.91-1.42 2.92-5.17 4.19-8.09 2.75z"
      
      />
      <path
        id="XMLID_2580_"
        d="M322.75 119.7c1.42-2.92 5.16-4.2 8.09-2.75 2.89 1.43 4.12 5.19 2.64 8.13-1.38 2.75-5.07 4-7.87 2.68-2.95-1.41-4.28-5.13-2.86-8.06z"
      
      />
      <path
        id="XMLID_2579_"
        d="M120.39 86.13c-2.92-1.43-4.15-5.17-2.69-8.11 1.44-2.89 5.19-4.07 8.15-2.57 2.69 1.36 3.94 5.12 2.61 7.89-1.4 2.92-5.15 4.22-8.07 2.79z"
      
      />
      <path
        id="XMLID_2578_"
        d="M198.75 156.69c-2.89-1.44-4.08-5.19-2.59-8.15 1.42-2.83 5.23-3.98 8.18-2.48 2.67 1.36 3.87 5.15 2.51 7.93-1.42 2.92-5.15 4.16-8.1 2.7z"
      
      />
      <path
        id="XMLID_2577_"
        d="M231.95 165.37c-3.1-1.6-4.38-5.52-2.9-8.26 1.54-2.84 5.51-4.17 8.13-2.48 1.26.81 2.48 1.86 2.9 3.57.38 1.52.28 2.97-.35 4.26-.69 1.4-1.83 2.45-3.44 3.03-1.63.6-3.08.03-4.34-.12z"
      
      />
      <path
        id="XMLID_2575_"
        d="M117.08 68.43c.78 1.72.14 3.29.05 4.97-1.18 1.06-1.89 2.55-3.61 2.95-1.12.26-3.68.24-4.59-.22-2.73-1.4-4.02-4.99-2.81-7.82"
      
      />
      <path
        id="XMLID_2573_"
        d="M141.47 119.2c1.24-2.38 5.87-3.87 8.13-2.63 2.34 1.29 3.78 5.96 2.52 8.17-1.27 2.22-5.68 3.64-7.91 2.55-2.4-1.15-3.96-5.76-2.74-8.09z"
      
      />
      <path
        id="XMLID_2572_"
        d="M155.52 137.97c-2.39-1.02-4.07-5.57-2.96-8.02 1.02-2.25 5.33-3.91 7.76-2.99 2.37.89 4.19 5.45 3.18 7.94-.96 2.37-5.51 4.12-7.98 3.07z"
      
      />
      <path
        id="XMLID_2567_"
        d="M315.63 139.22c-2.39-1.06-4.03-5.64-2.88-8.04 1.08-2.26 5.39-3.88 7.78-2.92 2.38.95 4.15 5.49 3.11 7.97-1 2.37-5.56 4.08-8.01 2.99z"
      
      />
      <path
        id="XMLID_2566_"
        d="M250.19 156.31c1.24-2.38 5.87-3.87 8.13-2.63 2.34 1.29 3.78 5.96 2.52 8.17-1.27 2.22-5.68 3.64-7.91 2.55-2.4-1.15-3.96-5.76-2.74-8.09z"
      
      />
      <path
        id="XMLID_2563_"
        d="M140.86 114.47c-1.12.97-2.14 2.37-3.48 2.85-1.35.48-3.23.49-4.49-.1-1.27-.59-2.48-2.04-2.95-3.38-.48-1.34-.41-3.19.16-4.5.51-1.19 1.87-2.35 3.12-2.8 1.37-.49 3.22-.57 4.51-.02 1.28.56 2.53 1.97 3.04 3.3.5 1.34.1 3.02.09 4.65z"
      
      />
      <path
        id="XMLID_2560_"
        d="M207.48 158.62c1.25-.99 2.32-2.36 3.68-2.79 1.36-.43 3.24-.36 4.48.29 1.24.65 2.39 2.15 2.81 3.51.42 1.36.31 3.25-.35 4.48-.67 1.23-2.18 2.31-3.55 2.77-1.25.42-3.04.35-4.18-.27-1.25-.68-2.44-2.09-2.91-3.44-.47-1.34-.02-3.01.02-4.55z"
      
      />
      <path
        id="XMLID_2558_"
        d="M163.37 139.55c1.22-.99 2.27-2.36 3.62-2.81 1.36-.45 3.24-.4 4.49.22 1.25.63 2.42 2.11 2.86 3.46.44 1.35.32 3.2-.28 4.49-.55 1.17-1.94 2.3-3.2 2.71-1.38.45-3.23.49-4.5-.1s-2.47-2.04-2.96-3.38c-.48-1.35-.05-3.02-.03-4.59z"
      
      />
      <path
        id="XMLID_2557_"
        d="M274.3 162.99c-.98-1.21-2.34-2.27-2.78-3.63-.44-1.36-.38-3.24.26-4.48.64-1.25 2.13-2.41 3.49-2.84 1.36-.43 3.2-.3 4.49.31 1.17.56 2.28 1.96 2.68 3.22.44 1.38.51 3.3-.15 4.48-.67 1.2-1.79 2.71-3.36 3-1.48.27-3.06-.01-4.63-.06z"
      
      />
      <path
        id="XMLID_2555_"
        d="M303.53 142.67c.2-1.58 1.52-1.98 2.86-2.64 1.7-.83 2.9-.06 4.34.48 1.52.56 2.01 1.7 2.68 3.03.87 1.75-.05 2.91-.55 4.34-.54 1.55-1.7 2.04-3.08 2.61-1.54.63-2.68.14-4.02-.45-1.41-.62-2.18-1.49-2.77-2.95-.68-1.68-.4-2.97.54-4.42z"
      
      />
      <path
        id="XMLID_2553_"
        d="M242.68 174.21c-1.58-.2-1.99-1.52-2.64-2.86-.83-1.7-.06-2.9.47-4.34.56-1.52 1.7-2.01 3.03-2.68 1.75-.87 2.91.05 4.34.55 1.55.54 2.05 1.7 2.61 3.08.63 1.54.15 2.68-.45 4.02-.62 1.4-1.48 2.18-2.95 2.77-1.66.68-2.96.4-4.41-.54z"
      
      />
      <path
        id="XMLID_2552_"
        d="M187.14 159.63c.2-1.58 1.52-1.98 2.86-2.64 1.7-.84 2.9-.06 4.34.48 1.52.56 2.01 1.7 2.67 3.03.87 1.75-.05 2.91-.55 4.34-.54 1.55-1.7 2.04-3.08 2.61-1.54.63-2.68.14-4.02-.45-1.41-.62-2.18-1.49-2.77-2.95-.68-1.67-.39-2.96.55-4.42z"
      
      />
      <path
        id="XMLID_2551_"
        d="M185.08 154.45c-.39 1.53-1.33 2.33-2.82 2.89-1.62.61-2.87.41-4.35-.32-1.36-.67-2.19-1.47-2.78-2.95-.66-1.67-.24-2.89.4-4.35.63-1.43 1.51-2.2 2.99-2.72 1.5-.53 2.66-.4 4.04.3 1.43.73 2.5 1.47 2.97 3.19.41 1.5.57 2.76-.45 3.96z"
      
      />
      <path
        id="XMLID_2549_"
        d="M128.68 104.16c-.39 1.53-1.33 2.33-2.82 2.89-1.62.61-2.87.41-4.35-.32-1.36-.67-2.19-1.47-2.78-2.95-.66-1.67-.24-2.89.4-4.35.63-1.43 1.51-2.2 2.99-2.72 1.5-.53 2.66-.4 4.04.3 1.43.73 2.5 1.47 2.97 3.19.42 1.5.57 2.76-.45 3.96z"
      
      />
      <path
        id="XMLID_2546_"
        d="M394.44 78.77c-1.37 2.9-4.3 3.94-7.16 2.55"
      
      />
      <path
        id="XMLID_2544_"
        d="M228.59 173.36c-1.39 2.87-4.33 3.86-7.2 2.44s-3.8-4.31-2.33-7.24c1.35-2.7 4.22-3.67 6.98-2.36 2.9 1.38 3.94 4.3 2.55 7.16z"
      
      />
      <path
        id="XMLID_2534_"
        d="M108.27 89.85c1.39-2.85 4.34-3.83 7.21-2.4 2.85 1.42 3.76 4.32 2.29 7.25-1.35 2.68-4.23 3.64-6.99 2.32-2.89-1.37-3.92-4.29-2.51-7.17z"
      
      />
      <path
        id="XMLID_2532_"
        d="M261.54 166.52c1.38-2.94 4.2-3.84 7.16-2.67 2.41.95 3.86 4.59 2.67 6.94-1.49 2.94-4.28 4.14-7.09 2.83-2.99-1.39-4.08-4.24-2.74-7.1z"
      
      />
      <path
        id="XMLID_2530_"
        d="M336.92 137.68c-2.87-1.42-3.79-4.31-2.33-7.24 1.41-2.81 4.34-3.7 7.28-2.21 2.64 1.34 3.58 4.27 2.25 7.02-1.39 2.85-4.33 3.85-7.2 2.43z"
      
      />
      <path
        id="XMLID_2528_"
        d="M344.55 119.05c1.42-2.87 4.31-3.8 7.24-2.33 2.81 1.41 3.7 4.34 2.21 7.28-1.34 2.64-4.27 3.58-7.02 2.25-2.86-1.38-3.85-4.32-2.43-7.2z"
      
      />
      <path
        id="XMLID_2526_"
        d="M198.13 170.47c1.39-2.87 4.33-3.86 7.2-2.44s3.8 4.31 2.33 7.24c-1.35 2.7-4.22 3.67-6.98 2.36-2.89-1.37-3.93-4.3-2.55-7.16z"
      
      />
      <path
        id="XMLID_2523_"
        d="M357.58 115.09c-2.87-1.39-3.86-4.33-2.44-7.2s4.31-3.8 7.24-2.33c2.7 1.35 3.67 4.22 2.36 6.98-1.37 2.9-4.29 3.94-7.16 2.55z"
      
      />
      <path
        id="XMLID_2522_"
        d="M295.71 161.43c-2.83-1.42-3.74-4.32-2.25-7.27 1.4-2.77 4.33-3.55 7.32-2.17 2.38 1.1 3.51 4.85 2.21 7.1-1.6 2.75-4.38 3.79-7.28 2.34z"
      
      />
      <path
        id="XMLID_2520_"
        d="M365.06 96.6c1.37-2.9 4.29-3.96 7.15-2.59 2.75 1.32 3.86 4.22 2.62 6.89-1.38 2.99-4.24 4.13-7.07 2.81-2.98-1.38-4.06-4.23-2.7-7.11z"
      
      />
      <path
        id="XMLID_2518_"
        d="M382.01 83.21c2.87 1.42 3.8 4.31 2.33 7.24-1.35 2.7-4.22 3.67-6.98 2.36-2.9-1.37-3.94-4.3-2.55-7.16"
      
      />
      <path
        id="XMLID_2515_"
        d="M150.07 138.29c2.6 1.32 3.57 4.91 2.24 7.48-1.11 2.13-4.72 2.99-7.12 2.09-1.6-.6-2.12-2.07-2.47-3.11-.4-1.17-.64-2.74.18-4.29.77-1.45 2.37-1.4 3.13-2.56 1.4.41 3.02-1.02 4.04.39z"
      
      />
      <path
        id="XMLID_2514_"
        d="M99.69 86.47c-2.53-1.19-3.81-4.67-2.62-7.14 1.14-2.39 4.52-3.54 6.9-2.72 1.67.58 2.47 2 2.86 3.18.35 1.06.87 2.52.03 3.98-1.4 2.38-4.78 3.83-7.17 2.7z"
      
      />
      <path
        id="XMLID_2513_"
        d="M285.33 172.19c-.9-1.15-2.15-2.16-2.55-3.44-.37-1.17-.14-2.79.43-3.9.57-1.11 1.79-2.38 2.91-2.58 1.29-.24 3-.73 4.24.31 1.01.84 2.31 1.88 2.51 3.01.21 1.19.46 2.65-.17 3.97-.69 1.44-2.11 2.14-3.29 2.75-1.03.52-2.61-.03-4.08-.12z"
      
      />
      <path
        id="XMLID_2510_"
        d="M134.39 138.31c-.94-1.1-2.31-2.01-2.54-3.15-.26-1.3-.69-2.99.28-4.24.81-1.03 1.86-2.35 2.98-2.55 1.3-.23 3-.7 4.24.33 1.01.85 2.28 1.89 2.5 3.02.23 1.18.45 2.65-.2 3.97-.7 1.42-2.11 2.15-3.3 2.73-1.03.52-2.6-.03-3.96-.11z"
      
      />
      <path
        id="XMLID_2505_"
        d="M156.32 158.6c-.9-1.15-2.14-2.16-2.55-3.44-.37-1.17-.14-2.79.43-3.9.57-1.11 1.79-2.38 2.91-2.58 1.29-.24 3-.73 4.24.31 1.01.84 2.31 1.88 2.51 3.01.21 1.19.46 2.65-.17 3.97-.69 1.44-2.11 2.14-3.29 2.75-1.02.52-2.6-.03-4.08-.12z"
      
      />
      <path
        id="XMLID_2503_"
        d="M324.58 141.36c1.19-.97 2.22-2.43 3.45-2.61 1.17-.18 2.78-.7 3.94.27 1.01.85 2.22 1.79 2.64 2.94.42 1.15.13 2.66-.15 3.95-.34 1.59-1.98 2.24-3.15 2.86-1 .53-2.75.28-3.88-.24-1.14-.52-2.33-1.65-2.75-2.79-.48-1.27-.1-2.84-.1-4.38z"
      
      />
      <path
        id="XMLID_2500_"
        d="M258.77 175.39c2.09 1.05 3.46 5.2 2.38 7.22-1.1 2.07-5.32 3.45-7.25 2.26-2.1-1.3-3.18-4.87-2.32-6.98.82-2.01 5.3-3.58 7.19-2.5z"
      
      />
      <path
        id="XMLID_2499_"
        d="M168.08 168.5c-1.84-.6-3.65-5-2.58-7.16.95-1.92 4.86-3.42 6.9-2.6 2.1.84 3.7 4.91 2.79 7.08-.87 2.09-4.97 3.63-7.11 2.68z"
      
      />
      <path
        id="XMLID_2491_"
        d="M127.78 118.6c2.07 1.08 3.41 5.25 2.33 7.24-1.13 2.07-5.31 3.34-7.28 2.21-1.91-1.09-3.16-5.07-2.26-7.02.98-2.12 5.53-3.51 7.21-2.43z"
      
      />
      <path
        id="XMLID_2489_"
        d="M230.42 178.76c.75-1.97 5.2-3.56 7.18-2.52 1.8.95 3.4 4.49 2.52 6.92-.77 2.13-4.93 3.67-7.08 2.74-2.08-.91-3.6-5.02-2.62-7.14z"
      
      />
      <path
        id="XMLID_2488_"
        d="M211.36 187.17c-.76-1.05-2-1.86-2.44-3.41-.38-1.32.34-2.46.12-3.69 1.32-.81 2.11-2.48 3.9-2.5 1.12-.01 2.67-.29 3.25.31.88.9 2.03 1.69 2.46 3.19.39 1.35-.19 2.48-.14 3.71.02.52-1.06 1.18-1.61 1.76-.68.71-1.97.97-3.04 1.12-.76.1-1.6-.29-2.5-.49z"
      
      />
      <path
        id="XMLID_2485_"
        d="M314.76 152.88c1.41-.87 2.18-2.56 3.98-2.58 1.12-.01 2.6-.29 3.28.29 1.12.96 2.51 2.07 2.54 3.83.02 1.12.25 2.63-.35 3.26-.94.99-1.9 2.16-3.54 2.51-1.23.26-2.25-.63-3.38-.17-.82-1.18-2.05-1.97-2.55-3.5-.44-1.36.54-2.47.02-3.64z"
      
      />
      <path
        id="XMLID_2484_"
        d="M176.9 171.35c.81-.68 1.63-1.45 2.53-2.12 1.43-1.07 3.12-.56 4.59-.3.8.14 1.49 1.33 2.07 2.15.98 1.4.77 3.1.51 4.6-.14.84-1.23 1.61-2.04 2.21-1.48 1.1-3.32.89-4.89.55-.9-.2-1.6-1.49-2.29-2.37-1.08-1.41-.27-3.08-.48-4.72z"
      
      />
      <path
        id="XMLID_2483_"
        d="M117.86 115.95c-.2 1.9-2.03 2.23-3.58 2.44-.93.12-1.97-.76-2.99-1.09-1.16-.38-1.69-1.15-2.08-2.29-.39-1.13-.46-2.08.22-3.09.31-.45.59-.97.72-1.49.29-1.16 1.22-1.42 2.16-1.75.94-.33 1.83-.69 2.78.04.43.33.96.56 1.49.73 1.16.36 1.69 1.15 2.08 2.29.39 1.13.48 2.09-.23 3.08-.21.26-.31.59-.57 1.13z"
      
      />
      <path
        id="XMLID_2482_"
        d="M282.02 180.83c-1.45 2.97-3.2 3.54-6.28 2.04-2.97-1.45-3.54-3.2-2.04-6.28 1.45-2.97 3.2-3.54 6.28-2.04 2.97 1.45 3.54 3.2 2.04 6.28z"
      
      />
      <path
        id="XMLID_2477_"
        d="M190.55 187.54c-2.78-1.36-3.48-3.45-2.1-6.26 1.36-2.78 3.45-3.48 6.26-2.1 2.78 1.36 3.48 3.45 2.1 6.26-1.36 2.77-3.45 3.48-6.26 2.1z"
      
      />
      <path
        id="XMLID_2471_"
        d="M100.72 106.97c-2.51-.77-3.34-4.01-2.25-6.27 1.13-2.34 4.09-3.3 6.35-2.21 2.34 1.13 3.3 4.09 2.21 6.35-1.13 2.34-4.23 3.59-6.31 2.13z"
      
      />
      <path
        id="XMLID_2470_"
        d="M304.82 164.36c1.37-2.82 3.42-3.54 6.23-2.18 2.82 1.37 3.54 3.42 2.17 6.23-1.37 2.82-3.42 3.54-6.23 2.18-2.82-1.36-3.54-3.41-2.17-6.23z"
      
      />
      <path
        id="XMLID_2469_"
        d="M244.71 195.25c-2.82-1.35-3.56-3.42-2.23-6.21 1.35-2.82 3.42-3.56 6.21-2.23 2.82 1.35 3.56 3.42 2.23 6.21-1.35 2.83-3.42 3.57-6.21 2.23z"
      
      />
      <path
        id="XMLID_2467_"
        d="M229.3 194.83c-1.09 2.41-3.92 3.15-6.41 1.76-2.3-1.29-3.13-3.96-1.74-6.46 1.29-2.3 3.96-3.13 6.46-1.74 2.3 1.29 3.27 4.05 1.69 6.44z"
      
      />
      <path
        id="XMLID_2457_"
        d="M294.89 175.67c.13-1.23 1.25-1.63 2.46-1.99 1.24-.37 2.35-.98 3.74-.09.98.63 1.77 1.16 2.02 2.35.27 1.24 1.13 2.32.26 3.74-.65 1.06-1.14 2.03-2.53 2.3-1.25.24-2.33.97-3.74.18-2.15-1.22-3.35-4.09-2.21-6.49z"
      
      />
      <path
        id="XMLID_2456_"
        d="M146.97 168.65c-1.23-.13-1.63-1.25-1.99-2.46-.37-1.25-.98-2.35-.09-3.74.63-.98 1.16-1.77 2.35-2.02 1.24-.27 2.32-1.13 3.74-.26 1.06.65 2.03 1.14 2.3 2.53.24 1.25.97 2.33.17 3.74-1.21 2.16-4.08 3.35-6.48 2.21z"
      
      />
      <path
        id="XMLID_2455_"
        d="M272.2 191.93c-1.24 2.51-4.24 3.36-6.54 2.42-1.5-.61-1.6-1.91-2.18-3.04-.65-1.26-.19-2.24.24-3.41.57-1.57 1.96-1.56 3.1-2.12 1.1-.54 2-.25 3.1.13 1.56.54 1.8 1.77 2.25 2.99.42 1.15 1.03 2.3.03 3.03z"
      
      />
      <path
        id="XMLID_2454_"
        d="M208.42 195.11c-1.24 2.51-4.24 3.36-6.54 2.42-1.5-.61-1.6-1.91-2.18-3.04-.65-1.26-.19-2.24.24-3.41.57-1.57 1.96-1.56 3.1-2.12 1.1-.54 2-.25 3.1.13 1.57.54 1.8 1.77 2.25 2.99.42 1.15 1.03 2.3.03 3.03z"
      
      />
      <path
        id="XMLID_2453_"
        d="M141.79 156.62c-.26 1.74-1.81 1.68-2.94 2.27-1.23.64-2.22.23-3.41-.21-1.57-.58-1.52-1.98-2.15-3.08-.72-1.28-.11-2.25.29-3.4.55-1.59 2-1.51 3.12-2.08 1.09-.56 2-.21 3.1.17 1.57.55 1.7 1.84 2.23 3.01.53 1.19.71 2.3-.24 3.32z"
      
      />
      <path
        id="XMLID_2452_"
        d="M368.81 124.16c-1.4-.18-1.88-1.23-2.21-2.47-.34-1.24-1.01-2.34-.1-3.74.63-.97 1.15-1.78 2.34-2.04 1.24-.27 2.33-1.15 3.74-.28 1.05.65 2.08 1.09 2.32 2.51.21 1.25 1.05 2.32.21 3.75-.65 1.1-1.23 1.97-2.56 2.26-1.26.27-2.45 1.18-3.74.01z"
      
      />
      <path
        id="XMLID_2451_"
        d="M398.95 90.67c-2.04-1.07-3.1-4.38-2.02-6.35 1.11-2.03 4.43-3.02 6.39-1.91 1.87 1.06 2.88 4.23 1.93 6.12-1.14 2.31-4.67 3.07-6.3 2.14z"
      
      />
      <path
        id="XMLID_2449_"
        d="M130.4 146.64c-1.07 2.04-4.38 3.1-6.35 2.02-2.03-1.11-3.02-4.43-1.91-6.39 1.06-1.86 4.23-2.88 6.12-1.94 2.28 1.13 3.07 4.66 2.14 6.31z"
      
      />
      <path
        id="XMLID_2448_"
        d="M395.21 100.13c-1.1 2.03-4.41 3.04-6.37 1.94-1.99-1.11-2.96-4.5-1.83-6.41 1.09-1.85 4.28-2.82 6.15-1.87 2.03 1.05 3.11 4.37 2.05 6.34z"
      
      />
      <path
        id="XMLID_2447_"
        d="M175.41 186.78c-.71 1.75-3.97 3.32-6.27 2.24-1.92-.9-3.08-4.02-2.28-6.01.85-2.08 4.07-3.36 6.19-2.46 2.08.87 3.31 4.13 2.36 6.23z"
      
      />
      <path
        id="XMLID_2446_"
        d="M364.94 133.56c-1.07 2.04-4.38 3.1-6.35 2.02-2.03-1.11-3.02-4.43-1.91-6.39 1.06-1.86 4.23-2.88 6.12-1.94 2.28 1.13 3.07 4.66 2.14 6.31z"
      
      />
      <path
        id="XMLID_2445_"
        d="M89.73 97.21c-2.03-1.1-3.04-4.41-1.94-6.37 1.11-1.99 4.5-2.96 6.41-1.83 1.85 1.09 2.82 4.28 1.87 6.15-1.04 2.03-4.37 3.11-6.34 2.05z"
      
      />
      <path
        id="XMLID_2444_"
        d="M354.98 144.94c-1.15 2.27-4.65 3.03-6.31 2.14-2.03-1.1-3.09-4.39-2.02-6.36 1.04-1.9 4.11-2.91 6.09-2.06 1.72.74 3.31 3.96 2.24 6.28z"
      
      />
      <path
        id="XMLID_2443_"
        d="M338.89 158.64c-2.07-.88-3.29-4.13-2.35-6.23.86-1.91 3.95-3.15 5.97-2.38 2.07.78 3.43 4.04 2.57 6.16-.84 2.07-4.06 3.35-6.19 2.45z"
      
      />
      <path
        id="XMLID_2442_"
        d="M111.22 132.71c1.07-2.04 4.38-3.1 6.35-2.02 2.03 1.11 3.02 4.43 1.91 6.39-1.06 1.87-4.23 2.88-6.12 1.93-2.31-1.14-3.07-4.68-2.14-6.3z"
      
      />
      <path
        id="XMLID_2441_"
        d="M164.59 177c-.88 2.07-4.13 3.29-6.23 2.35-1.91-.86-3.15-3.95-2.38-5.97.78-2.07 4.04-3.43 6.16-2.57 2.07.84 3.35 4.06 2.45 6.19z"
      
      />
      <path
        id="XMLID_2440_"
        d="M379.06 113.64c-1.68-.7-3.31-3.97-2.16-6.29.95-1.92 4.05-3.03 6.03-2.2 2.07.87 3.32 4.11 2.39 6.22-.9 2.04-4.19 3.24-6.26 2.27z"
      
      />
      <path
        id="XMLID_2439_"
        d="M232.38 200.46c1.34-.53 1.62-2.26 3.11-2.27 1.13-.01 2.64-.32 3.28.27.84.78 2.26 1.42 2.26 2.89.01 1.12-.14 2.25-.22 3.38-1.3.15-1.33 1.8-2.7 2.12-1.24.29-2.27-.64-3.41-.14-.44-1.22-1.94-1.49-2.36-2.89-.38-1.27.76-2.27.04-3.36z"
      
      />
      <path
        id="XMLID_2438_"
        d="M287.41 193.09c-.77-.91-1.54-1.74-2.22-2.65-.88-1.18.2-2.47-.11-3.7 1.08-.48 1.43-1.71 2.54-2.26 1.35-.67 2.5.47 3.73.02.34 1.19 1.7 1.41 2.24 2.57.59 1.27-.61 2.32.04 3.44-.83.77-1.63 1.56-2.49 2.29-1.18 1.01-2.49-.39-3.73.29z"
      
      />
      <path
        id="XMLID_2437_"
        d="M334.7 167.07c-1.31.45-1.61 2.19-3.09 2.19-1.13 0-2.76.28-3.26-.32-.77-.92-2.36-1.36-2.25-2.94.08-1.13-.22-2.73.4-3.26.89-.77 1.4-2.33 2.98-2.2 1.02.08 2.43-.23 2.98.31.84.83 2.27 1.38 2.31 2.87.04 1.12-.04 2.25-.07 3.35z"
      
      />
      <path
        id="XMLID_2436_"
        d="M180.5 198.96c-.71-.99-1.69-1.89-2.05-3-.33-1-.34-2.46.17-3.29.58-.94 1.84-1.67 2.94-2.01 1.02-.31 2.41-.31 3.31.17.9.49 1.65 1.66 1.97 2.68.32 1.02.38 2.44-.11 3.31-.56.99-1.77 1.83-2.87 2.22-1 .34-2.23-.02-3.36-.08z"
      
      />
      <path
        id="XMLID_2435_"
        d="M100.54 122.62c1.29-2.74 2.64-3.29 5.2-2.1 2.64 1.22 3.18 2.72 2.24 5.17-1.03 2.69-3.46 3.31-5.47 2.2-2.47-1.36-3.12-2.81-1.97-5.27z"
      
      />
      <path
        id="XMLID_2434_"
        d="M211.67 202.17c.69-2.31 3.35-2.99 5.31-1.89 2.6 1.47 3.05 2.79 1.83 5.6-.95 2.2-3.42 2.81-5.42 1.64-2.37-1.36-2.96-2.82-1.72-5.35z"
      
      />
      <path
        id="XMLID_2433_"
        d="M254.27 199.89c1.22-2.52 2.78-3.09 5.27-1.9 2.79 1.33 3.27 2.73 1.87 5.53-1.22 2.43-2.81 2.93-5.34 1.68-2.5-1.23-3.02-2.79-1.8-5.31z"
      
      />
      <path
        id="XMLID_2432_"
        d="M282.93 201.91c-1.06 2.19-3.16 2.92-5.34 1.86-2.19-1.06-2.92-3.16-1.86-5.34 1.06-2.19 3.16-2.92 5.34-1.86 2.18 1.05 2.91 3.15 1.86 5.34z"
      
      />
      <path
        id="XMLID_2431_"
        d="M191.98 208.23c-1.3-.22-1.3-1.48-1.81-2.41-.66-1.21.14-2.05.44-3.07.39-1.31 1.54-1.36 2.47-1.87 1.21-.66 2.05.14 3.07.44 1.31.39 1.36 1.54 1.87 2.47.66 1.21-.14 2.05-.44 3.07-.39 1.31-1.47 1.49-2.49 1.84-1.32.45-1.33.44-3.11-.47z"
      
      />
      <path
        id="XMLID_2430_"
        d="M318.65 179.95c-2.13-1.05-2.81-3.19-1.73-5.39 1.05-2.13 3.19-2.81 5.39-1.73 2.13 1.05 2.81 3.19 1.73 5.39-1.05 2.13-3.19 2.81-5.39 1.73z"
      
      />
      <path
        id="XMLID_2429_"
        d="M137.32 179.53c-1.62-1.03-2.89-3.01-1.7-5.37.86-1.7 2.98-2.9 5.34-1.71 1.7.86 2.9 2.98 1.71 5.34-.85 1.7-2.92 2.81-5.35 1.74z"
      
      />
      <path
        id="XMLID_2428_"
        d="M124.16 164.11c1.25-1.87 3.33-2.76 5.39-1.63 1.96 1.08 2.78 3.3 1.65 5.36-1.08 1.96-3.3 2.78-5.36 1.65-1.96-1.09-2.65-3.24-1.68-5.38z"
      
      />
      <path
        id="XMLID_2427_"
        d="M203.76 218.39c-1.9-.96-2.75-3.48-1.8-5.37.96-1.9 3.48-2.75 5.36-1.8 1.9.96 2.75 3.48 1.8 5.37-.95 1.9-3.47 2.75-5.36 1.8z"
      
      />
      <path
        id="XMLID_2426_"
        d="M148.27 189.28c-2.01-1.02-2.73-3.56-1.86-5.34.99-2.02 3.55-2.73 5.33-1.86 2.02.99 2.73 3.55 1.86 5.33-.98 2.03-3.53 2.72-5.33 1.87z"
      
      />
      <path
        id="XMLID_2425_"
        d="M114.63 159.65c-2.1-1.08-2.88-3.2-2.01-5.26.91-2.17 3.16-2.9 5.22-2.03 2.17.91 2.9 3.16 2.03 5.22-.92 2.17-3.1 2.76-5.24 2.07z"
      
      />
      <path
        id="XMLID_2424_"
        d="M314.08 189.59c-1.02 2.01-3.56 2.73-5.34 1.86-2.02-.99-2.73-3.55-1.86-5.33.99-2.02 3.55-2.73 5.33-1.86 2.03.98 2.72 3.53 1.87 5.33z"
      
      />
      <path
        id="XMLID_2423_"
        d="M244.56 210.74c.97-1.93 3.48-2.76 5.34-1.85 1.94.95 2.76 3.47 1.86 5.34-.95 1.94-3.47 2.76-5.34 1.86-1.94-.96-2.74-3.47-1.86-5.35z"
      
      />
      <path
        id="XMLID_2422_"
        d="M91.1 117.87c-2.34-1.25-2.76-3.55-1.95-5.28 1.12-2.4 3.52-2.77 5.25-1.97 2.4 1.12 2.77 3.52 1.97 5.25-1.12 2.4-3.46 2.64-5.27 2z"
      
      />
      <path
        id="XMLID_2421_"
        d="M223.13 212.16c.96-1.9 3.48-2.75 5.37-1.8 1.9.96 2.75 3.48 1.8 5.37-.96 1.9-3.48 2.75-5.37 1.8-1.91-.96-2.75-3.48-1.8-5.37z"
      
      />
      <path
        id="XMLID_2420_"
        d="M159.31 199.81c-1.87-1.25-2.76-3.33-1.63-5.39 1.08-1.96 3.29-2.78 5.36-1.65 1.96 1.08 2.78 3.3 1.65 5.36-1.09 1.96-3.23 2.66-5.38 1.68z"
      
      />
      <path
        id="XMLID_2419_"
        d="M265.11 209.61c.55-1.23 4.25-2.5 5.41-1.86 1.11.62 2.31 3.9 1.89 5.15-.41 1.21-3.97 2.6-5.33 2.08-1.22-.46-2.54-4.08-1.97-5.37z"
      
      />
      <path
        id="XMLID_2418_"
        d="M169 204.6c.48-1.22 4.14-2.53 5.38-1.93 1.08.52 2.36 3.84 1.97 5.12-.37 1.2-3.92 2.64-5.3 2.15-1.22-.42-2.58-3.99-2.05-5.34z"
      
      />
      <path
        id="XMLID_2417_"
        d="M304.2 200.88c-.82 1.37-4.2 2.66-5.31 2.04-.66-.38-1.17-1.18-1.53-1.9-1.27-2.55-.33-4.5 2.42-5.21 1.9-.49 3.2.08 3.87 1.95.38 1.05.41 2.23.55 3.12z"
      
      />
      <path
        id="XMLID_2416_"
        d="M187.06 217.8c-1.03 2.1-2.27 2.51-4.42 1.46-2.28-1.11-2.64-2.28-1.43-4.68 1-1.99 2.29-2.35 4.5-1.24 2.05 1.03 2.43 2.27 1.35 4.46z"
      
      />
      <path
        id="XMLID_2415_"
        d="M79.85 107.24c-2.15-1.04-2.6-2.27-1.6-4.37 1.1-2.31 2.31-2.72 4.63-1.56 2.07 1.03 2.45 2.27 1.38 4.45-1.03 2.1-2.27 2.51-4.41 1.48z"
      
      />
      <path
        id="XMLID_2414_"
        d="M102.07 143.95c1.05-2.16 2.27-2.61 4.37-1.61 2.32 1.1 2.73 2.31 1.57 4.63-1.03 2.07-2.27 2.45-4.45 1.39-2.11-1.03-2.53-2.28-1.49-4.41z"
      
      />
      <path
        id="XMLID_2413_"
        d="M375.23 142.89c-1.04 2.15-2.27 2.6-4.38 1.59-2.31-1.1-2.72-2.31-1.56-4.64 1.03-2.06 2.27-2.44 4.45-1.37 2.11 1.04 2.53 2.28 1.49 4.42z"
      
      />
      <path
        id="XMLID_2412_"
        d="M236.04 226.61c-2.1-1.03-2.51-2.27-1.46-4.42 1.11-2.28 2.28-2.64 4.68-1.43 1.99 1 2.35 2.29 1.24 4.5-1.03 2.06-2.27 2.43-4.46 1.35z"
      
      />
      <path
        id="XMLID_2411_"
        d="M390.58 122.18c-2.22-1.06-2.7-2.26-1.73-4.33 1.05-2.24 2.28-2.77 4.29-1.84 2.44 1.13 2.9 2.29 1.81 4.55-1.05 2.17-2.27 2.62-4.37 1.62z"
      
      />
      <path
        id="XMLID_2410_"
        d="M380.53 133.61c-2.18-1.05-2.64-2.26-1.65-4.36 1.1-2.34 2.31-2.76 4.61-1.62 2.09 1.03 2.49 2.27 1.43 4.43-1.03 2.14-2.27 2.57-4.39 1.55z"
      
      />
      <path
        id="XMLID_2409_"
        d="M360.7 155.87c-2.1-1.03-2.51-2.27-1.46-4.42 1.11-2.28 2.28-2.64 4.68-1.43 1.99 1 2.35 2.29 1.24 4.5-1.03 2.05-2.27 2.42-4.46 1.35z"
      
      />
      <path
        id="XMLID_2408_"
        d="M350.06 167.01c-2.07-1.03-2.46-2.27-1.4-4.44 1.09-2.23 2.3-2.59 4.7-1.37 2.05 1.04 2.35 2.19 1.18 4.52-1.01 2.02-2.27 2.39-4.48 1.29z"
      
      />
      <path
        id="XMLID_2407_"
        d="M338.67 174.01a3.309 3.309 0 0 1 4.41-1.49c1.78.86 2.49 2.99 1.44 4.66-1.04 1.64-2.62 2.22-4.47 1.27-1.56-.8-2.18-2.87-1.38-4.44z"
      
      />
      <path
        id="XMLID_2406_"
        d="M257.28 225.79c-1.66-.8-2.35-2.7-1.59-4.38.83-1.83 2.87-2.52 4.64-1.55 1.54.84 2.17 2.9 1.37 4.45a3.299 3.299 0 0 1-4.42 1.48z"
      
      />
      <path
        id="XMLID_2405_"
        d="M92.83 138.71c-1.6-.73-2.4-2.74-1.67-4.34.85-1.87 2.45-2.42 4.29-1.77 1.86.65 2.62 2.77 1.76 4.55a3.31 3.31 0 0 1-4.38 1.56z"
      
      />
      <path
        id="XMLID_2404_"
        d="M288.7 212.86c-1.72-.78-2.27-2.91-1.55-4.4.86-1.77 2.94-2.46 4.66-1.51 1.5.83 2.12 2.95 1.32 4.47a3.34 3.34 0 0 1-4.43 1.44z"
      
      />
      <path
        id="XMLID_2403_"
        d="M401.11 110.98c-1.62-.75-2.36-2.72-1.64-4.36.69-1.58 2.73-2.41 4.32-1.75 1.82.75 2.57 2.81 1.73 4.58-.78 1.64-2.89 2.33-4.41 1.53z"
      
      />
      <path
        id="XMLID_2402_"
        d="M214.55 228.06c-1.58-.84-2.19-2.83-1.42-4.44.97-2.03 3.42-2.19 4.71-1.38 1.48.93 2.05 2.98 1.18 4.53-.84 1.49-2.95 2.1-4.47 1.29z"
      
      />
      <path
        id="XMLID_2401_"
        d="M415.46 98.09c-.68 1.27-3.25 2.04-4.52 1.35-1.27-.68-2.04-3.25-1.35-4.52.68-1.27 3.25-2.04 4.52-1.35 1.27.69 2.03 3.25 1.35 4.52z"
      
      />
      <path
        id="XMLID_2400_"
        d="M330.52 189.41c-1.31-.62-2.22-3.13-1.61-4.43.62-1.31 3.13-2.22 4.43-1.61 1.31.62 2.22 3.13 1.61 4.43-.61 1.31-3.12 2.22-4.43 1.61z"
      
      />
      <path
        id="XMLID_2399_"
        d="M191.95 224.52c.63-1.3 3.16-2.17 4.45-1.54 1.3.63 2.17 3.16 1.54 4.45-.63 1.3-3.16 2.17-4.45 1.54-1.29-.62-2.17-3.15-1.54-4.45z"
      
      />
      <path
        id="XMLID_2398_"
        d="M150.11 210.18c-.99-.39-2.1-3.32-1.67-4.41.39-.99 3.32-2.1 4.41-1.67.99.39 2.1 3.32 1.67 4.41-.39.98-3.32 2.09-4.41 1.67z"
      
      />
      <path
        id="XMLID_2397_"
        d="M137.02 195.69c.46-.95 3.5-2.01 4.45-1.55.95.46 2.01 3.5 1.55 4.45-.46.95-3.5 2.01-4.45 1.55-.95-.46-2.01-3.5-1.55-4.45z"
      
      />
      <path
        id="XMLID_2396_"
        d="M120.72 178.85c-.41.95-3.41 2.04-4.43 1.61-.95-.41-2.04-3.41-1.61-4.43.41-.95 3.41-2.04 4.43-1.61.96.41 2.05 3.41 1.61 4.43z"
      
      />
      <path
        id="XMLID_2395_"
        d="M320.44 200.82c-.98-.54-1.98-3.57-1.48-4.48.54-.98 3.57-1.98 4.48-1.48.98.54 1.98 3.57 1.48 4.48-.54.98-3.57 1.98-4.48 1.48z"
      
      />
      <path
        id="XMLID_2394_"
        d="M278.73 224.33c-.95-.46-2.01-3.5-1.55-4.45.46-.95 3.5-2.01 4.45-1.55.95.46 2.01 3.5 1.55 4.45-.45.95-3.5 2.01-4.45 1.55z"
      
      />
      <path
        id="XMLID_2393_"
        d="M127.23 190.21c-.98-.36-2.14-3.29-1.74-4.39.36-.98 3.29-2.14 4.39-1.74.98.36 2.14 3.29 1.74 4.39-.36.98-3.29 2.14-4.39 1.74z"
      
      />
      <path
        id="XMLID_2392_"
        d="M163.95 215.4c2.06 1.02 2.15 1.34 1.05 3.57-1.05 2.13-1.43 2.23-3.83 1.02-1.84-.93-1.95-1.41-.83-3.64 1.01-2 1.33-2.08 3.61-.95z"
      
      />
      <path
        id="XMLID_2390_"
        d="M80.43 124.37c1.02-2.06 1.34-2.15 3.57-1.05 2.13 1.05 2.23 1.43 1.02 3.83-.93 1.84-1.41 1.95-3.64.83-2-1-2.08-1.33-.95-3.61z"
      
      />
      <path
        id="XMLID_2389_"
        d="M93.98 159.28c-1.29-.59-1.92-2.18-1.38-3.45.63-1.47 2.29-2.07 3.71-1.34 1.23.63 1.77 2.27 1.16 3.53-.6 1.24-2.23 1.84-3.49 1.26z"
      
      />
      <path
        id="XMLID_2388_"
        d="M248.01 236.12c-1.29-.55-1.99-2.14-1.49-3.41.57-1.45 2.29-2.13 3.67-1.45 1.25.61 1.84 2.24 1.27 3.49-.59 1.28-2.18 1.91-3.45 1.37z"
      
      />
      <path
        id="XMLID_2387_"
        d="M175.94 228.52c-.59 1.29-2.17 1.93-3.45 1.39a2.67 2.67 0 0 1-1.36-3.71c.62-1.23 2.28-1.77 3.53-1.17 1.25.61 1.85 2.24 1.28 3.49z"
      
      />
      <path
        id="XMLID_2386_"
        d="M225.14 234.02c.59-1.29 2.18-1.92 3.45-1.38 1.47.63 2.07 2.29 1.34 3.71-.63 1.23-2.27 1.77-3.53 1.16-1.24-.61-1.84-2.24-1.26-3.49z"
      
      />
      <path
        id="XMLID_2385_"
        d="M269.03 235.19c-1.25-.61-1.85-2.24-1.28-3.49.66-1.45 2.34-2.01 3.74-1.25 1.21.66 1.71 2.3 1.06 3.56-.62 1.23-2.27 1.78-3.52 1.18z"
      
      />
      <path
        id="XMLID_2384_"
        d="M310.32 211.48c-1.3-.56-1.98-2.14-1.48-3.42.57-1.45 2.28-2.12 3.67-1.45 1.25.6 1.84 2.24 1.26 3.49-.59 1.29-2.18 1.92-3.45 1.38z"
      
      />
      <path
        id="XMLID_2383_"
        d="M203.96 234.91c.58-1.31 2.15-1.96 3.44-1.42 1.46.6 2.09 2.29 1.39 3.69-.61 1.23-2.27 1.8-3.51 1.2-1.27-.6-1.88-2.22-1.32-3.47z"
      
      />
      <path
        id="XMLID_2382_"
        d="M104.1 165.75c.59-1.29 2.18-1.92 3.45-1.38 1.47.63 2.07 2.29 1.34 3.71-.63 1.23-2.27 1.77-3.53 1.16-1.24-.61-1.83-2.24-1.26-3.49z"
      
      />
      <path
        id="XMLID_2381_"
        d="M70.3 118.28c-.3-.34-.5-.47-.56-.65-.31-.82-.65-1.65-.84-2.5-.06-.29.23-.74.48-.99.21-.21.6-.24.92-.35 2.62-.9 2.17-1.1 3.13 1.51.57 1.55.71 1.75-1.12 2.29-.71.21-1.4.48-2.01.69z"
      
      />
      <path
        id="XMLID_2380_"
        d="M298.77 219.54c.27-.31.38-.54.55-.6.82-.32 1.66-.59 2.49-.88.67-.24 1 .08 1.19.68.23.73.57 1.44.72 2.19.07.32-.12.92-.36 1.03-.89.42-1.85.71-2.8 1-.16.05-.52-.08-.57-.21-.45-1.09-.85-2.2-1.22-3.21z"
      
      />
      <path
        id="XMLID_2379_"
        d="M216.85 248.44c-1.3-.56-1.67-1.34-1.19-2.52.53-1.32 1.33-1.74 2.48-1.3 1.47.56 1.87 1.42 1.26 2.74-.57 1.29-1.32 1.6-2.55 1.08z"
      
      />
      <path
        id="XMLID_2378_"
        d="M184.16 239.27c-1.28-.59-1.6-1.34-1.07-2.56.56-1.3 1.34-1.67 2.52-1.19 1.49.6 1.82 1.41 1.15 2.78-.61 1.26-1.36 1.54-2.6.97z"
      
      />
      <path
        id="XMLID_2377_"
        d="M354.91 186.37c-.6 1.27-1.34 1.56-2.58 1-1.48-.66-1.73-1.41-.97-2.85.63-1.18 1.41-1.41 2.66-.78 1.23.63 1.48 1.38.89 2.63z"
      
      />
      <path
        id="XMLID_2376_"
        d="M238.28 246.97c-1.29-.57-1.64-1.34-1.15-2.53.54-1.31 1.33-1.71 2.49-1.26 1.46.57 1.85 1.43 1.23 2.76-.59 1.26-1.34 1.56-2.57 1.03z"
      
      />
      <path
        id="XMLID_2375_"
        d="M364.85 175.04c-.63 1.21-1.39 1.44-2.65.82-1.33-.66-1.57-1.55-.78-2.91.63-1.09 1.49-1.28 2.73-.59 1.18.65 1.38 1.38.7 2.68z"
      
      />
      <path
        id="XMLID_2374_"
        d="M372.12 164.36c-1.21-.63-1.44-1.39-.82-2.65.66-1.33 1.55-1.57 2.91-.78 1.09.63 1.28 1.49.59 2.73-.65 1.19-1.38 1.38-2.68.7z"
      
      />
      <path
        id="XMLID_2373_"
        d="M381.59 151.17c.61-1.26 1.35-1.53 2.6-.97 1.45.66 1.7 1.44.93 2.86-.63 1.17-1.42 1.39-2.67.74-1.22-.62-1.46-1.37-.86-2.63z"
      
      />
      <path
        id="XMLID_2372_"
        d="M391.59 139.71c.61-1.26 1.35-1.53 2.6-.97 1.45.66 1.7 1.44.93 2.86-.63 1.17-1.42 1.39-2.67.74-1.22-.62-1.47-1.37-.86-2.63z"
      
      />
      <path
        id="XMLID_2371_"
        d="M85.46 147.65c-.59 1.28-1.34 1.6-2.56 1.08-1.3-.56-1.67-1.34-1.19-2.52.6-1.48 1.41-1.82 2.78-1.15 1.26.6 1.53 1.34.97 2.59z"
      
      />
      <path
        id="XMLID_2370_"
        d="M139.64 217.79c.59-1.28 1.34-1.6 2.56-1.07 1.3.56 1.67 1.34 1.19 2.52-.6 1.48-1.41 1.82-2.78 1.15-1.26-.61-1.54-1.35-.97-2.6z"
      
      />
      <path
        id="XMLID_2369_"
        d="M131.86 209.39c-.59 1.28-1.34 1.6-2.56 1.08-1.3-.56-1.67-1.34-1.19-2.52.6-1.48 1.41-1.82 2.78-1.15 1.26.6 1.53 1.35.97 2.59z"
      
      />
      <path
        id="XMLID_2368_"
        d="M154.5 229.24c-1.13.37-1.99.75-2.89.9-.51.08-1.47-2.47-1.02-2.73.78-.45 1.91-1.06 2.56-.78.63.26.84 1.53 1.35 2.61z"
      
      />
      <path
        id="XMLID_2367_"
        d="M105.25 186.98c1.16-.32 2.04-.69 2.94-.77.54-.05 1.37 2.55.92 2.77-.82.4-1.96.97-2.6.67-.62-.29-.78-1.57-1.26-2.67z"
      
      />
      <path
        id="XMLID_2366_"
        d="M412.29 119.76c-.28-1.16-.92-2.28-.6-2.88.33-.61 1.59-.85 2.48-.99.51-.08 1.37 2.5.92 2.76-.78.45-1.69.68-2.8 1.11z"
      
      />
      <path
        id="XMLID_2365_"
        d="M293.72 232.44c-1.16.27-2.29.89-2.89.56-.61-.34-.83-1.6-.96-2.49-.07-.51 2.53-1.34 2.78-.89.42.8.66 1.71 1.07 2.82z"
      
      />
      <path
        id="XMLID_2364_"
        d="M342.22 199.15c-.39-1.09-1.16-2.15-.9-2.78.27-.66 1.51-.98 2.38-1.3.44-.16 1.63 2.3 1.2 2.67-.69.58-1.61.88-2.68 1.41z"
      
      />
      <path
        id="XMLID_2363_"
        d="M262.27 245.36c-1.16.27-2.29.89-2.89.56-.61-.34-.83-1.6-.96-2.49-.07-.51 2.53-1.34 2.78-.89.43.8.66 1.71 1.07 2.82z"
      
      />
      <path
        id="XMLID_2362_"
        d="M117.65 200.77c-.38-1.12-.76-1.97-.93-2.87-.09-.49 2.46-1.49 2.72-1.05.46.78 1.08 1.9.82 2.54-.27.64-1.54.86-2.61 1.38z"
      
      />
      <path
        id="XMLID_2361_"
        d="M405.56 130.01c-1.09.39-2.15 1.16-2.78.9-.66-.27-.98-1.51-1.3-2.38-.16-.44 2.3-1.63 2.67-1.2.58.69.87 1.61 1.41 2.68z"
      
      />
      <path
        id="XMLID_2360_"
        d="M195.39 249.57c-.32-1.16-.69-2.03-.77-2.93-.05-.54 2.54-1.38 2.76-.92.4.82.98 1.96.68 2.59-.28.62-1.57.78-2.67 1.26z"
      
      />
      <path
        id="XMLID_2359_"
        d="M72.36 138.43c-1.4-.64-1.35-.62-.81-1.89.36-.84.69-1.23 1.67-.69 1.3.71 1.3.62.75 1.89-.36.84-.73 1.35-1.61.69z"
      
      />
      <path
        id="XMLID_2358_"
        d="M97.71 178.93c-.25.85-.66 1.18-1.63.65-1.3-.71-1.29-.62-.75-1.89.36-.84.69-1.23 1.67-.69 1.29.72 1.33.65.71 1.93z"
      
      />
      <path
        id="XMLID_2357_"
        d="M331.92 208.6c-1.32-.43-.52-1.17-.23-1.78.52-1.11.55-1.01 2.01-.35 1.24.56.51 1.2.22 1.81-.53 1.11-.6 1.1-2 .32z"
      
      />
      <path
        id="XMLID_2356_"
        d="M163.02 239.4c-1.07-.28-.87-.9-.44-1.69.65-1.21.58-1.15 1.95-.57 1.03.44.91.95.48 1.74-.65 1.2-.61 1.23-1.99.52z"
      
      />
      <path
        id="XMLID_2355_"
        d="M280.84 244.31c-.18-.9-.56-1.64-.34-2.05.23-.42 1.06-.5 1.62-.73.22.65.65 1.33.57 1.94-.04.31-1.02.49-1.85.84z"
      
      />
      <path
        id="XMLID_2354_"
        d="M322.23 220.56c-.3-.77-.63-1.32-.7-1.91-.06-.53 1.45-1.22 1.82-.81.38.42.76 1.21.61 1.65-.16.43-.98.63-1.73 1.07z"
      
      />
      <path
        id="XMLID_2351_"
        d="M252.07 255.83c-.89.22-1.78.67-2 .45-.44-.43-.5-1.24-.72-1.89.59-.16 1.3-.58 1.74-.39.42.19.57 1.01.98 1.83z"
      
      />
      <path
        id="XMLID_2350_"
        d="M230.58 257.14c-.77.3-1.32.63-1.91.7-.53.06-1.22-1.45-.81-1.82.42-.38 1.21-.76 1.65-.61.44.15.64.97 1.07 1.73z"
      
      />
      <path
        id="XMLID_2349_"
        d="M174.29 250.46c-.25-.84-.56-1.42-.56-2-.01-.56 1.51-1.14 1.85-.7.35.45.68 1.24.5 1.68-.17.43-.99.6-1.79 1.02z"
      
      />
      <path
        id="XMLID_2347_"
        d="M207.23 257.93c-.15-.26-.36-.65-.58-1.04.64-.2 1.28-.41 1.92-.61.17.57.62 1.25.45 1.7-.37.99-1.02.08-1.79-.05z"
      
      />
      <path
        id="XMLID_138_"
        d="M85.09 188.89c.6.02.99-.06 1.27.08.15.07.24.71.17.75-.3.16-.68.27-1.01.24-.12-.01-.2-.47-.43-1.07z"
      
      />
      <path
        id="XMLID_137_"
        d="M130.03 229.19c.6.02.99-.06 1.27.08.15.07.24.71.17.75-.3.16-.68.27-1.01.24-.12-.02-.2-.48-.43-1.07z"
      
      />
      <path
        id="XMLID_136_"
        d="M364.24 196.5c-.19-.61-.41-1.02-.32-1.1.24-.23.61-.38.94-.43.08-.01.4.54.33.69-.14.28-.49.45-.95.84z"
      
      />
      <path
        id="XMLID_135_"
        d="M120.89 220.59c-.6.03-1.04.16-1.12.04-.18-.28-.2-.67-.28-1.01.27-.06.66-.25.79-.14.25.2.34.58.61 1.11z"
      
      />
      <path
        id="XMLID_134_"
        d="M373.69 183.49c.6.02.99-.06 1.27.08.15.07.24.71.17.75-.3.16-.68.27-1.01.24-.11-.01-.19-.47-.43-1.07z"
      
      />
      <path
        id="XMLID_133_"
        d="M60.64 126.2c.58-.11.94-.28 1.25-.21.17.04.25.46.38.71-.32.15-.64.38-.97.41-.14.01-.32-.41-.66-.91"
      
      />
      <path
        id="XMLID_132_"
        d="M85.95 168.84c-.6.03-1.04.16-1.12.04-.18-.28-.2-.67-.28-1.01.27-.06.66-.25.79-.14.24.2.34.58.61 1.11z"
      
      />
      <path
        id="XMLID_131_"
        d="M353 206.34c.58-.11.94-.28 1.25-.21.17.04.25.46.38.71-.32.15-.64.38-.97.41-.14.01-.31-.42-.66-.91z"
      
      />
      <path
        id="XMLID_130_"
        d="M383.94 174.19c-.19-.61-.41-1.02-.32-1.1.24-.23.61-.38.94-.43.08-.01.4.54.33.69-.14.27-.49.45-.95.84z"
      
      />
      <path
        id="XMLID_129_"
        d="M261.92 265.94c-.6.03-1.04.16-1.12.04-.18-.28-.2-.67-.28-1.01.27-.06.66-.25.79-.14.25.2.34.59.61 1.11z"
      
      />
      <path
        id="XMLID_128_"
        d="M96.44 199.11c.58-.11.94-.28 1.25-.21.17.04.25.46.38.71-.32.15-.64.38-.97.41-.14.01-.31-.41-.66-.91z"
      
      />
      <path
        id="XMLID_127_"
        d="M62.11 148.67c-.11-.58-.28-.94-.21-1.25.04-.17.46-.25.71-.38.15.32.38.64.41.97.01.14-.42.31-.91.66z"
      
      />
      <path
        id="XMLID_126_"
        d="M395.12 162.28c-.6.03-1.04.16-1.12.04-.18-.28-.2-.67-.28-1.01.27-.06.66-.25.79-.14.25.21.34.59.61 1.11z"
      
      />
      <path
        id="XMLID_121_"
        d="M271.08 255.1c.02-.6-.06-.99.08-1.27.07-.15.71-.24.75-.17.16.3.27.68.24 1.01-.02.12-.48.2-1.07.43z"
      
      />
      <path
        id="XMLID_120_"
        d="M404.28 151.44c.02-.6-.06-.99.08-1.27.07-.15.71-.24.75-.17.16.3.27.68.24 1.01-.02.12-.48.2-1.07.43z"
      
      />
      <path
        id="XMLID_119_"
        d="M74.49 158.84c-.6.03-1.04.16-1.12.04-.18-.28-.2-.67-.28-1.01.27-.06.66-.25.79-.14.25.2.34.59.61 1.11z"
      
      />
      <path
        id="XMLID_118_"
        d="M108.51 210.42c-.11-.58-.28-.94-.21-1.25.04-.17.46-.25.71-.38.15.32.38.64.41.97.01.14-.41.31-.91.66z"
      
      />
      <path
        id="XMLID_117_"
        d="M413.88 138.77c.58-.11.94-.28 1.25-.21.17.04.25.46.38.71-.32.15-.64.38-.97.41-.14.01-.31-.41-.66-.91z"
      
      />
      <path
        id="XMLID_116_"
        d="M293.97 253.32c-.6.03-1.04.16-1.12.04-.18-.28-.2-.67-.28-1.01.27-.06.66-.25.79-.14.25.2.34.58.61 1.11z"
      
      />
      <path
        id="XMLID_115_"
        d="M303.68 242.46c-.6.03-1.04.16-1.12.04-.18-.28-.2-.67-.28-1.01.27-.06.66-.25.79-.14.24.2.34.58.61 1.11z"
      
      />
      <path
        id="XMLID_114_"
        d="M313.79 230.78c-.61.19-1.02.41-1.1.32-.23-.24-.38-.61-.43-.94-.01-.08.54-.4.69-.33.27.14.45.49.84.95z"
      
      />
      <path
        id="XMLID_113_"
        d="M142.02 240.74c-.19-.61-.41-1.02-.32-1.1.24-.23.61-.38.94-.43.08-.01.4.54.33.69-.14.27-.49.45-.95.84z"
      
      />
      <path
        id="XMLID_112_"
        d="M333.69 229.86c.02-.6-.06-.99.08-1.27.07-.15.71-.24.75-.17.16.3.27.68.24 1.01-.02.12-.48.2-1.07.43z"
      
      />
      <path
        id="XMLID_111_"
        d="M343.39 219c.02-.6-.06-.99.08-1.27.07-.15.71-.24.75-.17.16.3.27.68.24 1.01-.01.12-.47.2-1.07.43z"
      
      />
    </g>
  </svg>
    </>
  )
}

import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Context } from "../context";

export const Tradepro_Drop = ({ setActive, setIsOpen, menus }) => {
  const {
    isDemat,
    num,
    setIsDemat,
    setName,
    setNum,
    setEmail,
    setState,
    setCity,
    name,
    email,
    state,
    city,
    categoryID,
    urlData,
    ipAddress,
    setShowPath,
    setShowRoute,
  } = useContext(Context);

  const emptyField = () => {
    setCity("");
    setEmail("");
    setName("");
    setState("");
    setNum("");
    // setActive(!menus);
    setIsOpen(false);
  };
  return (
    <>
      <div className="menubox">
        {/* <div className="sitemapcol">
          <h4 style={{ cursor: "context-menu" }}>Trade</h4>
          <ul>
            <li>
              <NavLink to="/FuturesOptions" onClick={emptyField}>
                Futures and Options
              </NavLink>
            </li>
            <li>
              <NavLink to="/derivatives-market-analysis">Options</NavLink>
            </li>
            <li>
              <NavLink to="/Commodity" onClick={emptyField}>
                Commodities
              </NavLink>
            </li>
            <li>
              <NavLink to="/Currency" onClick={emptyField}>
                Currency
              </NavLink>
            </li>
          </ul>
        </div> */}

        <div className="sitemapcol">
          <h4 style={{ cursor: "context-menu" }}>Tools</h4>
          <ul>
            <li>
              <Link
                to="https://autotrender.smcindiaonline.com:8790/"
                target="_blank"
                onClick={() => {
                  emptyField();
                }}
              >
                Autotrender
              </Link>
            </li>
            <li>
              <NavLink
                to="/trade/algo-trading-platform"
                onClick={() => {
                  emptyField();
                  setShowPath("");
                }}
              >
                Algo Trading Platform
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="sitemapcol">
          <h4 style={{ cursor: "context-menu" }}>Telegram Channels</h4>
          <ul>
            <li>
              <NavLink
                to="https://subscriptions.smcindiaonline.com:8880/indexcalls"
                target="_blank"
                onClick={() => {
                  emptyField();
                }}
              >
                Index Trading
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://subscriptions.smcindiaonline.com:8880/equityfunda"
                target="_blank"
                onClick={() => {
                  emptyField();
                }}
              >
                Equity Ka Funda
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://subscriptions.smcindiaonline.com:8880/technofunda"
                target="_blank"
                onClick={() => {
                  emptyField();
                }}
              >
                Techno Funda
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://subscriptions.smcindiaonline.com:8880/commodity"
                target="_blank"
                onClick={() => {
                  emptyField();
                }}
              >
                Commodity Mantra
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://subscriptions.smcindiaonline.com:8880/technofunda"
                target="_blank"
                onClick={() => {
                  emptyField();
                }}
              >
                Maha Combo
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

import React from "react";
import "../src/font/stylesheet.css";
import "../src/css/stylesheet.css";
import "../src/css/header.css";
import "../src/css/footer.css";
import "../src/css/responsive.css";
import Routing from "./routing";
import CacheBuster from "react-cache-buster";
import version from "../package.json";
import Loader from "./components/Loader";
import LanguageIcon from "@mui/icons-material/Language";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const isProduction = process.env.NODE_ENV === "production";
  return (
    <CacheBuster
      currentVersion={JSON.stringify(version)} // Convert to string
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<Loader />}
      metaFileDirectory="." // If public assets are hosted somewhere other than root on your server
    >
      <HelmetProvider>
        <Routing />
      </HelmetProvider>
    </CacheBuster>
  );
}

export default App;

import React from "react";
import Algotrading from "../images/algotrading.webp";
import Autotrender from "../images/autotrender.webp";
import Telegramchannel from "../images/telegram-channel.webp";
import { Grid, Modal } from "@mui/material";
import BT01 from "../images/BT01.webp";
import BT02 from "../images/BT02.webp";
import BT03 from "../images/BT03.webp";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
export const Reserch = () => {
  var settings = {
    dots: false,
    navigation: false,
    loop: true,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <div
        className="webcent pt-0"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <span>RESEARCH</span>
                <h3>Better Research. Better Opportunity </h3>
              </div>

              <div className="btreserch">
                <Slider {...settings}>
                  <div>
                    <div className="btrebox redbg">
                      <div className="btlogo">
                        <img src={Autotrender} alt="Autotrender" />
                      </div>
                      <div className="bticon">
                        <span>
                          <img src={BT02} alt="icon" />
                        </span>
                        <ul className="scrollusenew">
                          <li>Automated market analysis research tool</li>
                          <li>Mitigate the risk by gauging market trends.</li>
                          <li>
                            Real time data analysis for performance enhancement.
                          </li>
                          <li>
                            Screeners and models for better trade opportunities.
                          </li>
                        </ul>
                      </div>
                      <div className="subsbtn">
                        <span>
                          <NavLink
                            to="https://autotrender.smcindiaonline.com:8790/"
                            target="_blank"
                          >
                            SUBSCRIBE NOW
                          </NavLink>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="btrebox yellowbg">
                      <div className="btlogo">
                        <img src={Algotrading} alt="Algotrading" />
                      </div>
                      <div className="bticon">
                        <span>
                          <img src={BT01} alt="" />
                        </span>
                        <ul className="scrollusenew">
                          <li>
                            Make automated trades to increase discipline in
                            trading
                          </li>
                          <li>
                            Simultaneous tracking & analysis of multiple markets
                          </li>
                          <li>
                            Well-defined and easily customisable templates for
                            strategies
                          </li>
                          <li>
                            Highly technically advanced charting system AMI
                            Broker
                          </li>
                        </ul>
                      </div>
                      <div className="subsbtn">
                        <span>
                          <NavLink to="/trade/algo-trading-platform">
                            SUBSCRIBE NOW
                          </NavLink>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="btrebox greenbg">
                      <div className="btlogo">
                        <img src={Telegramchannel} alt="Telegram channel" />
                      </div>
                      <div className="bticon">
                        <span>
                          <img src={BT03} alt="Icon" />
                        </span>
                        <ul className="scrollusenew">
                          <li>
                            Researched Market analyses from Renowned Sebi
                            Registered analyst.
                          </li>
                          <li>
                            Updates market view direction in Equity, Derivatives
                            and Commodity.
                          </li>
                          <li>
                            Real-time updates & calls alerts based on market
                            trends.
                          </li>
                          <li>
                            Improvised trading accuracy through research based
                            decision.
                          </li>
                        </ul>
                      </div>
                      <div className="subsbtn">
                        <span>
                          <NavLink
                            to="https://subscriptions.smcindiaonline.com:8880/"
                            target="_blank"
                          >
                            SUBSCRIBE NOW
                          </NavLink>
                        </span>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import Banner from "../../../../images/mobile-app.webp";
import SuperApp from "../../../../images/Tradimg.webp";
import Trade01 from "../../../../images/trade-smc.webp";
import Trade02 from "../../../../images/trade-smc-01.webp";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Faq } from "../../../../components/Faq";
import { Reviews } from "../../../../components/Reviews";
import Ios from "../../../../images/app.webp";
import Android from "../../../../images/google.webp";

export const MobApp = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getKeyFeatureImage = () => {
    switch (expanded) {
      case "panel1":
        return Trade01;
      case "panel2":
        return Trade02;
      case "panel3":
        return Trade01;
      case "panel4":
        return Trade02;
      case "panel5":
        return Trade01;
      default:
        return Trade01;
    }
  };
  return (
    <>
      <Header />
      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Mobile App</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1 className="pb-2">#SMCACE</h1>
                  <p>
                    {" "}
                    One-Stop Platform for Investing/Trading in Equities, Mutual
                    Funds and More.{" "}
                  </p>
                  <div className="webinartime mt-1 webapp">
                    <div className="webdate">
                      <span className="w-auto star fs-4">
                        <i className="fa fa-star"></i>
                      </span>
                      <h4 className="d-grid fs-2 lh-1 text-start">
                        3.8{" "}
                        <small className="opacity-50 fs-6 fw-normal mt-1">
                          Overall Rating
                        </small>
                      </h4>
                    </div>
                    <div className="webtime">
                      <span className="w-auto download fs-4">
                        <i className="fa fa-download"></i>
                      </span>
                      <h4 className="d-grid fs-2 lh-1 text-start">
                        2M+{" "}
                        <small className="opacity-50 fs-6 fw-normal mt-1">
                          Downloads
                        </small>
                      </h4>
                    </div>
                  </div>
                  <div className="col-12 float-start">
                    <div className="downloadimg">
                      <span>
                        <img src={Android} alt="Play Store" />
                      </span>
                      <span>
                        <img src={Ios} alt="IOS" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bannergt">
                  <img src={Banner} alt="Hassle-free investing" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent p-0 mobapp"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="keyfeaturerow align-items-start">
                    <div className="keyfeaturecont">
                      <img src={SuperApp} alt="key feature" />
                    </div>
                    <div className="keyfeaturecont">
                      <div className="title justify-content-start text-start mb-2">
                        <h3>ABOUT SMC ACE</h3>
                      </div>
                      <p>
                        One platform for investing and trading in equities,
                        mutual funds and more
                      </p>
                      <p>
                        Say goodbye to the old and step into the SUPER era of
                        investing and trading. Experience transformative
                        investing that combines cutting-edge technology and
                        unmatched speed to revolutionise how you invest.
                      </p>
                      <p>
                        With super simplicity, you can easily navigate the
                        complexities of the market using our all-in-one SUPER
                        app, covering Indian stocks, US stocks, IPOs, mutual
                        funds, commodities, futures, and options. Trusted by
                        over 1.5 crore Indians and built on two decades of
                        expertise, we're making investing super for everyone.
                      </p>
                      <p>
                        Ready to join the super revolution? Download the #SMCACE
                        App now!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent p-100 grey-background border-0"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="web-container">
                <div className="title">
                  <h3>Why Trade on SMC?</h3>
                </div>
                <div className="col-lg-10 m-auto col-12 webapps">
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="accordiancolum">
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChange("panel1")}
                          className="accordiansummery"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography>Trade from Charts</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordiandetails">
                            <Typography>
                              With SMC you have the best of both worlds in
                              Charting - Candlestick Precision through ChartIQ
                              and a wide range of Free & Advanced Indicators on
                              TradingView.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel2"}
                          onChange={handleChange("panel2")}
                          className="accordiansummery"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                          >
                            <Typography>Smart Orders</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordiandetails">
                            <Typography>
                              Stop Loss,GTT, Cover and more - With our advanced
                              order pad,You get more than 7 types of orders in
                              one place.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel3"}
                          onChange={handleChange("panel3")}
                          className="accordiansummery"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                          >
                            <Typography>Advanced Option Chain</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordiandetails">
                            <Typography>
                              We understand frequent traders like no one does!
                              You can navigate across charts, orders and Option
                              Chains very swiftly. You can glance through
                              OI,Greeks and Contracts across Puts & Calls in one
                              scroll. With SMC you can trade deep OTM or ITM
                              strikes directly from Option Chain.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel4"}
                          onChange={handleChange("panel4")}
                          className="accordiansummery"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                          >
                            <Typography>Margin Trading Facility</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordiandetails">
                            <Typography>
                              Get 4X leverage on 1000+ stocks.You can track all
                              your margin orders and related interest expenses
                              from the dedicated MTF section.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel5"}
                          onChange={handleChange("panel5")}
                          className="accordiansummery"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5bh-content"
                            id="panel5bh-header"
                          >
                            <Typography>Option Strategies</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordiandetails">
                            <Typography>
                              SMC ACE research team has simplified Option
                              trading for new traders using InstaTrade. You will
                              get Strategy builder, Free position analysis and
                              pay-off charts powered by Sensibull.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12">
                      <img src={getKeyFeatureImage()} alt="key feature" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="keyfeatures">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title gap-2">
                      <h3>Witness the Dawn of a Super Investing Era!</h3>
                      <p>
                        Our brand film encapsulates the transformative shift
                        from outdated, glitchy investing apps to the
                        super-efficient, user-friendly <b>#SMCACE</b>. It's more
                        than a revolution, it's a super evolution!
                      </p>
                    </div>
                    <div className="stockslider">
                      <iframe
                        width="100%"
                        height="480"
                        src="https://www.youtube.com/embed/3LeZWpWdJbE?rel=0"
                        title="YouTube video player"
                        frameborder="0"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="gradient p-100 whitetext">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title gap-2">
                      <h3>Start your investment journey with SMC ACE</h3>
                      <p className="text-white">
                        SMC ACE provides you option to diversify your portfolio
                        by investing in Stocks, Mutual Funds, ETFs, US Stocks,
                        Currencies, Commodities, Futures & Options, Bonds etc
                      </p>
                    </div>
                    <div className="stockslider flex-center">
                      <div className="btnn bg-white">
                        <span className="text-black">
                          Get Free Demat Account
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Faq />
          <Reviews />
        </section>
      </main>

      <Footer />
    </>
  );
};

import React, { createContext, useState } from "react";

export const Context = createContext();
export const ContextProvider = ({ children }) => {
  const [openIPOData, setOpenIPOData] = useState([]);
  const [upcominIPOData, setUpcominIPOData] = useState([]);
  const [closedIPOData, setClosedIPOData] = useState([]);
  const [isDemat, setIsDemat] = useState(false);
  const [exchange, setExchange] = useState("NSE");
  const [num, setNum] = React.useState();
  const [name, setName] = React.useState();
  const [email, setEmail] = React.useState("");
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [source, setSource] = React.useState("");
  const [categoryID, setCategoryID] = React.useState("");
  const [urlData, setUrlData] = React.useState({});
  const [ipAddress, setIPAddress] = React.useState("");
  const [openIpo, setOpenIpo] = useState([]);
  const [clientIdName, setClientIdName] = useState("");
  const [showPath, setShowPath] = useState("");
  const [researchData, setResearchData] = useState([]);
  const [researchCategory, setResearchCategory] = useState([]);
  const [showWebinar, setShowWebinar] = useState([]);
  const [webinarCatId, setWebinarCatId] = useState("");
  const [formData, setFormData] = useState([]);
  // const [isHovered, setIsHovered] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [loading, setLoading] = React.useState(false);
  const [showOtpScreen, setShowOtpScreen] = React.useState(false);
  const [leadId, setLeadId] = React.useState("");
  const [deviceID, setDeviceID] = React.useState("");
  const [isLeadCapture, setIsLeadCapture] = React.useState(false);
  const [showRoute, setShowRoute] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [allRiskData, setAllRiskData] = useState([]);
  const [riskFilters, setRiskFilters] = useState([]);
  const [token, setToken] = useState("");
  const [complienceData, setComplienceData] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [fundDetails, setFundDetails] = useState({});
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [bioData, setBioData] = useState({});
  const [quatesData, setQuatesData] = useState([]);
  const [formType, setFormType] = useState(false);
  const [webinarId, setWebinarId] = useState(0);
  const [companyReturnsData, setCompanyReturnsData] = useState({});
  const [stockExchange, setStockExchange] = useState({});
  const [openOtpDialog, setOpenOtpDialog] = useState(false);
  const [interactionId, setInteractionId] = useState(0);
  return (
    <Context.Provider
      value={{
        upcominIPOData,
        setUpcominIPOData,
        closedIPOData,
        setClosedIPOData,
        openIPOData,
        setOpenIPOData,
        isDemat,
        setIsDemat,
        exchange,
        setExchange,
        num,
        setNum,
        name,
        setName,
        email,
        setEmail,
        state,
        setState,
        city,
        setCity,
        urlData,
        setUrlData,
        categoryID,
        setCategoryID,
        ipAddress,
        setIPAddress,
        openIpo,
        setOpenIpo,
        clientIdName,
        setClientIdName,
        showPath,
        setShowPath,
        researchData,
        setResearchData,
        researchCategory,
        setResearchCategory,
        showWebinar,
        setShowWebinar,
        webinarCatId,
        setWebinarCatId,
        formData,
        setFormData,
        // isHovered,
        // setIsHovered,
        loading,
        setLoading,
        successMessage,
        setSuccessMessage,
        showOtpScreen,
        setShowOtpScreen,
        leadId,
        setLeadId,
        deviceID,
        setDeviceID,
        isLeadCapture,
        setIsLeadCapture,
        showRoute,
        setShowRoute,
        selectedFilters,
        setSelectedFilters,
        allRiskData,
        setAllRiskData,
        riskFilters,
        setRiskFilters,
        token,
        setToken,
        complienceData,
        setComplienceData,
        categoryName,
        setCategoryName,
        fundDetails,
        setFundDetails,
        subscriptionDetails,
        setSubscriptionDetails,
        bioData,
        setBioData,
        quatesData,
        setQuatesData,
        source,
        setSource,
        formType,
        setFormType,
        webinarId,
        setWebinarId,
        companyReturnsData,
        setCompanyReturnsData,
        stockExchange,
        setStockExchange,
        country,
        setCountry,
        openOtpDialog,
        setOpenOtpDialog,
        interactionId,
        setInteractionId
      }}
    >
      {children}
    </Context.Provider>
  );
};

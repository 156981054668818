import React from "react";
import { NavLink } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Research01 from "../../images/simply01.webp";
import Research02 from "../../images/simply02.webp";
import Research03 from "../../images/simply03.webp";
import Research04 from "../../images/simply04.webp";
import Stock from "../../images/Stocks.webp";
import Ipos from "../../images/IPo_img.webp";
import MFFunds from "../../images/Mutual-Funds.webp";

const WelthAndInvest = ({ isMobile, isMobileLandscape }) => {
  return (
    <>
      <div
        className="webcent pb-0"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <span>Asset Classes</span>
                <h3>Ways to grow your wealth</h3>
              </div>

              <div className="tradebenefit">
                <Tabs>
                  <div className="tradingben m-0 gap-0">
                    <div className="iconrow noicon gap">
                      <TabList>
                        <Tab>
                          <h4>Equities</h4>
                        </Tab>
                        <Tab>
                          <h4>Ipos</h4>
                        </Tab>
                        <Tab>
                          <h4>Mutual Funds</h4>
                        </Tab>
                      </TabList>
                    </div>
                  </div>
                  <TabPanel>
                    <div className="ipoodlist ">
                      <div className="d-flex benefitts gap-5 align-items-center">
                        <div className="col-6">
                          <img src={Stock} />
                        </div>
                        <div className="col-6">
                          <ul>
                            <li>Margins up to 5x for Intraday/CO Orders</li>
                            <li>Diverse portfolio-building options</li>
                            <li>Dedicated RM Support</li>
                            <li>Brokerage benefits and cashback offers</li>
                            <li>Fundamental Data for Equities Analysis</li>
                            <li>
                              Margin calculator, brokerage calculator, and much
                              more
                            </li>
                          </ul>
                          <div className={isMobile ? "main-view" : ""}>
                            <NavLink to="/invest/equities">
                              <button className="view-more">VIew More</button>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="ipoodlist ">
                      <div className="d-flex benefitts gap-5 align-items-center">
                        <div className="col-6">
                          <img src={Ipos} />
                        </div>
                        <div className="col-6">
                          <ul>
                            <li>
                              Early access to gain shareholding in profitable
                              businesses
                            </li>
                            <li>
                              Potential to bring significant returns in the long
                              as well as short term
                            </li>
                            <li>
                              Get advantage of the ‘Pre-apply’ feature to stay
                              ahead
                            </li>
                            <li>Timely alerts for upcoming and Open IPOs</li>
                            <li>
                              Transparency enhanced by providing access to
                              company documents
                            </li>
                          </ul>
                          <div className={isMobile ? "main-view" : ""}>
                            <NavLink to="/invest/ipo">
                              <button className="view-more">VIew More</button>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="ipoodlist ">
                      <div className="d-flex benefitts gap-5 align-items-center">
                        <div className="col-6">
                          <img src={MFFunds} />
                        </div>
                        <div className="col-6">
                          <ul>
                            <li>
                              In-depth risk and return analysis for various
                              funds
                            </li>
                            <li>
                              Start SIPs for consistency in your investments
                            </li>
                            <li>Get funds managed by seasoned professionals</li>
                            <li>
                              Adequate expertise and experience in funds
                              analysis
                            </li>
                            <li>
                              Diversify investments across assets and securities
                              categories
                            </li>
                            <li>Get tax benefits of up to ₹1,50,000</li>
                          </ul>
                          <div className={isMobile ? "main-view" : ""}>
                            <NavLink to="/invest/mutual-fund">
                              <button className="view-more">VIew More</button>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="webcent p-0"
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Simplify. Investments with SMC</h3>
              </div>
              <div className="reserchstn">
                <div className="researchbox">
                  <div className="researchcircle">
                    <img src={Research01} alt="" />
                  </div>
                  <div className="researchcont">
                    <h4>Smart Investment Choices </h4>
                    <p>
                      With award-winning research and specially curated trading
                      tools, SMC offers you to discover multiple investment
                      choices. Choosing from the latest IPOs and mutual fund
                      offerings is now just a click away.
                    </p>
                  </div>
                </div>
                <div className="researchbox">
                  <div className="researchcircle">
                    <img src={Research02} alt="" />
                  </div>
                  <div className="researchcont">
                    <h4>In-depth Company Details </h4>
                    <p>
                      Make sound investment choices and draw up stock analysis
                      by gaining access to all the critical information about a
                      company, such as profile, technical data, trading history,
                      and much more.
                    </p>
                  </div>
                </div>
                <div className="researchbox">
                  <div className="researchcircle">
                    <img src={Research03} alt="" />
                  </div>
                  <div className="researchcont">
                    <h4>Latest Market Updates</h4>
                    <p>
                      All daily stock updates and the latest news is just a
                      click away. Download the SMC ACE Or SMC Easy Trade App now
                      to track corporate action, significant announcements, and
                      price movements, among others, at your fingertips.
                    </p>
                  </div>
                </div>
                <div className="researchbox">
                  <div className="researchcircle">
                    <img src={Research04} alt="" />
                  </div>
                  <div className="researchcont">
                    <h4>Easy Buy and Sell</h4>
                    <p>
                      Single click and smooth buying and selling of stocks. SMC
                      enables you to place orders during non-market hours (After
                      Market orders) and orders that are valid for up to a year
                      (GTT Order).
                    </p>
                  </div>
                </div>
              </div>
              <div className="mbtn">
                <div className="btnn">
                  <span
                    onClick={() => {
                      if (isMobile) {
                        window.scrollTo(500, 500);
                      } else if (isMobileLandscape) {
                        window.scrollTo(600, 600);
                      } else {
                        window.scrollTo(0, 0);
                      }
                    }}
                  >
                    Start Investing
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelthAndInvest;

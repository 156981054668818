import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const DistFaq = () => {
  return (
    <>
      <div
        className="webcent"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Frequently Asked Questions</h3>
              </div>
              <div className="faqcont">
                <Accordion allowZeroExpanded="true">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What services does SMC provide?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          SMC Global Securities Ltd is a financial services
                          company that offers a range of services, including
                          equity and commodity trading, mutual funds
                          distribution, portfolio management, and investment
                          advisory.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who is eligible for becoming Independent Financial
                        Distributor?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        • An individual
                        <br /> • Sole Proprietor
                        <br /> • HUF <br />• Partnership Firm or Company
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How can I become an SMC distributor?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          An individual, Sole Proprietor, HUF, \Partnership
                          Firm, or Company is eligible to become an SMC
                          Distributor. You will have to fill out a prescribed
                          SMC-specific empanelment form and provide a copy of
                          your PAN Card, Address Proof, Passport Photo, and
                          Cancelled Cheque.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What product can an SMC Distributor access?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Once you become an SMC Distributor, you can
                          immediately start distribution of Mutual Funds,
                          Corporate Fixed Deposits, Capital Gain Bonds, NCD,
                          IPOs, Insurance, Mortgage, Secondary Debt, Floating
                          Rate Savings Bonds, 2020, and Realty.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is there any fee for joining as Independent Financial
                        Distributor?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>No fee is charged to join as SMC distributor.</p>
                      </div>
                    </AccordionItemPanel>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          What is the brokerage payment cycle for for different
                          products?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="accordiancont">
                          <p>
                            For most of the products, the brokerage payment is
                            done on monthly basis while for some products, it’s
                            on receivable basis.
                          </p>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DistFaq;

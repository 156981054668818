import { SipCalculor } from "../../../calculators/SipCalculor";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const MFReturnCalculator = () => {
    return (
        <div className="webcent">
            <div className="container">
                <div className="row">
                    <div className="web-container">
                        <div className="returncalulator">
                            <div className="title">
                                <h3>SIP Calculator</h3>
                            </div>
                            <div className="howtoapply">
                                <h3>Calculate your Mutual Fund SIP returns.</h3>
                                <Tabs>
                                    <div className="tradingben m-0">
                                        <div className="iconrow noicon">
                                            <TabList>
                                                <Tab>
                                                    <h4>SIP</h4>
                                                </Tab>
                                                {/* <Tab>
                                <h4>Lumpsum</h4>
                              </Tab> */}
                                            </TabList>
                                        </div>
                                    </div>
                                    <TabPanel>
                                        <div className="ipoodlist">
                                            <SipCalculor calculation={"sip"} />
                                        </div>
                                    </TabPanel>
                                    {/* <TabPanel>
                          <SipCalculor calculation={"lumSum"} />
                        </TabPanel> */}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default MFReturnCalculator;
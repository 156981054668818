import React from "react";
import Slider from "react-slick";
// import Awards01 from "../images/Awards-01.png";
// import Awards02 from "../images/Awards-02.png";
// import Awards03 from "../images/Awards-03.png";
// import Awards04 from "../images/Awards-04.png";
// import Awards05 from "../images/Awards-05.png";
// import Awards06 from "../images/Awards-06.png";
// import Awards07 from "../images/Awards-07.png";
// import Awards08 from "../images/Awards-08.png";
// import Awards09 from "../images/Awards-09.png";
// import Awards10 from "../images/Awards-10.png";
// import Awards11 from "../images/Awards-11.png";
// import Awards12 from "../images/Awards-12.png";
// import Awards13 from "../images/Awards-13.png";
// import Awards14 from "../images/Awards-14.png";
// import Awards15 from "../images/Awards-15.png";
// import Awards16 from "../images/Awards-16.png";
// import Awards17 from "../images/Awards-17.png";
// import Awards18 from "../images/Awards-18.png";

export const Excellence = () => {
  var settings2 = {
    dots: false,
    navigation: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        className="webcent pt-0"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Excellence. Awarded.</h3>
              </div>
              <div className="awardslider">
                <Slider {...settings2}>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-01.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-02.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-03.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-04.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-05.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-06.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-07.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-08.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-09.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-10.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-11.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-12.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-13.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-14.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-15.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-16.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-17.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="awards">
                      <img
                        src="../images/Awards-18.webp"
                        alt=""

                        // className="responsive"
                        // style={{ width: 194, height: 194 }}
                      />
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

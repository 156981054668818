import React, { useEffect } from "react";
import { useContext } from "react";
import { Context } from "../../../../context";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getAllRisk } from "../../../../utils/APICollection";

const MfDetails = ({ fundDetails }) => {
  const { setSelectedFilters, allRiskData, setAllRiskData, setRiskFilters } =
    useContext(Context);

  const location = useLocation();

  const addCategory = (categoryName) => {
    setSelectedFilters((prev) => [...prev, categoryName]);
  };

  const addRiskFilters = (riskName) => {
    const filteredData = allRiskData.filter((item) => item.name === riskName)[0]
      ?.frequency;
    if (filteredData.length > 1) {
      setRiskFilters((prev) => [...prev, filteredData]);
    } else {
      //console.log("else block", filteredData[0]);
      setRiskFilters((prev) => [...prev, filteredData[0]]);
    }
  };
  const fetchAllRisks = async () => {
    try {
      const res = await getAllRisk();
      const frequencyMap = {};
      res?.data.forEach((item) => {
        const { colour_id, colour_name } = item;
        if (!frequencyMap[colour_name]) {
          frequencyMap[colour_name] = [];
        }
        frequencyMap[colour_name].push(parseInt(colour_id));
      });

      const result = [];
      for (const [name, frequency] of Object.entries(frequencyMap)) {
        result.push({ name, frequency });
      }
      setAllRiskData(result);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    setSelectedFilters([]);
    setRiskFilters([]);
    fetchAllRisks();
  }, [location.pathname]);
  return (
    <div className="graphchartbtn">
      <ul>
        <li>
          {fundDetails?.asset_class && (
            <Link
              to="/invest/mutual-fund/category-filter"
              onClick={() => addCategory(fundDetails?.asset_class)}
            >
              {fundDetails?.asset_class}
            </Link>
          )}
        </li>
        <li>
          {fundDetails?.category?.split(":")[1] && (
            <Link style={{ cursor: "auto" }}>
              {fundDetails?.category?.split(":")[1]}
            </Link>
          )}
        </li>
        <li>
          {fundDetails?.risk_level && (
            <Link
              to="/invest/mutual-fund/category-filter"
              onClick={() => addRiskFilters(fundDetails?.risk_level)}
            >
              {fundDetails?.risk_level} Risk
            </Link>
          )}
        </li>
      </ul>
    </div>
  );
};

export default MfDetails;

import React from "react";
import { Link } from "react-router-dom";

const ConnectWithUs = () => {
  return (
    <>
      <div
        className="webcent"
        data-aos="fade-in"
        data-aos-offset="10"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Want to connect with us?</h3>
              </div>
              <div className="contactoverview">
                <div className="contactbox col-lg-8 m-auto">
                  <ul>
                    <li>
                      <span>EMAIL</span>
                      <p>
                        <a href="mailto:smc.care@smctradeonline.com">
                          smc.care@smcindiaonline.com
                        </a>
                      </p>
                    </li>
                    <li>
                      <span>Toll Free</span>
                      <p>
                        <a href="tel:1800110909">1800-11-0909*</a>
                      </p>
                    </li>
                    <li>
                      <span>SMS</span>
                      <p>
                        <a>“SMC HELP” to 56677</a>
                      </p>
                    </li>
                    <li>
                      <span>PHONE</span>
                      <p>
                        <a href="tel:+91-11-66075200">+91-11-66075200</a>
                      </p>
                    </li>
                    <li>
                      <span>FAX NO.</span>
                      <p>
                        <a href="tel:+91-11-25754365">+91-11-25754365</a>
                      </p>
                    </li>
                    <li>
                      <span>ADDRESS</span>
                      <p>
                        SMC Global Securities Ltd., 11/6B, Shanti Chamber, Pusa
                        Road, Delhi-110005
                      </p>
                    </li>
                  </ul>
                  <div className="col-12 pull-left mt-5">
                    <h2 className="gradient text-white fw700 p-4 text-center fs-4 m-0">
                      For any grievances reach out to our{" "}
                      <Link
                        to="/contact-us/escalation-matrix"
                        className="text-white text-decoration-underline"
                      >
                        Escalation Matrix
                      </Link>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="contactoverview">
                <div className="col-lg-8 m-auto row">
                  <div className="contactbox col-lg-6 col-md-6 col-12">
                    <h3>Corporate Office</h3>
                    <ul className="d-grid col-12 pull-left">
                      <li>
                        <span>Delhi</span>
                        <p>
                          SMC Global Securities <br />
                          Ltd 11/6B, Shanti Chamber Pusa Road, <br />
                          New Delhi – 110005
                        </p>
                      </li>
                      <li>
                        <span>Phone</span>
                        <p>
                          <a href="tel:+91-11-30111000">+91-11-30111000</a>
                        </p>
                      </li>
                      <li>
                        <span>Email</span>
                        <p>
                          <a href="mailto:smc.care@smctradeonline.com">
                            smc.care@smctradeonline.com
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="contactbox col-lg-6 col-md-6 col-12">
                    <h3>Regional Office</h3>
                    <div className="scrolluse">
                      <ul className="d-grid col-12 pull-left">
                        <li>
                          <span>Mumbai</span>
                          <p>
                            Lotus Corporate Park, A Wing 401 / 402 , 4th Floor
                            ,Graham Firth Steel Compound, Off Western Express
                            Highway, Jay Coach Signal, Goregaon (East), Mumbai -
                            400063
                          </p>
                        </li>
                        <li>
                          <span>Phone</span>
                          <p className="d-flex gap-1 mflex-wrap">
                            <a href="tel:+91-22-67341600">+91-22-67341600</a>,{" "}
                            <a href="tel:91-22-67341675">+91-22-67341675</a>
                          </p>
                        </li>
                      </ul>
                      <ul className="d-grid col-12 pull-left">
                        <li>
                          <span>Kolkata</span>
                          <p>
                            18, Rabindra Sarani, Poddar Court, Gate No-4, 5th
                            Floor Kolkata-700001
                          </p>
                        </li>
                        <li>
                          <span>Phone</span>
                          <p className="d-flex gap-1 mflex-wrap">
                            <a href="tel:+91-33-66127000">+91-33-66127000</a>
                          </p>
                        </li>
                      </ul>
                      <ul className="d-grid col-12 pull-left">
                        <li>
                          <span>Ahmedabad</span>
                          <p>
                            10/A, Kalapurnam Building, Near Muncipal Market
                            C.G.Road, Ahmedabad - 380009
                          </p>
                        </li>
                        <li>
                          <span>Phone</span>
                          <p className="d-flex gap-1 mflex-wrap">
                            <a href="tel:+91-79-26424801">+91-79-26424801</a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 pull-left my-5 border-top pt-5">
                    <p className="m-0">
                      * Available from 08:00 a.m. to 08:00 p.m. on Monday to
                      Friday.
                    </p>
                    <p>
                      * Available from 08:00 a.m. to 04:00 p.m. on all
                      Saturdays.
                    </p>
                  </div>
                  <div className="contactbox col-lg-6 col-md-6 col-12">
                    <h3>Cyber Security HelpLine</h3>
                    <ul className="d-grid col-12 pull-left">
                      <li>Dedicated Helpline:</li>
                      <li>
                        <span>Phone</span>
                        <p>
                          <a href="tel:011-66075242">011-66075242 </a>

                          <a>(Monday to Friday, 10:00 AM to 07:00 PM)</a>
                        </p>
                      </li>
                      <li>
                        <span>Email</span>
                        <p>
                          <a href="mailto:cyberincidentsmc@smcindiaonline.com">
                            cyberincidentsmc@smcindiaonline.com
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectWithUs;

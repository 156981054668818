import React, { useState, useEffect } from "react";

import { getPeerComparisonData } from "../../../../utils/APICollection";
import { useNavigate, useLocation } from "react-router-dom";

const PeerComparison = ({ fundDetails }) => {
  const [mfPeerComparison, setMfPeerComparison] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (fundDetails === undefined) {
      setMfPeerComparison([]);
    } else if (Object.keys(fundDetails).length > 0) {
      fetchMfPeerComparison();
    }
  }, [fundDetails, location.pathname]);

  const fetchMfPeerComparison = async () => {
    try {
      const res = await getPeerComparisonData(
        window.location.pathname.split("/")[3],
        fundDetails?.asset_class,
        fundDetails?.category
      );
      setMfPeerComparison(res?.data);
    } catch (err) {
      console.log(err);
    }
  };
  const showFundDetails = (id, name) => {
    navigate(`/invest/mutual-funds/${id}/${name.replaceAll(" ", "-").replaceAll("&", "-").replaceAll(")", "-").replaceAll("(", "-")}`);
  };

  return (
    <div className="returncalulator">
      <div className="calheading">
        <p className="text-black">
          <strong>Peer Comparison</strong>
        </p>
      </div>
      <div className="ipotable defaulttable">
        <table className="table">
          <thead className="bg-white">
            <tr>
              <th scope="col" width={"50"}>
                {fundDetails?.asset_class}
                {","}
                {fundDetails?.category?.split(":")[1]}
              </th>
              <th scope="col" width={"10"}>
                Rating
              </th>
              <th scope="col" width={"20"}>
                1Y
              </th>
              <th scope="col" width={"20"}>
                3Y
              </th>
              <th scope="col" width={"20"}>
                Fund Size(Cr)
              </th>
            </tr>
          </thead>
          <tbody>
            {mfPeerComparison.length > 0 ? (
              mfPeerComparison.map((peerFund) => (
                <tr>
                  <td
                    key={peerFund.output_plan_id}
                    scope="col"
                    width={"50"}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      showFundDetails(
                        peerFund?.output_plan_id,
                        peerFund?.output_basic_name
                      )
                    }
                  >
                    {peerFund?.output_basic_name}
                  </td>
                  <td scope="col" width={"10"}>
                    {peerFund?.output_fund_rating}
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </td>
                  <td scope="col" width={"20"}>
                    {Number(peerFund?.output_ret_1year).toFixed(2) === "NaN"
                      ? ""
                      : Number(peerFund?.output_ret_1year).toFixed(2)}
                    %
                  </td>
                  <td scope="col" width={"20"}>
                    {Number(peerFund?.output_ret_3year).toFixed(2) === "NaN"
                      ? ""
                      : Number(peerFund?.output_ret_3year).toFixed(2)}
                    %
                  </td>
                  <td scope="col" width={"20"}>
                    {" "}
                    <i className="finr"></i>
                    {Number(peerFund?.output_aum).toFixed(2) === "NaN"
                      ? ""
                      : Number(peerFund?.output_aum).toFixed(2)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>No Data Found</tr>
            )}

            {/* <tr>
                                      <td scope="row">
                                        Understand term{" "}
                                        <i
                                          className="question"
                                          onClick={() => setOpen1(true)}
                                        >
                                          i
                                        </i>
                                      </td>
                                    </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PeerComparison;

import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { getHoldingDetails } from "../../../../utils/APICollection";
const MFHoldings = () => {
  const [holdingsData, setHoldingsData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const myRef = useRef(null);
  const location = useLocation();
  const toggleRows = () => {
    setShowAll(!showAll);
  };
  const executeScroll = () => myRef.current.scrollIntoView();
  const closetoggleRows = () => {
    setShowAll(!showAll);
    executeScroll();
  };
  const fetchHoldingDetails = async () => {
    //setIsLoading(true);
    try {
      const res = await getHoldingDetails(
        window.location.pathname.split("/")[3]
      );

      setHoldingsData(res?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchHoldingDetails();
  }, [location.pathname]);
  return (
    <div className="webcent" ref={myRef}>
      <div className="returncalulator">
        <div className="calheading">
          <h3>Holdings ({holdingsData.length})</h3>
        </div>
        <div className="tablist">
          <div className="ipostn p-0">
            <div className="ipotable defaulttable">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Sector</th>
                    <th scope="col">Instrument</th>
                    <th scope="col">Assets</th>
                  </tr>
                </thead>
                <tbody>
                  {holdingsData.slice(0, 10).map((holding) => (
                    <tr>
                      <td>{holding?.full_name}</td>
                      <td>{holding?.sector}</td>
                      <td>{holding?.asset_class}</td>
                      <td>{Number(holding?.asset_percentage).toFixed(2)}%</td>
                    </tr>
                  ))}
                  {showAll && (
                    <>
                      {holdingsData
                        .slice(11, holdingsData.length)
                        .map((holding) => (
                          <tr>
                            <td>{holding?.full_name}</td>
                            <td>{holding?.sector}</td>
                            <td>{holding?.asset_class}</td>
                            <td>
                              {Number(holding?.asset_percentage).toFixed(2)}%
                            </td>
                          </tr>
                        ))}
                    </>
                  )}
                </tbody>
              </table>

              {showAll ? (
                <button className="showallcont" onClick={closetoggleRows}>
                  See Less
                </button>
              ) : (
                <button className="showallcont" onClick={toggleRows}>
                  See All
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MFHoldings;

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/material";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Define your colors
  const colors = ["#425bb0", "#45b6a6"];

  // Map the hash value to one of the colors
  const color = colors[Math.abs(hash) % colors.length];
  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 100,
      height: 100,
      fontSize: 50,
    },
    children: `${name.split("")[0]}`,
  };
}

export default function BackgroundLetterAvatars({ name }) {
  return <Avatar {...stringAvatar(name)} variant="square" />;
}

import { useState, useEffect } from "react";
import { getIpoRisk } from "../../../../utils/APICollection";

const IpoRisk = () => {
    const [riskDetails, setRiskDetails] = useState([]);
    const fetchRisk = async () => {
        try {
            const data = await getIpoRisk(window.location.pathname.split("/")[3])
            setRiskDetails(data?.data?.data);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        fetchRisk();
    }, []);
    return (
        <div
            className="cons"
            style={{ width: "100%" }}
        >
            <h6>Risks and threats</h6>
            {riskDetails ? (
                <ul>
                    {riskDetails
                        ?.slice(0, 3)
                        ?.map((data) => (
                            <li>
                                {data.RiskDetails
                                    ? data.RiskDetails
                                    : "No Data Found"}
                            </li>
                        ))}
                </ul>
            ) : (
                // <ul>
                //   <li>No Data Found</li>
                // </ul>
                <p style={{ padding: 10 }}>
                    No Data Found
                </p>
            )}
        </div>
    )
}

export default IpoRisk;
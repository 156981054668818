import { PieChart } from "../../../../charts/PieChart";
import { formatNumber } from "../../../../components/common";
const HoldingAnalysis = ({ fundDetails, assetAllocationDetailsData }) => {
    return (
        <div className="returncalulator">
            <div className="calheading">
                <h3>Holding Analysis</h3>
            </div>
            <p className="text-black">
                <strong>
                    {assetAllocationDetailsData.map(
                        (asset) =>
                            asset?.asset_class + " " + "/" + " "
                    )}
                </strong>
            </p>
            <PieChart
                assetAllocationDetailsData={
                    assetAllocationDetailsData
                }
                fundSize={
                    Number(fundDetails?.latest_aum).toFixed(2) ===
                        "NaN"
                        ? ""
                        : formatNumber(
                            Number(fundDetails?.latest_aum).toFixed(
                                2
                            )
                        )
                }
            />
        </div>
    )
}

export default HoldingAnalysis;
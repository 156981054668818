import React from "react";
import Footer from "../../../components/Footer";
import Banner from "../../../images/autotrender-banner.webp";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autotrender from "../../../images/autotrender-black.webp";
import Laptop from "../../../images/laptop-screen.webp";
import Keyfeature from "../../../images/keyfeature.webp";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Slider from "react-slick";
import Vinod from "../../../images/Vinod-Kumar-Atal.webp";
import Deepak from "../../../images/deepak-modi.webp";
import Ketan from "../../../images/ketan-manubhai.webp";
import Nilesh from "../../../images/nilesh.webp";
import Pankaj from "../../../images/pankaj-jain.webp";
import { Excellence } from "../../../components/Excellence";
import Header from "../../../components/Header";

const Autotrander = () => {
  var settings = {
    dots: false,
    navigation: true,
    loop: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  var settings3 = {
    dots: false,
    navigation: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 8000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Header />

      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Trade</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt text-center">
                  <img src={Autotrender} alt="autotrender" />
                  <h1>Trade. Secure.</h1>
                </div>
                <div className="bannergt">
                  <img src={Banner} alt="Hassle-free investing" />
                </div>
                <div className="form autotrender">
                  <div className="formbg">
                    <h4>
                      To Subscribe a Plan <br /> Fill-In Your Details
                    </h4>
                    <Box component="form" noValidate autoComplete="off">
                      <ul>
                        <li>
                          <TextField
                            id="filled-basic"
                            label="Client ID/ 10Digit Mobile No"
                            variant="standard"
                          />
                        </li>
                        <li>
                          <button type="submit">LOGIN NOW</button>
                        </li>
                      </ul>
                    </Box>
                    <p className="col-12 pull-left mt-2 text-center">
                      Disclaimer
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent p-0"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Best Time to Subscribe is Now</h3>
                    <p>
                      Subscribe and enjoy advanced trading research software
                      designed to help you with fast and rich analysis of the
                      market through unique data analysis and automated tool to
                      time your trade with greater efficiency.
                    </p>
                  </div>
                  <div className="title">
                    <span>Key features </span>
                  </div>
                  <div className="crosssection">
                    <div className="crossrow">
                      <div className="leftcross">
                        <img src={Laptop} alt="SMC Ace Web" />
                      </div>
                      <div className="rightcross">
                        <ul>
                          <li>Automate Your Analysis</li>
                          <li>Fast and Timely Information</li>
                          <li>Improve Trade Accuracy</li>
                        </ul>
                      </div>
                    </div>
                    <div className="crossrow">
                      <div className="leftcross">
                        <img src={Laptop} alt="SMC Ace Web" />
                      </div>
                      <div className="rightcross">
                        <ul>
                          <li>Real Time Analysis Update</li>
                          <li>Cover Multiple Index</li>
                          <li>Easy Access from Multiple Device</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="pricingstn">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>Pricing Plans</h3>
                    </div>
                    <div className="pricetabs">
                      <div className="howtoapply">
                        <Tabs>
                          <div className="tradingben mt-0 mb-50 ">
                            <div className="iconrow noicon justify-content-center d-flex">
                              <TabList>
                                <Tab>
                                  <h4>New Plan</h4>
                                </Tab>
                                <Tab>
                                  <h4>Renew Plan</h4>
                                </Tab>
                              </TabList>
                            </div>
                          </div>
                          <TabPanel>
                            <div className="planslider">
                              <Slider {...settings}>
                                <div>
                                  <div className="planslidebox">
                                    <div className="planshead">
                                      <h4>3 Months</h4>
                                    </div>
                                    <div className="plansbody">
                                      <p>Offer Price</p>
                                      <h5>
                                        Get <strong>40%</strong> Off on
                                        Subscription
                                      </h5>
                                      <strike>
                                        <i className="finr"></i>9,999
                                      </strike>
                                      <div className="planprice">
                                        <i className="finr"></i>5,999/-
                                      </div>
                                      <div className="mbtn">
                                        <div className="btnn">
                                          <span>Subscribe</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="planslidebox">
                                    <div className="planshead">
                                      <h4>6 Months</h4>
                                    </div>
                                    <div className="plansbody">
                                      <p>Offer Price</p>
                                      <h5>
                                        Get <strong>58%</strong> Off on
                                        Subscription
                                      </h5>
                                      <strike>
                                        <i className="finr"></i>19,999
                                      </strike>
                                      <div className="planprice">
                                        <i className="finr"></i>8,499/-
                                      </div>
                                      <div className="mbtn">
                                        <div className="btnn">
                                          <span>Subscribe</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="planslidebox">
                                    <div className="planshead">
                                      <h4>12 Months</h4>
                                    </div>
                                    <div className="plansbody">
                                      <p>Offer Price</p>
                                      <h5>
                                        Get <strong>68%</strong> Off on
                                        Subscription
                                      </h5>
                                      <strike>
                                        <i className="finr"></i>37,999
                                      </strike>
                                      <div className="planprice">
                                        <i className="finr"></i>11,999/-
                                      </div>
                                      <div className="mbtn">
                                        <div className="btnn">
                                          <span>Subscribe</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Slider>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <h4 className="nodata">Not Found</h4>
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="keyfeatures">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <span>Key Benefits</span>
                    </div>
                    <div className="keyfeaturerow">
                      <div className="keyfeaturecont">
                        <img src={Keyfeature} alt="key feature" />
                      </div>
                      <div className="keyfeaturecont">
                        <ul>
                          <li>
                            <span>1</span>
                            <strong>Helps in better decision making</strong>
                          </li>
                          <li>
                            <span>2</span>
                            <strong>
                              Visualize performance through data analysis
                            </strong>
                          </li>
                          <li>
                            <span>3</span>
                            <strong>Boost trading confidence</strong>
                          </li>
                          <li>
                            <span>4</span>
                            <strong>Identify trading ideas</strong>
                          </li>
                          <li>
                            <span>5</span>
                            <strong>Helps in minimizing trading risks</strong>
                          </li>
                          <li>
                            <span>6</span>
                            <strong>Create alerts for the future</strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="keyfeatures">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title gap-2">
                      <h3>Know More About Autotrender nxt</h3>
                      <p>
                        <strong>
                          SMC Autotrender - Finest Market Analysis Tool with Buy
                          & Sell Indicators
                        </strong>
                      </p>
                      <p>
                        SMC Autotrender, a unique market analysis tool which is
                        designed by professional traders to help you with fast
                        and rich analysis of the market through unique data
                        analysis and automated tools to time your trade with
                        greater efficiency.
                      </p>
                    </div>
                    <div className="stockslider">
                      <iframe
                        width="100%"
                        height="480"
                        src="https://www.youtube.com/embed/3LeZWpWdJbE?rel=0"
                        title="YouTube video player"
                        frameborder="0"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent pt-0"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="minicontainer">
                    <div className="title">
                      <h3>Creating Proud Investors.</h3>
                      <p>
                        You can feel the pride of being a SMC investor in their
                        words.
                      </p>
                    </div>
                    <div className="clientreview">
                      <Slider {...settings3}>
                        <div>
                          <div className="reviewbox">
                            <div className="commentcircle">
                              <div className="comment">
                                <div className="profile">
                                  <div className="climg">
                                    <img src={Nilesh} alt="" />
                                  </div>
                                  <div className="rating">
                                    <ul className="d-flex">
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="commentcent">
                                  <p>
                                    The relationship managers are not only quick
                                    but they also make that extra effort to
                                    resolve all my queries properly. I also find
                                    the recommendations quite handy. They have
                                    helped me in each and every aspect of
                                    trading. Thanks SMC!
                                  </p>
                                </div>
                                <div className="name">
                                  <h4>Mr. Nilesh Jain</h4>
                                  <h5>Mumbai</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="reviewbox">
                            <div className="commentcircle">
                              <div className="comment">
                                <div className="profile">
                                  <div className="climg">
                                    <img src={Vinod} alt="" />
                                  </div>
                                  <div className="rating">
                                    <ul className="d-flex">
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="commentcent">
                                  <p>
                                    SMC Group is a complete solution for all
                                    finance needs & goals, a supportive team
                                    which is always available for any of our
                                    requirement and fully dedicated to the
                                    customer’s needs. Truly, a complete
                                    financial solution platform for everyone's
                                    need. I am very happy and satisfied with
                                    SMC. Thank You SMC for all the support.
                                  </p>
                                </div>
                                <div className="name">
                                  <h4>Mr. CA Vinod Kumar Atal</h4>
                                  <h5>Ahemdabad</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="reviewbox">
                            <div className="commentcircle">
                              <div className="comment">
                                <div className="profile">
                                  <div className="climg">
                                    <img src={Deepak} alt="" />
                                  </div>
                                  <div className="rating">
                                    <ul className="d-flex">
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="commentcent">
                                  <p>
                                    SMC is one of the best brokerage service
                                    providers I have been associated with. I
                                    would like to specifically mention their
                                    mobile trading platform which is quick and
                                    efficient. Trades can be executed and
                                    confirmed in no time and the back office
                                    functions work very smoothly.
                                  </p>
                                </div>
                                <div className="name">
                                  <h4>Mr. Deepak Modi</h4>
                                  <h5> Jaipur</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="reviewbox">
                            <div className="commentcircle">
                              <div className="comment">
                                <div className="profile">
                                  <div className="climg">
                                    <img src={Ketan} alt="" />
                                  </div>
                                  <div className="rating">
                                    <ul className="d-flex">
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="commentcent">
                                  <p>
                                    It has been a great experience of trading
                                    and investment across all segments with SMC
                                    since 2011. SMC research has made my trading
                                    & investment strategy far easier and helped
                                    me to grow financially over these years. A
                                    very positive, supportive and experienced
                                    staff has always been there for any help and
                                    guidance
                                  </p>
                                </div>
                                <div className="name">
                                  <h4>Mr. Ketan Manubhai Dave</h4>
                                  <h5> Ahemdabad</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="reviewbox">
                            <div className="commentcircle">
                              <div className="comment">
                                <div className="profile">
                                  <div className="climg">
                                    <img src={Pankaj} alt="" />
                                  </div>
                                  <div className="rating">
                                    <ul className="d-flex">
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                      <li>
                                        <i className="fa fa-star"></i>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="commentcent">
                                  <p>
                                    SMC is a trusted name in the industry and
                                    they strongly believe in customer
                                    relationship. Besides, their robust trading
                                    platform, simple and easy website, Portfolio
                                    Health Checkup and customer support are the
                                    key USPs.
                                  </p>
                                </div>
                                <div className="name">
                                  <h4>Mr .Pankaj Jain</h4>
                                  <h5>Delhi</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Excellence />
          <div className="webcent">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Frequently Asked Questions</h3>
                  </div>
                  <div className="faqcont">
                    <Accordion allowZeroExpanded="true">
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What is NAV?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The Net Asset Value (NAV) is the price per share
                              of any particular share at any given time. This
                              does not include any brokerage or overheads you
                              may pay for the share. It is updated every day at
                              the close of the market day.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Which is better - FD or Mutual Funds?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The Net Asset Value (NAV) is the price per share
                              of any particular share at any given time. This
                              does not include any brokerage or overheads you
                              may pay for the share. It is updated every day at
                              the close of the market day.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Are Mutual Funds safe?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The Net Asset Value (NAV) is the price per share
                              of any particular share at any given time. This
                              does not include any brokerage or overheads you
                              may pay for the share. It is updated every day at
                              the close of the market day.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Can I withdraw Mutual Funds anytime?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The Net Asset Value (NAV) is the price per share
                              of any particular share at any given time. This
                              does not include any brokerage or overheads you
                              may pay for the share. It is updated every day at
                              the close of the market day.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Is SIP and Mutual Funds the same?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The Net Asset Value (NAV) is the price per share
                              of any particular share at any given time. This
                              does not include any brokerage or overheads you
                              may pay for the share. It is updated every day at
                              the close of the market day.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Are Mutual Funds tax free?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The Net Asset Value (NAV) is the price per share
                              of any particular share at any given time. This
                              does not include any brokerage or overheads you
                              may pay for the share. It is updated every day at
                              the close of the market day.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What is the minimum investment in Mutual Funds?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The Net Asset Value (NAV) is the price per share
                              of any particular share at any given time. This
                              does not include any brokerage or overheads you
                              may pay for the share. It is updated every day at
                              the close of the market day.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What are high risk Mutual Funds?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The Net Asset Value (NAV) is the price per share
                              of any particular share at any given time. This
                              does not include any brokerage or overheads you
                              may pay for the share. It is updated every day at
                              the close of the market day.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            How do I choose a Mutual Fund?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The Net Asset Value (NAV) is the price per share
                              of any particular share at any given time. This
                              does not include any brokerage or overheads you
                              may pay for the share. It is updated every day at
                              the close of the market day.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            What is the average returns on Mutual Funds in
                            India?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accordiancont">
                            <p>
                              The Net Asset Value (NAV) is the price per share
                              of any particular share at any given time. This
                              does not include any brokerage or overheads you
                              may pay for the share. It is updated every day at
                              the close of the market day.
                            </p>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Autotrander;

import React, { useRef, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import InfoCard from "../../../../components/CurvedCard";

const data = [
  {
    title: "Publicly Traded in BSE and NSE",
    description:
      "SMC is listed on two of India's major stock exchanges, providing transparency and credibility.",
  },
  {
    title: "Top 11 Qualified Stock Broker",
    description:
      "Recognized as one of the top stock brokers in India, ensuring reliability.",
  },
  {
    title: "Ample Rack Space in NSE and BSE",
    description:
      "Provides significant infrastructure space for seamless operations on major exchanges.",
  },
  {
    title: "Serving 325+ Trading Members & FPIs",
    description:
      "Has a vast client base, including trading members and Foreign Portfolio Investors (FPIs).",
  },
  {
    title: "Tech Team of 250+",
    description:
      "A large, skilled technical team supports cutting-edge solutions.",
  },
  {
    title: "Ultra-Low Latency Trading Infrastructure",
    description: "Ensures high-speed trading for competitive advantage.",
  },
  {
    title: "HFT and MFT Trading Solutions",
    description:
      "Offers High-Frequency Trading (HFT) and Multi-Frequency Trading (MFT) strategies for sophisticated trading needs.",
  },
  {
    title: "Strong Compliance and RMS Team",
    description:
      "SMC has a robust Compliance and Risk Management System (RMS) team, ensuring adherence to regulations, minimizing trading risks, and safeguarding investor interests. This ensures a secure and compliant trading environment for clients.",
  },
];

const BenifitsInvesting = () => {
  const cardRefs = useRef([]); // To store references of all cards
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    // Calculate the height of the tallest card after the component mounts
    const heights = cardRefs.current.map((ref) => ref?.clientHeight || 0);
    const tallestHeight = Math.max(...heights);
    setMaxHeight(tallestHeight);
  }, []);

  return (
    <div className="webcent">
      <div className="col-12 pull-left">
        <div className="container">
          <div className="title">
            <h3>Key Advantages of Investing with SMC</h3>
            <Typography variant="subtitle1" align="center" gutterBottom>
              Trusted, Innovative, and Secure Trading Partner
            </Typography>
          </div>

          <Grid
            container
            spacing={3}
            justifyContent={{ xs: "center", md: "flex-start" }} // Center on mobile, align left on larger screens
          >
            {data.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={index}
                display="flex"
                justifyContent="center" // Center the card on all screens
              >
                <InfoCard
                  ref={(el) => (cardRefs.current[index] = el)}
                  title={item.title}
                  desc={item.description}
                  height={maxHeight} // Pass maxHeight as a prop
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default BenifitsInvesting;

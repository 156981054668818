import {
  EKYC_API,
  MAIN_API,
  TALISMA_SMC_SEARCH_API,
  MAIN_REGISTER_API,
  CMOTS_API,
} from "./API";

export const leadApi = async (payload) => {
  try {
    const response = await MAIN_API.post("/lead/generateLead", payload);

    return { data: response.data, message: response.message };
  } catch (error) {
    return { error };
  }
};
export const updateLead = async (payload) => {
  try {
    const response = await MAIN_API.post("/lead/updateLead", payload);

    return { data: response.data, message: response.message };
  } catch (error) {
    return { error };
  }
};
export const sendOtpApi = async (payload) => {
  try {
    const response = await MAIN_API.post("/notification/sendSms", payload);
    return { data: response.data, message: response.message };
  } catch (error) {
    return { error };
  }
};
export const sendwebinarNotification = async (payload) => {
  try {
    const response = await MAIN_API.post(
      "/webinar/sendWebinarNotification",
      payload
    );

    return { data: response.data, message: response.message };
  } catch (error) {
    return { error };
  }
};
export const register = async (payload) => {
  try {
    const response = await MAIN_REGISTER_API.post("/auth/register", payload);
    return { data: response.data, message: response.message };
  } catch (error) {
    return { error };
  }
};

export const sendEkycOtpApi = async (payload) => {
  try {
    const response = await EKYC_API.post("/user/register", payload);
    return { data: response.data, message: response.message };
  } catch (error) {
    return { error };
  }
};
export const verifyKycOtpApi = async (payload) => {
  try {
    const response = await EKYC_API.post(
      "user/validate/otp?product_code=FRANCHISE&group3=HO&location=HO&branch=HO&promo_code=BRFREE&param1=DIY",
      payload
    );
    return { data: response.data, message: response.message };
  } catch (error) {
    return { error };
  }
};

export const verifyOtpApi = async (payload) => {
  try {
    const response = await MAIN_API.post("/notification/validateOtp", payload);
    return { data: response.data, message: response.message };
  } catch (error) {
    return { error };
  }
};

export const getStateCityImport = async () => {
  try {
    const response = await MAIN_API.get("/lead/getStatesCities");
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};
export const getCityImport = async (city) => {
  try {
    const response = await MAIN_API.get(`/lead/citySeearch/${city}`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getCountryImport = async (city) => {
  try {
    const response = await MAIN_API.get(`/lead/countrySearch/${city}`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const searchCompanies = async (companyCode) => {
  try {
    const response = await TALISMA_SMC_SEARCH_API.get(
      `/market/generalSearch/${companyCode}`
    );

    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};
export const getAllStock = async () => {
  try {
    const response = await MAIN_API.get(`/stockRecommendation/getAllStock`);

    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};
export const getReportCategoryWithSubcategory = async () => {
  try {
    const response = await MAIN_API.get(
      `/researchReport/getReportCategoryWithSubcategory`
    );

    return { data: response?.data || null };
  } catch (error) {
    console.log(error);
    return { data: null }; // Ensure we always return an object
  }
};
export const getReportByCategory = async (id) => {
  try {
    const response = await MAIN_API.get(
      `/researchReport/getReportByCategoryUpdated/${id}`
    );

    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};
export const getWebinarById = async (id) => {
  try {
    const response = await MAIN_API.get(`/webinar/getWebinarById/${id}`);

    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};
export const GetQuates = async (code, exchange) => {
  try {
    const response = await TALISMA_SMC_SEARCH_API.get(
      `/stocks/GetQuates/${code}/${exchange}`
    );

    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getAllWebinar = async () => {
  try {
    const response = await MAIN_API.get(`/webinar/getAllWebinar`);

    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getAllForm = async () => {
  try {
    const response = await MAIN_API.get(`/downloadForm/getAllDownloadForms`);

    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getComplienceDoc = async () => {
  try {
    const response = await MAIN_API.get(`/downloadForm/getAllComplienceDoc`);

    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getFileUrl = async (payload) => {
  try {
    const response = await MAIN_API.post("/aws/getFileUrl", payload);
    return { data: response.data, message: response.message };
  } catch (error) {
    return { error };
  }
};
export const addUrl = async (payload) => {
  try {
    const response = await MAIN_API.post("/sitemap/addUrl", payload);
    return { data: response.data, message: response.message };
  } catch (error) {
    return { error };
  }
};

export const getThumbnailFileUrl = async (payload) => {
  try {
    const response = await MAIN_API.post("/aws/getMultipleFileUrl", payload);
    return { data: response.data, message: response.message };
  } catch (error) {
    return { error };
  }
};

export const getOpenIpo = async (exchange) => {
  try {
    const response = await CMOTS_API.get(`/ipo/OpenIssues/${exchange}/all/-`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getUpcomingIpo = async (exchange) => {
  try {
    const response = await CMOTS_API.get(
      `/ipo/ForthcomingIPO/${exchange}/IPO/10`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getListedIpo = async (exchange) => {
  try {
    const response = await CMOTS_API.get(`/ipo/IPOListed/${exchange}/10`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getSubscriptionStatus = async (coCode) => {
  try {
    const response = await CMOTS_API.get(`/ipo/SubscriptionStatus/${coCode}`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getIpoTimeline = async (coCode) => {
  try {
    const response = await CMOTS_API.get(`/ipo/IPOtimeline/${coCode}`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getStrength = async (coCode) => {
  try {
    const response = await CMOTS_API.get(`/ipo/IPOStrengthDetails/${coCode}`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getIpoAllocationDetails = async (coCode) => {
  try {
    const response = await CMOTS_API.get(`/ipo/IPOAllocationDetails/${coCode}`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getIpoRisk = async (coCode) => {
  try {
    const response = await CMOTS_API.get(`/ipo/IPORiskDetails/${coCode}`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getCompetitivePeers = async (coCode, records) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/PeerComparison/${coCode}/C/${records}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getIpoFinancials = async (coCode) => {
  try {
    const response = await CMOTS_API.get(`/ipo/IPOFInancials/${coCode}/S`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getRegistrarInfo = async (coCode) => {
  try {
    const response = await CMOTS_API.get(`/ipo/IPOSynopsis/${coCode}`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getIndicesData = async () => {
  try {
    const response = await CMOTS_API.get(`/market/Indices`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getStockData = async (type, exchange, group) => {
  let url = "";
  if (type === "Gain" || type === "lose") {
    url = `/market/GainersLosers/${exchange}/${type}/${group}/50`;
  } else {
    url = `/market/${type}/${exchange}/${group}/50`;
  }
  try {
    const response = await CMOTS_API.get(url);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getBioData = async (coCode) => {
  try {
    const response = await CMOTS_API.get(`/stocks/Biodata/${coCode}`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getPriceData = async (exchange, coCode, tenure) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/price_change/${exchange}/${coCode}/${tenure}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getHistoricalPerformance = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/historicalperformance/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getCompanyReturns = async (exchange, tenure, coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/Companyreturns/${exchange}/${tenure}/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getIncomeStatement = async (coCode, financeType) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/IncomeStatement/${coCode}/${financeType}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getProfitAndLoss = async (coCode) => {
  try {
    const response = await CMOTS_API.get(`/stocks/ProftandLoss/${coCode}/S`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getShareHolding = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/Share-Holding-Pattern-Aggregate/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getStockNewsData = async (coCode) => {
  try {
    const response = await CMOTS_API.get(`/stocks/Company-News/${coCode}`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getCompanyNewsDetails = async (srno) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/Company-News-Details/${srno}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getFundsCategory = async () => {
  try {
    const response = await CMOTS_API.get(`/mutualFunds/getFundsCategory`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getFundsPlanByCategory = async (category) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/getFundsPlansByCategory/${category}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getAllAmc = async () => {
  try {
    const response = await CMOTS_API.get(`/mutualFunds/getAllAMC`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getAllFunds = async () => {
  try {
    const response = await CMOTS_API.get(`/mutualFunds/GetAllFunds`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getAllRisk = async () => {
  try {
    const response = await CMOTS_API.get(`/mutualFunds/GetAllRisks`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getAdvancedRatiosData = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/GetAdvancedRatios/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getExpenseRatioData = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/getExpenseRatio/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getExitLoadData = async (coCode) => {
  try {
    const response = await CMOTS_API.get(`/mutualFunds/getExitLoad/${coCode}`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getFundManagerDetailsData = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/GetFundManagerDetails/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getFundManagerPortfolioData = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/GetFundManagerPortfolio/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getFundPlanBasicDetailsData = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/GetFundPlanBasicDetails/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getAssetAllocationDetailsData = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/GetAssetAllocationDetails/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getFundReturnsData = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/GetFundReturns/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getSectorAllocationDetailsData = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/GetSectorAllocationDetails/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getFundNavDetailsData = async (coCode, tenure) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/GetFundNAVDetails/${coCode}/${tenure}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getReturnPercentage = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/GetReturnPercentages/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getHoldingDetails = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/GetHoldingDetails/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getPeerComparisonData = async (coCode, assetClass, category) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/GetPeerComparison/${coCode}/${assetClass}/${category}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getFundRankingsData = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/getFundRankings/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getPlanCategoryReturnsData = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/mutualFunds/getFundRankings/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getYearlyResult = async (coCode, financeType) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/Yearly-Results/${coCode}/${financeType}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getHalfYearlyResult = async (coCode, financeType) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/Half-Yearly-Results/${coCode}/${financeType}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getStockQuaterlyResult = async (coCode, financeType) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/QuarterlyResults/${coCode}/${financeType}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getStockProfitAndLoss = async (coCode, financeType) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/ProftandLoss/${coCode}/${financeType}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getStockBalanceSheet = async (coCode, financeType) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/Balancesheet/${coCode}/${financeType}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getStockCashFlow = async (coCode, financeType) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/CashFlow/${coCode}/${financeType}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getStockExchange = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/market/stockListedExchanges/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getBseHoliday = async () => {
  try {
    const response = await CMOTS_API.get(`/market/ExchangeHolidays/BSE`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getNseHoliday = async () => {
  try {
    const response = await CMOTS_API.get(`/market/ExchangeHolidays/NSE`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getStockRatio = async (coCode) => {
  try {
    const response = await CMOTS_API.get(`/stocks/OverviewRatio/${coCode}/C`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getOptionChain = async (coCode, selectedDate) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/OptionChain/${coCode}/${selectedDate}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getExpiryDate = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/GetDerExpiryDate/FUTSTK/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getQuoteDerivatives = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/GetQuoteDerivatives/MW/FUTSTK/${coCode}`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getCompanyWiseHolding = async (coCode) => {
  try {
    const response = await CMOTS_API.get(
      `/stocks/CompanyWiseMFHolding/${coCode}/10`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

//fixed income futures and option api's will be used in future

export const getCorporateBondData = async (index) => {
  try {
    const response = await CMOTS_API.get(
      `/BondsTradingData/${index}/OTC%20Trades`
    );
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getDebtFundsGilts = async () => {
  try {
    const response = await CMOTS_API.get(`/DebtFunds/gilts`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getGiltFundsGilts = async () => {
  try {
    const response = await CMOTS_API.get(`/GiltFunds/giltl/10`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getGiltFundsGiltsShort = async () => {
  try {
    const response = await CMOTS_API.get(`/GiltFunds/gilts/10`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getNreFixedDeposits = async () => {
  try {
    const response = await CMOTS_API.get(`/FixedDepositNRENRO/NRE/-`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getNroFixedDeposits = async () => {
  try {
    const response = await CMOTS_API.get(`/FixedDepositNRENRO/NRO/-`);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const clientApi = async (payload) => {
  try {
    const response = await CMOTS_API.post("/other/client-info", payload);
    return { data: response.data, message: response.message };
  } catch (error) {
    return { error };
  }
};

export const verifyOtpGrievance = async (payload) => {
  try {
    const response = await MAIN_API.post("/notification/validateOtpGrievance", payload);
    return { data: response.data, message: response.message };
  } catch (error) {
    return { error };
  }
};

export const grievanceLeadApi = async (payload) => {
  try {
    const response = await MAIN_API.post("/lead/grievanceLead", payload);

    return { data: response.data, message: response.message };
  } catch (error) {
    return { error };
  }
};


import React, { useContext } from "react";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import { getComplienceDoc, getFileUrl } from "../../../utils/APICollection";
import { Context } from "../../../context";
import { AWS_S3_complience_doc } from "../../../utils/constants";
import Loader from "../../../components/Loader";

export const Escalation = () => {
  const { complienceData, setComplienceData, loading, setLoading } =
    useContext(Context);

  const getComplience = async () => {
    const data = await getComplienceDoc();
    setComplienceData(data?.data?.data);
  };
  React.useEffect(() => {
    getComplience();
  }, []);
  const getUrl = async (fileName) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { data } = await getFileUrl({
        file_name: fileName,
        folder_name: AWS_S3_complience_doc,
      });

      if (data?.statusCode === 200) {
        window.open(data?.data?.[0]?.file_url);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />

      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>Escalation Matrix</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent"
            id="Account"
            data-aos="fade-in"
            data-aos-offset="10"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Escalation Matrix for SMC</h3>
                  </div>
                  <div className="ipotable">
                    <div className="table-responsive m-0">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Details of </th>
                            <th>Contact Person</th>
                            <th>Address</th>
                            <th>Contact Number</th>
                            <th>Email ID </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Customer Care</td>
                            <td>Mr. Rohit Chhabra</td>
                            <td>
                              11/6B Shanti Chamber Pusa Road New Delhi 110005
                            </td>
                            <td>
                              <a href="tel:1800110909">1800-11-0909</a>
                              <br /> Working Hours:- 10:00 to 07:00 PM
                            </td>
                            <td>
                              <a
                                href="mailto:igc@smcindiaonline.com"
                                target="_blank"
                                data-kmt="1"
                              >
                                igc@smcindiaonline.com
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Head of Customer Care</td>
                            <td>Ms. Monika Kumar</td>
                            <td>
                              11/6B Shanti Chamber Pusa Road New Delhi 110005
                            </td>
                            <td>
                              <p>
                                <a href="tel:01130111000">011-30111000</a>
                              </p>
                              Extn. 666
                              <br /> Working Hours:- 10:00 to 07:00 PM
                            </td>
                            <td>
                              <a
                                href="mailto:monika@smcindiaonline.com"
                                target="_blank"
                                data-kmt="1"
                              >
                                monika@smcindiaonline.com
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Compliance Officer</td>
                            <td>Mr. Vikas Sethi</td>
                            <td>
                              11/6B Shanti Chamber Pusa Road New Delhi 110005
                            </td>
                            <td>
                              <a href="tel:01130111000">011-30111000</a> Extn.
                              340 <br /> Working Hours:- 10:00 to 07:00 PM
                            </td>
                            <td>
                              <a
                                href="mailto:vikassethi@smcindiaonline.com"
                                target="_blank"
                                data-kmt="1"
                              >
                                vikassethi@smcindiaonline.com
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>• Alt Compliance </td>
                            <td>Dev Roy Chowdhary</td>
                            <td></td>
                            <td>011-30111333</td>
                            <td>dp@smcindiaonline.com</td>
                          </tr>
                          <tr>
                            <td>• Compliance Officer, Mumbai</td>
                            <td>Divya Pramod</td>
                            <td></td>
                            <td>022-67341600</td>
                            <td>dp.mumbai@smcindiaonline.com</td>
                          </tr>
                          <tr>
                            <td>• Compliance Officer, Kolkata</td>
                            <td>Sanket Bera </td>
                            <td></td>
                            <td>033-66127000</td>
                            <td>dp@smcindiaonline.com</td>
                          </tr>
                          <tr>
                            <td>CEO </td>
                            <td>Mr. Ajay Garg</td>
                            <td>
                              11/6B Shanti Chamber Pusa Road New Delhi 110005
                            </td>
                            <td>
                              <a href="tel:01140753333">011-40753333</a>
                              <br /> Working Hours:- 10:00 to 07:00 PM
                            </td>
                            <td>
                              <a
                                href="mailto:ceo@smcindiaonline.com"
                                target="_blank"
                                data-kmt="1"
                              >
                                ceo@smcindiaonline.com
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="col-12 col-lg-12 ipos_txt mt-3 pull-left linkcolor">
                      <p>
                        In absence of response/complaint not addressed to your
                        satisfaction, you may lodge a complaint with SEBI at{" "}
                        <a
                          href="https://scores.gov.in/scores/Welcome.html"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://scores.gov.in/scores/Welcome.html
                        </a>{" "}
                        or respective Exchanges at
                        <br /> NSE:
                        <a
                          href="https://investorhelpline.nseindia.com/NICEPLUS/"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://investorhelpline.nseindia.com/NICEPLUS/
                        </a>{" "}
                        <br />
                        BSE{" "}
                        <a
                          href="https://bsecrs.bseindia.com/ecomplaint/frmInvestorHome.aspx"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://bsecrs.bseindia.com/ecomplaint/frmInvestorHome.aspx
                        </a>
                        , <br />
                        MCX:{" "}
                        <a
                          href="https://www.mcxindia.com/Investor-Services"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://www.mcxindia.com/Investor-Services
                        </a>
                        , <br />
                        NCDEX:{" "}
                        <a
                          href="https://ncdex.com/grievances"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://ncdex.com/grievances
                        </a>{" "}
                        <br />
                        CDSL:{" "}
                        <a
                          href="https://www.cdslindia.com/Footer/grievances.aspx"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://www.cdslindia.com/Footer/grievances.aspx
                        </a>
                        ,<br />
                        <a
                          href="https://www.epass.nsdl.com/complaints/websitecomplaints.aspx"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://www.epass.nsdl.com/complaints/websitecomplaints.aspx
                        </a>
                        ,<br /> MSEI:{" "}
                        <a
                          href="https://www.msei.in/Investors/Introduction"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://www.msei.in/Investors/Introduction
                        </a>
                        <br /> NSDL:
                        <a
                          href="https://nsdl.co.in"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://nsdl.co.in
                        </a>
                        <br /> RBI:
                        <a
                          href="https://www.rbi.org.in/home.aspx"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://www.rbi.org.in/home.aspx
                        </a>
                        <br /> IRDA:
                        <a
                          href="http://www.irdaindia.org"
                          target="_blank"
                          data-kmt="1"
                        >
                          http://www.irdaindia.org
                        </a>
                        <br />
                        AMFI :
                        <a
                          href="https://www.amfiindia.com"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://www.amfiindia.com
                        </a>
                        <br />
                        FMC:
                        <a
                          href="http://www.fmc.gov.in"
                          target="_blank"
                          data-kmt="1"
                        >
                          http://www.fmc.gov.in
                        </a>
                        <br />
                        Ministry of Finance :
                        <a
                          href="https://www.finmin.nic.in"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://www.finmin.nic.in
                        </a>
                        <br />
                        Government of India :
                        <a
                          href="https://www.india.gov.in"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://www.india.gov.in
                        </a>
                        <br />
                        Investor Grievances for DP :
                        <a
                          href="mailto:dp@smcindiaonline.com"
                          target="_blank"
                          data-kmt="1"
                        >
                          mailto:dp@smcindiaonline.com
                        </a>
                        <br />
                        Investor Awareness :
                        <a
                          href="http://www.old.smctradeonline.com/Static/careers.aspx"
                          target="_blank"
                          data-kmt="1"
                        >
                          http://www.old.smctradeonline.com/Static/careers.aspx
                        </a>
                        <br />
                        Investor Complaint Form BSE :
                        <a
                          href="https://www.bseindia.com/investors/Arbitrage.aspx?expandable=3"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://www.bseindia.com/investors/Arbitrage.aspx?expandable=3
                        </a>
                        <br />
                        SCORES :
                        <a
                          href="https://scores.gov.in/scores/Welcome.html"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://scores.gov.in/scores/Welcome.html
                        </a>
                        <br />
                        Enhancement of IDeAS and e – voting integration :
                        <a
                          href="https://eservices.nsdl.com"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://eservices.nsdl.com
                        </a>
                        <br />
                        ODR:
                        <a
                          href="https://smartodr.in/login"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://smartodr.in/login
                        </a>
                        <br />
                        Advisory for Investor :
                        <a
                          href="https://www.smctradeonline.com/advisory-for-investors"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://www.smctradeonline.com/advisory-for-investors
                        </a>
                        <br />
                        Investor Education Material :
                        <a
                          href="https://www.nseindia.com/invest/first-time-investor-fraud-dos-and-donts"
                          target="_blank"
                          data-kmt="1"
                        >
                          https://www.nseindia.com/invest/first-time-investor-fraud-dos-and-donts
                        </a>
                        {complienceData?.map((item, index) => {
                          return (
                            <div key={index} style={{ display: "flex" }}>
                              {item.doc_name}:
                              <p
                                style={{ color: "#0c47a1", cursor: "pointer" }}
                                onClick={() => {
                                  getUrl(item.doc_file);
                                }}
                              >
                                {item.doc_file}
                              </p>
                            </div>
                          );
                        })}
                        <br /> <br /> Please quote complaint Ref No. while
                        raising your complaint at SEBI SCORES/Exchange portal.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

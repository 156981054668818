import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const Faq = () => {
  return (
    <>
      <div
        className="webcent"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Frequently Asked Questions</h3>
              </div>
              <div className="faqcont">
                <Accordion allowZeroExpanded="true">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is technical analysis?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Technical analysis is a method used in the stock
                          market to predict future price movements based on
                          historical data.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are candlestick charts, and how are they used in
                        technical analysis?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Candlestick charts display price movements over a
                          specified time period. Each candle represents the
                          opening, closing, high, and low prices. Analysts use
                          candlestick patterns to identify trends and potential
                          reversals.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How do support and resistance levels work in technical
                        analysis?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Support levels are price levels at which a stock often
                          finds buying interest, preventing the price from
                          falling further. Resistance levels are where selling
                          interest tends to emerge. These levels help identify
                          potential turning points.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are moving averages, and how are they used?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Moving averages smooth out price data to create a
                          single flowing line. Traders use them to identify
                          trends and potential trend reversals. Common types
                          include the simple moving average (SMA) and the
                          exponential moving average (EMA).
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the Relative Strength Index (RSI), and how is it
                        used?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          The RSI is a momentum oscillator that measures the
                          speed and change of price movements. It ranges from 0
                          to 100 and is used to identify overbought or oversold
                          conditions, helping traders anticipate potential
                          reversals.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How do chart patterns like head and shoulders or
                        triangles assist in technical analysis?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Chart patterns, such as head and shoulders, triangles,
                          and flags, provide visual representations of price
                          movements. Analysts use these patterns to predict
                          future price movements and potential trend reversals.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the significance of volume in technical
                        analysis?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Volume measures the number of shares traded. High
                          volume often accompanies strong price movements,
                          confirming the validity of a trend. Volume analysis
                          helps traders assess the strength and sustainability
                          of a price movement.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How can trendlines be used in technical analysis?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                          Trendlines are lines drawn on a price chart to connect
                          successive lows or highs. They help identify the
                          direction of a trend and potential trend reversals.
                          Breakouts or breakdowns from trendlines can signal
                          entry or exit points.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;

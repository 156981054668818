import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import { NavLink } from "react-router-dom";
import { formatNumber } from "../../../../components/common";
import { Context } from "../../../../context";
import { useContext } from "react";


const StockFaqDetails = ({ bioData, quatesData, ratiosData }) => {
    const { stockExchange } = useContext(Context);
    const getDate = (date) => {
        let updatedDate = new Date(date);
        let monthList = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        return (
            updatedDate.getDate() +
            "-" +
            monthList[updatedDate.getMonth()] +
            "-" +
            updatedDate.getFullYear()
        );
    };
    return (
        <div className="webcent" itemscope="" itemtype="http://schema.org/FAQPage">
            <div className="title">
                <h3>Frequently Asked Questions</h3>
            </div>
            <div className="faqcont">
                <Accordion allowZeroExpanded="true">
                    <AccordionItem>
                        <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                            <AccordionItemHeading>
                                <AccordionItemButton itemProp="name">{`How to invest in ${stockExchange?.company_name || "NA"} share online ?`}</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                    <div itemProp="text">
                                        <p>
                                            You need a demat account to invest in  {stockExchange?.company_name || "NA"}. You can  <NavLink to={"https://www.smctradeonline.com/demat-account"}>open a demat account</NavLink> for free at SMC.
                                            And after KYC Completion you can start investing in  {stockExchange?.company_name || "NA"} shares.
                                        </p>
                                    </div>
                                </div>

                            </AccordionItemPanel>
                        </div>
                    </AccordionItem>
                    <AccordionItem>
                        <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                            <AccordionItemHeading>
                                <AccordionItemButton itemProp="name">
                                    {`What is  ${stockExchange?.company_name || "NA"} share price today ?`}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                    <div itemProp="text">
                                        <p>
                                            {stockExchange?.company_name} today is <i className="finr"></i>
                                            {(quatesData?.Price || quatesData?.price) && formatNumber(quatesData?.Price || quatesData?.price)} as on {getDate(new Date().toISOString().slice(0, 10))}.
                                        </p>
                                    </div>
                                </div>
                            </AccordionItemPanel>
                        </div>
                    </AccordionItem>
                    <AccordionItem>
                        <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                            <AccordionItemHeading>
                                <AccordionItemButton itemprop="name">
                                    {`What is the Market Cap of  ${stockExchange?.company_name} ?`}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                    <div itemProp="text">
                                        <p>
                                            The Market Cap of  {stockExchange?.company_name || "NA"} is <i className="finr"></i> {quatesData?.mcap && formatNumber(quatesData?.mcap)} Cr. as on {getDate(new Date().toISOString().slice(0, 10))}.
                                        </p>
                                    </div>
                                </div>
                            </AccordionItemPanel>
                        </div>
                    </AccordionItem>
                    <AccordionItem>
                        <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                            <AccordionItemHeading>
                                <AccordionItemButton itemprop="name">
                                    {`What is the PE and PB ratio of  ${stockExchange?.company_name || "NA"} ?`}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                    <div itemProp="text">
                                        <p>
                                            The PE and PB ratios of  {stockExchange?.company_name || "NA"} is {ratiosData?.PE_TTM} and {ratiosData?.PB_TTM} as of {getDate(new Date().toISOString().slice(0, 10))}.
                                        </p>
                                    </div>
                                </div>
                            </AccordionItemPanel>
                        </div>
                    </AccordionItem>
                    <AccordionItem>
                        <div itemprop="mainEntity" itemscope="" itemtype="http://schema.org/Question">
                            <AccordionItemHeading>
                                <AccordionItemButton itemprop="name" >
                                    What is the 52 Week High and Low of  {stockExchange?.company_name || "NA"} ?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="accordiancont" itemprop="acceptedAnswer" itemscope="" itemtype="http://schema.org/Answer">
                                    <div itemProp="text">
                                        <p>
                                            The 52-week high of  {stockExchange?.company_name || "NA"} is Rs. {quatesData?.HI_52_WK && formatNumber(quatesData?.HI_52_WK)} and the 52-week low is Rs. {quatesData?.LO_52_WK && formatNumber(quatesData?.LO_52_WK)}
                                        </p>
                                    </div>
                                </div>
                            </AccordionItemPanel>
                        </div>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    );
};

export default StockFaqDetails;

import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Context } from "../context";
import { getComplienceDoc } from "../utils/APICollection";
import { importantLinks } from "../utils/Data";

export default function ImportantLinks({
  setImpLinks,
  localImpLinks,
  setLocalImpLinks,
  isMobile,
}) {
  const { complienceData, setComplienceData } = React.useContext(Context);
  const handleChange = (event) => {
    setLocalImpLinks(event.target.value);
  };

  const getComplience = async () => {
    const data = await getComplienceDoc();
    setComplienceData(data?.data?.data);
  };
  React.useEffect(() => {
    getComplience();
  }, []);

  return (
    <>
      <FormControl
        sx={{ ml: 1, width: 200, color: "#FFF" }}
        size="small"
        variant="outlined"
      >
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={localImpLinks}
          IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
          label="Important Links"
          onChange={handleChange}
          sx={{ color: "#919191" }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 250,
                fontSize: 11,
              },
            },
          }}
        >
          {importantLinks?.map((item, index) => {
            return (
              <MenuItem value={item.doc_file} key={index}>
                {item.doc_name}
              </MenuItem>
            );
          })}
          {complienceData?.map((item, index) => {
            return (
              <MenuItem
                value={item.doc_file}
                key={index}
                onClick={() => setImpLinks(item.doc_file)}
              >
                {item.doc_name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
}

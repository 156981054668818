import { Shape } from "../../../images/svg/Shape";
import Icon01 from "../../../images/Instant-Account-Opening.webp";
import Icon02 from "../../../images/Years-of-Experience.webp";
import Icon03 from "../../../images/All-In-One-Mobile-App.webp";
import Icon04 from "../../../images/Higher-Leverage for-Trading.webp";
import Icon05 from "../../../images/Award-Winning-Research.webp";
import Icon06 from "../../../images/Portfolio-Restructuring-Advisory.webp";
import Icon07 from "../../../images/All-In-One-Mobile-App.webp";
import Icon08 from "../../../images/advance-training-programs.webp";
const StockWhyInvestInSmc = () => {
    return (
        <div className="webcent">
            <div className="title">
                <h3>Why invest in SMC?</h3>
            </div>
            <div className="reserchstn m-0 w-100 align-items-start">
                <div className="researchbox">
                    <div className="researchcircle">
                        <img src={Icon01} alt="" />
                        <Shape />
                    </div>
                    <div className="researchcont">
                        <h4>Instant Account Opening</h4>
                        <p>
                            Open your account within 15 minutes at your
                            convenience.
                        </p>
                    </div>
                </div>

                <div className="researchbox">
                    <div className="researchcircle">
                        <img src={Icon02} alt="" />
                        <Shape />
                    </div>
                    <div className="researchcont">
                        <h4>35+ Years of Experience</h4>
                        <p>
                            Comprehensive and time-tested strategies to
                            meet your needs
                        </p>
                    </div>
                </div>
                <div className="researchbox">
                    <div className="researchcircle">
                        <img src={Icon03} alt="" />
                        <Shape />
                    </div>
                    <div className="researchcont">
                        <h4>Personalised Relationship Manager</h4>
                        <p>
                            Expert guidance to analyse and meet your
                            financial goals
                        </p>
                    </div>
                </div>

                <div className="researchbox">
                    <div className="researchcircle">
                        <img src={Icon04} alt="" />
                        <Shape />
                    </div>
                    <div className="researchcont">
                        <h4>Higher Leverage for Trading</h4>
                        <p>
                            Leveraging your capital more significantly to
                            give your trading strategy an advantage
                        </p>
                    </div>
                </div>
                <div className="researchbox">
                    <div className="researchcircle">
                        <img src={Icon05} alt="" />
                        <Shape />
                    </div>
                    <div className="researchcont">
                        <h4>Award Winning Research</h4>
                        <p>
                            Providing the data you need to quickly achieve
                            your objectives
                        </p>
                    </div>
                </div>
                <div className="researchbox">
                    <div className="researchcircle">
                        <img src={Icon06} alt="" />
                        <Shape />
                    </div>
                    <div className="researchcont">
                        <h4>Portfolio Restructuring and Advisory</h4>
                        <p>
                            Timely and helpful interventions to implement
                            portfolio changes
                        </p>
                    </div>
                </div>
                <div className="researchbox">
                    <div className="researchcircle">
                        <img src={Icon07} alt="" />
                        <Shape />
                    </div>
                    <div className="researchcont">
                        <h4>All-In-One Mobile App</h4>
                        <p>All the trading at your fingertips</p>
                    </div>
                </div>
                <div className="researchbox">
                    <div className="researchcircle">
                        <img src={Icon08} alt="" />
                        <Shape />
                    </div>
                    <div className="researchcont">
                        <h4>
                            Access to Advance Training Program by SMC
                        </h4>
                        <p>
                            A program designed to help you grow your money
                            further!{" "}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StockWhyInvestInSmc;